import { GeneralMessageDialogComponent } from './../dialog/general-message-dialog/general-message-dialog.component';
import { Component, OnInit, ElementRef, AfterViewInit, HostListener, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { ToastUtil } from '../util/toast-util';
import { CommonUtil } from '../util/common-util';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NoticeDelDialogComponent } from './../dialog/notice-del-dialog/notice-del-dialog.component';
import { JobofferAdoptDialogComponent } from './../dialog/joboffer-adopt-dialog/joboffer-adopt-dialog.component';
import { PrcontentDetailDialogComponent } from './../dialog/prcontent-detail-dialog/prcontent-detail-dialog.component';
import { GeneralYesNoDialogComponent } from '../dialog/general-yes-no-dialog/general-yes-no-dialog.component';
import { environment } from './../../environments/environment';
import { ImageUtil } from './../util/image-util';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { MomentUtil } from '../util/moment-util';
import { sprintf } from 'sprintf-js';
import { TalentEditDialogComponent } from '../dialog/talent-edit-dialog/talent-edit-dialog.component';
import { TalentDelDialogComponent } from './../dialog/talent-del-dialog/talent-del-dialog.component';
import { MaterialTalentDialogComponent } from '../dialog/material-talent-dialog/material-talent-dialog.component';

@Component({
  selector: 'app-talent-detail',
  templateUrl: './talent-detail.component.html',
  styleUrls: ['./talent-detail.component.css'],
  providers: [Title]
})
export class TalentDetailComponent implements OnInit, AfterViewInit {
  private talentId: number;
  private csvFlg = 0;
  private inScroll = false;
  private unReadMessages = []; // 未読メッセージ管理用
  private htmlElement;
  private scrollContainer;
  private gettingMessageFlg = false;
  private scrolledLength = 0;
  private profileMaster = {};
  public showSpinner = true;
  public isMessageSending = false;
  public forceScrollFlag = true;
  public talent: any;
  public memo: string;
  public memoSchool: string;
  public memoCareer: string;
  public messages = [];
  public msgClickCnt  // メッセージ内クリックの総数
  public sendMessage = '';
  public sendMessageTitle = '';
  public isHideMessageDialog = true;
  public msgFileUploading = false; // 添付ファイルアップロード中
  public msgFileDeleting = false; // 添付ファイル削除中
  public msgFileDeletingId = 0;  // 添付ファイル削除中のID
  public msgFileName = null;
  public msgFilePath = null;
  public msgFileUrl = null;
  public msgConfirmFlg = false;
  public msgInputMode = false;  // CSVユーザー向けメール作成モード
  public readonly Constant = Constant;
  public isHideProgressSpinner: boolean;
  public isHideScrollMessage = false;
  public isHideStartMessage = true;
  public scrollMessageTop: number; // メッセージ領域のスクロール量
  public contactForm: FormGroup;
  public isContactEditMode = false;
  public contacts; // タレントの接点履歴
  public allContacts; // 全接点
  public selectableContacts; // 追加可能の接点
  public contactDate; // 接点追加時の登録日
  public contactDateMin; // 接点追加時の最小
  public contactDateMax; // 接点追加時の最大（本日）
  public talenttagForm: FormGroup;
  public isTalenttagEditMode = false;
  public talenttag;           // タレントのタレントタグ履歴
  public allTalenttag;        // 全タレントタグ
  public selectableTalenttag; // 追加可能のタレントタグ
  public jobOfferActions;
  public jobOfferView;
  public jobOfferViewAll;
  public jobOfferViewShow;
  public jobOfferShare;
  public jobOfferShareAll;
  public jobOfferReplyForm: FormGroup;
  public replyMaster;
  public offerActionTypeMaster;
  public employmentTypeMaster;
  public isReplyEditMode;
  public isReplySaving = false;
  public presentFormData = {};
  public contentsActionInfo;
  public contentsTagMaster;
  public materialActionInfo;
  public maxLenSendMessage = Constant.vlMaxSendMessage;
  public favoriteFlag: number;
  public favFlgSending = false; // ★ボタン連打無効に使用
  public msgFlgSending = false; // 除外リストボタン連打無効に使用
  public newGraduateYear = 0;
  public viewCntConfig = {
    jobview: 3,
    congood: 3,
    conview: 3,
    jobshare: 3,
    conshare: 3,
    mategood: 3,
    mateshare: 3,
    mateview: 3,
  };
  public contentsTitleMaxLength = 35;
  public sideCloseFlg = false;
  public newsDate;
  public memoTextMaxLen = 10000;
  public memoTypeTalent = 1;
  public memoTypeCareer = 2;
  public memoTypeSchool = 3;
  public memoUpdateType = null;

  // メッセージ添付ファイル用
  @ViewChild('msgFileInput') private msgFileInput: ElementRef;
  @ViewChildren('msgLists') private msgLists: QueryList<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private elementRef: ElementRef,
    private toastUtil: ToastUtil,
    private commonUtil: CommonUtil,
    private router: Router,
    private title: Title,
    private dialog: MatDialog,
    private imageUtil: ImageUtil,
    private http: HttpClient,
    private momentUtil: MomentUtil,
    private domSanitizer: DomSanitizer,
  ) {
    this.talentId = activatedRoute.snapshot.params['id'];
    this.csvFlg = this.activatedRoute.snapshot.data['csv_flag'];
    this.htmlElement = elementRef.nativeElement;
    this.memo = '';
    this.title.setTitle(Constant.pageTitleCommon);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  ngOnInit() {
    // タレント情報取得
    this.getTalentProfile();
    // コンテンツアクション取得 自己登録のみ
    if (this.csvFlg === Constant.tlRegistTypeTalent) {
      this.getContentsAction()
    }

    this.isHideMessageDialog = true;
    this.isHideProgressSpinner = true;

    // フォーム初期化
    this.initTalenttagtForm();
    this.initContactForm();
    this.jobOfferReplyForm = new FormGroup({});

    // 現在の新卒年度を計算
    this.newGraduateYear = this.commonUtil.getNewGraduateYear();
  }

  ngAfterViewInit() {
    this.scrollContainer = this.htmlElement.querySelector('.message-history');
    // 検索条件クリア
    localStorage.removeItem(Constant.lsTalentListCondition);

    // メッセージ内リンク既読表示
    this.msgLists.changes
      .subscribe(data => {
        setTimeout(() => {
          this.showMsgLinkClick()
          // this.showMsgFileClick()
        });
      });

    // プロフ更新用マスタ取得
    setTimeout(() => {
      this.getProfileMaster()
    }, 2000);

  }

  getTalentProfile() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    Auth.currentSession().then(session => {
      // プロフィール
      const apiPath = '/talent';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        id: this.talentId,
        csv_flag: this.csvFlg
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          // 基本情報無しの場合、エラーページにリダイレクト
          if (!res.data.base) {
            this.router.navigate(['error']);
            return;
          }
          this.talent = res.data;
          this.showSpinner = false;
          // HTMLの表示に使用する★フラグに取得したデータのフラグを代入する
          this.favoriteFlag = res.data.base.favorite_flag;
          this.title.setTitle(this.talent.base.name + Constant.pageTitleCommon);
          // 希望職種-職種のデータを使いやすい形式に整形する
          this.shapingDesireJobData();
          // 職歴-職種のデータを使いやすい形式に整形する
          this.shapingWorkCareerJobData();
          // 質問データの整形
          this.shapingQuestionData();

          // 画像取得
          const imageApiPath = Constant.apiNameTarent + Constant.apiNameUrl + Constant.apiPathComponent;
          const param = imageApiPath + this.talentId;

          this.commonUtil
            .apiGet(param, options)
            .then(image => {
              this.talent.base.image_path = image.data.url;
            })
            .catch(err => { });

          // メモ取得
          this.getMemo(options);

          // 求人アクション取得
          this.getJobofferAction(options);

          // タレントタグ情報取得
          this.getAllTalenttag(options);

          // 全接点リスト取得
          this.getAllContact(options);

          // メッセージ取得
          this.getMessage(options);

          // ニュース日付取得
          this.getNews();
        })
        .catch(err => {
          this.showSpinner = false;
        });
    });
  }

  /* 左メニュー */
  // 閉じる
  onClose() {
    window.close();

    setTimeout(() => {
      this.sideCloseFlg = true;
    }, 500);
  }

  /* タレント編集 */
  onEdit() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    if (Object.keys(this.profileMaster).length === 0) {
      return;
    }

    const dialogRef = this.dialog.open(TalentEditDialogComponent, {
      width: Constant.multiSelectDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      disableClose: true,
      panelClass: 'talentedit-dialog',
      data: {mode: Constant.csvTalentTypeEdit, talentId: this.talentId, csvFlg: this.csvFlg, master: this.profileMaster}
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res === Constant.csvTalentTypeDel) {
        // 削除済み
        this.onClose();

      } else if (res) {
        // 更新実行
        this.talent = null;
        this.getTalentProfile();
      }
    });
  }

  // タレント削除
  onDel() {
    const elm = <HTMLElement>document.activeElement;
    elm.blur();

    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    // 削除確認ダイアログ＆削除実行
    const dialogRef = this.dialog.open(TalentDelDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.msgConfirmCarteDel, talentId: this.talentId }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.afterDelTalent(res);
    });
  }

  // タレントカルテ削除後
  private afterDelTalent(res) {
    if (res === true) {
      // 削除成功
      const dialogRef = this.dialog.open(GeneralMessageDialogComponent, {
        width: Constant.dialogWidth,
        autoFocus: false,
        disableClose: true,
        data: Constant.msgCompleteCarteDel
      });
      dialogRef.afterClosed().subscribe(_res2 => {
        this.onClose();
      });
    } else if (res === false) {
      // 削除失敗
      const dialogRef = this.dialog.open(GeneralMessageDialogComponent, {
        width: Constant.dialogWidth,
        autoFocus: false,
        disableClose: true,
        data: sprintf(Constant.msgFailedCarteSave, Constant.csvTalentTypeDel)
      });
    }
  }

  // タレントカルテ登録用マスタ取得
  private getProfileMaster() {
    Auth.currentSession().then(session => {
      const apiPath = '/talent/master';
      const options = this.auth.createApiHeader(session);

      this.commonUtil.apiGet(apiPath, options).then(res => {
        res.data.forEach(element => {
          this.profileMaster[element.type] = element.data;
        });
      })
      .catch(err => {
      });
    });
  }

  onRequestForCaLin() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionRequest);
    window.open(Constant.requestForCalinUrl);
  }
  onCalinInformation() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionNews);
    window.open(Constant.calinInformationUrl);
  }
  onAgreement() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionAgreement);
    window.open(Constant.footerUrlAgreement);
  }
  onContact() {
    window.open(Constant.footerUrlContact);
  }
  onCalin() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionCompany);
    window.open(Constant.footerUrlCalin);
  }
  onPrivacy() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionPolicy);
    window.open(Constant.footerUrlPrivacy);
  }
  private getNews() {
    const baseDatetime = this.commonUtil.createCurrentDateString('', 'YYYY/MM/DD hh:mm:ss');
    const options = this.auth.createApiHeaderBeforeLoginForCorp();
    let apiPath = '/news?BaseDateTime=' + baseDatetime;
    apiPath = apiPath + '&LpShowFlag=1';

    this.commonUtil.apiGetForCorp(apiPath, options).then(res => {
      if (res.data[0]) {
        this.newsDate = res.data[0].ShowDateFormat;
      }
    })
    .catch(err => {
      this.commonUtil.debug().log(err);
    });
  }

  // メモ取得
  private getMemo(options) {
    let apiPath = '/talent/memo/' + this.talentId;
    this.commonUtil
      .apiGet(apiPath, options)
      .then(res => {
        this.memo = res.data.comment;
      })
      .catch(err => { });

    apiPath = '/talent/memo/school/' + this.talentId;
    this.commonUtil
      .apiGet(apiPath, options)
      .then(res => {
        this.memoSchool = res.data.comment;
      })
      .catch(err => { });

    apiPath = '/talent/memo/career/' + this.talentId;
    this.commonUtil
      .apiGet(apiPath, options)
      .then(res => {
        this.memoCareer = res.data.comment;
      })
      .catch(err => { });
  }

  // メッセージ取得
  private getMessage(options, date?: string) {
    // ネットワークチェック
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      this.isHideProgressSpinner = true;
      return;
    }

    let apiPath = '/talent/message/' + this.talentId;
    if (date) {
      apiPath += '/' + date;
    }
    this.commonUtil
      .apiGet(apiPath, options)
      .then(res => {
        this.getMessageExec(res, date);
      })
      .catch(err => {
        this.isHideProgressSpinner = true;
        this.showErrorToast(Constant.msgNetworkError);
      });
  }

  // メモ更新
  onMemoUpdate(memo: string, type: Number) {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionMemo);

    // ネットワークチェック
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    this.memoUpdateType = type;
    memo = this.commonUtil.replaceSpace(memo);
    let apiPath;
    if (type === this.memoTypeTalent) {
      this.memo = memo;
      apiPath = '/talent/memo/' + this.talentId.toString();
    } else if(type === this.memoTypeCareer) {
      this.memoCareer = memo;
      apiPath = '/talent/memo/career/' + this.talentId.toString();
    } else {
      this.memoSchool = memo;
      apiPath = '/talent/memo/school/' + this.talentId.toString();

    }
    Auth.currentSession().then(session => {
      // プロフィール
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        comment: memo
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          const mstMemoUpdated = 'メモを更新しました。';
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showInformationToast('', mstMemoUpdated, Constant.toastShowMiliSec);
          this.upateProfileByCompanyDate();
          this.memoUpdateType = null;
        })
        .catch(err => {
          const mstMemoUpdated = 'メモの更新に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', mstMemoUpdated, Constant.toastShowMiliSecErr);
          this.memoUpdateType = null;
        });
    });
  }

  // メモ更新時にプロフィールの更新日時も更新
  private upateProfileByCompanyDate() {
    this.talent.base.updated_at_by_company = this.momentUtil.createTodaySlash();
    this.talent.base.profile_by_c_elapsed_days = 0;
  }

  // メッセージダイアログを表示する
  onShowMessageDialog() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionMessageOpen);

    this.isHideMessageDialog = false;
    setTimeout(() => {
      // メッセージビューを非表示にしているとscrollHeightが常に0になってしまうので、このタイミングで取得する
      this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;

      // 初期表示の既読チェック
      this.checkUnreadMessage()
    }, 1);
  }
  // メッセージダイアログを閉じる
  onHideMessageDialog() {
    this.isHideMessageDialog = true;
  }
  // メッセージ送信
  onSendMessage() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionMessageSend);

    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      this.isMessageSending = false;
      return;
    }
    if (this.isMessageSending) {
      return;
    }
    this.isMessageSending = true;
    this.isHideProgressSpinner = false;
    Auth.currentSession().then(session => {
      const apiPath = '/talent/message/' + this.talentId.toString();
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        message: this.commonUtil.replaceSpace(this.sendMessage),
        title: this.commonUtil.replaceSpace(this.sendMessageTitle),
        csv_flag: this.talent.base.csv_flag
      };
      if (this.msgFilePath) {
        options['body']['attached_file_path'] = this.msgFilePath;
        options['body']['attached_file_name'] = this.msgFileName;
      }

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          if (this.talent.base.csv_flag === Constant.tlRegistTypeTalent) {
            // 自己登録タレントはトースト
            const msgUpdateMsg = 'メッセージを送信しました。';
            this.toastUtil.clearAllShowingToast();
            this.toastUtil.showInformationToast('', msgUpdateMsg, Constant.toastShowMiliSec);

            this.clearMsgAndReflesh();

          } else {
            // CSVタレントにはダイアログ
            const dialogRef = this.dialog.open(GeneralMessageDialogComponent, {
              width: Constant.dialogWidth,
              autoFocus: false,
              disableClose: false,
              data: Constant.msgCompleteMailSend
            });
            dialogRef.afterClosed().subscribe(res => {
              this.clearMsgAndReflesh();
            });
          }

        })
        .catch(err => {
          let message;
          let disableClose = false;
          if (err.data && err.data.deleted && this.talent.base.csv_flag === Constant.tlRegistTypeTalent) {
            // プール削除済み
            message = Constant.msgErrorSendMessageNoPool;
          } else if (err.message === Constant.msgSendErrorSameText) {
            // 同一テキストの送信の場合
            message = Constant.msgSendErrorSendRepeat;
          } else if (err.message === Constant.msgErrorSendMessage) {
            // その他エラー
            message = Constant.msgErrorSendMessage;
          } else {
            // タイムアウト等でレスポンスを受信出来なかった場合
            message = Constant.msgSendErrorLong;
            disableClose = true;
          }
          const dialogRef = this.dialog.open(GeneralMessageDialogComponent, {
            width: Constant.dialogWidth,
            autoFocus: false,
            disableClose: disableClose,
            data: message
          });
          dialogRef.afterClosed().subscribe(res => {
            this.isMessageSending = false;
            this.isHideProgressSpinner = true;
            this.clearMsgFileData();
            if (err.message && err.message === Constant.msgSendErrorSameText) {
              this.sendMessage = '';
              this.sendMessageTitle = '';
              this.getMessage(options);
            }
          });
        });
    });
  }

  onMessageReflesh() {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      this.isHideProgressSpinner = true;
      return;
    }
    this.isHideProgressSpinner = false;
    Auth.currentSession().then(session => {
      // メッセージ取得
      const options = this.auth.createApiHeader(session);
      this.getMessage(options);
    });
  }

  // メッセージリンク化の注意書き
  onMessageInfo() {
    this.dialog.open(GeneralMessageDialogComponent, {
      width: Constant.msgUrlInfoDialogWidth,
      autoFocus: false,
      data: Constant.carteMsgInfoAboutLink
    });

  }
  // 既読チェック
  private readMessage(id: number) {
    Auth.currentSession().then(session => {
      const apiPath = '/talent/message/' + this.talentId;
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        message_id: id
      };

      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => { })
        .catch(err => { });
    });
  }
  // メッセージビューがスクロールされた時に呼ばれる
  onMessageScroll() {
    // メッセージダイアログが非表示の場合は表示確認しない
    if (this.isHideMessageDialog) {
      return;
    }

    if (this.inScroll) {
      return;
    }

    // 次のメッセージを取得する
    if (this.scrollContainer.scrollTop < 100 && !this.gettingMessageFlg && this.messages.length > 0) {
      this.isHideProgressSpinner = false;
      this.gettingMessageFlg = true;
      Auth.currentSession().then(session => {
        const options = this.auth.createApiHeader(session);
        this.getMessage(options, this.messages[0].created_at_full);
      });
    }

    // 未読メッセージのチェック、既読登録
    this.checkUnreadMessage()
  }

  // 未読メッセージのチェック、既読登録
  private checkUnreadMessage() {
    if (this.unReadMessages.length === 0) {
      // 未読のメッセージがない場合は終了
      this.inScroll = false;
      return;
    }
    this.inScroll = true;
    // メッセージダイアログの上端Y座標
    const scrollContainerY = this.scrollContainer.scrollTop + this.scrollContainer.offsetTop;
    const newUnReadMessages = [];

    // 未読メッセージをループして表示確認
    this.unReadMessages.forEach(element => {
      const messageItem = this.htmlElement.querySelector('.message-id-' + element);
      // メッセージ要素の上端Y座標 + メッセージ要素の高さの3割
      const messageY = messageItem.offsetTop + (messageItem.clientHeight * 0.3);
      // メッセージ要素の約2/3がメッセージダイアログに表示されたら既読とする
      if (messageY > scrollContainerY) {
        // 表示されたので既読にする
        this.readMessage(element);
      } else {
        newUnReadMessages.push(element);
      }
    });
    this.unReadMessages = newUnReadMessages;
    this.inScroll = false;
  }

  // タレントから送信されたメッセージのうち、未読のものを取得する
  private setUnreadMessage() {
    this.unReadMessages = [];
    this.messages.forEach(element => {
      if (element.readed_flag === Constant.msgUnReaded && element.type_id === Constant.tdMsgTypeIdTalent) {
        this.unReadMessages.push(element.id);
      }
    });
  }
  // CSVタレントのメッセージ作成画面切り替え
  onMailInputMode(enable) {
    this.msgInputMode = enable;
  }

  private clearMsgAndReflesh() {
    this.sendMessage = '';
    this.sendMessageTitle = '';
    this.clearMsgFileData();
    this.msgInputMode = false;
    this.isMessageSending = false;

    this.onMessageReflesh();
  }


  // 取得した希望職種のデータを使用しやすい形に整形する
  private shapingDesireJobData() {
    if (this.talent.desirejob) {
      let currentLargeItem = '';
      const desirejobAry = [];
      let desirejobLargeItemDic = {};
      let desirejobItemAry = [];
      for (const key in this.talent.desirejob) {
        if (this.talent.desirejob[key].large_item_value !== currentLargeItem) {
          // 新しい大分類のレコードだった場合
          if (desirejobItemAry.length > 0 && Object.keys(desirejobLargeItemDic).length !== 0) {
            // いままで格納してきた希望職種データを使用するための配列に格納する
            desirejobLargeItemDic['item_values'] = desirejobItemAry;
            desirejobAry.push(desirejobLargeItemDic);
            desirejobLargeItemDic = {};
            desirejobItemAry = [];
          }

          currentLargeItem = this.talent.desirejob[key].large_item_value;
          desirejobLargeItemDic['large_item'] = currentLargeItem;
          desirejobItemAry.push(this.talent.desirejob[key].item_value);
        } else {
          // 前回が同じ大分類だった場合
          desirejobItemAry.push(this.talent.desirejob[key].item_value);
        }
      }
      // 最後のデータも格納する
      if (desirejobItemAry.length > 0) {
        desirejobLargeItemDic['item_values'] = desirejobItemAry;
        desirejobAry.push(desirejobLargeItemDic);
      }
      this.talent['desirejobs'] = desirejobAry;
    }
  }
  // 取得した職歴の職種を使用しやすい形に整形する
  private shapingWorkCareerJobData() {
    if (this.talent.work_career) {
      let careerjobAry = [];
      for (const wcIndex in this.talent.work_career) {
        if (this.talent.work_career[wcIndex] !== null) {
          let currentLargeItem = '';
          let careerjobLargeItemDic = {};
          let careerjobItemAry = [];
          const currentId = this.talent.work_career[wcIndex].id;
          for (const djIndex in this.talent.wc_desirejob) {
            // word_careerとwc_desirejobでidが一致するレコードを判別する
            if (this.talent.wc_desirejob[djIndex].id === currentId) {
              if (this.talent.wc_desirejob[djIndex].large_item_value !== currentLargeItem) {
                // 新しい大分類のレコードだった場合
                if (careerjobItemAry.length > 0 && Object.keys(careerjobLargeItemDic).length !== 0) {
                  // いままで格納してきた職種データを使用するための配列に格納する
                  careerjobLargeItemDic['item_values'] = careerjobItemAry;
                  careerjobAry.push(careerjobLargeItemDic);
                  careerjobLargeItemDic = {};
                  careerjobItemAry = [];
                }

                currentLargeItem = this.talent.wc_desirejob[djIndex].large_item_value;
                careerjobLargeItemDic['large_item'] = currentLargeItem;
                careerjobItemAry.push(this.talent.wc_desirejob[djIndex].item_value);
              } else {
                // 前回が同じ大分類だった場合
                careerjobItemAry.push(this.talent.wc_desirejob[djIndex].item_value);
              }
            }
          }
          if (careerjobItemAry.length > 0) {
            careerjobLargeItemDic['item_values'] = careerjobItemAry;
            careerjobAry.push(careerjobLargeItemDic);
          }
          this.talent.work_career[wcIndex]['careerjobs'] = careerjobAry;
          careerjobAry = [];
        }
      }
    }
  }
  // 取得した質問のデータを使用しやすい形に整形する
  private shapingQuestionData() {
    if (this.talent.question?.length > 0) {
      // question_id別の配列作成
      const tmp = this.talent.question.reduce(function(rv, x) {
        (rv[x['question_id']] = rv[x['question_id']] || []).push(x['select_text'] ? x['select_text'] : '選択肢 管理ID（' + x['item_no'] + '）');
          return rv;
      }, {});

      // question_idの重複を削除
      this.talent.question = this.talent.question.filter((item, index, self) => {
        const qlist = self.map(item => item['question_id']);
        // 重複を削除する
        if (qlist.indexOf(item.question_id) === index) {
          return item;
        }
      });

      // 選択肢配列を元の配列に組み込む
      this.talent.question.forEach(element => {
        if (element['type'] !== Constant.aqAnswerTypeTextId) {
          element['select_text'] = tmp[element['question_id']];
        }
      });
    }
  }

  // メッセージのスクロール
  private setMessageDivScroll() {
    this.scrollMessageTop = this.scrollContainer.scrollHeight - this.scrolledLength;
    this.scrollContainer.scrollTop = this.scrollMessageTop;
  }

  // メッセージAPI取得後の処理
  getMessageExec(data, date?) {
    this.isHideProgressSpinner = true;
    if (data.status === Constant.OK) {
      // 取得結果が10件なければメッセージを切り替える
      const msgs = data.data.msg
      if (msgs.length < 10) {
        this.isHideScrollMessage = true;
        this.isHideStartMessage = false;
      } else {
        this.isHideScrollMessage = false;
        this.isHideStartMessage = true;
      }

      // message内のURLをリンクに変換
      this.convertMessageLink(msgs)

      // message内添付ファイル情報を整形
      this.convertMessageFile(msgs)

      this.msgClickCnt = data.data.click

      if (this.messages.length > 0 && msgs.length > 0 && date) {
        // インフィニティスクロールで追加取得時
        this.messages = msgs.concat(this.messages);
        // スクロール量の保持
        this.scrolledLength = this.scrollContainer.scrollHeight - this.scrollContainer.scrollTop;

        this.gettingMessageFlg = false;
      } else if (msgs.length > 0) {
        // 初回取得時 or メッセージ送信時
        this.messages = msgs;
        this.scrolledLength = 0;
        this.gettingMessageFlg = false;
      } else if (this.messages.length > 0) {
        // データが無い場合もスクロール量は保持する
        this.scrolledLength = this.scrollContainer.scrollHeight - this.scrollContainer.scrollTop;
      }
      this.setUnreadMessage();
      this.msgFileDeleting = false;
      this.msgFileDeletingId = 0;
      setTimeout(() => {
        this.setMessageDivScroll();
      }, 1);
    }
  }

  // メッセージ内URLのリンク化
  private convertMessageLink(data) {
    var regexp_makeLink = function(url, messageId, index) {
      return '<a href="' + url + '" target="_blank" rel="noopener noreferrer" id="msg-' + messageId + '-' + index + '">' + url + '</a>';
    }

    data.forEach(element => {
      if (element.message) {
        const urlAllMatches = element.message.match(Constant.msgUrlRegExp);
        if (urlAllMatches !== null){
          let index = 0;

          let newMessage = ''
          let oldMessage = element.message
          urlAllMatches.forEach(url => {
            index++
            const tmp = oldMessage.replace(url, regexp_makeLink(url, element.id, index))

            // </a>で分割
            const newMessageAry = tmp.split('</a>', 2)
            newMessage += newMessageAry[0] + '</a>'
            if (newMessageAry.length>1) {
              oldMessage = newMessageAry[1]
            } else {
              oldMessage = ''
            }
          });
          newMessage += oldMessage
          element.message = this.domSanitizer.bypassSecurityTrustHtml(newMessage)
        }
      }
    });
  }

  // メッセージURL横に既読表示
  private showMsgLinkClick() {
    this.messages.forEach(msg => {
      if (msg.link_no) {
        let show_cnt = 0  // 表示済みをカウント
        const link_ary = msg.link_no.split(',')
        link_ary.forEach(link_no => {
          const atag = document.getElementById('msg-' + msg.id + '-' + link_no)
          if (atag) {
            const top = atag.offsetTop;
            const text = document.createElement('div');
            text.textContent = '既読';
            text.style.top = top + 'px'
            text.style.position = 'absolute'
            text.style.left = '-30px'
            text.style['font-size'] = '11px'
            text.style.color = 'rgba(0, 0, 0, 0.3)'

            // 指定した要素の中の末尾に挿入
            atag.before(text);
            show_cnt++
          }

        });

        if (link_ary.length === show_cnt) {
          // 再描画しないよう、link_noを消しておく
          msg.link_no = null
        }
      }
    });
  }

  // // 添付ファイルの既読表示
  // private showMsgFileClick() {
  //   this.messages.forEach(msg => {
  //     if (msg.file_no) {
  //       // 現状1つのみ
  //       const fileDiv = document.getElementById('msg-file-id-' + msg.id)
  //       if (fileDiv) {
  //         const top = fileDiv.offsetTop + fileDiv.offsetHeight / 2 - 8;
  //         const text = document.createElement('div');
  //         text.textContent = '既読';
  //         text.style.top = top + 'px'
  //         text.style.position = 'absolute'
  //         text.style.left = '-50px'
  //         text.style['font-size'] = '11px'
  //         text.style.color = 'rgba(0, 0, 0, 0.3)'

  //         // 指定した要素の中の末尾に挿入
  //         fileDiv.before(text);

  //         // 再描画しないように削除
  //         msg.file_no = null
  //       }
  //     }
  //   });
  // }

  // メッセージ添付ファイル情報を整理
  private convertMessageFile(data) {
    data.forEach(element => {
      if (element.attached_file_ids) {
        const ids = element.attached_file_ids.split(',').map(Number)
        const names = element.attached_file_name.split(',')
        const paths = element.attached_file_path.split(',')
        const del_flg = element.attached_delete_flag.split(',').map(Number)
        const err_flg = element.attached_error_flag.split(',').map(Number)

        const fileAry = []
        ids.forEach((id, index) => {
          fileAry.push({
            attached_file_id: id,
            attached_file_name: names[index],
            attached_file_path: paths[index],
            attached_delete_flag: del_flg[index],
            attached_error_flag: err_flg[index],
          })
        });

        element.attached_file = fileAry
      }
    });
  }

  /* 接点管理 */
  // 全接点取得
  private getAllContact(options) {
    const apiPath = '/talent/contacttag/taglist';
    this.commonUtil
      .apiGet(apiPath, options)
      .then(res => {
        this.allContacts = res.data;
        // タレント別接点履歴取得
        this.getContact(options)
      })
      .catch(err => { });
  }

  // 接点履歴取得
  private getContact(options) {
    const apiPath = '/talent/contacttag/' + this.talentId;
    this.commonUtil
      .apiGet(apiPath, options)
      .then(res => {
        this.contacts = res.data;
        this.setContactSelect();
      })
      .catch(err => { });
  }

  // 編集
  onContactEdit() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionKarteContactEdit);
    this.isContactEditMode = true;
    this.contactForm.reset();
    this.contactDate = null;
  }

  // 閉じる
  onContactSave() {
    this.isContactEditMode = false;
  }

  // 削除
  onContactDelete(contactId) {
    // 確認ダイアログ表示
    const dialogRef = this.dialog.open(NoticeDelDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { type: Constant.noticeTypeTalentDel }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delContactExec(contactId);
      }
    });
  }

  // 追加
  onContactAdd() {
    this.addContactExec();
  }

  // 記事詳細画面表示
  onContentDetail(index) {
    let prContent;
    prContent = this.contentsActionInfo.contents[index];

    const dialogRef = this.dialog.open(PrcontentDetailDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      data: {
        index: index,
        id: prContent.id,
        prContent: prContent,
        viewMode: true
      }
    });
  }

  // 資料詳細ダイアログ表示
  onMaterialDetail(index) {
    if (!this.commonUtil.checkOnline()) {
      return
    }

    let material;
    material = this.materialActionInfo.view_detail[index];

    const dialogRef = this.dialog.open(MaterialTalentDialogComponent, {
      width: '700px',
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      data: {
        id: material.id,
        talent_id: this.talentId
      }
    });
  }

  /* タレントタグ管理 */
  // 全タレントタグ取得
  private getAllTalenttag(options) {
    const apiPath = '/talenttag/master';
    this.commonUtil.apiGet(apiPath, options).then(res => {
      this.allTalenttag = res.data;
      // 追加済みタレントタグ取得
      this.getTalenttag(options)
    })
    .catch(err => { });
  }

  // 追加済みタレントタグ取得
  private getTalenttag(options) {
    const apiPath = '/talent/talenttag/' + this.talentId;
    this.commonUtil.apiGet(apiPath, options).then(res => {
      this.talenttag = res.data;
      this.setTalenttagSelect();
    })
    .catch(err => { });
  }

  // 選択可能なタレントタグの一覧作成
  private setTalenttagSelect() {
    if (this.talenttag && this.allTalenttag) {
      this.selectableTalenttag = this.allTalenttag;
      this.talenttag.forEach(element => {
        this.selectableTalenttag = this.selectableTalenttag.filter(function (element2) {
          return element2.id !== element.talenttag_id;
        });
      });
    }
  }

  // タレントタグ編集
  onTalenttagEdit() {
    this.isTalenttagEditMode = true;
    this.talenttagForm.reset();
  }

  // 閉じる
  onTalenttagClose() {
    this.isTalenttagEditMode = false;
  }

  // タレントタグ追加
  onTalenttagAdd() {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    const tagId = this.talenttagForm.value['title'];

    this.talenttagForm.reset();

    Auth.currentSession().then(session => {
      const apiPath = '/talent/talenttag/' + this.talentId;
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        talenttag_id: tagId
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showInformationToast('', Constant.msgTalenttagAdd, Constant.toastShowMiliSec);

          // 再読み込み
          options['body'] = null;
          this.getTalenttag(options);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.clearAllShowingToast();
          if (err.message === Constant.msgTalenttagAddFailed) {
            this.toastUtil.showErrorToast('', Constant.msgTalenttagAddFailed, Constant.toastShowMiliSecErr);
          } else {
            this.toastUtil.showErrorToast('', Constant.msgAddError, Constant.toastShowMiliSec);
          }
        });
    });
  }

  // タレントタグ削除
  onTalenttagDelete(tagId) {
    // 確認ダイアログ表示
    const dialogRef = this.dialog.open(NoticeDelDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { type: Constant.noticeTypeTalenttagDel }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delTalenttagExec(tagId);
      }
    });
  }

  // タレントタグ追加フォーム
  private initTalenttagtForm() {
    this.talenttagForm = new FormGroup({
      title: new FormControl('', [Validators.required])
    });
  }

  // タレントタグ削除実行
  private delTalenttagExec(tagId) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = '/talent/talenttag/' + this.talentId;
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        id: tagId
      };

      this.commonUtil
        .apiDel(apiPath, options)
        .then(res => {
            this.toastUtil.clearAllShowingToast();
            this.toastUtil.showInformationToast('', Constant.msgTalenttagDel, Constant.toastShowMiliSec);

            // 再読み込み
            options['body'] = null;
            this.getTalenttag(options);
        })
        .catch(err => {
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', Constant.msgDeleteError, Constant.toastShowMiliSecErr);
        });
    });
  }

  /* プライベート */
  // フォーム作成
  // 接点管理用フォーム
  private initContactForm() {
    this.contactForm = new FormGroup({
      contactTitle: new FormControl('', [Validators.required]),
      contactDate: new FormControl({ value: '', disabled: true }, [Validators.required])
    });

    // カレンダー最小値、最大値
    this.contactDateMin = new Date(1930, 0, 1);
    this.contactDateMax = new Date();
  }

  // 求人アクション取得
  private getJobofferAction(options) {
    let apiPath = '/talent/jobofferaction/' + this.talentId;
    this.commonUtil
      .apiGet(apiPath, options)
      .then(actions => {
        this.jobOfferActions = actions.data;
        // マスタ情報を取得
        apiPath = '/talent/jobofferaction/master';
        this.commonUtil
          .apiGet(apiPath, options)
          .then(actionMasters => {
            this.commonUtil
              .apiGet('/joboffer/master', options)
              .then(jobofferMasters => {
                if (!this.replyMaster && !this.offerActionTypeMaster) {
                  this.createMasterData(actionMasters.data, jobofferMasters.data);
                }
                this.convertOfferActionMasterIdToText();
                // 求人アクション・対応のフォーム
                this.initJobOfferReplyForm();
                this.isReplyEditMode = false;
                this.isReplySaving = false;
              })
              .catch(err => {
                this.isReplyEditMode = false;
                this.isReplySaving = false;
                this.showErrorToast(Constant.msgNetworkError);
                this.commonUtil.debug().log(err);
              });
          })
          .catch(err => {
            this.isReplyEditMode = false;
            this.isReplySaving = false;
            this.showErrorToast(Constant.msgNetworkError);
            this.commonUtil.debug().log(err);
          });
      })
      .catch(err => {
        this.isReplyEditMode = false;
        this.isReplySaving = false;
        this.showErrorToast(Constant.msgNetworkError);
        this.commonUtil.debug().log(err);
      });

      // 求人閲覧回数取得
      apiPath = '/talent/jobofferview/' + this.talentId;
      this.commonUtil.apiGet(apiPath, options)
      .then(res => {
        this.jobOfferViewAll = res.data.view_cnt_all;
        this.jobOfferView = res.data.view_cnt;
        this.jobOfferShareAll = res.data.share_cnt_all;
        this.jobOfferShare = res.data.share_cnt;
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
      });
  }

  // コンテンツアクション情報取得
  private getContentsAction() {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    Auth.currentSession().then(session => {
      // プロフィール
      const apiPath = '/talent/contentsaction';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        talent_id: this.talentId
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.contentsActionInfo = res.data.contents;
          this.materialActionInfo = res.data.document;
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
        });
    });
  }

  // 取得したマスターデータをアクション、対応に分割する
  private createMasterData(actionMasters, jobofferMasters) {
    for (const actionMaster of actionMasters) {
      if (actionMaster.type === Constant.offerReactType) {
        this.replyMaster = actionMaster.data;
      }
      if (actionMaster.type === Constant.offerActionType) {
        this.offerActionTypeMaster = actionMaster.data;
      }
    }
    for (const jobofferMaster of jobofferMasters) {
      if (jobofferMaster.type === Constant.joEmploymentType) {
        this.employmentTypeMaster = jobofferMaster.data;
      }
    }
  }

  // 求人アクションのテキストを作成する
  private convertOfferActionMasterIdToText() {
    for (const jobOfferAction of this.jobOfferActions) {
      for (const master of this.offerActionTypeMaster) {
        if (jobOfferAction.action_kind === master.id) {
          jobOfferAction.action_text = master.item_value;
        }
      }
    }
  }

  // 求人アクション用フォーム
  private initJobOfferReplyForm() {
    const replyControl = {};
    for (const jobOfferAction of this.jobOfferActions) {
      // FormControlの作成
      const keyString: string = jobOfferAction.id;
      replyControl[keyString] = new FormControl(jobOfferAction.reply_kind, [Validators.required]);
      // jobOfferActionsに対応テキストの設定
      for (const reply of this.replyMaster) {
        if (jobOfferAction.reply_kind === reply.id) {
          jobOfferAction.reply_text = reply.item_value;
          // 取得時の対応内容を退避
          this.presentFormData[keyString] = jobOfferAction.reply_kind;
          break;
        }
      }
    }
    this.jobOfferReplyForm = new FormGroup(replyControl);
  }

  onActionReply(editMode) {
    if (editMode === Constant.offerReplyEditOn) {
      // 編集モード
      this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionKarteApplyEdit);
      this.isReplyEditMode = true;

      // 編集モード時にキャンセルがタップされた時のために、編集前の対応値を退避しておきたいが、
      // 画面表示後に初めて編集がタップされた場合にはform.valueが取得できない。
      // そのため、まずデータをサーバから取得した時に対応値を退避しておき、２回目以降は
      // form.valueから取得した値を退避するようにする
      const formValue = this.jobOfferReplyForm.value;
      const formKeys = Object.keys(formValue).filter(function (key) {
        return formValue[key] === '';
      });
      if (formKeys.length === 0) {
        this.presentFormData = this.jobOfferReplyForm.value;
      }
      return;
    }

    if (editMode === Constant.offerReplyEditCancel) {
      // 編集モードキャンセル
      this.jobOfferReplyForm.setValue(this.presentFormData);
      this.isReplyEditMode = false;
      return;
    }

    // 対応を更新
    const keys = Object.keys(this.jobOfferReplyForm.value);
    const replyParams = [];
    const jobOfferReplyAdoptIds = [];
    for (const key of keys) {
      let join_plan_date = null;
      if (
        this.presentFormData[key] === Constant.offerReplyAdopt &&
        this.jobOfferReplyForm.value[key] !== Constant.offerReplyAdopt
      ) {
        // 採用合意 → 採用合意以外
        join_plan_date = null;
      } else if (
        this.presentFormData[key] === Constant.offerReplyAdopt &&
        this.jobOfferReplyForm.value[key] === Constant.offerReplyAdopt
      ) {
        // 採用合意 → 採用合意(変更なし)
        for (const action of this.jobOfferActions) {
          if (action.id === Number(key)) {
            // 変更前の入社予定日を格納しておく
            join_plan_date = action.join_plan_date;
          }
        }
      } else if (
        this.presentFormData[key] !== Constant.offerReplyAdopt &&
        this.jobOfferReplyForm.value[key] === Constant.offerReplyAdopt
      ) {
        // 採用合意以外 → 採用合意
        // この後の処理で入力された日付を入社予定日に格納するので、
        // ここでは一旦nullに設定しておく
        jobOfferReplyAdoptIds.push(Number(key));
        join_plan_date = null;
      } else {
        // 採用合意以外→採用合意以外
        join_plan_date = null;
      }
      replyParams.push({
        id: key,
        reply_kind: this.jobOfferReplyForm.value[key],
        join_plan_date: join_plan_date
      });
    }

    // "対応済：採用"に変更された求人があるかチェック
    if (jobOfferReplyAdoptIds.length > 0) {
      // 採用合意のダイアログを表示する
      const dialogRef = this.dialog.open(JobofferAdoptDialogComponent, {
        width: Constant.offerDialogWidth,
        disableClose: true,
        autoFocus: false,
        data: {talentId: this.talentId, adoptIds: jobOfferReplyAdoptIds}
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          for (const replyParam of replyParams) {
            for (const jobOfferReplyAdoptId of jobOfferReplyAdoptIds) {
              if (Number(replyParam.id) === jobOfferReplyAdoptId) {
                replyParam.join_plan_date = data.adopt_date;
              }
            }
          }
          this.updateReplyExec(replyParams);
        }
      });
    } else {
      // 更新処理実行
      this.updateReplyExec(replyParams);
    }
  }

  // "対応済：採用"に変更された求人があるかチェック
  private isUpdateToAdopt(params) {
    const adoptedList = [];
    for (const param of params) {
      if (param.reply_kind === Constant.offerReplyAdopt) {
        // "採用合意"だった場合は変更前の値を調査
        if (this.presentFormData[param.id] !== Constant.offerReplyAdopt) {
          adoptedList.push(Number(param.id));
        }
      }
    }
    return adoptedList;
  }

  // 求人対応更新
  private updateReplyExec(params) {
    this.isReplySaving = true;
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionKarteApplySave);
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      this.isReplySaving = false;
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = '/talent/jobofferaction';
      let options = this.auth.createApiHeader(session);
      options['body'] = {
        params: params
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.toastUtil.clearAllShowingToast();
            this.toastUtil.showInformationToast('', Constant.msgActionReplyEdit, Constant.toastShowMiliSec);
            options = this.auth.createApiHeader(session);
            options['body'] = {
              id: this.talentId
            };
            // 求人アクション取得
            this.getJobofferAction(options);
          }
        })
        .catch(err => {
          this.isReplySaving = false;
          this.showErrorToast(Constant.msgNetworkError);
          this.commonUtil.debug().log(err);
        });
    });
  }

  // 削除実行
  private delContactExec(contactId) {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionKarteContactDelete);
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = '/talent/contacttag/' + this.talentId;
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        id: contactId
      };

      this.commonUtil
        .apiDel(apiPath, options)
        .then(res => {
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showInformationToast('', Constant.msgTalentContactDel, Constant.toastShowMiliSec);

          // 再読み込み
          options['body'] = null;
          this.getContact(options);
        })
        .catch(err => {
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', Constant.msgDeleteError, Constant.toastShowMiliSecErr);
        });
    });
  }

  // 追加実行
  private addContactExec() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionKarteContactAdd);
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    const contactId = this.contactForm.value['contactTitle'];

    // 登録日設定
    const month = ('00' + (this.contactDate._i.month + 1).toString()).slice(-2);
    const day = ('00' + this.contactDate._i.date.toString()).slice(-2);
    const registered_date = this.contactDate._i.year.toString() + month + day;

    // フォームのリセット
    this.contactForm.reset();
    this.contactDate = null;

    Auth.currentSession().then(session => {
      const apiPath = '/talent/contacttag/' + this.talentId;
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        contact_tag_id: contactId,
        registered_date: registered_date
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showInformationToast('', Constant.msgTalentContactAdd, Constant.toastShowMiliSec);

          // 再読み込み
          options['body'] = null;
          this.getContact(options);
        })
        .catch(err => {
          if (err.message === Constant.msgTalentContactFailed) {
            this.toastUtil.clearAllShowingToast();
            this.toastUtil.showErrorToast('', Constant.msgTalentContactFailed, Constant.toastShowMiliSecErr);
          } else {
            this.toastUtil.clearAllShowingToast();
            this.toastUtil.showErrorToast('', Constant.msgAddError, Constant.toastShowMiliSecErr);
          }
        });
    });
  }

  // 全接点より登録済みの接点を除く
  private setContactSelect() {
    if (this.contacts && this.allContacts) {
      this.selectableContacts = this.allContacts;
      this.contacts.forEach(element => {
        this.selectableContacts = this.selectableContacts.filter(function (element2) {
          return element2.id !== element.contact_tag_id;
        });
      });
    }
  }

  setContactDate(date) {
    this.contactDate = date;
  }

  // お気に入りボタンをクリックした時の処理
  onFavoriteButton(id: number, flag: number) {
    // ネットワークチェック
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    // 更新処理中はボタン押下無効化
    this.favFlgSending = true;
    // APIへ渡す、登録するフラグの数字をセット
    let RegistrationFlgNo: number;
    if (flag === 0) {
      // 現在のフラグが0=未登録の場合は1を渡す
      RegistrationFlgNo = 1;
    } else {
      // 現在のフラグが1=登録の場合は0を渡す
      RegistrationFlgNo = 0;
    }
    // API接続
    Auth.currentSession().then(session => {
      const apiPath = '/talentfavorite';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        talent_id: id,
        favorite_flag: RegistrationFlgNo
      };
      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            // HTMLの表示に使用する★フラグに取得したデータのフラグを代入する
            this.favoriteFlag = res.data.favorite_flag;
            // ★ボタン押下無効を解除
            this.favFlgSending = false;
          }
        })
        .catch(err => {
          this.showErrorToast(Constant.msgFavoriteError);
          // ★ボタン押下無効を解除
          this.favFlgSending = false;
        });
    });
  }

  // 一斉メッセージ除外ボタンをクリックした時の処理
  onExceptButton(id: number) {
    // ネットワークチェック
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    // 更新処理中はボタン押下無効化
    this.msgFlgSending = true;
    // APIへ渡す、登録するフラグの数字をセット
    let except_flag: number;
    if (this.talent.base.message_except_flag) {
      except_flag = 0;
    } else {
      except_flag = 1;
    }

    // API接続
    Auth.currentSession().then(session => {
      const apiPath = '/multimessage/except';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        talent_id: id,
        except_flag: except_flag
      };

      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.talent.base.message_except_flag = except_flag;
            this.msgFlgSending = false;
          }
        })
        .catch(err => {
          this.showErrorToast(Constant.msgMultiMsgExceptError);
          this.msgFlgSending = false;
        });
    });
  }

  // エラートースト表示
  private showErrorToast(msg) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showErrorToast('', msg, Constant.toastShowMiliSec);
  }

  /* メッセージ添付ファイル対応 */
  // ファイルアップロードボタン
  onMsgFileUpload() {
    if (this.isMessageSending) {
      return;
    }
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }
    // 確認ビュー表示
    this.msgConfirmFlg = true;
  }

  // 背景クリックで確認ビューを閉じる
  onHideConfirmView(target) {
    if (target.className.indexOf('file-upload-back') > -1) {
      this.msgConfirmFlg = false;
    }
  }

  // ファイル確認ビューのボタン
  onFileConfirm(res) {
    this.msgConfirmFlg = false;
    if (res) {
      this.msgFileInput.nativeElement.value = '';
      this.msgFileInput.nativeElement.click();
    }
  }

  // ファイル変更イベント
  onChangeMsgFile(evt) {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.clearMsgFileData();
      return;
    }

    this.msgFileUploading = true;
    const file = evt.target.files[0];
    const config = environment.amplify.Storage.messageFile;

    this.imageUtil.uploadMsgFileDataToS3(file, config).then(data => {
      if (this.msgFileUploading) {
        this.msgFilePath = data;
        this.msgFileName = file.name;
        this.msgFileUploading = false;
        this.setUploadFileUrl();
      }
    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      if (this.msgFileUploading) {
        // アップロード失敗ダイアログ表示
        this.msgFileUploading = false;
        this.showErrorUpload();
      }
    });

    // タイムアウトの設定
    setTimeout(() => {
      if (this.msgFileUploading) {
        this.msgFileUploading = false;
        this.showErrorUpload();
      }
    }, Constant.apiTimeoutSecond);
  }

  // アップロードしたファイルのURL取得
  private setUploadFileUrl() {
    const bucket = environment.amplify.Storage.messageFile;
    this.imageUtil.getUploadFileUrlS3(this.msgFilePath, bucket).then(url => {
      this.msgFileUrl = url;
    })
    .catch(err => {
    });
  }

  // アップロードしたファイルの削除
  onDelUploadFile() {
    if (this.msgFileDeleting || this.isMessageSending) {
      return;
    }
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    this.msgFileDeleting = true;
    const config = environment.amplify.Storage.messageFile;

    this.imageUtil.delMsgFileFromS3(this.msgFilePath.split('/').pop(), config).then(res => {
      this.clearMsgFileData();
      this.msgFileDeleting = false;
      this.toastUtil.showInformationToast('', Constant.msgNoticeDel, Constant.toastShowMiliSec);
    })
    .catch(err => {
      if (this.msgFileDeleting) {
        this.msgFileDeleting = false;
        this.toastUtil.showErrorToast('', Constant.msgDeleteError, Constant.toastShowMiliSec);
      }
    });

    // タイムアウトの設定
    setTimeout(() => {
      if (this.msgFileDeleting) {
        this.msgFileDeleting = false;
        this.toastUtil.showErrorToast('', Constant.msgDeleteError, Constant.toastShowMiliSec);
      }
    }, Constant.apiTimeoutSecond);
  }
  // メッセージ内添付ファイル削除
  onDelMessageFile(id) {
    if (this.msgFileDeleting || this.isMessageSending) {
      return;
    }
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.msgConfirmDelSendFile }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.execDelSendFile(id);
      }
    });
  }
  // メッセージ添付ファイルのファイル名クリック
  onUploadMsgFileName(id) {
    if (this.msgFileDeleting || this.isMessageSending) {
      return false;
    }
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return false;
    }

    if (id === 0) {
      localStorage.setItem(Constant.lsShowFilePath, this.msgFilePath);
      localStorage.setItem(Constant.lsShowFileName, this.msgFileName);
    }
  }

  // ファイル名が長い場合、前後9文字ずつにする
  onShowFileName(name) {
    if (name.length < 19) {
      return name;
    } else {
      return name.substr(0, 9) + '…' + name.substr(-9);
    }
  }

  private clearMsgFileData() {
    this.msgFileName = null;
    this.msgFilePath = null;
    if (this.msgFileInput) {
      this.msgFileInput.nativeElement.value = '';
    }
  }

  // アップロード失敗ダイアログの表示
  private showErrorUpload() {
    const dialogRef = this.dialog.open(GeneralMessageDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: Constant.msgFileUploadError
    });
    dialogRef.afterClosed().subscribe(res => {
      this.clearMsgFileData();
      this.msgFileUploading = false;
    });
  }

  private execDelSendFile(id) {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return;
    }

    this.msgFileDeleting = true;
    this.msgFileDeletingId = id;

    const apiPath = '/message/file/' + id;

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiDel(apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            // トースト
            this.toastUtil.showInformationToast('', Constant.msgNoticeDel, Constant.toastShowMiliSec);
            // 最新メッセージ取得
            this.getMessage(options);
          }
        })
        .catch(err => {
          if (err.data && err.data.deleted === 1) {
            // 削除済みエラー
            this.toastUtil.showErrorToast('', Constant.msgDeleteFileAlreadyError, Constant.toastShowMiliSec);
            // 最新取得
            this.getMessage(options);
          } else {
            // その他エラー
            this.toastUtil.showErrorToast('', Constant.msgDeleteError, Constant.toastShowMiliSec);
            this.msgFileDeleting = false;
            this.msgFileDeletingId = 0;
          }
        });
    });
  }

  // プロフィールのファイル名クリック
  onProfileFileName() {
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return false;
    }
  }

  /* 閲覧数関連 */
  // 閲覧数 もっと見る
  onViewtableMore(name) {
    // 3件増やす
    this.viewCntConfig[name] += 3;
  }

  // タイトル省略
  ellipsisText(text, maxLen) {
    const len = text.length;

    if (len <= maxLen) {
      return text;
    } else {
      return text.substr(0, maxLen) + '… ';
    }
  }

  // ダウンロード可否
  canDownload(filename) {
    const ext = filename.split('.').pop().toLocaleLowerCase();
    if (ext === Constant.fileExtensionPng || ext === Constant.fileExtensionJpeg
      || ext === Constant.fileExtensionJpg || ext === Constant.fileExtensionPdf) {
      return false;
    } else {
      return true;
    }
  }

  // ファイルダウンロード用のURL取得
  getFileUrl(id, type) {
    if (this.msgFileDeleting || this.isMessageSending) {
      return false;
    }
    if (!this.commonUtil.isOnline()) {
      this.showErrorToast(Constant.msgNetworkError);
      return false;
    }

    if (id === 0) {
      // 未送信のファイル
      this.downloadFile(this.msgFileUrl, this.msgFileName);
    } else {
      Auth.currentSession().then(session => {
        const apiPath = '/' + type + '/file/' + id;
        const options = this.auth.createApiHeader(session);

        this.commonUtil.apiGet(apiPath, options).then(res => {
          const url = res.data.url;
          const fileName = res.data.name;
          if (!url) {
            this.toastUtil.showErrorToast('', Constant.msgFileDownloadError, Constant.toastShowMiliSec);
            return;
          }
          this.downloadFile(url, fileName);
        })
        .catch(err => {
          this.toastUtil.showErrorToast('', Constant.msgFileDownloadError, Constant.toastShowMiliSec);
        });
      });
    }
  }

  // ファイルダウンロード
  private downloadFile(url, fileName) {
    this.http.get(url, {responseType: 'arraybuffer'}).subscribe(data => {
      const blob = new Blob([data], {type: Constant.fileFormatDownload});
      saveAs(blob, fileName);
    }, err => {
      this.toastUtil.showErrorToast('', Constant.msgFileDownloadError, Constant.toastShowMiliSec);
      this.commonUtil.debug().log(err);
    });
  }
}
