<div class="title-area">
  <h2 mat-subheader class="title">採用PRコンテンツ</h2>
  <div class="btn-area">
    <button mat-raised-button type="button" color="warn" [disabled]="showSpinner" (click)="onEdit()">
      新規作成
    </button>
    <button mat-raised-button type="button" [disabled]="showSpinner" (click)="onPriority()" class="white">
      優先表示
    </button>
    <button mat-raised-button type="button" [disabled]="showSpinner" (click)="onTagRegist()" class="white">
      #タグ 登録
    </button>
    <button mat-raised-button type="button" [disabled]="showSpinner" (click)="onCategory()" class="white">
      カテゴリー登録
    </button>
  </div>
</div>
<div class="desc">
  採用PRコンテンツを公開する場合は、「新規作成」した採用PRコンテンツのステータスを「未公開」から「公開中」に変更してください。<br>
  各アクション数の上段は「全体数」で、タレントプール未登録/解除済のタレントによるものを含みます。<br>
  下段の（　）内は、タレントプール登録中のタレントによるアクション数です。
</div>

<div *ngIf="showSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<table *ngIf="!showSpinner" class="pr-content">
  <tr>
    <th>ステータス</th>
    <th colspan="2">採用PRコンテンツ</th>
    <th>公開日</th>
    <th>閲覧数</th>
    <th>シェア</th>
    <th>いいね</th>
    <th>Menu</th>
  </tr>
  <ng-container *ngFor="let prContent of prContents; index as idx">
    <tr class="inuse{{ prContent.release_status }}">
      <!-- ステータス -->
      <td rowspan="3" class="use" *ngIf="prContent.release_status == Constant.pcReleaseStatusNoRelease">
        <img src="assets/notice/default-check.png" srcset="
            assets/notice/default-check.png    1x,
            assets/notice/default-check@2x.png 2x,
            assets/notice/default-check@3x.svg 3x
          " />
        <br />未公開
      </td>
      <td rowspan="3" class="use" *ngIf="prContent.release_status == Constant.pcReleaseStatusRelease">
        <img src="assets/notice/use-check.png" srcset="
            assets/notice/use-check.png    1x,
            assets/notice/use-check@2x.png 2x,
            assets/notice/use-check@3x.svg 3x
          " />
        <br />公開中
      </td>
      <td rowspan="3" class="use" *ngIf="prContent.release_status == Constant.pcReleaseStatusStop">
        <img src="assets/notice/stop-check.png" srcset="
            assets/notice/stop-check.png    1x,
            assets/notice/stop-check@2x.png 2x,
            assets/notice/stop-check@3x.svg 3x
          " />
        <br />非公開
      </td>
      <!-- 採用PRコンテンツ -->
      <td class="image-container" rowspan="3" colspan="1">
        <div *ngIf="prContent.image_url == Constant.empty" class="noimage">
          <span>No Image</span>
        </div>
        <img *ngIf="prContent.image_url != Constant.empty" src="{{ prContent.image_url }}" />
      </td>
      <!-- コンテンツタイトル -->
      <td rowspan="1" colspan="1" class="title-row">
        <div class="title-container">
          <a (click)="onDetail(idx)">{{ prContent.title }}</a>
        </div>
      </td>
      <!-- 公開日 -->
      <td *ngIf="prContent.release_date" class="last-modify" rowspan="3">
        {{ prContent.release_date.slice(0, 10) }}
      </td>
      <td *ngIf="!prContent.release_date" class="last-modify" rowspan="3">
        {{ Constant.pythen }}
      </td>
      <!-- 閲覧数 -->
      <td class="cnt" rowspan="3">
        <ng-container *ngIf="prContent.release_status==Constant.pcReleaseStatusNoRelease">-</ng-container>
        <ng-container *ngIf="prContent.release_status!=Constant.pcReleaseStatusNoRelease">
          {{ prContent.more_cnt }}<br>
          （<a (click)="onToList(Constant.pcActionMore, idx)">{{ prContent.more_cnt_talent }}</a>）
        </ng-container>
      </td>
      <!-- シェア -->
      <td class="cnt" rowspan="3">
        <ng-container *ngIf="prContent.release_status==Constant.pcReleaseStatusNoRelease">-</ng-container>
        <ng-container *ngIf="prContent.release_status!=Constant.pcReleaseStatusNoRelease">
          {{ prContent.share_cnt }}<br>
          （<a (click)="onToList(Constant.pcActionShare, idx)">{{ prContent.share_cnt_talent }}</a>）
        </ng-container>
      </td>
      <!-- いいね -->
      <td class="cnt" rowspan="3">
        <ng-container *ngIf="prContent.release_status==Constant.pcReleaseStatusNoRelease">-</ng-container>
        <ng-container *ngIf="prContent.release_status!=Constant.pcReleaseStatusNoRelease">
          {{ prContent.good_cnt }}<br>（<a (click)="onToList(Constant.pcActionGood, idx)">{{ prContent.good_cnt_talent }}</a>）
        </ng-container>
      </td>
      <!-- サブメニュー -->
      <td class="btn-edit" rowspan="3">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-btn">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" overlapTrigger="false">
          <button mat-menu-item (click)="onDetail(idx)">詳細</button>
          <button mat-menu-item (click)="onEdit(idx)">編集</button>
          <button mat-menu-item (click)="onRelease(idx, false)"
            *ngIf="prContent.release_status == Constant.pcReleaseStatusRelease">
            非公開にする
          </button>
          <button mat-menu-item (click)="onRelease(idx, true)" *ngIf="
              prContent.release_status == Constant.pcReleaseStatusNoRelease ||
              prContent.release_status == Constant.pcReleaseStatusStop
            ">
            公開する
          </button>
          <button mat-menu-item (click)="onDel(idx)">削除</button>
        </mat-menu>
      </td>
    </tr>
    <tr class="inuse{{ prContent.release_status }}">
      <td colspan="1" class="tag-container">
        <div class="text-container display-flex">
          <div class="label-category">カテゴリー：</div>
          <div class="text">
              <span class="tag">{{ prContent.category }}</span>
          </div>
        </div>
        <div class="text-container display-flex">
          <div class="label">#タグ：</div>
          <div class="text">
            <ng-container *ngFor="let tag of prContent.tagTextAry; first as first">
              <span *ngIf="!first">、</span>
              <span class="tag">{{ tag }}</span>
            </ng-container>
          </div>
        </div>
      </td>
    </tr>
    <!-- URL -->
    <tr class="inuse{{ prContent.release_status }} border-bottom">
      <td colspan="1" class="url-container">
        <div class="url-text-container" *ngIf="prContent.release_status !== Constant.pcReleaseStatusNoRelease">
          <div class="text">URL</div>
          <div class="url"><a href="{{ prContent.url }}" target="_blank">{{ prContent.url }}</a></div>
          <div class="copy" (click)="onUrlCopy(idx)">
            <img
              src="assets/notice/copy-icon.png"
              srcset="
                assets/notice/copy-icon.png    1x,
                assets/notice/copy-icon@2x.png 2x,
                assets/notice/copy-icon@3x.svg 3x
              "
            />
            URLをコピー
          </div>
          <input id="url-code-{{ idx }}" type="hidden" value="{{ prContent.url }}" />
        </div>
      </td>
    </tr>
  </ng-container>
</table>

<div
  *ngIf="!showSpinner && prContents && prContents.length == 0"
  class="no-prContents"
>
  採用PRコンテンツがありません。採用PRコンテンツを作成してください。
</div>
