import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Constant } from '../constant';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { CommonUtil } from '../util/common-util';
import { MomentUtil } from '../util/moment-util';
import { ToastUtil } from '../util/toast-util';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChartjsUtil  } from './../util/chartjs-util';
import { AnalyticsCalendarDialogComponent } from '../dialog/analytics-calendar-dialog/analytics-calendar-dialog.component';

@Component({
  selector: 'app-analytics-preference',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  templateUrl: './analytics-preference.component.html',
  styleUrl: './analytics-preference.component.css'
})
export class AnalyticsPreferenceComponent {
  public readonly Constant = Constant;
  public showSpinner = true;
  public startDay
  public endDay
  public selectedTerm = 7
  public transitionData  // 推移データ
  public scoreDetail // スコア分布などの詳細データ
  public selectedType = Constant.alPreferenceTypeProfile
  public scoreTransition // スコア推移
  public totalData // タレント推移
  public careerTypeLabel;  // 採用枠（年度）のタイトル

  private chartScorebunpu
  @ViewChild('canvasScorebunpu') canvasScorebunpu: ElementRef;

  private chartAge
  @ViewChild('canvasAge') canvasAge: ElementRef;
  private chartSex
  @ViewChild('canvasSex') canvasSex: ElementRef;
  private chartSchool
  @ViewChild('canvasSchool') canvasSchool: ElementRef;
  private chartCareerType
  @ViewChild('canvasCareerType') canvasCareerType: ElementRef;
  private chartExpCareer
  @ViewChild('canvasExpCareer') canvasExpCareer: ElementRef;
  private chartDesireCareer
  @ViewChild('canvasDesireCareer') canvasDesireCareer: ElementRef;
  private chartEmployment
  @ViewChild('canvasEmployment') canvasEmployment: ElementRef;
  private chartIncome
  @ViewChild('canvasIncome') canvasIncome: ElementRef;
  private chartContact
  @ViewChild('canvasContact') canvasContact: ElementRef;
  private chartTalenttag
  @ViewChild('canvasTalenttag') canvasTalenttag: ElementRef;

  private chartPreferenceTotal
  @ViewChild('canvasPreferenceTotal') canvasPreferenceTotal: ElementRef;
  private chartScoreTransition
  @ViewChild('canvasScoreTransition') canvasScoreTransition: ElementRef;
  private chartTalentUpDown
  @ViewChild('canvasTalentUpDown') canvasTalentUpDown: ElementRef;

  constructor(
    private title: Title,
    private auth: AuthService,
    private dialog: MatDialog,
    private commonUtil: CommonUtil,
    private momentUtil: MomentUtil,
    private toastUtil: ToastUtil,
    private chartjsUtil: ChartjsUtil,
  ) {
    this.title.setTitle(Constant.pageTitleAnalyticsPreference + Constant.pageTitleCommon);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    // 初期表示は7日前
    this.onTermSelected(7)
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  // 表示期間変更
  onTermSelected(term) {
    if (term > 0) {
      // 今日からterm日前の日付を取得
      const today = new Date()
      const startDay = new Date(today.getTime())
      startDay.setDate(startDay.getDate() - term)
      this.startDay = startDay
      this.endDay = today
      this.clearData()
      this.getReport()
    }
  }

  // 表示期間カスタム
  onTermCustom() {
    const dialogRef = this.dialog.open(AnalyticsCalendarDialogComponent, {
      width: Constant.analyticsCalendarDialogWidth,
      height: Constant.analyticsCalendarDialogHeight,
      maxHeight: Constant.analyticsCalendarDialogMaxHeight,
      autoFocus: false,
      data: {
        start: this.startDay,
        end: this.endDay
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.startDay = result.start
        this.endDay = result.end
        this.clearData()
        this.getReport()
      }
    });
  }

  // プロフィール/経路/タグ切り替え
  onChangeType(type) {
    this.selectedType = type
  }

  // データクリア
  private clearData() {
    this.totalData = null
    this.scoreTransition = null
    this.scoreDetail = null
  }


  // レポート取得
  private getReport() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    this.showSpinner = true
    Auth.currentSession().then(session => {
      // タレント推移取得
      this.getTalentTotal(session)

      // スコア推移
      this.getPreferenceTransition(session)

      // 詳細分析
      this.getPrefereceDetail(session)
    });

  }

  // プレファレンススコア推移取得
  private getPreferenceTransition(session) {
    let apiPath = '/analytics/preference/transition?interval=year'
    apiPath += '&start=' + this.momentUtil.getDateFormat(this.startDay)
    apiPath += '&end=' + this.momentUtil.getDateFormat(this.endDay)
    const options = this.auth.createApiHeader(session)

    this.commonUtil.apiGet(apiPath, options).then(res => {
      this.scoreTransition = res.data.preferent_score_transition
      // スコア推移チャート
      const chartData = this.createPreferenceLineChartData(this.scoreTransition)
      setTimeout(() => {
        this.chartScoreTransition = this.chartjsUtil.drawLineChartFill(chartData, this.canvasScoreTransition, this.chartScoreTransition, Constant.alChartTypeScore)
      });
      this.showSpinner = false

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });
  }

  // その他タレントデータ取得
  private getPrefereceDetail(session) {
    const apiPath = '/analytics/preference'
    const options = this.auth.createApiHeader(session)
    this.commonUtil.apiGet(apiPath, options).then(res => {
      this.scoreDetail = res.data

      // スコア分布
      const data = {
        label: ['9以下', '10-19', '20-29', '30-39'],
        // datalabel: ['0-19', '20-39', '40-59', '60-79', '80以上'],
        chart: this.scoreDetail.preference_score_distribution
      }
      setTimeout(() => {
        this.chartScorebunpu = this.chartjsUtil.drawBarChartScorebunpu(data, this.canvasScorebunpu, this.chartScorebunpu)
      });

      // 年齢
      const ageData = this.createTalentDetailData(this.scoreDetail.profile.age, 'range')
      setTimeout(() => {
        const table = document.getElementById('table-age')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasAge.nativeElement.style.height = height
        this.chartAge = this.chartjsUtil.drawPreferenceBarChart(ageData, this.canvasAge, this.chartAge, height)
      });

      // 性別
      const sexData = this.createTalentDetailData(this.scoreDetail.profile.sex, 'sex')
      setTimeout(() => {
        const table = document.getElementById('table-sex')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasSex.nativeElement.style.height = height
        this.chartSex = this.chartjsUtil.drawPreferenceBarChart(sexData, this.canvasSex, this.chartSex, height)
      });

      // 最終学歴
      const schoolData = this.createTalentDetailData(this.scoreDetail.profile.school_type, 'school_type')
      setTimeout(() => {
        const table = document.getElementById('table-school')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasSchool.nativeElement.style.height = height
        this.chartSchool = this.chartjsUtil.drawPreferenceBarChart(schoolData, this.canvasSchool, this.chartSchool, height)
      });

      // 採用枠
      const careerTypeData = this.createTalentDetailData(this.scoreDetail.profile.recruite_quota, 'career_type')
      setTimeout(() => {
        const table = document.getElementById('table-recruite')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasCareerType.nativeElement.style.height = height
        this.chartCareerType = this.chartjsUtil.drawPreferenceBarChart(careerTypeData, this.canvasCareerType, this.chartCareerType, height)
      });
      // 採用枠のラベル作成
      const now = new Date();
      const currentYear = now.getMonth() >= 3 ? now.getFullYear() + 1 : now.getFullYear()
      this.careerTypeLabel = {
        '中途': '中途\n' + (currentYear-4).toString() + '年卒以前',
        '第二新卒': '第二新卒\n' + (currentYear-3).toString() + '-' + (currentYear-1).toString() + '年卒',
        '新卒1': '新卒\n' + (currentYear).toString() + '年卒',
        '新卒2': '新卒\n' + (currentYear+1).toString() + '年卒',
        '新卒3': '新卒\n' + (currentYear+2).toString() + '年卒',
        '新卒4': '新卒\n' + (currentYear+3).toString() + '年卒以降',
        'その他': 'その他',
        '未登録': '未登録',
      }

      // 経験職種
      const expCareerData = this.createTalentDetailData(this.scoreDetail.career.experience_work_career, 'career_type')
      setTimeout(() => {
        const table = document.getElementById('table-exp-job')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasExpCareer.nativeElement.style.height = height
        this.chartExpCareer = this.chartjsUtil.drawPreferenceBarChart(expCareerData, this.canvasExpCareer, this.chartExpCareer, height)
      });

      // 経験職種
      const desireCareerData = this.createTalentDetailData(this.scoreDetail.career.hope_work_career, 'career_type')
      setTimeout(() => {
        const table = document.getElementById('table-desire-job')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasDesireCareer.nativeElement.style.height = height
        this.chartDesireCareer = this.chartjsUtil.drawPreferenceBarChart(desireCareerData, this.canvasDesireCareer, this.chartDesireCareer, height)
      });

      // 雇用形態
      const employmentData = this.createTalentDetailData(this.scoreDetail.career.employment_status, 'employment_type')
      setTimeout(() => {
        const table = document.getElementById('table-employment')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasEmployment.nativeElement.style.height = height
        this.chartEmployment = this.chartjsUtil.drawPreferenceBarChart(employmentData, this.canvasEmployment, this.chartEmployment, height)
      });

      // 現年収
      const incomeData = this.createTalentDetailData(this.scoreDetail.career.income_status, 'income_range')
      setTimeout(() => {
        const table = document.getElementById('table-income')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasIncome.nativeElement.style.height = height
        this.chartIncome = this.chartjsUtil.drawPreferenceBarChart(incomeData, this.canvasIncome, this.chartIncome, height)
      });

      // 接点
      const contactData = this.createTalentDetailData(this.scoreDetail.tag.contact_tag, 'contact_tag')
      setTimeout(() => {
        const table = document.getElementById('table-contact')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasContact.nativeElement.style.height = height
        this.chartContact = this.chartjsUtil.drawPreferenceBarChart(contactData, this.canvasContact, this.chartContact, height)
      });

      // タレント管理タグ
      const talentTagData = this.createTalentDetailData(this.scoreDetail.tag.talent_management_tag, 'talent_tag')
      setTimeout(() => {
        const table = document.getElementById('table-talenttag')
        const height = table.scrollHeight - 11 + 'px'
        this.canvasTalenttag.nativeElement.style.height = height
        this.chartContact = this.chartjsUtil.drawPreferenceBarChart(talentTagData, this.canvasTalenttag, this.chartTalenttag, height)
      });

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });
  }

  // タレント推移取得
  private getTalentTotal(session) {
    // 初日の増減表示のため、1日前から取得する
    const startDay = new Date(this.startDay.getTime())
    startDay.setDate(this.startDay.getDate() - 1)
    const apiPath = '/analytics/talenttotal/'
    const options = this.auth.createApiHeader(session)
    options['body'] = {
      start_date: this.momentUtil.getDateFormat(startDay),
      end_date: this.momentUtil.getDateFormat(this.endDay),
    };
    this.commonUtil.apiPost(apiPath, options).then(res => {
      this.totalData = res.data
      if (this.totalData.list) {
        // タレント数推移
        const chartData = this.createTalentUpDownData(this.totalData.list)
        setTimeout(() => {
          this.chartTalentUpDown = this.chartjsUtil.drawLineChartUpdown(chartData, this.canvasTalentUpDown, this.chartTalentUpDown)
        });
      }

      // プレファレンススコア円グラフ
      const score = this.totalData.preference_score
      setTimeout(() => {
        this.chartPreferenceTotal = this.chartjsUtil.drawPreferenceChart(score, this.canvasPreferenceTotal, this.chartPreferenceTotal)
      });
      this.showSpinner = false

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });

  }


  // プレファレンススコア用チャートデータ作成
  createPreferenceLineChartData(originData) {
    const date = new Date(this.startDay.getTime())
    const labelData = []
    const chartData = []
    while (date <= this.endDay) {
      const label = this.momentUtil.getDateFormatMonthDaySlash(date)
      labelData.push(label)
      const dateText = this.momentUtil.getDateFormat(date)
      const item = originData.filter(function(data) {
        return dateText === data.score_date;
      });
      if (item.length > 0) {
        chartData.push(item[0].median_score > 100 ? 100 : item[0].median_score)
      } else {
        chartData.push(0)
      }
      date.setDate(date.getDate() + 1)
    }
    return {
      label: labelData,
      chart: chartData
    }
  }

  // タレント増減　チャートデータ作成
  createTalentUpDownData(originData) {
    const labelAry = []
    const chartAry = []
    let preCnt = 0
    originData.forEach((element, index) => {
      if (index == 0) {
        preCnt = element.count
      } else {
        const date = new Date(element.target_date)
        labelAry.push((date.getMonth() + 1).toString() + '/' + date.getDate().toString())
        const changeCnt = element.count - preCnt
        chartAry.push(changeCnt)

        preCnt = element.count
      }

    });

    return {
      label: labelAry,
      chart: chartAry
    }
  }

  private createTalentDetailData(targetDataAry, titleName) {
    const labelAry = []
    const dataAry = {
      '19以下': [],
      '20-39': [],
      '40-59': [],
      '60-79': [],
      '80以上': [],
    }

    const maxCntAry = {}  // 最大人数フラグ用配列
    targetDataAry.forEach(element => {
      if (element.talent_total > 0) {
        let maxCnt = 0
        labelAry.push(element[titleName])
        element.score_range.forEach(element2 => {
          dataAry[element2.range].push(element2.talent_total)
          if (element2.talent_total > maxCnt) {
            maxCnt = element2.talent_total
          }
        });
        maxCntAry[element[titleName]] = maxCnt
      }
    });

    targetDataAry.forEach(element => {
      if (element.talent_total > 0) {
        element.score_range.forEach(element2 => {
          if (element2.talent_total > 0 && maxCntAry[element[titleName]] == element2.talent_total) {
            element2.max_flg = true
          }
        });
      }
    });

    let chartData = null
    if (labelAry.length > 0) {
      chartData = {
        label: labelAry,
        chart: Object.values(dataAry)
      }
    }
    return chartData
  }

}
