<div class="side">
  <div class="button-container">
    <button mat-raised-button (click)="onClose()" [disabled]="sideCloseFlg">閉じる</button>
    <div class="close-desc"><ng-container *ngIf="sideCloseFlg">ブラウザを操作してタブを閉じてください。</ng-container></div>
    <div *ngIf="talent?.base.csv_flag==1" class="edit-area">
      <button mat-raised-button (click)="onEdit()" class="orange">編集</button>
      <button mat-raised-button (click)="onDel()" class="gray">削除</button>
    </div>
  </div>

  <div class="sidenav-content">
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/map']">トップページ</a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="onCalinInformation()">お知らせ　<span class="font-small">{{ newsDate }}</span></a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="onRequestForCaLin()" >質問・要望はこちら</a>
      <mat-divider></mat-divider>
    </mat-nav-list>
    <div class="information">
      <a class="infomation-link" mat-list-item (click)="onAgreement()">
        <img class="information-icon" src="assets/talent-list/information_link_icon.png" />利用規約</a
      >
      <a class="infomation-link" mat-list-item (click)="onCalin()">
        <img class="information-icon" src="assets/talent-list/information_link_icon.png" />運営企業</a
      >
      <a class="infomation-link" mat-list-item (click)="onPrivacy()">
        <img
          class="information-icon"
          src="assets/talent-list/information_link_icon.png"
        />プライバシーポリシー</a
      >
    </div>
  </div>
</div>
<div *ngIf="showSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<div class="container" *ngIf="talent">
  <div class="profile-container" [class.gray]="talent.base.bounce_flag || talent.base.optout_flag">
    <!-- 基本情報 -->
    <div class="basic-info">
      <div class="image-div">
        <div class="img-block" *ngIf="talent.base.image_path != Constant.empty else noTalentImage">
          <img class="image" src="{{talent.base.image_path}}" />
        </div>
      </div>
      <!-- 画像が登録されていない場合はデフォルト画像を表示 -->
      <ng-template #noTalentImage>
        <ng-container *ngIf="talent.base.csv_flag!=1">
          <ng-container class="img-block" *ngIf="talent.base.sex_id == Constant.tlSexIDMen">
            <img class="image" src="assets/talent-list/icon_User_men@2x.png" />
          </ng-container>
          <ng-container class="img-block" *ngIf="talent.base.sex_id == Constant.tlSexIDWomen">
            <img class="image" src="assets/talent-list/icon_User_woman@2x.png" />
          </ng-container>
          <ng-container class="img-block" *ngIf="talent.base.sex_id == Constant.tlSexIDOther || !talent.base.sex_id">
            <img class="image" src="assets/talent-list/icon_User@2x.png" />
          </ng-container>
        </ng-container>
        <ng-container *ngIf="talent.base.csv_flag==1">
          <ng-container class="img-block" *ngIf="talent.base.sex_id == Constant.tlSexIDMen">
            <img class="image" src="assets/talent-list/icon_User_men_csv@2x.png" />
          </ng-container>
          <ng-container class="img-block" *ngIf="talent.base.sex_id == Constant.tlSexIDWomen">
            <img class="image" src="assets/talent-list/icon_User_woman_csv@2x.png" />
          </ng-container>
          <ng-container class="img-block" *ngIf="talent.base.sex_id == Constant.tlSexIDOther || !talent.base.sex_id">
            <img class="image" src="assets/talent-list/icon_User_csv@2x.png" />
          </ng-container>
        </ng-container>
      </ng-template>
      <table class="profile-table">
        <!-- 氏名 -->
        <tr>
          <td>
            <div class="name">
              <span>{{talent.base.name}}</span>
            </div>
            <div class="name-kana">{{talent.base.name_kana}}</div>
          </td>
        </tr>
        <!-- 年齢(生年月日)・性別 -->
        <tr>
          <td class="basic-font">
            <div class="flex">
              <div class="age">
                <ng-container *ngIf="talent.base.birthday == ''">
                  <span>生年月日：</span>
                  <span class="font-gray">(未登録)</span>
                </ng-container>
                <ng-container *ngIf="talent.base.birthday != ''">
                  <span>{{talent.base.age}}歳（{{talent.base.birthday.substr(0, 7)}}）</span>
                </ng-container>
              </div>
              <div class="sex">
                <ng-container *ngIf="talent.base.sex == ''">
                  <span>性別：</span>
                  <span class="font-gray">(未登録)</span>
                </ng-container>
                <ng-container *ngIf="talent.base.sex != ''">
                  <span>{{talent.base.sex}}性</span>
                </ng-container>
              </div>
            </div>
          </td>
        </tr>
        <!-- 居住地 -->
        <tr>
          <td class="basic-font">
            <div class="basic-data">
              <div class="address">
                <ng-container *ngIf="talent.base.address1 == ''">
                  <span>居住地：</span>
                  <span class="font-gray">(未登録)</span>
                </ng-container>
                <span *ngIf="talent.base.address1 != ''">{{talent.base.address1}}{{talent.base.address2}}</span>
              </div>
            </div>
          </td>
        </tr>
        <!-- 電話番号 -->
        <tr>
          <td class="basic-font">
            <div class="contact-data">
              <div class="tel">
                <img class="icon-small" src="assets/talent-carte/tell.png">
                <span *ngIf="talent.base.telno != ''">{{talent.base.telno}}</span>
                <span class="font-gray" *ngIf="talent.base.telno == ''">(未登録)</span>
              </div>
            </div>
          </td>
        </tr>
        <!-- メールアドレス -->
        <tr>
          <td class="basic-font">
            <div>
              <img class="icon-small" src="assets/talent-carte/mail_blue.png">
              <span *ngIf="talent.base.mail_address != ''">{{talent.base.mail_address}}</span>
              <span class="font-gray" *ngIf="talent.base.mail_address == ''">(未登録)</span>
            </div>
            <div class="red-text" *ngIf="talent.base.bounce_flag || talent.base.optout_flag">
              配信拒否/停止されました。
            </div>
          </td>
        </tr>
      </table>
      <!-- プール登録日・最終アクセス日・プロフ更新日 -->
      <div class="update-info-area">
        <div class="text">
          <div class="info-title">
            最終アクセス
          </div>
          <div *ngIf="talent.base.csv_flag!=1">
            {{talent.base.last_access_date}} - {{talent.base.access_elapsed_days}}日経過
          </div>
          <div *ngIf="talent.base.csv_flag==1">
            -
          </div>
        </div>
        <div class="text">
          <div class="info-title">
            プロフ更新/タレント操作
          </div>
          <div *ngIf="talent.base.csv_flag!=1 && talent.base.updated_at">
            {{talent.base.updated_at}} - {{talent.base.profile_elapsed_days}}日経過
          </div>
          <div *ngIf="talent.base.csv_flag==1 || !talent.base.updated_at">
            -
          </div>
        </div>
        <div class="text">
          <div class="info-title">
            最新のプール登録日
          </div>
          <div>
            {{talent.base.talent_created_at}} - {{talent.base.pool_elapsed_days}}日経過
          </div>
        </div>
        <div class="text last">
          <div class="info-title">
            プロフ･メモ更新/貴社操作
          </div>
          <div *ngIf="!talent.base.updated_at_by_company">
            -
          </div>
          <div *ngIf="talent.base.updated_at_by_company">
            {{talent.base.updated_at_by_company}} - {{talent.base.profile_by_c_elapsed_days}}日経過
          </div>
        </div>
      </div>
    </div>
    <!-- お気に入り機能 -->
    <div class="button-area">
      <!-- お気に入りボタン -->
      <div class="favorite-area">
        <button mat-icon-button
          (click)="onFavoriteButton(talent.base.id, this.favoriteFlag)"
          [disabled]="favFlgSending"
        >
          <!-- フラグ登録あり = 1 -->
          <img *ngIf="this.favoriteFlag == 1" class="icon-star" src="assets/talent-list/bookmark_on.svg" />
          <!-- フラグ登録なし = 0 -->
          <img *ngIf="this.favoriteFlag == 0" class="icon-star" src="assets/talent-list/bookmark_off.svg" />
        </button>
        <div class="favorite-text">絞り込み用フラグ</div>
      </div>
      <!-- 一斉メッセージ除外ボタン -->
      <div class="except-area" *ngIf="talent">
        <button mat-button (click)="onExceptButton(talent.base.id)" [disabled]="msgFlgSending" [class.off]="talent.base.message_except_flag">
          <span *ngIf="!talent.base.message_except_flag">一斉メッセージ<br>除外リストに追加</span>
          <span *ngIf="talent.base.message_except_flag">一斉メッセージ<br>除外リストに追加済</span>
        </button>
      </div>
    </div>
    <!-- 管理用メモ -->
    <div class="memo-area">
      <div class="memo-input">
        <textarea class="memo-textarea" matInput [(ngModel)]="memo" name="memo" #memotext placeholder="メモ"></textarea>
      </div>
      <div class="memo-footer">
        <div class="memo-length" [class.length-red]="memotext.value.length>memoTextMaxLen">{{memotext.value.length}}/{{ memoTextMaxLen }}文字</div>
        <div class="memo-button">
          <mat-spinner diameter="24" *ngIf="memoUpdateType == memoTypeTalent"></mat-spinner>
          <button class="general-button" (click)="onMemoUpdate(memotext.value, memoTypeTalent)" [disabled]="memotext.value.length>memoTextMaxLen || memoUpdateType == memoTypeTalent">更新</button>
        </div>
      </div>
    </div>
  </div>
  <mat-divider class="clear top0"></mat-divider>

  <div class="detail-container">
    <div class="desire-info">
      <!-- タレントタグ -->
      <div class="icon-big-container">
        <img class="icon-big" src="assets/talent-carte/tag.png">
        <span class="header-font right-btn">タレント管理タグ</span>
        <button (click)="onTalenttagEdit()" mat-raised-button class="tag-button tag-edit-button" *ngIf="!isTalenttagEditMode">編集</button>
        <button (click)="onTalenttagClose()" mat-raised-button class="tag-button tag-close-button" *ngIf="isTalenttagEditMode">閉じる</button>
      </div>
      <div class="detail">
        <table class="tag-list">
          <tr *ngFor="let tag of talenttag">
            <td class="title">{{tag.title}}</td>
            <td class="del-button">
              <button (click)="onTalenttagDelete(tag.id)" mat-raised-button class="tag-button tag-del-button" *ngIf="isTalenttagEditMode">削除</button>
            </td>
          </tr>
        </table>
        <div class="tag-add" *ngIf="isTalenttagEditMode">
          <form [formGroup]="talenttagForm">
            <table class="tag-list">
              <tr>
                <td class="title">
                  <mat-form-field floatLabel="never" class="select-tag-title">
                    <mat-select formControlName="title" placeholder="選択 *必須" panelWidth="">
                      <mat-option *ngFor="let tag of selectableTalenttag" [value]="tag.id">
                        {{tag.title}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td class="del-button">
                  <button (click)="onTalenttagAdd()" mat-raised-button class="tag-button tag-add-button" [disabled]="talenttagForm.invalid">追加</button></td>
              </tr>
            </table>
          </form>
        </div>
      </div>
      <mat-divider></mat-divider>
      <!-- 採用枠 -->
      <div>
        <div class="icon-big-container">
          <img class="icon-big" src="assets/talent-list/university_blue.png">
          <span class="header-font">新卒採用枠（卒業年）</span>
        </div>
      </div>
      <div class="detail-s">
        <ng-container *ngIf="talent.base.graduate_year_skip_flag==1">
          <div class="graduate gray">未登録</div>
        </ng-container>
        <ng-container *ngIf="talent.base.graduate_year_skip_flag==0">
          <div *ngIf="talent.base.graduate_year_type==Constant.tlGraduateYearMin" class="graduate past">〜{{Constant.tlGraduateYearMin}}年卒</div>
          <div *ngIf="talent.base.graduate_year_type>Constant.tlGraduateYearMin" class="graduate"
            [class.past]="talent.base.graduate_year_type < newGraduateYear"
            [class.future]="talent.base.graduate_year_type >= newGraduateYear"
            >{{talent.base.graduate_year_type}}年卒</div>
          <div *ngIf="talent.base.graduate_year_type===Constant.tlGraduateYearOther.toString()" class="graduate gray">その他</div>
          <div *ngIf="talent.base.graduate_year_type===''" class="graduate gray">未登録</div>
        </ng-container>
      </div>
      <mat-divider></mat-divider>
      <!-- 求職活動予定時期 -->
      <div>
        <div class="icon-big-container">
          <img class="icon-big" src="assets/talent-carte/calendar.png">
          <span class="header-font">就業/転職 活動予定時期</span>
        </div>
        <div class="detail-s basic-font-bold">
          <ng-container *ngIf="talent.base.active_undecided_flag==1">
            <span class="basic-font">未定</span>
          </ng-container>
          <ng-container *ngIf="talent.base.active_undecided_flag!=1">
            <span class="basic-font" *ngIf="talent.base.active_planned_start == '' && talent.base.active_planned_end == ''">
              <span class="font-gray">(未登録)</span>
            </span>
            <span class="basic-font" *ngIf="talent.base.active_planned_start != '' && talent.base.active_planned_end == ''">
              {{talent.base.active_planned_start}} 〜
              <span class="font-gray">(未登録)</span>
            </span>
            <span class="basic-font" *ngIf="talent.base.active_planned_start == '' && talent.base.active_planned_end != ''">
              <span class="font-gray">(未登録)</span> 〜 {{talent.base.active_planned_end}}
            </span>
            <span class="basic-font" *ngIf="talent.base.active_planned_start != '' && talent.base.active_planned_end != ''">
              {{talent.base.active_planned_start}} 〜 {{talent.base.active_planned_end}}
            </span>
          </ng-container>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- 就業・転職先の希望情報 -->
      <div class="icon-big-container">
        <img class="icon-big" src="assets/talent-carte/schedule.png">
        <span class="header-font"> 就業・転職 希望情報</span>
      </div>
      <div class="detail">
        <div class="talentpool-div">
          <div>
            <img class="icon-small" src="assets/talent-carte/business.png">
          </div>
          <div class="desirejob-title">
            <span class="basic-font">雇用形態</span>
          </div>
          <div class="general-margin-left" *ngIf="talent.base[Constant.apDesireEmployeement] != ''">
            <span class="basic-font">{{talent.base[Constant.apDesireEmployeement]}}</span>
          </div>
          <div class="general-margin-left" *ngIf="talent.base[Constant.apDesireEmployeement] == ''">
            <span class="basic-font font-gray">(未登録)</span>
          </div>
        </div>
        <div class="talentpool-div">
          <div>
            <img class="icon-small" src="assets/talent-carte/map.png">
          </div>
          <div class="desirejob-title">
            <span class="basic-font">勤務地</span>
          </div>
          <div class="general-margin-left" *ngIf="talent.base.desirejob_place != ''">
            <span class="basic-font">{{talent.base.desirejob_place}}</span>
          </div>
          <div class="general-margin-left" *ngIf="talent.base.desirejob_place == ''">
            <span class="basic-font font-gray">(未登録)</span>
          </div>
        </div>
        <div class="talentpool-div">
          <div>
            <img class="icon-small" src="assets/talent-carte/label.png">
          </div>
          <div class="desirejob-title">
            <span class="basic-font">職種</span>
          </div>
          <div class="general-margin-left" *ngIf="talent.base.desirejob_undecided_flag">
            <span class="basic-font">未定</span>
          </div>
          <div class="general-margin-left" *ngIf="!talent.base.desirejob_undecided_flag && talent.desirejobs.length==0">
            <span class="basic-font font-gray">(未登録)</span>
          </div>
        </div>
        <div class="desirejob_container">
          <ng-container *ngIf="!talent.base.desirejob_undecided_flag">
            <ng-container *ngFor="let largeCategory of talent.desirejobs">
              <div class="desirejob_large_item">
                <span>{{largeCategory.large_item}}</span>
              </div>
              <ul class="desirejob_item_value">
                <ng-container *ngFor="let itemvalue of largeCategory.item_values">
                  <li>
                    <span>{{itemvalue}}</span>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </ng-container>
        </div>
        <div class="talentpool-div">
          <div>
            <img class="icon-small" src="assets/talent-carte/money_bag.png">
          </div>
          <div class="desirejob-title">
            <span class="basic-font">年収</span>
          </div>
          <div class="general-margin-left">
            @if(talent.base.desire_annual_income > -1) {
              <span class="basic-font">{{ talent.base.desire_annual_income }}万円</span>
            } @else {
              <span class="basic-font font-gray">(未登録)</span>
            }
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <!-- 接点・経路 -->
      <div class="icon-big-container">
        <img class="icon-big" src="assets/talent-carte/root.png" srcset="assets/talent-carte/root.png 1x,
        assets/talent-carte/root@2x.png 2x, assets/talent-carte/root@3x.svg 3x">
        <span class="header-font right-btn">接点・経路</span>
        <button (click)="onContactEdit()" mat-raised-button class="tag-button tag-edit-button" *ngIf="!isContactEditMode">編集</button>
        <button (click)="onContactSave()" mat-raised-button class="tag-button tag-close-button" *ngIf="isContactEditMode">閉じる</button>
      </div>
      <div class="detail">
        <table class="tag-list">
          <tr *ngFor="let contact of contacts">
            <td>
              <div class="tag-title">{{contact.title}}</div>
              <div class="tag-date">{{contact.registered_date}}登録</div>
            </td>
            <td class="del-button">
              <button (click)="onContactDelete(contact.id)" mat-raised-button class="tag-button tag-del-button" *ngIf="isContactEditMode">削除</button>
            </td>
          </tr>
        </table>
        <div class="tag-add" *ngIf="isContactEditMode">
          <form [formGroup]="contactForm">
            <table class="tag-list">
              <tr>
                <td colspan="2">
                  <mat-form-field floatLabel="never" class="select-tag-title">
                    <mat-select formControlName="contactTitle" placeholder="選択 *必須" panelWidth="">
                      <mat-option *ngFor="let contact of selectableContacts" [value]="contact.id">
                        {{contact.title}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td>
                  <mat-form-field class="input-date" (click)="picker.open()" hideRequiredMarker="true">
                    <mat-label>登録日 *必須</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="contactDateMin" [max]="contactDateMax" (dateChange)="setContactDate($event.value)" formControlName="contactDate"
                      class="input-date-text">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="date-pick-icon"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                  </mat-form-field>
                </td>
                <td class="del-button">
                  <button (click)="onContactAdd()" mat-raised-button class="tag-button tag-add-button" [disabled]="contactForm.invalid || !contactDate">追加</button></td>
              </tr>
            </table>
          </form>
        </div>
      </div>
    </div>
    <!-- 求人アクション・職歴 -->
    <div class="right-content-area">
      <!-- 求人アクション -->
      <ng-container *ngIf="talent.base.csv_flag!=1">
        <div class="jobofferaction-info">
          <div class="header">
            <img class="icon-big icon-mostbehind" src="assets/talent-carte/offer_action.png" />
            <span class="header-font">求人アクション・対応</span>
              <div *ngIf="jobOfferActions && jobOfferActions.length > 0" class="headerbutton-area">
              <button
                *ngIf="isReplyEditMode"
                (click)="onActionReply(Constant.offerReplyEditCancel)"
                mat-raised-button
                class="offeraction-button cancel-button"
              >
                キャンセル
              </button>
              <button
                *ngIf="isReplyEditMode"
                (click)="onActionReply(Constant.offerReplyEditOff)"
                mat-raised-button
                class="offeraction-button save-button"
                [disabled]="isReplySaving"
              >
                保存
              </button>
              <button
                *ngIf="!isReplyEditMode"
                (click)="onActionReply(Constant.offerReplyEditOn)"
                mat-raised-button
                class="offeraction-button edit-button"
              >
                編集
              </button>
            </div>
          </div>
          <!-- 求人アクション・対応情報 -->
          <div *ngIf="jobOfferActions && jobOfferActions.length == 0" class="no-action">
            「応募」や「気になる」はまだありません。
          </div>
          <form *ngIf="jobOfferActions && jobOfferActions.length > 0" [formGroup]="jobOfferReplyForm">
            <table class="basic-font action-area">
              <ng-container *ngFor="let jobOfferAction of jobOfferActions; index as idx;">
                <tr>
                  <td class="action">
                    <span>{{ jobOfferAction.action_text }}</span>
                  </td>
                  <td class="updated-at">
                    <span>{{ jobOfferAction.updated_at }}</span>
                  </td>
                  <td class="title-area">
                    <div class="title-text">
                      <span class="pre-line vertical-middle">{{ jobOfferAction.title }}</span>
                    </div>
                  </td>
                  <td  *ngIf="!isReplyEditMode" class="actionreply">
                    <span [class.text-important]="jobOfferAction.reply_kind == Constant.offerReplyNoReply">{{ jobOfferAction.reply_text }}</span>
                    <div *ngIf="jobOfferAction.reply_kind == Constant.offerReplyAdopt" class="join-date text-important">入社日：{{ jobOfferAction.join_plan_date }}</div>
                  </td>
                  <td *ngIf="isReplyEditMode" >
                    <mat-form-field floatLabel="never" class="actionreply-select">
                      <mat-select
                        [formControlName]="jobOfferAction.id"
                        placeholder="選択"
                        panelWidth=""
                      >
                        <mat-option *ngFor="let reply of replyMaster" [value]="reply.id">
                          {{ reply.item_value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
              </ng-container>
            </table>
          </form>
          <!-- 求人　シェア数 -->
          <div class="jobofferview" *ngIf="jobOfferShareAll>0">
            <img class="icon-middle" src="assets/talent-carte/share.png">
            <span class="header-font sub">シェア数 {{ jobOfferShareAll }}</span>
            <table class="basic-font view-table">
              <ng-container *ngFor="let share of jobOfferShare; index as idx;">
              <tr [class.hidden]="idx>=viewCntConfig.jobshare">
                <td class="title">{{ share.title }}</td>
                <td class="cnt">{{ share.cnt }}回</td>
                <td>：</td>
                <td class="date">最新 {{ share.show_date}}</td>
              </tr>
            </ng-container>
            </table>
            <div class="view-more"><a (click)="onViewtableMore('jobshare')" *ngIf="viewCntConfig.jobshare<jobOfferShare.length">もっと見る ▼</a></div>
          </div>
          <!-- 求人　閲覧数 -->
          <div class="jobofferview" *ngIf="jobOfferViewAll>=0">
            <img class="icon-middle" src="assets/talent-carte/eye.png">
            <span class="header-font sub">閲覧数 {{ jobOfferViewAll }}</span>
            <table class="basic-font view-table">
              <ng-container *ngFor="let view of jobOfferView; index as idx;">
              <tr [class.hidden]="idx>=viewCntConfig.jobview">
                <td class="title">{{ view.title }}</td>
                <td class="cnt">{{ view.cnt }}回</td>
                <td>：</td>
                <td class="date">最新 {{ view.show_date}}</td>
              </tr>
            </ng-container>
            </table>
            <div class="view-more"><a (click)="onViewtableMore('jobview')" *ngIf="viewCntConfig.jobview<jobOfferView.length">もっと見る ▼</a></div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- 記事アクション -->
        <div *ngIf="contentsActionInfo" class="contents-action-area">
          <div class="icon-big-container">
            <img class="icon-big" src="assets/talent-carte/action.png">
            <span class="header-font">記事 アクション状況</span>
          </div>
          <div class="viewcnt-area">
            <img class="icon-middle" src="assets/talent-carte/good.png">
            <span class="header-font sub">いいね {{ contentsActionInfo.good_cnt_all }}</span>
            <table class="basic-font view-table">
              <ng-container *ngFor="let content of contentsActionInfo.contents; index as idx;">
              <tr [class.hidden]="idx>=viewCntConfig.congood">
                <td class="title long"><a (click)="onContentDetail(idx)">{{ellipsisText(content.title, contentsTitleMaxLength)}}</a></td>
                <td class="date">{{ content.good_at}}</td>
              </tr>
            </ng-container>
            </table>
            <div class="view-more"><a (click)="onViewtableMore('congood')" *ngIf="viewCntConfig.congood<contentsActionInfo.contents.length">もっと見る ▼</a></div>

            @if (contentsActionInfo.share_cnt_all > 0) {
              <img class="icon-middle" src="assets/talent-carte/share.png">
              <span class="header-font sub">シェア数 {{ contentsActionInfo.share_cnt_all }}</span>
              <table class="basic-font view-table">
                <ng-container *ngFor="let share of contentsActionInfo.share_cnt_detail; index as idx;">
                  <tr [class.hidden]="idx>=viewCntConfig.conshare">
                    <td class="title">{{ ellipsisText(share.title, contentsTitleMaxLength)}}</td>
                    <td class="cnt">{{ share.cnt }}回</td>
                    <td>：</td>
                    <td class="date">最新 {{ share.show_date}}</td>
                  </tr>
              </ng-container>
              </table>
              <div class="view-more"><a (click)="onViewtableMore('conshare')" *ngIf="viewCntConfig.conshare<contentsActionInfo.share_cnt_detail.length">もっと見る ▼</a></div>
            }

            <img class="icon-middle" src="assets/talent-carte/eye.png">
            <span class="header-font sub">閲覧数 {{ contentsActionInfo.view_cnt_all }}</span>
            <span class="basic-font">　過去１ヶ月の閲覧数 {{contentsActionInfo.view_cnt_month}}</span>
            <table class="basic-font view-table">
              <ng-container *ngFor="let view of contentsActionInfo.view_cnt_detail; index as idx;">
                <tr [class.hidden]="idx>=viewCntConfig.conview">
                  <td class="title">{{ ellipsisText(view.title, contentsTitleMaxLength)}}</td>
                  <td class="cnt">{{ view.cnt }}回</td>
                  <td>：</td>
                  <td class="date">最新 {{ view.show_date}}</td>
                </tr>
            </ng-container>
            </table>
            <div class="view-more"><a (click)="onViewtableMore('conview')" *ngIf="viewCntConfig.conview<contentsActionInfo.view_cnt_detail.length">もっと見る ▼</a></div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- 資料アクション -->
        @if (materialActionInfo?.good_cnt_all>0 || materialActionInfo?.share_cnt_all>0 || materialActionInfo?.view_detail.length>0) {
        <div class="contents-action-area">
          <div class="icon-big-container align-center">
            <img class="icon-big" src="assets/talent-carte/menu_book.svg">
            <span class="header-font">資料 アクション状況</span>
          </div>
          <div class="viewcnt-area">
            @if (materialActionInfo.good_cnt_all > 0) {
              <img class="icon-middle" src="assets/talent-carte/good.png">
              <span class="header-font sub">いいね {{ materialActionInfo.good_cnt_all }}</span>
              <table class="basic-font view-table">
                <ng-container *ngFor="let content of materialActionInfo.good_cnt_detail; index as idx;">
                <tr [class.hidden]="idx>=viewCntConfig.mategood">
                  <td class="title long">{{content.title}}</td>
                  <td class="date">{{ content.good_at}}</td>
                </tr>
              </ng-container>
              </table>
              <div class="view-more"><a (click)="onViewtableMore('mategood')" *ngIf="viewCntConfig.mategood<materialActionInfo.good_cnt_detail.length">もっと見る ▼</a></div>
            }

            @if (materialActionInfo.share_cnt_all > 0) {
              <img class="icon-middle" src="assets/talent-carte/share.png">
              <span class="header-font sub">シェア数 {{ materialActionInfo.share_cnt_all }}</span>
              <table class="basic-font view-table">
                <ng-container *ngFor="let share of materialActionInfo.share_cnt_detail; index as idx;">
                  <tr [class.hidden]="idx>=viewCntConfig.mateshare">
                    <td class="title">{{ share.title }}</td>
                    <td class="cnt">{{ share.cnt }}回</td>
                    <td>：</td>
                    <td class="date">最新 {{ share.show_date}}</td>
                  </tr>
              </ng-container>
              </table>
              <div class="view-more"><a (click)="onViewtableMore('mateshare')" *ngIf="viewCntConfig.mateshare<materialActionInfo.share_cnt_detail.length">もっと見る ▼</a></div>
            }

            @if (materialActionInfo.view_detail?.length>0) {
              <img class="icon-middle" src="assets/talent-carte/eye.png">
              <span class="header-font sub">閲覧状況</span>
              <table class="basic-font view-table">
                <ng-container *ngFor="let view of materialActionInfo.view_detail; index as idx;">
                  <tr [class.hidden]="idx>=viewCntConfig.mateview">
                    <td class="title doc"><a (click)="onMaterialDetail(idx)">{{ view.title }}</a></td>
                    <td>読了率：</td>
                    <td class="doc-ratio-value">{{ view.reading_ratio }}%</td>
                    <td>閲覧時間：</td>
                    <td class="doc-time">{{ view.time }}</td>
                    <td class="date">最新 {{ view.show_date}}</td>
                  </tr>
              </ng-container>
              </table>
              <div class="view-more"><a (click)="onViewtableMore('mateview')" *ngIf="viewCntConfig.mateview<materialActionInfo.view_detail.length">もっと見る ▼</a></div>
            }
          </div>
        </div>
        <mat-divider></mat-divider>
        }

        <!-- 追加質問 -->
        <div *ngIf="talent.question?.length>0" class="contents-action-area">
          <div class="icon-big-container">
            <img class="icon-big" src="assets/talent-carte/check.png">
            <span class="header-font">追加プロフィール</span>
          </div>

          <div *ngFor="let q of talent.question" class="question-area">
            <div class="desirejob_large_item">
              <span>{{ q.title }}</span>
            </div>
            <div *ngIf="q.type == Constant.aqAnswerTypeTextId" class="question-text">
              {{ q.answer_text }}
            </div>
            <div *ngIf="q.type != Constant.aqAnswerTypeTextId" class="question-text">
              <span *ngFor="let text of q.select_text; last as last">
                {{ text }}<ng-container *ngIf="!last">、</ng-container>
              </span>
            </div>
          </div>

          <mat-divider></mat-divider>
        </div>

      </ng-container>

        <!-- 添付ファイル -->
        <div class="file-area" *ngIf="talent.profile_file.length > 0">
          <div class="">
            <div class="icon-big-container">
              <img class="icon-big" src="assets/talent-carte/clip.png" />
              <span class="header-font">添付ファイル</span>
            </div>
            <table class="basic-font file-table">
              <tr class="file-tr" *ngFor="let file of talent.profile_file">
                <td class="file-icon-area">
                  <img class="file-circle disp-block" src="assets/common/file.png" />
                </td>
                <td class="title">
                  <a *ngIf="!canDownload(file.file_name)" routerLink="/{{Constant.rtAttachment}}/{{file.id}}" target="_blank" class='file-font' (click)="onProfileFileName()">{{file.file_name}}</a>
                  <a *ngIf="canDownload(file.file_name)" class='file-font' (click)="getFileUrl(file.id, Constant.fileTypeProfile)">{{file.file_name}}</a>
                </td>
              </tr>
            </table>
          </div>
        </div>

      <div class="jobhistory-info">
        <!-- 職歴 -->
        <div class="history-line"></div>
        <div class="history-header">
          <div class="icon-big-container">
            <img class="icon-big icon-mostbehind" src="assets/talent-carte/schedule.png" />
            <span class="header-font">職歴</span>
          </div>
          <div *ngIf="talent.work_career.length == 0">
            <span class="basic-font career-no-regist font-gray">(未登録)</span>
          </div>
          <ng-container *ngIf="talent.work_career.length > 0">
            <table class="detail-table career">
              <ng-container *ngFor="let career of talent.work_career">
                <tr>
                  <td class="icon">
                    <img class="icon-small icon-circle" src="assets/talent-carte/career_circle.png" />
                  </td>
                  <td class="date basic-font">
                    <ng-container *ngIf="!career.exit_date" && last>
                      <div class="text-center">現在</div>
                    </ng-container>
                    <div class="text-center">{{ career.exit_date }}</div>
                    <div class="text-center">|</div>
                    <div class="text-center">{{ career.join_date }}</div>
                  </td>
                  <td class="icon">
                    <img class="icon-small" src="assets/talent-carte/business.png" />
                  </td>
                  <td>
                    <div class="header-font">{{ career.company_name }}</div>
                    <div class="career-category" *ngIf="career.employeement_type !== ''">
                      <div class="basic-font-bold">雇用形態</div>
                      <div class="careerdata_container basic-font">{{ career.employeement_type }}</div>
                    </div>
                    <div class="career-category" *ngIf="career.annual_income >= 0">
                      <div class="basic-font-bold">年収</div>
                      <div class="careerdata_container basic-font">{{ career.annual_income }}万円</div>
                    </div>
                    <div class="career-category" *ngIf="career.position != ''">
                      <div class="basic-font-bold">役職</div>
                      <div class="careerdata_container basic-font">{{ career.position }}</div>
                    </div>
                    <div class="career-category" *ngIf="career.department != ''">
                      <div class="basic-font-bold">部署</div>
                      <div class="careerdata_container basic-font">{{ career.department }}</div>
                    </div>
                    <div class="career-category">
                      <div class="basic-font-bold" *ngIf="career.careerjobs && career.careerjobs.length>0">職種</div>
                      <div class="careerdata_container">
                        <ng-container *ngFor="let largeCategory of career.careerjobs">
                          <div class="careerjob_large_item">
                            <span>{{ largeCategory.large_item }}</span>
                          </div>
                          <ul class="careerjob_item_value carrer-margin">
                            <ng-container *ngFor="let itemvalue of largeCategory.item_values">
                              <li class="careerjob_item_value">
                                <span>{{ itemvalue }}</span>
                              </li>
                            </ng-container>
                          </ul>
                        </ng-container>
                      </div>
                    </div>
                    <div class="career-category" *ngIf="career.job_detail != ''">
                      <div class="basic-font-bold">活動</div>
                      <div class="careerdata_container basic-font pre-line">{{ career.job_detail }}</div>
                    </div>
                    <div class="career-category" *ngIf="career.skill != ''">
                      <div class="basic-font-bold">活かしたスキル</div>
                      <div class="careerdata_container basic-font pre-line">{{ career.skill }}</div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </table>
          </ng-container>
          <!-- 職歴メモ -->
          <div class="memo-area history">
            <div class="memo-input">
              <textarea class="memo-textarea" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" rows="3"
              [(ngModel)]="memoCareer" name="memo-career" #memocareer placeholder="職歴メモ"></textarea>
            </div>
            <div class="memo-footer">
              <div class="memo-length" [class.length-red]="memocareer.value.length>memoTextMaxLen">{{memocareer.value.length}}/{{ memoTextMaxLen }}文字</div>
              <div class="memo-button">
                <mat-spinner diameter="24" *ngIf="memoUpdateType == memoTypeCareer"></mat-spinner>
                <button class="general-button" (click)="onMemoUpdate(memocareer.value, memoTypeCareer)" [disabled]="memocareer.value.length>memoTextMaxLen || memoUpdateType == memoTypeCareer">更新</button>
              </div>
            </div>
          </div>

          <div class="icon-big-container">
            <img class="icon-big icon-mostbehind" src="assets/talent-carte/school.png" />
            <span class="header-font">学歴</span>
          </div>
          <div *ngIf="talent.school_history.length == 0">
            <span class="basic-font career-no-regist font-gray">(未登録)</span>
          </div>
          <ng-container *ngIf="talent.school_history.length > 0">
            <table class="detail-table career" cellpadding="3">
              <ng-container *ngFor="let school of talent.school_history">
                <tr class="school-history-row">
                  <td class="icon">
                    <img class="icon-small icon-circle icon-adjust" src="assets/talent-carte/career_circle.png" />
                  </td>
                  <td class="date basic-font">
                    <ng-container *ngIf="!school.exit_date">
                      <div class="text-center">現在</div>
                    </ng-container>
                    <div class="text-center">{{ school.exit_date }}</div>
                    <div class="text-center">|</div>
                    <div class="text-center">{{ school.admission_date }}</div>
                  </td>
                  <td class="detail-data">
                    <div>
                      <div class="header-font">{{ school.school_name }}
                        <span *ngIf="school.school_type" class="school-type">（{{ school.school_type }}<ng-container *ngIf="school.dropout_flag == 1">／中退・転学</ng-container>）</span>
                        <span *ngIf="!school.school_type && school.dropout_flag == 1" class="school-type">（中退・転学）</span>
                      </div>
                      <div class="header-font">{{ school.department }}<span class="school-type" *ngIf="school.bunri_type">（{{ school.bunri_type }}）</span></div>
                    </div>
                    <div *ngIf="school.main_activities != ''" class="general-margin-left basic-font pre-line">{{ school.main_activities }}</div>
                  </td>
                </tr>
              </ng-container>
            </table>
          </ng-container>
          <!-- 学歴メモ -->
          <div class="memo-area history">
            <div class="memo-input">
              <textarea class="memo-textarea" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" rows="3"
              [(ngModel)]="memoSchool" name="memo-school" #memoschool placeholder="学歴メモ"></textarea>
            </div>
            <div class="memo-footer">
              <div class="memo-length" [class.length-red]="memoschool.value.length>memoTextMaxLen">{{memoschool.value.length}}/{{ memoTextMaxLen }}文字</div>
              <div class="memo-button">
                <mat-spinner diameter="24" *ngIf="memoUpdateType == memoTypeSchool"></mat-spinner>
                <button class="general-button" (click)="onMemoUpdate(memoschool.value, memoTypeSchool)" [disabled]="memoschool.value.length>memoTextMaxLen || memoUpdateType == memoTypeSchool">更新</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div mat-icon-button class="message-button" (click)="onShowMessageDialog()">
        <img src="assets/talent-carte/message_open.png" *ngIf="talent?.base.bounce_flag==0 && talent?.base.optout_flag==0" />
        <img src="assets/talent-carte/message_open_gray.png" *ngIf="talent?.base.bounce_flag!=0 || talent?.base.optout_flag!=0" />
      </div>
    </div>
  </div>
</div>
<div class="message-dialog" [class.dialog-visible]="!isHideMessageDialog" [class.dialog-hidden]="isHideMessageDialog">
  <div class="header-container">
    <div class="left">
      <button class="header-button" (click)="onMessageInfo()">
        <img src="assets/talent-carte/info.svg" />
      </button>
      @if (msgClickCnt) {
        <div class="link-cnt">リンク閲覧 {{ msgClickCnt.link_cnt }}</div>
        <div class="link-cnt" *ngIf="talent?.base.csv_flag == Constant.tlRegistTypeTalent">添付閲覧 {{ msgClickCnt.file_cnt }}</div>
      }
    </div>
    <div class="right">
      <button class="header-button" (click)="onMessageReflesh()" *ngIf="talent?.base.csv_flag == Constant.tlRegistTypeTalent">
        <img class="message-header-icon-update" src="assets/talent-carte/refresh.png" />
      </button>
      <button class="header-button" (click)="onHideMessageDialog()">
        <img class="message-header-icon-close" src="assets/talent-carte/message_close.png" />
      </button>
    </div>
  </div>
  <div class="messege-spinner-container" [hidden]="isHideProgressSpinner">
    <mat-progress-spinner mode="indeterminate" [diameter]="40"></mat-progress-spinner>
  </div>
  <div class="main-area">
    <div class="message-history" [scrollTop]="scrollMessageTop" (scroll)="onMessageScroll() ">
      <div class="scroll-message" [hidden]="isHideScrollMessage">↑スクロールしてメッセージを取得する↑</div>
      <div class="start-message" [hidden]="isHideStartMessage"> - start of message -</div>
      <div class="message-container" *ngFor="let msg of messages; " #msgLists>
        <div class="message type{{msg.type_id}} message-id-{{msg.id}} ">
          <ng-container *ngIf="msg.mail_title">
            <b>{{ msg.mail_title }}</b>
            <mat-divider></mat-divider>
          </ng-container>
          <span [innerHtml]="msg.message"></span>
          <div *ngIf="msg.attached_file_path" class="msg-file-parent">
            <mat-divider *ngIf="msg.message" [class.gray-text]="msgFileDeleting"></mat-divider>
            <div class="flex msg-file-area" id="msg-file-id-{{ msg.id }}">
              <ng-container *ngIf="msg.type_id != Constant.tlMsgTypeReceived">
                <div *ngIf="!msgFileDeleting || msgFileDeletingId != msg.id" (click)="onDelMessageFile(msg.id)" [class.gray-text]="msgFileDeleting">
                  <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
                  <g>
                    <path class="st0" d="M442.258,60.422H356l-8.32-35.824C344.312,10.114,331.574,0,316.707,0H195.269
                      c-14.878,0-27.609,10.121-30.965,24.606l-8.324,35.816H69.742c-33.488,0-60.73,27.242-60.73,60.73v31.637
                      c0,19.73,16.054,35.778,35.782,35.778h5.102l33.254,290.328C84.992,497.718,100.715,512,119.711,512h272.578
                      c19.109,0,34.801-14.18,36.5-32.988l33.312-290.446h5.106c19.726,0,35.782-16.047,35.782-35.778v-31.637
                      C502.988,87.664,475.746,60.422,442.258,60.422z M195.269,6.785c-11.703,0-21.718,7.958-24.355,19.352l-2.926,12.586l0,0
                      l2.922-12.586C173.547,14.742,183.566,6.785,195.269,6.785z M421.856,182.222l-33.149,289.074H123.246L90.14,182.222H421.856z
                      M324.937,67.207H187.043l8.226-35.418l121.446,0.004L324.937,67.207z M335.281,15.004c-3.172-3.52-7.352-5.992-12.008-7.254
                      C327.929,9.012,332.109,11.484,335.281,15.004L335.281,15.004z M49.719,121.152c0-11.042,8.98-20.023,20.023-20.023h372.516
                      c11.042,0,20.023,8.981,20.023,20.023v26.711H49.719V121.152z" style="fill: #3f51b5;"></path>
                    <path class="st0" d="M166.203,389.73h11.446c6.098,0,11.039-4.946,11.039-11.043V254.246c0-6.098-4.942-11.039-11.039-11.039
                      h-11.446c-6.098,0-11.039,4.942-11.039,11.039v124.441C155.164,384.785,160.105,389.73,166.203,389.73z" style="fill: #3f51b5;"></path>
                    <path class="st0" d="M250.269,389.73h11.442c6.098,0,11.043-4.946,11.043-11.043V254.246c0-6.098-4.946-11.039-11.043-11.039
                      h-11.442c-6.098,0-11.042,4.942-11.042,11.039v124.441C239.227,384.785,244.172,389.73,250.269,389.73z" style="fill: #3f51b5;"></path>
                    <path class="st0" d="M334.332,389.73h11.446c6.098,0,11.043-4.946,11.043-11.043V254.246c0-6.098-4.946-11.039-11.043-11.039
                      h-11.446c-6.094,0-11.038,4.942-11.038,11.039v124.441C323.293,384.785,328.238,389.73,334.332,389.73z" style="fill: #3f51b5;"></path>
                  </g>
                  </svg>
                </div>
                <mat-spinner *ngIf="msgFileDeleting && msgFileDeletingId == msg.id" diameter="20"></mat-spinner>
              </ng-container>
              <ng-container *ngIf="msg.type_id == Constant.tlMsgTypeReceived">
                <a *ngIf="!canDownload(msg.attached_file_name)" routerLink="/{{Constant.rtMsgFile}}/{{msg.id}}" target="_blank"><img src="assets/common/file.png" style="width: 20px; height: 20px;" (click)="onUploadMsgFileName(msg.id)"></a>
                <a *ngIf="canDownload(msg.attached_file_name)"><img src="assets/common/file.png" style="width: 20px; height: 20px;" (click)="getFileUrl(msg.id, Constant.fileTypeMessage)"></a>
              </ng-container>
              <a *ngIf="!canDownload(msg.attached_file_name)" class="msg-file-name" routerLink="/{{Constant.rtMsgFile}}/{{msg.id}}" target="_blank" (click)="onUploadMsgFileName(msg.id)" [class.gray-text]="msgFileDeleting && msgFileDeletingId == msg.id">{{ msg.attached_file_name }}</a>
              <a *ngIf="canDownload(msg.attached_file_name)" class="msg-file-name" (click)="getFileUrl(msg.id, Constant.fileTypeMessage)" [class.gray-text]="msgFileDeleting && msgFileDeletingId == msg.id">{{ msg.attached_file_name }}</a>
            </div>
          </div>
          <div *ngIf="msg.attached_delete_flag==1" class="msg-file-parent">
            <mat-divider *ngIf="msg.message"></mat-divider><span class="gray-text" id="msg-file-id-{{ msg.id }}">このファイルは削除されました。</span>
          </div>
        </div>
        <div class="date type{{msg.type_id}} ">
          <div>{{msg.created_at.toString()}}</div>
          <ng-container *ngIf="msg.type_id != 2 && msg.readed_flag == 1">
            <div>既読</div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="talent?.base.bounce_flag==0 && talent?.base.optout_flag==0">
    <div class="message-input-container" *ngIf="talent?.base.csv_flag==0">
      <div class="message-input">
        <div class="form-area">
          <mat-form-field class="message-form-field">
            <mat-label>メッセージを入力してください</mat-label>
            <textarea [(ngModel)]="sendMessage" #message name="sendMessage" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="20"></textarea>
            <mat-hint align="end">
              <span id="message-length" [class.length-red]="message.value.length>Constant.vlMaxSendMessage">{{message.value.length}} / {{Constant.vlMaxSendMessage}}</span>
            </mat-hint>
          </mat-form-field>
          <!-- ファイルアップロード-->
          <div class="upload-area" (click)="onMsgFileUpload()" *ngIf="!msgFileUploading && !msgFileName">
            <div [class.gray-text]="msgFileUploading || isMessageSending">
              <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
                <g>
                  <path class="st0" d="M480.216,71.601c-42.379-42.382-111.34-42.382-153.719,0L147.911,250.187
                    c-27.503,27.508-27.503,72.266,0,99.77c27.508,27.508,72.266,27.508,99.774,0l125.012-125.012l-39.91-39.906L207.775,310.051
                    c-5.5,5.5-14.454,5.5-19.954,0c-5.5-5.504-5.5-14.453,0-19.957l178.586-178.586c20.375-20.371,53.523-20.375,73.898,0
                    c20.375,20.375,20.375,53.527,0,73.902L208.454,417.262c-34.734,34.734-91.254,34.734-125.988,0s-34.734-91.254,0-125.989
                    l239.09-239.09l-39.91-39.91l-239.09,239.09c-56.742,56.742-56.738,149.066,0,205.809c56.742,56.738,149.066,56.742,205.809,0
                    L480.216,225.32C522.595,182.937,522.595,113.98,480.216,71.601z" style="fill: #3f51b5;"></path>
                </g>
                </svg>
            </div>
            <span [class.gray-text]="msgFileUploading || isMessageSending">ファイルを添付</span>
          </div>
          <div class="upload-area gray-text" *ngIf="msgFileUploading">
            <mat-spinner diameter="20"></mat-spinner><span>ファイルをアップロードしています。</span>
          </div>
          <div class="upload-area" [class.uploading]="msgFileDeleting" [class.gray-text]="msgFileDeleting || isMessageSending" *ngIf="msgFileName">
            <a *ngIf="!msgFileDeleting && !canDownload(msgFileName)" (click)="onUploadMsgFileName(0)" routerLink="/{{Constant.rtTmpFile}}" target="_blank">
              <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
                <g>
                  <path class="st0" d="M480.216,71.601c-42.379-42.382-111.34-42.382-153.719,0L147.911,250.187
                    c-27.503,27.508-27.503,72.266,0,99.77c27.508,27.508,72.266,27.508,99.774,0l125.012-125.012l-39.91-39.906L207.775,310.051
                    c-5.5,5.5-14.454,5.5-19.954,0c-5.5-5.504-5.5-14.453,0-19.957l178.586-178.586c20.375-20.371,53.523-20.375,73.898,0
                    c20.375,20.375,20.375,53.527,0,73.902L208.454,417.262c-34.734,34.734-91.254,34.734-125.988,0s-34.734-91.254,0-125.989
                    l239.09-239.09l-39.91-39.91l-239.09,239.09c-56.742,56.742-56.738,149.066,0,205.809c56.742,56.738,149.066,56.742,205.809,0
                    L480.216,225.32C522.595,182.937,522.595,113.98,480.216,71.601z" style="fill: #3f51b5;"></path>
                </g>
              </svg>
            </a>
            <a *ngIf="!msgFileDeleting && canDownload(msgFileName)" (click)="getFileUrl(0, Constant.fileTypeMessage)">
              <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
                <g>
                  <path class="st0" d="M480.216,71.601c-42.379-42.382-111.34-42.382-153.719,0L147.911,250.187
                    c-27.503,27.508-27.503,72.266,0,99.77c27.508,27.508,72.266,27.508,99.774,0l125.012-125.012l-39.91-39.906L207.775,310.051
                    c-5.5,5.5-14.454,5.5-19.954,0c-5.5-5.504-5.5-14.453,0-19.957l178.586-178.586c20.375-20.371,53.523-20.375,73.898,0
                    c20.375,20.375,20.375,53.527,0,73.902L208.454,417.262c-34.734,34.734-91.254,34.734-125.988,0s-34.734-91.254,0-125.989
                    l239.09-239.09l-39.91-39.91l-239.09,239.09c-56.742,56.742-56.738,149.066,0,205.809c56.742,56.738,149.066,56.742,205.809,0
                    L480.216,225.32C522.595,182.937,522.595,113.98,480.216,71.601z" style="fill: #3f51b5;"></path>
                </g>
              </svg>
            </a>
            <mat-spinner *ngIf="msgFileDeleting" diameter="20"></mat-spinner>
            <a *ngIf="!canDownload(msgFileName)" (click)="onUploadMsgFileName(0)" class="file-text" [class.gray-text]="msgFileDeleting || isMessageSending" href="/{{Constant.rtTmpFile}}" target="_blank">{{ onShowFileName(msgFileName) }}</a>
            <a *ngIf="canDownload(msgFileName)" (click)="getFileUrl(0, Constant.fileTypeMessage)" class="file-text" [class.gray-text]="msgFileDeleting || isMessageSending">{{ onShowFileName(msgFileName) }}</a>
            <div class="delete" (click)="onDelUploadFile()">
              <svg version="1.1" id="_x31_0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 20px; height: 20px; opacity: 1;" xml:space="preserve">
                <g>
                  <path class="st0" d="M442.258,60.422H356l-8.32-35.824C344.312,10.114,331.574,0,316.707,0H195.269
                    c-14.878,0-27.609,10.121-30.965,24.606l-8.324,35.816H69.742c-33.488,0-60.73,27.242-60.73,60.73v31.637
                    c0,19.73,16.054,35.778,35.782,35.778h5.102l33.254,290.328C84.992,497.718,100.715,512,119.711,512h272.578
                    c19.109,0,34.801-14.18,36.5-32.988l33.312-290.446h5.106c19.726,0,35.782-16.047,35.782-35.778v-31.637
                    C502.988,87.664,475.746,60.422,442.258,60.422z M195.269,6.785c-11.703,0-21.718,7.958-24.355,19.352l-2.926,12.586l0,0
                    l2.922-12.586C173.547,14.742,183.566,6.785,195.269,6.785z M421.856,182.222l-33.149,289.074H123.246L90.14,182.222H421.856z
                     M324.937,67.207H187.043l8.226-35.418l121.446,0.004L324.937,67.207z M335.281,15.004c-3.172-3.52-7.352-5.992-12.008-7.254
                    C327.929,9.012,332.109,11.484,335.281,15.004L335.281,15.004z M49.719,121.152c0-11.042,8.98-20.023,20.023-20.023h372.516
                    c11.042,0,20.023,8.981,20.023,20.023v26.711H49.719V121.152z" style="fill: #3f51b5;"></path>
                  <path class="st0" d="M166.203,389.73h11.446c6.098,0,11.039-4.946,11.039-11.043V254.246c0-6.098-4.942-11.039-11.039-11.039
                    h-11.446c-6.098,0-11.039,4.942-11.039,11.039v124.441C155.164,384.785,160.105,389.73,166.203,389.73z" style="fill: #3f51b5;"></path>
                  <path class="st0" d="M250.269,389.73h11.442c6.098,0,11.043-4.946,11.043-11.043V254.246c0-6.098-4.946-11.039-11.043-11.039
                    h-11.442c-6.098,0-11.042,4.942-11.042,11.039v124.441C239.227,384.785,244.172,389.73,250.269,389.73z" style="fill: #3f51b5;"></path>
                  <path class="st0" d="M334.332,389.73h11.446c6.098,0,11.043-4.946,11.043-11.043V254.246c0-6.098-4.946-11.039-11.043-11.039
                    h-11.446c-6.094,0-11.038,4.942-11.038,11.039v124.441C323.293,384.785,328.238,389.73,334.332,389.73z" style="fill: #3f51b5;"></path>
                </g>
                </svg>
            </div>
          </div>
          <input hidden type="file" name="msgFileInput" #msgFileInput [accept]="Constant.fileTypeAttached" (change)="onChangeMsgFile($event)" />
        </div>
        <div>
          <button class="send-button" (click)="onSendMessage()" [disabled]="(!sendMessage && !msgFilePath) || message.value.length > Constant.vlMaxSendMessage || isMessageSending || msgFileDeleting || msgFileUploading">送信</button>
          <div class="send-spinner-area">
            <ng-container *ngIf="isMessageSending">
              <mat-spinner diameter="30"></mat-spinner>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- 企業作成ユーザー用 -->
    <div class="message-input-container csv" *ngIf="talent?.base.csv_flag==1" (click)="onMailInputMode(true)">
      メッセージ作成・編集
    </div>
    </ng-container>
    <div class="message-input-container stop" *ngIf="talent?.base.bounce_flag!=0 || talent?.base.optout_flag!=0">
      このタレントへのメッセージ配信は拒否/停止されています。
    </div>
  </div>
  <div *ngIf="msgInputMode" class="mail-input-container">
    <!-- 企業作成ユーザー用　メール作成画面 -->
    <div class="input-content">
      <div class="label floatleft">
        <div>タイトル </div>
        <div class="red-text">※必須</div>
      </div>
      <div class="label-count" [class.length-red]="messagetitle.value.length>Constant.vlMaxSendMessageTitle">{{ messagetitle.value.length }}/{{ Constant.vlMaxSendMessageTitle }}</div>
      <input class="input-title" type="text" [(ngModel)]="sendMessageTitle" #messagetitle name="sendMessageTitle" placeholder="タレントに届くメールの件名になります。" [maxlength]="Constant.vlMaxSendMessageTitle">
    </div>
    <div class="input-content maintext">
      <div class="label floatleft">
        <div>本文 </div>
        <div class="red-text">※必須</div>
        <button class="info-button" (click)="onMessageInfo()">
          <img src="assets/talent-carte/info.svg" />
        </button>
        <a class="info-text" (click)="onMessageInfo()">URL入力時の注意</a>
      </div>
      <div class="label-count" [class.length-red]="message.value.length>Constant.vlMaxSendMessage">{{ message.value.length }}/{{ Constant.vlMaxSendMessage }}</div>
      <textarea class="textarea" matInput
      [(ngModel)]="sendMessage" #message name="sendMessage" placeholder="本文を入力してください。"></textarea>
    </div>
    <div class="button-container">
      <button mat-raised-button class="half" (click)="onMailInputMode(false)">履歴に戻る</button>
      <button mat-raised-button class="half" color="warn" type="button" (click)="onSendMessage()"
        [disabled]="!sendMessage || !sendMessageTitle || message.value.length > Constant.vlMaxSendMessage
        || messagetitle.value.length > Constant.vlMaxSendMessageTitle || isMessageSending">
        送信
      </button>
      <mat-spinner diameter="28" *ngIf="isMessageSending" class="spinner"></mat-spinner>
    </div>
  </div>
</div>
<!-- ファイルアップロード確認ビュー -->
<div class="file-upload-back" [class.show]="msgConfirmFlg" (click)="onHideConfirmView($event.target)">
  <div class="file-upload-confirm">
    <h3>ファイルを添付します。</h3>
    <ul>
      <li>送信できるファイルの拡張子は、pdf、jpg、png、ppt、pptx、xls、xlsx、doc、docxで、容量は5Mまでです。</li>
      <li>1回のメッセージにつき、1つのファイルを添付できます。</li>
      <li>送信したファイルは削除できます。</li>
    </ul>
    <div class="button-container" *ngIf="msgConfirmFlg">
      <button mat-raised-button (click)="onFileConfirm(false)">キャンセル</button>
      <button mat-raised-button (click)="onFileConfirm(true)" class="yes-btn">OK</button>
    </div>
  </div>
</div>
