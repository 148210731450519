import { Component, OnInit, HostListener } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../auth/auth.service';
import { Constant } from './../constant';
import { Router } from '@angular/router';
import { TalentListCondition } from './../model/talent-list-condition';
import { ToastUtil } from './../util/toast-util';
import { CommonUtil } from './../util/common-util';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { UserguideDialogComponent } from '../dialog/userguide-dialog/userguide-dialog.component';
import { MomentUtil } from './../util/moment-util';

@Component({
  selector: 'app-talent-map',
  templateUrl: './talent-map.component.html',
  styleUrls: ['./talent-map.component.css'],
  providers: [Title]
})
export class TalentMapComponent implements OnInit {
  public Constant = Constant;
  public showSpinner = true;
  public showSpinnerPv = true;
  public mapData;
  public pvData;
  public newGraduateYear;
  public graduateSinsotsu = [];
  public graduateChuto = [];
  public graduateOther = [];
  public sinsotsuCnt = 0;
  public chutoCnt = 0;
  public otherCnt = 0;
  public updateFlg;

  constructor(
    private auth: AuthService,
    private router: Router,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private title: Title,
    private dialog: MatDialog,
    private momentUtil: MomentUtil
  ) {
    this.title.setTitle(Constant.pageTitleTalentMap + Constant.pageTitleCommon);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.showSpinner = false;
      this.showSpinnerPv = false;
      return;
    }
    Auth.currentSession().then(session => {
      // タレントマップ情報取得
      this.getTalentMapInfo(session);
      // タレント獲得パフォーマンス取得
      this.getPageviewInfo(session);
      // 新着チェック
      this.checkUpdate(session);
    });

    // チュートリアル表示
    this.showUserGuide();
  }

  toList(detail?: any) {
    const condition: TalentListCondition[] = [];
    if (detail) {
      condition[0] = {
        type: detail.type,
        id: detail.id
      };
    }
    this.toListWithCondition(condition);
  }

  // タレントプール登録数押下
  toListRegistered(period, registType?) {
    const condition: TalentListCondition[] = [];

    if (period === Constant.tlPeriodTypeThisMonth) {
      // 今月
      condition[0] = {
        type: Constant.tlRegisteredDateStartType,
        id: this.momentUtil.createThisMonthStartDate()
      };
      condition[1] = {
        type: Constant.tlRegisteredDateEndType,
        id: this.momentUtil.createToday()
      };
    } else if (period === Constant.tlPeriodTypeLastMonth) {
      // 先月
      condition[0] = {
        type: Constant.tlRegisteredDateStartType,
        id: this.momentUtil.createLastMonthStartDate()
      };
      condition[1] = {
        type: Constant.tlRegisteredDateEndType,
        id: this.momentUtil.createLastMonthEndDate()
      };
    } else if (period === Constant.tlPeriodTypeTwoMonthAgo) {
      // 先々月
      condition[0] = {
        type: Constant.tlRegisteredDateStartType,
        id: this.momentUtil.createTwoMonthAgoStartDate()
      };
      condition[1] = {
        type: Constant.tlRegisteredDateEndType,
        id: this.momentUtil.createTwoMonthAgoEndDate()
      };
    } else {
      condition[0] = {
        type: Constant.tlRegisteredDateEndType,
        id: this.momentUtil.createToday()
      };
    }

    if (registType !== null) {
      condition.push({
        type: Constant.tlTalentRegistType,
        id: registType
      });
    }

    this.toListWithCondition(condition);
  }

  // 閲覧数、いいね数など押下
  toListView(type, id, period) {
    const condition: TalentListCondition[] = [];

    condition[0] = {
      type: type,
      id: id
    };

    let start;
    let end;
    if (type === Constant.pcActionType) {
      start = Constant.tlContentsActionStartType;
      end = Constant.tlContentsActionEndType;
    } else if (type === Constant.offerActionType) {
      start = Constant.tlJobOfferActionStartType;
      end = Constant.tlJobOfferActionEndType;
    }

    if (period === Constant.tlPeriodTypeThisMonth) {
      // 今月
      condition[1] = {
        type: start,
        id: this.momentUtil.createThisMonthStartDate()
      };
      condition[2] = {
        type: end,
        id: this.momentUtil.createToday()
      };
    } else if (period === Constant.tlPeriodTypeLastMonth) {
      // 先月
      condition[1] = {
        type: start,
        id: this.momentUtil.createLastMonthStartDate()
      };
      condition[2] = {
        type: end,
        id: this.momentUtil.createLastMonthEndDate()
      };
    } else if (period === Constant.tlPeriodTypeTwoMonthAgo) {
      // 先々月
      condition[1] = {
        type: start,
        id: this.momentUtil.createTwoMonthAgoStartDate()
      };
      condition[2] = {
        type: end,
        id: this.momentUtil.createTwoMonthAgoEndDate()
      };
    } else {
      // 累計
      condition[1] = {
        type: end,
        id: this.momentUtil.createToday()
      };
    }

    this.toListWithCondition(condition);
  }

  toListGraduate(graduate) {
    const condition: TalentListCondition[] = [];
    if (graduate.id >= Constant.tlGraduateYearMin) {
      condition[0] = {
        type: Constant.tlGraduateStartYearType,
        id: graduate.id
      };
      condition[1] = {
        type: Constant.tlGraduateEndYearType,
        id: graduate.id
      };
    } else {
      condition[0] = {
        type: Constant.tlGraduateYearType,
        id: graduate.id
      };
    }
    this.toListWithCondition(condition);
  }

  // 性別指定でタレントリストへ
  toListSex(sex_id, cnt) {
    if (cnt === 0) {
      return;
    }

    const condition: TalentListCondition[] = [];
    condition[0] = {
      type: Constant.tlSexType,
      id: sex_id
    };
    this.toListWithCondition(condition);
  }

  // 年齢指定でタレントリストへ
  toListAge(detail) {
    if ( detail.cnt === 0) {
      return;
    }

    const condition: TalentListCondition[] = [];
    if (detail.start) {
      condition.push({
        type: Constant.tlTalentAgeFromType,
        id: detail.start
      });
    }
    if (detail.end) {
      condition.push({
        type: Constant.tlTalentAgeToType,
        id: detail.end
      });
    }
    if (!detail.start && !detail.end) {
      condition.push({
        type: Constant.tlTalentAgeTypeNone,
        id: true
      });
    }
    this.toListWithCondition(condition);
  }

  // 未読メールあり
  onUnreadMail() {
    const condition: TalentListCondition[] = [];
    condition[0] = {
      type: Constant.tlMsgStatusType,
      id: Constant.tlMsgStatusReceivedUnread
    };
    this.toListWithCondition(condition);
  }

  // 未対応あり
  onNoAction() {
    const condition: TalentListCondition[] = [];
    condition[0] = {
      type: Constant.offerActionType,
      id: Constant.offerActionApply
    };
    condition[1] = {
      type: Constant.offerReactType,
      id: Constant.offerReplyNoReply
    };
    this.toListWithCondition(condition);
  }

  // タレントマップ情報取得
  private getTalentMapInfo(session): void {
    const apiPath = '/talentmap';
    const options = this.auth.createApiHeader(session);

    this.commonUtil
      .apiPost(apiPath, options)
      .then(res => {
        this.mapData = res.data;
        this.convertGraduateData();
        this.convertAgeData();
        this.showSpinner = false;
      })
      .catch(err => {
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        this.showSpinner = false;
      });
  }

  // タレント獲得パフォーマンス取得
  private getPageviewInfo(session): void {
    const apiPath = '/talentmap/pageview';
    const options = this.auth.createApiHeader(session);

    this.commonUtil
      .apiGet(apiPath, options)
      .then(res => {
        this.pvData = res.data;
        this.showSpinnerPv = false;
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        this.showSpinnerPv = false;
      });
  }

  private checkUpdate(session): void {
    const apiPath = '/talentmap/checkupdate';
    const options = this.auth.createApiHeader(session);

    this.commonUtil
      .apiGet(apiPath, options)
      .then(res => {
        this.updateFlg = res.data;
      })
      .catch(err => { });
  }

  // 採用枠ごとの人数集計
  private convertGraduateData() {
    const master = this.mapData['graduate_master'];
    const data = this.mapData['graduate_data'];
    master.forEach(m => {
      const cntData = data.filter(function(item) {
        return item.graduate_year_type === m.id;
      });
      let cnt = 0;
      if (cntData.length > 0) {
        cnt = cntData[0].cnt;
      }
      m.cnt = cnt;
    });

    // 新卒と中途採用に分ける
    const newGraduateYear = this.commonUtil.getNewGraduateYear();
    master.forEach(m => {
      if (m.id >= newGraduateYear) {
        this.graduateSinsotsu.push(m);
        this.sinsotsuCnt += m.cnt;
      } else if (m.id !== 0) {
        this.graduateChuto.push(m);
        this.chutoCnt += m.cnt;
      } else {
        this.graduateOther.push(m);
        this.otherCnt += m.cnt;
      }
    });

    // 未登録を追加
    const nullData = data.filter(function(item) {
      return item.graduate_year_type === null;
    });
    let nullCnt = 0;
    if (nullData.length > 0) {
      nullCnt = nullData[0].cnt;
    }
    this.graduateOther.push({id: 1, item_value: '未登録', cnt: nullCnt});
    this.otherCnt += nullCnt;
  }

  // 年齢別の人数集計
  private convertAgeData() {
    const data = this.mapData['age_data'];
    const ageDataAry = [];
    ageDataAry.push({name: '〜24歳', cnt: data['age24'], start: null, end: 24});
    ageDataAry.push({name: '25〜29歳', cnt: data['age25'], start: 25, end: 29});
    ageDataAry.push({name: '30〜34歳', cnt: data['age30'], start: 30, end: 34});
    ageDataAry.push({name: '35〜39歳', cnt: data['age35'], start: 35, end: 39});
    ageDataAry.push({name: '40〜44歳', cnt: data['age40'], start: 40, end: 44});
    ageDataAry.push({name: '45〜49歳', cnt: data['age45'], start: 45, end: 49});
    ageDataAry.push({name: '50歳〜', cnt: data['age50'], start: 50, end: null});
    ageDataAry.push({name: '未登録', cnt: data['age0'], start: null, end: null});
    this.mapData['age_data_ary'] = ageDataAry;
  }

  // チュートリアル表示
  private showUserGuide() {
    if (localStorage.getItem(Constant.lsShowTutorial) !== Constant.lsTrue) {
      return;
    }

    this.dialog.open(UserguideDialogComponent, {
      width: '800px',
      autoFocus: false,
      disableClose: true,
    });
    localStorage.removeItem(Constant.lsShowTutorial);
  }

  // タレントリストへ遷移
  private toListWithCondition(condition) {
    localStorage.setItem(Constant.lsTalentListCondition, JSON.stringify(condition));
    this.router.navigate(['/list']);
  }
}
