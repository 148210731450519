<nav class="float-menu">
  <div class="logo-area">
    <a routerLink="/"><img src="../../assets/lp/logo_blue.png" (click)="onTopPage()" height="30" class="logo"/></a>
    <span class="color-navy"><b>タレントプールを<br>手軽に・身近に</b></span>
    <div class="show-pc flex">
      <a class="menu" routerLink="/" (click)="onTopPage()" [class.active]="page=='top'">トップ</a>
      <a class="menu" routerLink="/{{ Constant.rtLpNewgrad }}" [class.active]="page=='newgrad'">新卒採用</a>
      <a class="menu" routerLink="/{{ Constant.rtLpCareer }}" [class.active]="page=='career'">中途採用</a>
      <a class="menu" routerLink="/case" [class.active]="page=='case'">活用例</a>
      <a class="menu" routerLink="/faq" [class.active]="page=='faq'">FAQ</a>
      <a class="menu" href="{{Constant.footerUrlContact}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionMenuContact)">
        お問い合わせ <img src="../../assets/lp/launch_gray.png" width="13" height="13">
      </a>
    </div>
  </div>
  <div class="button-area show-pc">
    <a class="btn doc-request-btn" (click)="onClickGAEvent(Constant.gaActionMenuWhitepaper)"
      routerLink="/{{Constant.rtRequest}}/{{Constant.modeWhitePaper}}">
      資料請求
    </a>
  </div>
  <div class="show-sp mb-menu">
    <button mat-icon-button #trigger [matMenuTriggerFor]="menu" class="menu-btn" (click)="onMenuOpen()">
      <img src="../../assets/lp/btnMenu.png" width="20">
    </button>
    <mat-menu #menu="matMenu" backdropClass="menu-backdrop" [overlapTrigger]="true">
      <div (click)="onDisabledMenu($event)">
        <div class="mb-menu-close">
          <button mat-icon-button (click)="onMenuClose()"><img src="../../assets/lp/btnClose.png" width="20"></button>
        </div>
        <div class="logo" mat-menu-item [disabled]="true">
          <img src="../../assets/lp/logo_blue.png" height="30" />タレントプール採用サービス
        </div>
        <a mat-menu-item class="mb-menu" routerLink="/" (click)="onTopPage()">トップ</a>
        <a mat-menu-item class="mb-menu" routerLink="/{{ Constant.rtLpNewgrad }}">新卒採用</a>
        <a mat-menu-item class="mb-menu" routerLink="/{{ Constant.rtLpCareer }}">中途採用</a>
        <a mat-menu-item class="mb-menu" routerLink="/case">活用例</a>
        <a mat-menu-item class="mb-menu" routerLink="/faq">FAQ</a>
        <a mat-menu-item class="mb-menu" href="{{Constant.footerUrlContact}}" target="_blank" (click)="onClickGAEvent(Constant.gaActionMenuContact)">
          <div class="mb-menu-item-flex">
            <div>お問い合わせ</div>
            <img src="../../assets/lp/launch_gray.png" width="13" height="13">
          </div>
        </a>
        <div class="mb-menu-btn">
          <a mat-button class="btn doc-request-btn" (click)="onClickGAEvent(Constant.gaActionMenuWhitepaper)"
            routerLink="/{{Constant.rtRequest}}/{{Constant.modeWhitePaper}}">
            資料請求
          </a>
        </div>
      </div>
    </mat-menu>
  </div>
</nav>
