import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-analytics-select-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './analytics-select-dialog.component.html',
  styleUrl: './analytics-select-dialog.component.css'
})
export class AnalyticsSelectDialogComponent {
  public readonly Constant = Constant
  public showSpinner = true
  public type // 種別（記事/求人/資料）
  public dataList  // 一覧
  public allCnt  // すべて

  constructor(
    public dialogRef: MatDialogRef<AnalyticsSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) { }

  ngOnInit(): void {
    if (!this.commonUtil.checkOnline()) {
      this.dialogRef.close();
    }
    this.type = this.data.type;

    this.showSpinner = true
    // マスタ取得
    this.getMaster();

  }

  // 選択
  onSelect(id) {
    // 選択したIDを元画面に戻す
    // すべての場合は0
    this.dialogRef.close(id)
  }

  // マスタ取得
  private getMaster() {
    Auth.currentSession().then(session => {
      let apiPath
      if (this.type == Constant.alTypeContents) {
        apiPath = '/analytics/prcontent/master'
      } else if (this.type == Constant.alTypeJoboffer) {
        apiPath = '/analytics/joboffer/master'
      } else if (this.type == Constant.alTypeMaterial) {
        apiPath = '/analytics/document/master'
      }

      const options = this.auth.createApiHeader(session)
      this.commonUtil.apiGet(apiPath, options).then(res => {
        this.dataList = res.data.list;
        this.allCnt = res.data.all
        this.showSpinner = false

      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
        this.dialogRef.close();
      });
    });
  }
}
