import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Constant } from '../constant';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtil } from '../util/common-util';
import { MomentUtil } from '../util/moment-util';
import { ToastUtil } from '../util/toast-util';
import { ChartjsUtil } from '../util/chartjs-util';
import { Auth } from '@aws-amplify/auth';
import { Router, RouterModule } from '@angular/router';
import { TalentListCondition } from '../model/talent-list-condition';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {
  public readonly Constant = Constant;
  public showSpinner = false;
  public totalData
  public actionInfo  // 対応状況
  public newsInfo // お知らせ
  public startDay
  public endDay
  public actionLabel = {
    contents_good: '記事にいいね',
    contents_share_history: '記事をシェア',
    document_good: '資料にいいね',
    document_share_history: '資料をシェア',
    profile_update: 'プロフィールを更新',
    question_update: '追加プロフィールの質問に回答'
  }

  private chartTalent
  private chartPreference

  @ViewChild('canvasTalent') canvasTalent: ElementRef;
  @ViewChild('canvasPreference') canvasPreference: ElementRef;

  constructor(
      private title: Title,
      private router: Router,
      private auth: AuthService,
      private dialog: MatDialog,
      private commonUtil: CommonUtil,
      private momentUtil: MomentUtil,
      private toastUtil: ToastUtil,
      private chartjsUtil: ChartjsUtil,
    ) {
      this.title.setTitle(Constant.pageTitleTalentDashboard + Constant.pageTitleCommon);
    }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    // 表示データ取得
    this.getTalentTotal()

    // 新着確認
    this.checkUpdate()

    // タレントアクション
    this.getTalentAction()
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  // タレントカルテ表示
  onShowTalentKarte(id: number) {
    let url = 'detail/';
    window.open(url + id);
  }

  // タレントリスt遷移
  onToList(type, id, type2?, id2?) {
    const condition: TalentListCondition[] = [];
    condition[0] = {
      type: type,
      id: id
    };
    if (type2) {
      condition[1] = {
        type: type2,
        id: id2
      };
    }
    localStorage.setItem(Constant.lsTalentListCondition, JSON.stringify(condition));
    this.router.navigate(['/list']);
  }

  private getTalentTotal() {
    // 日時は14日前から固定
    const today = new Date()
    this.startDay = new Date(today.getTime())
    this.startDay.setDate(this.startDay.getDate() - 13)
    this.endDay = today
    this.showSpinner = true
    Auth.currentSession().then(session => {
      const apiPath = '/analytics/talenttotal/'
      const options = this.auth.createApiHeader(session)
      options['body'] = {
        start_date: this.momentUtil.getDateFormat(this.startDay),
        end_date: this.momentUtil.getDateFormat(this.endDay),
      };
      this.commonUtil.apiPost(apiPath, options).then(res => {
        this.totalData = res.data
        if (this.totalData.list) {
          // タレント数推移
          const chartData = this.createChartData(this.totalData.list)
          setTimeout(() => {
            this.chartTalent = this.chartjsUtil.drawLineChartFill(chartData, this.canvasTalent, this.chartTalent, Constant.alChartTypeTalent)
          });
        }
        // プレファレンススコアチャート描画
        const score = this.totalData.preference_score
        setTimeout(() => {
          this.chartTalent = this.chartjsUtil.drawPreferenceChart(score, this.canvasPreference, this.chartPreference)
        });

        this.showSpinner = false

      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });

  }

  // 新着確認
  private checkUpdate(): void {
    const apiPath = '/talentmap/checkupdate';
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          this.actionInfo = res.data;

          // 急上昇タレントの円グラフ描画
          setTimeout(() => {
            this.createTalentScoreChart()
          }, 100);

        })
        .catch(err => { });
      })
  }

  // タレントアクション
  private getTalentAction(): void {
    const apiPath = '/analytics/talentactionlist';
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session)
      options['body'] = {
        limit: 100
      };

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.newsInfo = res.data;
        })
        .catch(err => { });
      })
  }

  // チャート用データ整形
  createChartData(originData) {
    const labelAry = []
    const chartAry = []
    originData.forEach(element => {
      const date = new Date(element.target_date)
      labelAry.push((date.getMonth() + 1).toString() + '/' + date.getDate().toString())
      chartAry.push(element.count)
    });

    return {
      label: labelAry,
      chart: chartAry
    }
  }

  private createTalentScoreChart() {
    this.actionInfo.preference_top.forEach((element, idx) => {
      const canvas = document.getElementById('score-top-'+idx)
      const score = element.preference_score
      this.chartjsUtil.drawPreferenceChartMini(score, canvas)
    });
  }


}
