<div class="title-area">
  <h2 mat-subheader class="title">PDF資料</h2>
  <div class="btn-area">
    <button mat-raised-button type="button" color="warn" [disabled]="showSpinner" (click)="onEdit()">
      新規作成
    </button>
  </div>
</div>
<div class="desc">
  資料内PVはPDF資料内の各ページを表示した総回数です。読了率は、資料全体の何％まで閲覧したか、全タレントの中央値です。<br>
  各アクション数の上段は「全体数」で、タレントプール未登録/解除済のタレントによるものを含みます。<br>
  下段の（　）内は、タレントプール登録中のタレントによるアクション数です。<br>
</div>

<div *ngIf="showSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<table *ngIf="!showSpinner" class="table">
  <tr>
    <th class="use">ステータス</th>
    <th colspan="2">資料</th>
    <th class="cnt">資料内PV</th>
    <th class="cnt">シェア</th>
    <th class="cnt">いいね</th>
    <th class="cnt">読了率</th>
    <th class="btn-edit">Menu</th>
  </tr>
  <ng-container *ngFor="let doc of materials; index as idx">
    <tr class="inuse{{ doc.release_status }}">
      <!-- ステータス -->
      <td rowspan="2" class="use" *ngIf="doc.release_status == Constant.pcReleaseStatusRelease">
        @if (doc.limited_flag != 1) {
          <img src="assets/notice/use-check.png" srcset="
            assets/notice/use-check.png    1x,
            assets/notice/use-check@2x.png 2x,
            assets/notice/use-check@3x.svg 3x">
        } @else {
          <img src="assets/common/lock_blue.svg" class="limited-icon">
        }
        <br />公開中
      </td>
      <td rowspan="2" class="use" *ngIf="doc.release_status == Constant.pcReleaseStatusStop">
        @if (doc.limited_flag != 1) {
          <img src="assets/notice/stop-check.png" srcset="
            assets/notice/stop-check.png    1x,
            assets/notice/stop-check@2x.png 2x,
            assets/notice/stop-check@3x.svg 3x">
        } @else {
          <img src="assets/common/lock_gray.svg" class="limited-icon">
        }
        <br />非公開
      </td>
      <!-- 画像 -->
      <td class="image-container" rowspan="2">
        <div *ngIf="doc.image_url == Constant.empty" class="image noimage">
          <span>No Image</span>
        </div>
        <img *ngIf="doc.image_url != Constant.empty" src="{{ doc.image_url }}" class="image" />
      </td>
      <!-- タイトル -->
      <td class="title-row">
        <div class="top-container">
          <span class="top" *ngIf="doc.top_flag==1">PICK UP表示</span>
        </div>
        <a (click)="onDetail(idx)">{{ doc.title }}</a>
      </td>
      <!-- PV数 -->
      <td class="cnt" rowspan="2">
        {{ doc.pv_cnt }}<br>
        （<a (click)="onToList(Constant.dcActionView, idx)">{{ doc.pv_cnt_talent }}</a>）
      </td>
      <!-- シェア -->
      <td class="cnt" rowspan="2">
        {{ doc.share_cnt }}<br>
        （<a (click)="onToList(Constant.dcActionShare, idx)">{{ doc.share_cnt_talent }}</a>）
      </td>
      <!-- いいね -->
      <td class="cnt" rowspan="2">
        {{ doc.good_cnt }}<br>
        （<a (click)="onToList(Constant.dcActionGood, idx)">{{ doc.good_cnt_talent }}</a>）
      </td>
      <!-- 読了率 -->
      <td class="cnt" rowspan="2">
        {{ doc.reading_ratio }}%<br>
        （<a (click)="onToList(Constant.dcActionView, idx)">{{ doc.reading_ratio_talent }}%</a>）
      </td>
      <!-- メニュー -->
      <td class="btn-edit" rowspan="2">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-btn">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" overlapTrigger="false">
          <button mat-menu-item (click)="onDetail(idx)">詳細</button>
          <button mat-menu-item (click)="onEdit(idx)">編集</button>
          <button mat-menu-item (click)="onRelease(idx, false)"
            *ngIf="doc.release_status == Constant.pcReleaseStatusRelease">
            非公開にする
          </button>
          <button mat-menu-item (click)="onRelease(idx, true)" *ngIf="
              doc.release_status == Constant.pcReleaseStatusNoRelease ||
              doc.release_status == Constant.pcReleaseStatusStop
            ">
            公開する
          </button>
          <button mat-menu-item (click)="onDel(idx)">削除</button>
        </mat-menu>
      </td>
    </tr>
    <!-- URL -->
    <tr class="inuse{{ doc.release_status }} border-bottom">
      <td colspan="1" class="url-container">
        <div class="url-text-container">
          <div class="text">URL</div>
          <div class="url"><a href="{{ doc.url }}" target="_blank">{{ doc.url }}</a></div>
          <div class="copy" (click)="onUrlCopy(idx)">
            <img
              src="assets/notice/copy-icon.png"
              srcset="
                assets/notice/copy-icon.png    1x,
                assets/notice/copy-icon@2x.png 2x,
                assets/notice/copy-icon@3x.svg 3x
              "
            />
            URLをコピー
          </div>
          <input id="url-code-{{ idx }}" type="hidden" value="{{ doc.url }}" />
        </div>
      </td>
    </tr>
  </ng-container>
</table>

<div
  *ngIf="!showSpinner && materials && materials.length == 0"
  class="no-data"
>
  資料がありません。資料を作成してください。
</div>
