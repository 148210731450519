<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">カテゴリー設定</div>
    </div>
    <div class="list-area">
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <form [formGroup]="form" *ngIf="!showSpinner">
        <div class="flex">
          <div *ngFor="let item of category; let i = index;" class="col">
            <div class="label-count" [class.length-red]="title.value.length>maxLenTitle">{{ title.value.length }}/{{ maxLenTitle }}</div>
            <div class="title-flex">
              <div>{{ item.order_no}}:</div>
              <mat-form-field class="input-area no-line">
                <input matInput type="text" [formControlName]="'title' + item.order_no" [maxlength]="maxLenTitle" #title />
              </mat-form-field>
            </div>

          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="showSpinner || !form || form.invalid || isSaving" class="yes-btn">保存</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
