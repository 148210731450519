export class Constant {
  static readonly OK = 'OK';
  static readonly NG = 'NG';
  static readonly VersionNG = 'VersionNG';
  static readonly MaintenanceNG = 'MaintenanceNG';
  static readonly NetworkError = 'Network Error';
  static readonly timeoutError = 'timeout';

  // ローカルストレージのキー
  static readonly lsCompanyCode = 'lsCompanyCode';
  static readonly lsCompanyName = 'lsCompanyName';
  static readonly lsTalentListCondition = 'lsTalentListCondition';
  static readonly lsMultiMessageText = 'lsMultiMessageText';
  static readonly lsMultiMessageDetail = 'lsMultiMessageDetail';
  static readonly lsLoginStatus = 'lsLoginStatus';
  static readonly lsOperator = 'lsOperator';
  static readonly lsShowTutorial = 'lsShowTutorial';
  static readonly lsShowFilePath = 'lsShowFilePath';
  static readonly lsShowFileName= 'lsShowFileName';
  static readonly lsTrue = 'true';
  static readonly lsFalse = 'false';

  // API名
  static readonly apiNameCompany = 'company';
  static readonly apiNameCorp = 'corp';
  static readonly apiNameTarent = '/talent';
  static readonly apiNameUrl = '/url';
  static readonly apiPathComponent = '/';
  static readonly apiTimeoutSecond = 30000;

  // タレントリスト
  static readonly tlDataStatusTypeIdNew = 41;
  static readonly tlDataStatusTypeIdUpdate = 42;
  static readonly tlDataStatusTypeIdUnsubscribe = 359;
  static readonly tlMsgNotReaded = 0;
  static readonly tlMsgAlreadyReaded = 1;
  static readonly tlMsgTypeSended = 1;
  static readonly tlMsgTypeReceived = 2;
  static readonly tlCareerTypeNew = 1;
  static readonly tlCareerTypeMiddle = 2;
  static readonly tlCareerTypeOther = 3;
  static readonly tlGraduateYearMin = 2018;
  static readonly tlGraduateYearOther = 0;
  static readonly tlContactTagNullId = 0;
  static readonly tlTalentFavoriteOn = 1;
  static readonly tlTalentFavoriteOff = 0;
  static readonly tlMsgStatusReceivedUnread = 324;
  static readonly tlOrderDataType = 'ORDER_DATA_TYPE';
  static readonly tlOrderMsgType = 'ORDER_MSG_TYPE';
  static readonly tlOrderOfrApplyType = 'ORDER_OFR_APPLY_TYPE';
  static readonly tlOrderOfrMindType = 'ORDER_OFR_MIND_TYPE';
  static readonly tlOrderPoolType = 'ORDER_POOL_TYPE';
  static readonly tlOrderAccessType = 'ORDER_ACCESS_TYPE';
  static readonly tlOrderCompanyType = 'ORDER_COMPANY_TYPE';
  static readonly tlOrderDefault = 'ORDER_DEFAULT_TYPE';
  static readonly tlJobOfferActionStartType = 'JOB_OFFER_ACTION_START_TYPE';
  static readonly tlJobOfferActionEndType = 'JOB_OFFER_ACTION_END_TYPE';
  static readonly tlContentsActionStartType = 'CONTENTS_ACTION_START_TYPE';
  static readonly tlContentsActionEndType = 'CONTENTS_ACTION_END_TYPE';
  static readonly tlContactTagType = 'CONTACT_TAG_TYPE';
  static readonly tlContactTagPeriodType = 'CONTACT_TAG_PERIOD_TYPE';
  static readonly tlContactTagStartType = 'CONTACT_TAG_START_TYPE';
  static readonly tlContactTagEndType = 'CONTACT_TAG_END_TYPE';
  static readonly tlRegisteredDateType = 'REGISTERED_DATE_TYPE';
  static readonly tlRegisteredDateStartType = 'REGISTERED_DATE_START_TYPE';
  static readonly tlRegisteredDateEndType = 'REGISTERED_DATE_END_TYPE';
  static readonly tlTalentNameType = 'TALENT_NAME_TYPE';
  static readonly tlGraduateYearType = 'GRADUATE_YEAR_TYPE';
  static readonly tlGraduateStartYearType = 'GRADUATE_START_YEAR_TYPE';
  static readonly tlGraduateEndYearType = 'GRADUATE_END_YEAR_TYPE';
  static readonly tlActivitiesType = 'ACTIVITIES_TYPE';
  static readonly tlActivitiesStartYearType = 'ACTIVITIES_START_YEAR_TYPE';
  static readonly tlActivitiesStartMonthType = 'ACTIVITIES_START_MONTH_TYPE';
  static readonly tlActivitiesEndYearType = 'ACTIVITIES_END_YEAR_TYPE';
  static readonly tlActivitiesEndMonthType = 'ACTIVITIES_END_MONTH_TYPE';
  static readonly tlDesireJobType = 'DESIRE_JOB_TYPE';
  static readonly tlExpJobType = 'EXP_JOB_TYPE';
  static readonly tlExpJobLatestType = 'EXP_JOB_LATEST_TYPE';
  static readonly tlSchoolType = 'SCHOOL_TYPE';
  static readonly tlBunriType = 'BUNRI_TYPE';
  static readonly tlExceptDropoutType = 'EXCEPT_DROPOUT_TYPE';
  static readonly tlTalentAgeFromType = 'TALENT_AGE_FROM_TYPE';
  static readonly tlTalentAgeToType = 'TALENT_AGE_TO_TYPE';
  static readonly tlTalentAgeTypeNone = 'TALENT_AGE_TYPE_NONE';
  static readonly tlSexType = 'SEX_TYPE';
  static readonly tlTalentFavoriteType = 'TALENT_FAVORITE_TYPE';
  static readonly tlDataStatusType = 'DATA_STATUS_TYPE';
  static readonly tlMsgStatusType = 'MSG_STATUS_TYPE';
  static readonly tlMultiMsgSendType = 'MULTI_MSG_SEND_TYPE';
  static readonly tlMultiMsgActionType = 'MULTI_MSG_ACTION_TYPE';
  static readonly tlTalentMultiMsgType = 'TALENT_MULTI_MSG_TYPE';
  static readonly tlMultiMsgExceptType = 'MULTI_MSG_EXCEPT_TYPE';
  static readonly tlTalentTagType = 'TALENT_TAG_TYPE';
  static readonly tlTalentRegistType = 'TALENT_REGIST_TYPE';
  static readonly tlAddQuestionType = 'ADD_QUESTION_TYPE';
  static readonly tlDesireIncomeFromType = 'DESIRE_INCOME_FROM_TYPE';
  static readonly tlDesireIncomeToType = 'DESIRE_INCOME_TO_TYPE';
  static readonly tlDesireIncomeTypeNone = 'DESIRE_INCOME_TYPE_NONE';
  static readonly tlSexIDMen = 45;
  static readonly tlSexIDWomen = 46;
  static readonly tlSexIDOther = -1;
  static readonly tlOfferExistNotReply = 1;
  static readonly tlSearchKindTalentName = '3';
  static readonly tlSearchKindDesireJob = '6';
  static readonly tlSearchKindJobOfferTitle = '7';
  static readonly tlSearchKindJobPlace = '8';
  static readonly tlSearchKindContact = '9';
  static readonly tlSearchKindPeriod = '11';
  static readonly tlActiveUndecided = '1';
  static readonly tlSearchCategoryOfferAction = '求人応募';
  static readonly tlSearchCategoryContents = 'コンテンツ閲覧';
  static readonly tlSearchCategoryContactTag = '接点・経路';
  static readonly tlSearchCategoryHistory = '学歴・職歴';
  static readonly tlItemPerPage = 10;
  static readonly tlPeriodTypeThisMonth = 1;
  static readonly tlPeriodTypeLastMonth = 2;
  static readonly tlPeriodTypeTwoMonthAgo = 3;
  static readonly tlSchoolTypeNone = 358;
  static readonly tlExpJobTypeNone = 23;
  static readonly tlRegistTypeTalent = 0;
  static readonly tlRegistTypeCsv = 1;

  // タレントカルテ
  static readonly msgUnReaded = 0;
  static readonly tdMsgTypeIdCompany = 1; // 未使用
  static readonly tdMsgTypeIdTalent = 2;
  static readonly tdMsgTypeIdCompanyAll = 3; // 未使用
  static readonly offerReplyEditOff = 1;
  static readonly offerReplyEditOn = 2;
  static readonly offerReplyEditCancel = 3;
  static readonly profileNotModify = -1;
  static readonly carteMsgInfoAboutLink = 'リンク化したURLは、タレントによる押下を計測できます。\nURLをリンク化するには、https:// を含み、末尾をスペースや改行で区切ってください。';

  // 採用PRコンテンツ
  static readonly prContentTagType = 'CONTENTS_TAG';
  static readonly pcMsgComfirmStop = '採用PRコンテンツを「非公開」 にします。\nよろしいですか。';
  static readonly pcMsgComfirmRelease = '採用PRコンテンツを「公開中」 にします。\nよろしいですか。';
  static readonly pcMsgComfirmDel = '採用PRコンテンツを「削除」 します。\nタレント毎に計測した閲覧数やいいねも消失します。\nよろしいですか。';
  static readonly pcMsgComfirmDelCategory = 'カテゴリーを削除します。\nよろしいですか。';
  static readonly pcMsgConfirmEditCancel = 'コンテンツの%sをキャンセルします。\n内容は保存されません。\nよろしいですか？';
  static readonly pcReleaseStatusNoRelease = 0;
  static readonly pcReleaseStatusRelease = 1;
  static readonly pcReleaseStatusStop = 2;
  static readonly pcTitleType = 'CONTENTS_TITLE_TYPE';
  static readonly pcStatusType = 'CONTENTS_STATUS_TYPE';
  static readonly pcActionType = 'CONTENTS_ACTION_TYPE';
  static readonly pcActionGood = 309;
  static readonly pcActionShare = 385;
  static readonly pcActionMore = 310;
  static readonly pcActionNone = 311;

  // 求人情報管理
  static readonly joReleaseStatusNoRelease = 0;
  static readonly joReleaseStatusRelease = 1;
  static readonly joReleaseStatusStop = 2;
  static readonly joTypeAdd = '作成';
  static readonly joTypeEdit = '編集';
  static readonly joEmploymentType = 'DESIRE_EMPLOYEEMENT_TYPE';
  static readonly joJobPlaceType = 'DESIRE_JOB_PLACE_TYPE';
  static readonly joJobType = 'DESIRE_JOB_TYPE';
  static readonly joContractPeriodType = 'JOB_OFFER_CONTRACT_PERIOD_TYPE';
  static readonly joTrialPeriodType = 'JOB_OFFER_TRIAL_PERIOD_TYPE';
  static readonly joSalaryType = 'JOB_OFFER_SALARY_TYPE';
  static readonly joFixedOvertimeType = 'JOB_OFFER_FIXED_OVERTIME_TYPE';
  static readonly joMsgComfirmStop = '求人情報を「休止中」にします。\nよろしいですか。';
  static readonly joMsgComfirmRelease = '求人情報を「公開中」にします。\nよろしいですか。';
  static readonly joMsgComfirmDel = '求人情報を「削除」します。\nよろしいですか。';
  static readonly joMsgComfirmDelCant = '気になるや応募があるため、この求人情報は「削除」できません。';
  static readonly joMsgComfirmCopy = '求人情報を「コピー」します。\nよろしいですか。';
  static readonly joMsgConfirmEditCancel = '求人情報の%sをキャンセルします。\n内容は保存されません。\nよろしいですか？';
  static readonly joMsgConfirmEditCancel2 = '求人一覧ページの登録をキャンセルします。\n内容は保存されません。\nよろしいですか？';
  static readonly joCategoryNotSelected = '未選択';
  static readonly offerActionApply = 252;
  static readonly offerActionMind = 253;
  static readonly offerActionShare = 386;
  static readonly offerActionNone = 323;
  static readonly offerActionView = 322;
  static readonly offerReplyNoReply = 255;
  static readonly offerReplyAdopt = 257;
  static readonly employmentTypeRegular = 35;
  static readonly contractPeriodTypeNone = 365;
  static readonly trialPeriodTypeNone = 367;
  static readonly salaryTypeTime = 370;
  static readonly salaryTypeDay = 371;
  static readonly salaryTypeMonth = 372;
  static readonly salaryTypeNensyu = 373;
  static readonly salaryTypeNenpou = 374;
  static readonly fixedOvertimeNone = 378;
  static readonly offerTitleType = 'JOB_OFFER_TITLE_TYPE';
  static readonly offerStatusType = 'JOB_OFFER_STATUS_TYPE';
  static readonly offerActionType = 'JOB_OFFER_ACTION_TYPE';
  static readonly offerReactType = 'JOB_OFFER_REACT_TYPE';

  // 企業情報
  static readonly companyInfoIndustryType = 'COMPANY_INDUSTRY';
  static readonly companyInfoEmploymentAreaType = 'DESIRE_JOB_PLACE_TYPE';
  static readonly companyTextSelectIndustry = '業種選択';
  static readonly companyTextSelectEmploymentArea = '勤務地（採用エリア）選択';
  static readonly companyTextSelectSubJobPlace = '勤務地（追加）選択';
  static readonly companyUpdateTypeProfile = 'profile';
  static readonly companyUpdateTypeMessage = 'message';
  static readonly companyUpdateTypeAbout = 'about';
  static readonly companyUpdateTypeColor = 'color';
  static readonly companyImageCompanyLogo = 'logo_path';
  static readonly companyImagePrImage = 'pr_image_path';
  static readonly companyDocument = 'document_path';
  static readonly companyMainColor = '25a99f';
  static readonly companyMsgConfirmEditCancel = '%sの保存をキャンセルします。\nよろしいですか？';

  // リッチテキストエディタ
  static readonly editorTypeCompany = 'company';
  static readonly editorTypePrcontent = 'prcontent';
  static readonly editorTypeJoboffer = 'joboffer';
  static readonly editorTypeJobBase = 'jobbase';

  // 告知用URL
  static readonly noticeTypeEdit = 'edit';
  static readonly noticeTypeAdd = 'add';
  static readonly noticeTypeDel = 'del';
  static readonly noticeTypeTalentDel = 'talent-del';

  // 追加質問
  static readonly aqAnswerTypeTextId = 360;
  static readonly aqAnswerTypeOneSelectId = 361;
  static readonly aqAnswerTypeMultiSelectId = 362;
  static readonly aqMsgComfirmDel = '質問を「削除」 します。\n' + 'タレントが回答した結果も全て消失します。'.fontcolor('red') + '\nよろしいですか。';

  // タレント管理タグ
  static readonly noticeTypeTalenttagDel = 'talenttag-del';

  // タレント作成・CSV取り込み
  static readonly csvTalentTypeAdd = '作成';
  static readonly csvTalentTypeEdit = '更新';
  static readonly csvTalentTypeDel = '削除';
  static readonly csvTalentConfirmFileUp = 'ファイルを送信（アップロード）します。\nよろしいですか。';

  // APIパラメータ
  static readonly apBase = 'base';
  static readonly apCareers = 'careers';
  static readonly apSchools = 'schools';
  static readonly apActivePlannedEnd = 'active_planned_end';
  static readonly apActivePlannedStart = 'active_planned_start';
  static readonly apActiveUndecidedFlag = 'active_undecided_flag';
  static readonly apGraduateYearType = 'graduate_year_type';
  static readonly apGraduateYearTypeName = 'graduate_year_type_name';
  static readonly apSchoolTypeId = 'school_type_id';
  static readonly apSchoolTypeName = 'school_type';
  static readonly apDropout = 'dropout_flag';
  static readonly apAddress1 = 'address1';
  static readonly apAddress2 = 'address2';
  static readonly apBirthday = 'birthday';
  static readonly apBirthdayYear = 'birthday_year';
  static readonly apBirthdayMonth = 'birthday_month';
  static readonly apBirthdayDay = 'birthday_day';
  static readonly apDesireEmployeement = 'desire_employeement';
  static readonly apDesireEmployeementId = 'desire_employeement_id';
  static readonly apDesirejob = 'desirejob';
  static readonly apDesirejobPlace = 'desirejob_place';
  static readonly apDesirejobUndecidedFlag = 'desirejob_undecided_flag';
  static readonly apDesireAnnualIncome = 'desire_annual_income';
  static readonly apFirstName = 'first_name';
  static readonly apFirstNameKana = 'first_name_kana';
  static readonly apImagePath = 'image_path';
  static readonly apLastName = 'last_name';
  static readonly apLastNameKana = 'last_name_kana';
  static readonly apMailAddress = 'mail_address';
  static readonly apSex = 'sex';
  static readonly apSexId = 'sex_id';
  static readonly apTelno = 'telno';
  static readonly apCategory = 'category';
  static readonly apJoinDate = 'join_date';
  static readonly apExitDate = 'exit_date';
  static readonly apAdmissionDate = 'admission_date';
  static readonly apWorkCareer = 'work_career';
  static readonly apWcDesirejob = 'wc_desirejob';
  static readonly apMainId = 'main_id';
  static readonly apItemValue = 'item_value';
  static readonly apLargeItemValue = 'large_item_value';
  static readonly apLargeId = 'large_id';
  static readonly apOnlyDesirePlace = 'only_desire_place';
  static readonly apCompanyIndustry = 'company_industry';
  static readonly apDesirePlace = 'desire_place';
  static readonly apCommentCareer = 'comment_career';
  static readonly apCommentSchool = 'comment_school';
  static readonly apCommentTalent = 'comment_talent';
  static readonly apCompanyName = 'company_name';
  static readonly apEmployeementTypeId = 'employeement_type_id';
  static readonly apAnnualIncome = 'annual_income';
  static readonly apPosition = 'position';
  static readonly apDepartment = 'department';
  static readonly apJobDetail = 'job_detail';
  static readonly apSkill = 'skill';
  static readonly apSchoolName = 'school_name';
  static readonly apBunriTypeId = 'bunri_type_id';
  static readonly apMainActivities = 'main_activities';
  static readonly apComment = 'comment';
  static readonly apOrderNo = 'order_no';
  static readonly apError = 'err';

  // 一斉メッセージ
  static readonly multiMsgEditTypeMessage = 'MultiMsgEditTypeMessage';
  static readonly multiMsgEditTypeMemo = 'MultiMsgEditTypeMemo';
  static readonly multiMsgEditTypeCsv = 'MultiMsgEditTypeCsv';
  static readonly multiMsgConfirmSend = '一斉メッセージを「送信」します。\nOKボタンを押下した時点で、最新のタレントプール登録状況などを取得し送信対象を確定します。\n\nなお、実際にタレントにメッセージが届くまで時間がかかる場合があります。';
  static readonly multiMsgConfirmCopy =
  '一斉メッセージを「コピー」して新規作成します。\n送信対象は、現時点で一斉メッセージを送信できるタレントのみが選択されます。タレントプールの登録を解消したタレントや、除外リストに登録中のタレントは選択されません。\nよろしいですか。';
  static readonly multiMsgComfirmDel = '一斉メッセージを「削除」 します。\nよろしいですか。';
  static readonly multiMsgSendSuccess = '一斉メッセージを送信しました。';
  static readonly multiMsgSendFailed = '一斉メッセージの送信に失敗しました。';
  static readonly multiMsgSendFailedRetry = '一斉メッセージ送信に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly multiMsgActionRead = 330;
  static readonly multiMsgActionUnRead = 329;

  // ファイル閲覧
  static readonly fileTypeMessage = 'message';
  static readonly fileTypeProfile = 'talent';

  // アナリティクス
  static readonly alTermUnitDayId = 331;
  static readonly alTermUnitMonthId = 332;
  static readonly alTermUnitYearId = 333;
  static readonly alTermUnitDay = 'days';
  static readonly alTermUnitMonth = 'months';
  static readonly alTermUnitYear = 'year';
  static readonly alTypeTalent = 334;
  static readonly alTypeContactAll = 335;
  static readonly alTypeContactUse = 336;
  static readonly alTypePageviewAll = 337;
  static readonly alTypePageviewUse = 338;
  static readonly alTypeLoginpageviewAll = 363;
  static readonly alTypeLoginpageviewUse = 364;
  static readonly alTypeContentsViewAll = 339;
  static readonly alTypeContentsViewUse = 340;
  static readonly alTypeContentsShareAll = 387;
  static readonly alTypeContentsShareUse = 388;
  static readonly alTypeContentsGoodAll = 341;
  static readonly alTypeContentsGoodUse = 342;
  static readonly alTypeJobofferViewAll = 343;
  static readonly alTypeJobofferViewUse = 344;
  static readonly alTypeJobofferShareAll = 389;
  static readonly alTypeJobofferShareUse = 390;
  static readonly alTypeJobofferMindAll = 345;
  static readonly alTypeJobofferMindUse = 346;
  static readonly alTypeJobofferApplyAll = 347;
  static readonly alTypeJobofferApplyUse = 348;

  // LP
  static readonly LpModeTop = 'top';
  static readonly LpModeNewgrad = 'newgrad';
  static readonly LpModeCareer = 'career';
  static readonly modeNewAccount = 'account';
  static readonly modeWhitePaper = 'whitepaper';
  static readonly modeSeminar = 'seminar';
  static readonly requestTitleAccount = `CaLinを無料ではじめる - アカウント申請`;
  static readonly requestTitleAccountCmp = '新規アカウント登録 申請を承りました';
  static readonly requestTitleWhitePaper = '資料請求';
  static readonly requestTitleWhitePaperCmp = '資料請求を承りました';
  static readonly useSceneNewgrad = 1;
  static readonly useSceneCareer = 2;
  static readonly useSceneBoth = 3;
  static readonly discountApply = 1;
  static readonly discountNotApply = 2;
  static readonly discountConsult = 3;
  static readonly msgRequestCompanyNameEmpty = '企業名を入力してください。';
  static readonly msgRequestDepartmentEmpty = '部署を入力してください。';
  static readonly msgRequestNameEmpty = '名前を入力してください。';
  static readonly msgRequestTellNoEmpty = '電話番号を入力してください。';
  static readonly msgRequestTellNoInvalid = '電話番号は正しい形式で入力してください。';
  static readonly msgRequestMailAddressEmpty = 'メールアドレスを入力してください。';
  static readonly msgRequestMailAddressInvalid = 'メールアドレスは正しい形式で入力してください';
  static readonly msgRequestHomepageEmpty = '企業ホームページを入力してください。';
  static readonly contentMain = 1;
  static readonly contentDetail = 2;

  // ダイアログ
  static readonly dialogWidth = '400px';
  static readonly offerDialogWidth = '600px';
  static readonly offerDialogHeight = '750px';
  static readonly compnayDialogWidth = '100%';
  static readonly jobOfferDialogWidth = '840px';
  static readonly jobOfferDialogMaxHeight = '90vh';
  static readonly jobOfferDialogMinHeight = '600px';
  static readonly multiSelectDialogWidth = '900px';
  static readonly analyticsDialogMinWidth = '900px';
  static readonly analyticsDialogMaxWidth = '90vw';
  static readonly analyticsDialogMinHeight = '400px';
  static readonly analyticsDialogMaxHeight = '90vh';
  static readonly tagEditDialogWidth = '600px';
  static readonly tagEditDialogHeight = '600px';
  static readonly questionEditDialogWidth = '670px';

  // URL
  static readonly footerUrlPrivacy = 'https://www.corp.calin.co.jp/privacy';
  static readonly footerUrlAgreement = 'https://www.corp.calin.co.jp/agreement-company';
  static readonly footerUrlContact = 'https://www.corp.calin.co.jp/contact';
  static readonly footerUrlCalin = 'https://www.corp.calin.co.jp/';
  static readonly footerUrlTalent = 'https://calin.co.jp/';
  static readonly calinInformationUrl = 'https://www.corp.calin.co.jp/newslist';
  static readonly moreCompanyListUrl = 'https://calin.co.jp/page/1';
  static readonly requestForCalinUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLSdXSv0ERX3I-pjRIW1JQt5Z_4RSb8GCDdO_QWgtvB7wn4fErA/viewform';
  static readonly zhieniusUrl = 'https://zhienius.co.jp/';
  static readonly zhieniusPrivacy = 'https://zhienius.co.jp/privacy-policy/';

  // メッセージ
  static readonly msgNetworkError = '通信に失敗しました。通信環境をご確認の上、再度実行するか、ページを更新してください。';
  static readonly msgMailOrPassEmpty = 'メールアドレスとパスワードは両方入力してください。';
  static readonly msgMailOrPassWrong =
    'メールアドレス、パスワード、ログインURL、いずれかが正しくありません。';
  static readonly msgFormInvalid = 'エラーを修正してください。';
  static readonly msgAccountExpired = 'アカウントの有効期限が切れています。CaLin運営へお問い合わせください。';
  static readonly msgPassInvalid = 'パスワードは英字と数字を含む8桁以上で設定してください。';
  static readonly msgPassChangeError = 'パスワードを変更できませんでした。再度ログインしてください。';
  static readonly msgLoginAttemptExceeded = '連続してログインに失敗した為、一時的にログインが制限されています。';
  static readonly msgInputSingleByteInteger = '半角整数を入力してください。';
  static readonly msgInputMoreZeroInteger = '0より大きい半角整数を入力してください。';
  static readonly msgInputZeroAndMoreInteger = '0以上の半角整数を入力してください。';
  static readonly msgInputMinBiggerThanMax = '下限の方が大きくなっています。';
  static readonly msgInputYearAndMonth = 'には年と月を入力してください。';
  static readonly msgInputCheck = 'を選択してください。';
  static readonly msgCopySuccess = 'コピーしました。';
  static readonly msgCopyError = 'コピーに失敗しました。';
  static readonly msgCopyErrorRetry = 'コピーに失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgNoticeNew = '作成しました。';
  static readonly msgNoticeNewFailed = '作成に失敗しました。';
  static readonly msgNoticeNewFailedRetry = '作成に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgNoticeEdit = '更新しました。';
  static readonly msgNoticeEditFailed = '更新に失敗しました。';
  static readonly msgNoticeSend = '送信しました。';
  static readonly msgNoticeSendFailed = '送信に失敗しました。';
  static readonly msgNoticeSendFailedRetry = '送信に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgNoticeStop = '停止中にしました。';
  static readonly msgNoticeUse = '利用中にしました。';
  static readonly msgNoticeStatusFailed = 'ステータス変更に失敗しました。';
  static readonly msgNoticeStatusFailedRetry = 'ステータス変更に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgNoticeStatusFailedContents = 'ステータス変更に失敗しました。求人内容にエラーがあります。';
  static readonly msgNoticeDel = '削除しました。';
  static readonly msgNoticeDelFailed = '削除に失敗しました。';
  static readonly msgNoticeDelFailed2 = '削除に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgNoticeUpdateError = '%sに失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgResultSaveSuccess = '保存しました。';
  static readonly msgResultSaveFailed = '保存に失敗しました。';
  static readonly msgSaveFailedRetry = '保存に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgTalentContactAdd = '接点を追加しました。';
  static readonly msgTalentContactFailed = '接点の追加に失敗しました。';
  static readonly msgTalentContactDel = '接点を削除しました。';
  static readonly msgTalenttagAdd = '管理タグを追加しました。';
  static readonly msgTalenttagAddFailed = '管理タグの追加に失敗しました。';
  static readonly msgTalenttagDel = '管理タグを削除しました。';
  static readonly msgStatusStop = '休止中にしました。';
  static readonly msgStatusRelease = '公開中にしました。';
  static readonly msgStatusNotRelease = '非公開にしました。';
  static readonly msgJobOfferEdit = '編集内容を保存しました。';
  static readonly msgErrorInvalidFile = 'ファイル形式が違います。';
  static readonly msgErrorLargeFile = 'ファイルが大きすぎます。';
  static readonly msgErrorUploadFile = 'ファイルのアップロードに失敗しました。ファイルを選択し直してください。';
  static readonly msgActionReplyEdit = '対応内容を更新しました。';
  static readonly msgConfirmDataSave = '内容を保存します。\nよろしいですか。';
  static readonly msgDuplicateJobPlace = '勤務地が重複しています。';
  static readonly msgDownloadFailed = 'ダウンロードに失敗しました。';
  static readonly msgFavoriteError = 'フラグ（星）の変更ができませんでした。';
  static readonly msgMultiMsgExceptError = '一斉メッセージ除外リストの変更ができませんでした。';
  static readonly msgDeleteError = '削除に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgAddError = '追加に失敗した可能性があります。通信状況をご確認の上、再度実行してください。';
  static readonly msgConfirmDelSendFile = '送信済ファイルを削除しますか?';
  static readonly msgDeleteFileAlreadyError = 'このファイルは既に削除済みです。';
  static readonly msgFileUploadError = '<span style="color: #ff5252;">ファイルのアップロードに失敗しました。</span>\n\nファイル形式、容量、通信状態などを確認してください。';
  static readonly msgFileDownloadError = 'ファイルのダウンロードに失敗しました。ファイル取得元のページを更新して再度実行してください。';
  static readonly msgErrorSendMessage = 'メッセージの送信に失敗しました。';
  static readonly msgErrorSendMessageNoPool = 'つながりが解消されているため、メッセージを送信できませんでした。';
  static readonly msgSendErrorLong = '送信に失敗した可能性があります。念のため' + '作成内容を保存'.fontcolor('red') + 'してから、ページを更新して送信状況をご確認ください。';
  static readonly msgSendErrorSameText = '最新のメッセージと同じ内容なので登録しません。';
  static readonly msgSendErrorSendRepeat = '同一のメッセージが連続して送信されました。\n重複したメッセージの送信はキャンセルされました。';
  static readonly msgAnalyticsFailed = '実行に失敗しました。期間を狭めるなど、出力数を減らしてください。';
  static readonly msgConfirmDelTalenttag = 'タレント管理タグを「削除」すると、削除したタグが' + '全てのタレント情報から消失'.fontcolor('red') + 'します。\n\nこの操作は戻せません。よろしいですか。';
  static readonly msgConfirmDelContenttag = 'コンテンツ #タグを「削除」すると、削除した#タグが' + '全てのコンテンツから消失'.fontcolor('red') + 'します。\n\nこの操作は戻せません。よろしいですか。';
  static readonly msgDeleteContentAlreadyError = 'この採用PRコンテンツは削除済です。';
  static readonly msgDeleteJobAlreadyError = 'この求人情報は削除済です。';
  static readonly msgConfirmCarteSave = 'タレントカルテを%sします。\nよろしいですか。';
  static readonly msgConfirmCarteCancel = 'タレントカルテの%sをキャンセルします。\n内容は保存されません。\nよろしいですか？';
  static readonly msgConfirmCarteDelHistory = '%sを「削除」します。\nよろしいですか。';
  static readonly msgCompleteCarteSave = 'タレントカルテの%sに成功しました。';
  static readonly msgFailedCarteSave = 'タレントカルテの%sに失敗した可能性があります。\n通信状況をご確認の上、再度実行してください。';
  static readonly msgFailedCarteSaveDeleted = 'このタレントカルテは削除済です。OK押下でページを閉じます。';
  static readonly msgConfirmCarteDel = 'このタレントカルテを「削除」します。\n' + '削除後、元には戻せません。'.fontcolor('red') + '\nよろしいですか。';
  static readonly msgCompleteCarteDel = 'タレントカルテを削除しました。\nOK押下でページを閉じます。';
  static readonly msgCompleteMailSend = 'メッセージの送信に成功しました。';
  static readonly msgDuplicateUrl = 'URLが作成済のものと重複しています。';
  static readonly msgErrorSaveEditorImg = '保存に失敗しました。<br />画像・動画・リンク（サムネイル画像）は、合計50点以内にしてください。';

  // メッセージタイトル
  static readonly titleGradiateYear = '最終学歴卒業年';
  static readonly titleActivities = '就業/転職活動予定時期';
  static readonly titleActivitiesStart = '就業/転職活動予定時期(開始)';
  static readonly titleActivitiesEnd = '就業/転職活動予定時期(終了)';
  static readonly titleAge = '年齢';
  static readonly titleOfferAction = '求人応募のアクション';
  static readonly titlePcAction = 'コンテンツ閲覧のアクション';

  // Google Analytics
  static readonly gaEvent = 'event';
  static readonly gaEventName = 'action_buttons';
  static readonly gaEventCategory = 'event_category';
  static readonly gaEventLabel = 'event_label';
  static readonly gaCategoryButtons = 'Buttons';
  static readonly gaActionLogin = 'Login';
  static readonly gaActionNews = 'News';
  static readonly gaActionRequest = 'Request';
  static readonly gaActionDownload = 'Download';
  static readonly gaActionAgreement = 'Agreement（Company）';
  static readonly gaActionCompany = 'Corporate';
  static readonly gaActionPolicy = 'Privacy Policy';
  static readonly gaActionLogout = 'Logout';
  static readonly gaActionSearch = 'Search（List）';
  static readonly gaActionSearchClear = 'Search Clear';
  static readonly gaActionSortStandard = 'Sort Standard';
  static readonly gaActionSortMessage = 'Sort Message';
  static readonly gaActionSortApply = 'Sort Apply';
  static readonly gaActionSortAttract = 'Sort Attract';
  static readonly gaActionSortProfile = 'Sort Profile';
  static readonly gaActionSortPool = 'Sort Pool';
  static readonly gaActionSortAccess = 'Sort Access';
  static readonly gaActionSortProfileCompany = 'Sort Profile Company';
  static readonly gaActionMemo = 'Memo';
  static readonly gaActionMessageOpen = 'Message Open';
  static readonly gaActionMessageSend = 'Message Send';
  static readonly gaActionKarteContactEdit = 'Karte Contact Point Edit';
  static readonly gaActionKarteContactDelete = 'Karte Contact Point Delete';
  static readonly gaActionKarteContactAdd = 'Karte Contact Point Add';
  static readonly gaActionKarteApplyEdit = 'Karte Apply Edit';
  static readonly gaActionKarteApplySave = 'Karte Apply / Save';
  static readonly gaActionKarteApplyEngageOK = 'Karte Apply Engage / OK';
  static readonly gaActionPrContentMenu = 'PR Menu';
  static readonly gaActionPrContentMenuDraftToOpen = 'PR Menu Status Change from draft to open / OK';
  static readonly gaActionPrContentMenuCloseToOpen = 'PR Menu Status Change from close to open / OK';
  static readonly gaActionPrContentMenuOpenToClose = 'PR Menu Status Change from open to close / OK';
  static readonly gaActionPrContentDetailDraftToOpen = 'PR Detail Status Change from draft to open / OK';
  static readonly gaActionPrContentDetailCloseToOpen = 'PR Detail Status Change from close to open / OK';
  static readonly gaActionPrContentDetailOpenToClose = 'PR Detail Status Change from open to close / OK';
  static readonly gaActionPrContentNew = 'PR New';
  static readonly gaActionPrContentNewSave = 'PR New / Save';
  static readonly gaActionPrContentEditSave = 'PR Edit / Save';
  static readonly gaActionJobOfferMenuDraftToOpen = 'Job Menu Status Change from draft to open / OK';
  static readonly gaActionJobOfferMenuCloseToOpen = 'Job Menu Status Change from close to open / OK';
  static readonly gaActionJobOfferMenuOpenToClose = 'Job Menu Status Change from open to close / OK';
  static readonly gaActionJobOfferDetailDraftToOpen = 'Job Detail Status Change from draft to open / OK';
  static readonly gaActionJobOfferDetailCloseToOpen = 'Job Detail Status Change from close to open / OK';
  static readonly gaActionJobOfferDetailOpenToClose = 'Job Detail Status Change from open to close / OK';
  static readonly gaActionJobOfferNew = 'Job New';
  static readonly gaActionJobOfferNewSave = 'Job New / Save';
  static readonly gaActionJobOfferEditSave = 'Job Edit / Save';
  static readonly gaActionJobOfferCommonInfo = 'Job Common Information';
  static readonly gaActionJobOfferCommonInfoSave = 'Job Common Information / Save';
  static readonly gaActionCompanyAreaOK = 'Company Area / OK';
  static readonly gaActionCompanySaveOK = 'Company Save / OK';
  static readonly gaActionUrlMenu = 'URL Menu';
  static readonly gaActionUrlMenuStatusChangeOpenToCloseOK = 'URL Menu Status Change from open to close / OK';
  static readonly gaActionUrlMenuStatusChangeCloseToOpenOK = 'URL Menu Status Change from close to open / OK';
  static readonly gaActionUrlNew = 'URL New';
  static readonly gaActionUrlNewSave = 'URL New / Save';
  static readonly gaActionUrlEditSave = 'URL Edit / Save';
  static readonly gaActionUrlDelete = 'URL Delete / Delete';

  static readonly gaActionMenuContact = 'Menu contact';
  static readonly gaActionMenuWhitepaper = 'Menu whitepaper';
  static readonly gaActionMenuAccount = 'Menu account';
  static readonly gaActionFtContact = 'Ft contact';
  static readonly gaActionFtAgreement = 'Ft agreement';
  static readonly gaActionFtPrivacy = 'Ft privacy';
  static readonly gaActionFtCalin = 'Ft calin';
  static readonly gaActionFtTalentLP = 'Ft talent LP';
  static readonly gaActionTopAccount = 'Top account';
  static readonly gaActionTopNewsMore = 'Top News more';
  static readonly gaActionTopTalentLPText = 'Top talent LP text';
  static readonly gaActionTopTalentLPPic = 'Top talent LP pic';
  static readonly gaActionTopPickupCompany = 'Top pickup company';
  static readonly gaActionTopPickupMore = 'Top pickup more';
  static readonly gaActionTopAgreement = 'Top agreement';
  static readonly gaActionTopContact = 'Top contact';
  static readonly gaActionTopWhitepaper = 'Top whitepaper';
  static readonly gaActionFaqForm = 'Faq form';
  static readonly gaActionFaqContact = 'Faq contact';
  static readonly gaActionWhitepaperPrivacy = 'Whitepaper privacy';
  static readonly gaActionWhitepaperSend = 'Whitepaper send';
  static readonly gaActionAccountAgreement = 'Account agreement';
  static readonly gaActionAccountPrivacy = 'Account privacy';
  static readonly gaActionAccountSend = 'Account send';

  // ページタイトル
  static readonly pageTitleRoot = 'タレントプール採用サービス CaLin（キャリン）';
  static readonly pageTitleCommon = ' - CaLin';
  static readonly pageTitleTalentList = 'タレントリスト';
  static readonly pageTitleTalentCarte = 'タレントカルテ';
  static readonly pageTitleTalentMap = 'タレントマップ';
  static readonly pageTitlePrContent = '採用PRコンテンツ';
  static readonly pageTitlePrContentCategory = '採用PRコンテンツ カテゴリー管理';
  static readonly pageTitleJobOffer = '求人情報管理';
  static readonly pageTitleMultiMessage = '一斉メッセージ';
  static readonly pageTitleCompanyInfo = '企業情報';
  static readonly pageTitleNoticeUrl = '告知用URL';
  static readonly pageTitleQuestion = 'プロフィール追加収集 質問管理';
  static readonly pageTitleAnalytics = 'アナリティクス';
  static readonly pageTitleUserGuide = 'CaLinの使い方';
  static readonly pageTitleFile = '添付ファイル';
  static readonly pageTitleImportTalent = 'タレントデータ CSV取込';
  static readonly pageTitleLogin = 'ログイン';
  static readonly pageTitlePasswordChange = 'パスワード変更';
  static readonly pageTitleError = 'エラー';
  static readonly pageTitleMaintenance = 'メンテナンス';
  static readonly pageDescriptionRoot = 'タレントプール採用は【CaLin（キャリン）】。「就活前の学生」や「転職潜在層」といった将来の候補者を囲い込み、採用のチャンスを広げます。新卒採用・中途採用どちらのデータベースとしても手軽に利用できます。';
  static readonly metaCalin = ' - CaLin';

  // CaLinの情報
  static readonly calinTellNo = '03-5324-2271';
  static readonly calinWorkTime = '（平日10～18時）';
  static readonly calinSupportMail = 'support@calin.co.jp';

  // ルーティング
  static readonly rtPrContentsCategory = 'pr_contents/category';
  static readonly rtAnalytics = 'analytics';
  static readonly rtRequest = 'request';
  static readonly rtLpNewgrad = 'newgrad';
  static readonly rtLpCareer = 'career';
  static readonly rtCase = 'case';
  static readonly rtTmpFile = 'tmpfile';
  static readonly rtMsgFile = 'msgfile';
  static readonly rtAttachment = 'attachment';
  static readonly rtQuestion = 'question';
  static readonly rtDownload = 'download/CaLin_whitepaper';
  static readonly rtImportTalent = 'import/talent';

  // 共通
  static readonly toastShowMiliSec = 3000;
  static readonly toastShowMiliSecErr = 4000;
  static readonly imageFormatJpg = 'image/jpeg';
  static readonly imageFormatPng = 'image/png';
  static readonly fileFormatCsv = 'text/csv';
  static readonly fileFormatPdf = 'application/pdf';
  static readonly fileFormatPpt = 'application/vnd.ms-powerpoint';
  static readonly fileFormatPptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
  static readonly fileFormatXls = 'application/vnd.ms-excel';
  static readonly fileFormatXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  static readonly fileFormatDoc = 'application/msword';
  static readonly fileFormatDocx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  static readonly fileFormatBinary = 'binary/octet-stream';
  static readonly fileFormatDownload = 'application/octet-stream';
  static readonly fileExtensionPdf = 'pdf';
  static readonly fileExtensionPng = 'png';
  static readonly fileExtensionJpg = 'jpg';
  static readonly fileExtensionJpeg = 'jpeg';
  static readonly fileMaxSize = 5242880;
  static readonly fileTalentMaxSize = 10000000;
  static readonly fileTypeAttached = 'image/png,image/jpeg,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  static readonly empty = '';
  static readonly pythen = '-';
  static readonly commma = '、';
  static readonly hsCommma = ',';
  static readonly slash = '/';
  static readonly monthJan = '01';
  static readonly dateFirst = '01';
  static readonly coron = ':';
  static readonly sharp = '#';
  static readonly pipe = '|';
  static readonly prefixRequire = '※必須';
  static readonly prefixOnlyNumber = '※半角数字';
  static readonly prefixOnlyNumberAD = '※半角数字・西暦';
  static readonly prefixFormatPngJpg = '※png/jpg';
  static readonly prefixMultiSelectEnable = '※複数選択可';
  static readonly prefixRequireMark = '※';
  static readonly prefixYear = '年';
  static readonly prefixPeople = '人';
  static readonly prefixYen = '円';
  static readonly prefixImageTriming165 = '16:5サイズに自動トリミングされます';
  static readonly prefixImageTriming169 = '16:9サイズに自動トリミングされます';
  static readonly textNotRegist = '未登録';
  static readonly modeEdit = 'edit';
  static readonly modeAdd = 'add';
  static readonly modeDel = 'del';
  static readonly uploadImageWidth = 1200;
  static readonly uploadImageHeight = 1200;
  static readonly uploadImageFullWidth = 1920;
  static readonly uploadImageFullHeight = 1080;
  static readonly flagOn = 1;

  // バリデーション
  static readonly vlInvalidYear = '年';
  static readonly vlInvalidMonth = '月';
  static readonly vlInvalidNumber = '無効な数値です';
  static readonly vlMaxSendMessage = 1000;
  static readonly vlMaxSendMessageTitle = 30;
  static readonly fvActiveStartYear = 2018;
  static readonly fvMinYear = 1930;
  static readonly fvMaxYear = 2017;
  static readonly fvMinMonth = 1;
  static readonly fvMaxMonth = 12;
  static readonly fvMinDay = 1;
  static readonly fvMaxDay = 31;
  static readonly fvMaxMemo = 10000;
  static readonly fvMaxCompanyName = 50;
  static readonly fvMaxLenCompanyDetail = 200;
  static readonly fvMaxLenIncome = 5;
  static readonly fvAlpahNumericReg = /^[a-z0-9]*$/;
  static readonly tfStartYear = 'start_year';
  static readonly tfStartMonth = 'start_month';
  static readonly tfEndYear = 'end_year';
  static readonly tfEndMonth = 'end_month';
  static readonly msgErrorInputAllTerm = '%sを登録する場合は全て入力してください。';
  static readonly msgErrorInputAllTerm2 = '期間は全て入力してください。';
  static readonly msgErrorInputStartDate = '年と月は両方入力してください。期初の年月は必須です。';
  static readonly msgErrorInvalidType = '正しい形式で入力してください。';
  static readonly msgErrorInvalidAddress = '都道府県を選択してください。';

  // element
  static readonly elementIdItem = '#item';

  static readonly elementValHeightNone = '0px';
  static readonly elementValHeight1000 = '1000px';
  static readonly elementValHeight1500 = '1500px';
  static readonly elementValGeneralFontSize = '13px';
  static readonly elementValSmallFontSize = '12px';
  static readonly elementValTwinRowWidth = '404px';
  static readonly elementVal4RowWidth = '195px';
  static readonly elementValBlock = 'block';
  static readonly elementValNone = 'none';

  static readonly elementfontSize = 'font-size';
  static readonly elementWidth = 'width';
  static readonly elemPropMaxHeight = 'max-height';
  static readonly elemPropDisplay = 'display';
  static readonly elemNewLine = '\n';

  // keyCode
  static readonly keyCodeEnter = 13;

  // LP広告出稿用
  static readonly lpHrnoteScriptId = 'script-ad';
  static readonly lpHrnoteAdStart = `(function acsKeep(){
  var PK = "p";
  var IMK = "im";
  var LKEYS = {cid : ["cid", "CL_", "ACT_"]};
  var DKEYS = [];
  var PDIR = "./";
  var durl = "https://s8.aspservice.jp/hrnote/direct.php";
  function saveCookies(data){ var p = data[PK]; var out = Object.keys(LKEYS).reduce(function(ret, k){ if(k in data && data[k]) ret[k] = data[k]; return ret; }, {}); if(!p || !Object.keys (out).length) return;
  var purl = PDIR + "lptag.php?p=" + p; Object.keys(out). forEach(function(k){ purl += "&" + LKEYS[k][0] + "=" + out[k]; localStorage.setItem(LKEYS[k][1] + p, out[k]); });
  var xhr = new XMLHttpRequest(); var args = "; expires=" + new Date(new Date().getTime() + 63072000000). toUTCString() + "; path=/; SameSite=None; Secure"; xhr.open ("GET", purl);
  xhr.onloadend = function(){ if(xhr.status === 200) return; Object.keys(out).forEach(function(k){ document.cookie = LKEYS[k][1] + p + "=" + decodeURIComponent(out[k]) + args; if(LKEYS[k][2]) document.cookie = LKEYS[k][2] + p + "=js" + args; }); }; xhr.send(); }
  var data = location.search.substring(1).split("&").reduce (function(ret, s){ var kv = s.split("="); if(kv[1]) ret[kv[0]] = kv[1]; return ret; }, {}); if(!(IMK in data)){ saveCookies(data); return; } durl += "?im=" + data[IMK] + "&navi=" + performance. navigation.type; DKEYS.forEach(function(k){ if(!(k in data)) return; durl += "&" + k + "=" + data[k]; });
  var xhr = new XMLHttpRequest(); xhr.open("GET", durl); function merge(a, b){ return Object.keys(LKEYS).reduce (function(ret, k){ if(k in b && !(k in a)) ret[k] = b[k]; return ret; }, a); }
  xhr.onloadend = function(){ if(xhr.status !== 200) return; try{ saveCookies(merge(JSON.parse(xhr.responseText), data)); } catch(_){ } }; xhr.send(); })();`;
  static readonly lpHrnoteAdEnd = `(function acsTrack(){
  var PV = "pggt018ry96q";
  var KEYS = {cid : ["CL_", "ACT_", "cid_auth_get_type"]};
  var turl = "https://s8.aspservice.jp/hrnote/track.php?p=" + PV; var cks = document.cookie.split("; ").reduce(function(ret, s){ var kv = s.split("="); if(kv[0] && kv[1]) ret[kv[0]] = kv[1]; return ret; }, []);
  turl = Object.keys(KEYS).reduce(function(url, k){ var vk = KEYS[k][0] + PV; var tk = KEYS[k][1] + PV; var v = "", t = ""; if (cks[vk]){ v = cks[vk]; if(cks[tk]) t = cks[tk]; }else if (localStorage.getItem(vk)){ v = localStorage.getItem(vk); t = "ls"; } if(v) url += "&" + k + "=" + v; if(t) url += "&" + KEYS[k][2] + "=" + t; return url; }, turl);
  var xhr = new XMLHttpRequest(); xhr.open("GET", turl + "&t=5f102e9f"); xhr.send(); })();`;
}
