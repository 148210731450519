import { Injectable } from "@angular/core";
import { Chart, ChartType, registerables } from 'chart.js';
import { Constant } from './../constant';

Chart.register(...registerables);

// 型定義
declare module 'chart.js' {
  interface PluginOptionsByType<TType extends ChartType> {
    circleCenterText?: {
      cnt?: string,
      unit?: string,
    },
    circleCenterTextOnlyCnt?: {
      cnt?: string,
      fontSize?: number,
    }
  }
}

@Injectable()
export class ChartjsUtil {
  public colorChart9 = ['#B9E4E1','#A4DCD7','#79CBC5','#4FBAB2','#25A99F','#1E877F','#16655F','#0F4440','#D4D4D4']
  public colorChart7 = ['#B9E4E1','#A4DCD7','#79CBC5','#4FBAB2','#25A99F','#1E877F','#D4D4D4']
  public colorChart5 = ['#CEEDEA','#A4DCD7','#79CBC5','#4FBAB2','#25A99F']
  public colorChart3 = ['#A4DCD7','#25A99F','#D4D4D4']
  public colorChartScorebunpu = ['#CEEDEA','#CEEDEA','#A4DCD7','#A4DCD7','#79CBC5','#79CBC5','#4FBAB2','#4FBAB2','#25A99F','#25A99F']  // プレファレンススコア分布用

  constructor() {

  }

  // ドーナッツ型チャートの中央テキスト描画プラグイン
  // option: {
  //   cnt: 上段数字,
  //   unut: 下段単位
  // }
  circleCenterTextPlugin() {
    return {
      id: 'circleCenterText',
      beforeDraw (chart: any, args, options) {
        const { ctx, chartArea: { top, width, height } } = chart
        ctx.save()
        //チャート描画部分の中央を指定
        ctx.fillRect(width / 2, top + (height / 2), 0, 0)
        //フォントのスタイル指定
        ctx.font = 'bold 30px "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, "MS PGothic", arial, helvetica, sans-serif'
        ctx.fillStyle = '#18181B'
        ctx.textAlign = 'center'
        // テキスト描画
        ctx.fillText(options.cnt, width / 2, top + (height / 2) - 6)
        ctx.font = 'bold 12px "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, "MS PGothic", arial, helvetica, sans-serif'
        ctx.fillText(options.unit, width / 2, top + (height / 2 + 20))
      }
    }
  }

  // ドーナッツ型チャートの中央テキスト描画プラグイン
  // 数字のみ
  circleCenterTextPluginOnlyCnt() {
    return {
      id: 'circleCenterTextOnlyCnt',
      beforeDraw (chart: any, args, options) {
        const { ctx, chartArea: { top, width, height } } = chart
        ctx.save()
        //チャート描画部分の中央を指定
        ctx.fillRect(width / 2, top + (height / 2), 0, 0)
        //フォントのスタイル指定
        if (!options.fontSize) {
          options.fontSize = 42
        }
        ctx.font = 'bold ' + options.fontSize + 'px "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, "MS PGothic", arial, helvetica, sans-serif'

        ctx.fillStyle = '#18181B'
        ctx.textAlign = 'center'
        // テキスト描画
        ctx.fillText(options.cnt, width / 2, top + (height / 2) + options.fontSize / 2 - 2)
      }
    }
  }

  // ドーナツチャート チャート部分に背景色をつける（丸みを持たせた時の背景）
  doughnutBackgroundColor() {
    return {
      id: 'doughnutBackgroundColor',
      beforeDatasetsDraw (chart: any, args, options) {
        const { ctx, width, height } = chart
        const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius
        const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius
        const radiusLength = outerRadius - innerRadius

        const x = width / 2,
          y = height / 2

        ctx.beginPath()
        ctx.arc(x, y, outerRadius - radiusLength / 2, 0, 2 * Math.PI)
        ctx.lineWidth = radiusLength
        ctx.strokeStyle = '#F5F5F5'
        ctx.stroke()

        return
      }
    }
  }

  // 判例の上にマージンを入れ、高さを固定にするプラグイン
  legendMarginTopPlugin() {
    return {
      id: 'legendMarginTop',
      beforeInit: function(chart) {
        const originalFit = chart.legend.fit
        chart.legend.fit = function fit() {
          if (originalFit) {
            originalFit.call(this)
            let top;
            const marginTop = 24;

            Object.defineProperty(this, 'top', {
              get() {
                return top + marginTop;
              },
              set(v) {
                top = v;
              },
            });

            return this.height = 80;
          }
        }
      }
    }
  }

  // 判例の下にマージンを入れるプラグイン
  // 未使用
  // legendMarginBottomPlugin() {
  //   return {
  //     id: 'legendMarginBottom',
  //     beforeInit: function(chart) {
  //       const originalFit = chart.legend.fit
  //       chart.legend.fit = function fit() {
  //         if (originalFit) {
  //           originalFit.call(this)
  //           let top;
  //           const marginTop = 24;

  //           // Object.defineProperty(this, 'bottom', {
  //           //   get() {
  //           //     return top + marginTop;
  //           //   },
  //           //   set(v) {
  //           //     top = v;
  //           //   },
  //           // });

  //           return this.height += 10;
  //         }
  //       }
  //     }
  //   }
  // }

  // ラインチャート描画
  drawLineChart(chartData, canvas, chart, type, maxYGridCnt=9, maxXGridCnt=29) {
    if ( !canvas || !chartData) {
      return
    }
    if (chart) {
      chart.destroy();
    }

    // Y軸最大最小
    const yMax = Math.max(...chartData.chart) + 1
    const yMin = Math.min(...chartData.chart) - 10 < 0 ? 0 : Math.min(...chartData.chart) - 10

    // 線のポイントスタイル
    let pointStyle = null

    // データが1つだけの場合
    if (chartData.label.length == 1) {
      chartData.label = ["", chartData.label[0], ""]
      chartData.chart = [null, chartData.chart[0], null]
      pointStyle = 'circle'
    }

    const context = canvas.nativeElement.getContext('2d');
    chart = new Chart(context, {
      type: 'line',
      data: {
        labels: chartData.label,
        datasets: [{
          data: chartData.chart,
          borderColor: Constant.alChartMainColor,
          backgroundColor: Constant.alChartMainColor,
          borderWidth: 2,
          pointStyle: pointStyle ? pointStyle : false,
        }]
      },
      options: {
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              maxTicksLimit: maxXGridCnt
            }
          },
          y: {
            suggestedMax: yMax,
            suggestedMin: yMin,
            ticks: {
              stepSize: 1,
              maxTicksLimit: maxYGridCnt
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false, // 凡例を非表示
            },
            tooltip: {
              padding: 8,
              intersect: false,
              position: 'nearest',
              // enabled: false,
              bodyFont: {
                weight: 'bold'
              },
              footerFont: {
                weight: 'normal'
              },
              multiKeyBackground: 'transparent',
              callbacks: {
                // タイトル（非表示）
                title: function(context) {
                  return null;
                },
                // ラベル
                label: function(context) {
                  return ' ' + context.label
                },
                footer: function(context) {
                  return '     ' + type + ' : ' + context[0].formattedValue
                },
                labelColor: function(context) {
                  return {
                    borderColor: context.element.options.backgroundColor,
                    backgroundColor: context.element.options.backgroundColor,
                    borderWidth: 3,
                    borderRadius: 2,
                  }
                },
              }
            }
        },
      },
    })

    return chart
  }

  // 円チャート描画
  drawCircleChart(chartData, canvas, chart, type, sum) {
    if ( !canvas || !chartData) {
      return
    }
    if (chart) {
      chart.destroy();
    }

    const context = canvas.nativeElement.getContext('2d');
    context.canvas.height = "100%";
    chart = new Chart(context, {
      type: 'doughnut',
      data: {
        labels: chartData.label,
        datasets: [{
          data: chartData.chart,
          backgroundColor: chartData.color,
        }],
      },
      options: {
        // responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',
        plugins: {
          circleCenterText: {
            cnt: sum.toLocaleString(),
            unit: type
          },
          legend: {
              display: true,
              position: 'bottom',
              align: chartData.align,
              labels: {
                boxWidth: 12,
                usePointStyle: true,
                pointStyle: 'rectRounded',
              }
          },
          tooltip: {
            padding: 8,
            intersect: true,
            position: 'nearest',
            bodyFont: {
              weight: 'bold'
            },
            footerFont: {
              weight: 'normal'
            },
            multiKeyBackground: 'transparent',
            callbacks: {
              // タイトル（非表示）
              title: function(context) {
                return null;
              },
              // ラベル
              label: function(context) {
                return ' ' + context.label
              },
              footer: function(context) {
                return '     ' + type + ' : ' + context[0].formattedValue
              },
              labelColor: function(context) {
                return {
                  borderColor: context.element.options.backgroundColor,
                  backgroundColor: context.element.options.backgroundColor,
                  borderWidth: 3,
                  borderRadius: 2,
                }
              },
            }
          },
        }
      },
      plugins: [this.legendMarginTopPlugin(), this.circleCenterTextPlugin()]
    })

    return chart
  }

  // ラインチャート塗りつぶし描画
  drawLineChartFill(chartData, canvas, chart, type) {
    if ( !canvas || !chartData) {
      return
    }
    if (chart) {
      chart.destroy();
    }

    // Y軸設定
    let yAxis
    if (type == Constant.alChartTypeScore) {
      yAxis =  {
        suggestedMax: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 25,
          maxTicksLimit: 5
        }
      }
    } else {
      const yMax = Math.max(...chartData.chart) + 1
      const yMin = Math.min(...chartData.chart) - 10 < 0 ? 0 : Math.min(...chartData.chart) - 10
      yAxis =  {
        suggestedMax: yMax,
        suggestedMin: yMin,
        ticks: {
          stepSize: 1,
          maxTicksLimit: 5
        }
      }

    }

    // 線のポイントスタイル
    let pointStyle = null

    // データが1つだけの場合
    if (chartData.label.length == 1) {
      chartData.label = ["", chartData.label[0], ""]
      chartData.chart = [null, chartData.chart[0], null]
      pointStyle = 'circle'
    }

    const context = canvas.nativeElement.getContext('2d');
    chart = new Chart(context, {
      type: 'line',
      data: {
        labels: chartData.label,
        datasets: [{
          label: type == Constant.alChartTypeScore ? 'プレファレンススコア（中央値）': '',
          data: chartData.chart,
          borderColor: Constant.alChartMainColor,
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            if (!ctx.chart.chartArea) {
              return
            }
            const gradient = canvas.createLinearGradient(0, 0, 0, ctx.chart.chartArea.height);

            gradient.addColorStop(0, '#79CBC5');
            gradient.addColorStop(1, '#79CBC518');

            return gradient;
          },
          borderWidth: 2,
          pointStyle: pointStyle ? pointStyle : false,
          pointBackgroundColor: Constant.alChartMainColor,
          fill: true
        }]
      },
      options: {
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              maxTicksLimit: 20
            },
          },
          y: yAxis,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
              display: false
            },
            tooltip: {
              padding: 8,
              intersect: false,
              position: 'nearest',
              bodyFont: {
                weight: 'bold'
              },
              footerFont: {
                weight: 'normal'
              },
              multiKeyBackground: 'transparent',
              callbacks: {
                // タイトル（非表示）
                title: function(context) {
                  return null;
                },
                // ラベル
                label: function(context) {
                  return ' ' + context.label
                },
                footer: function(context) {
                  if (type == Constant.alChartTypeTalent) {
                    return '     ' + type + ' : ' + context[0].formattedValue + '人'

                  } else if(type == Constant.alChartTypeScore) {
                    return '     ' + type + ' : ' + context[0].formattedValue

                  }
                },
                labelColor: function(context) {
                  return {
                    borderColor: context.element.options.backgroundColor,
                    backgroundColor: context.element.options.backgroundColor,
                    borderWidth: 3,
                    borderRadius: 2,
                  }
                },
              }
            }
        },
      },
    })

    return chart
  }

  // プレファレンススコア用円チャート描画
  drawPreferenceChart(score, canvas, chart) {
    if ( !canvas) {
      return
    }
    if (chart) {
      chart.destroy();
    }
    if (score > 100) {
      score = 100
    }

    const context = canvas.nativeElement.getContext('2d');
    context.canvas.height = "100%";
    chart = new Chart(context, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [score, 100-score],
          backgroundColor: (ctx) => {
            if (ctx.dataIndex == 0) {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createConicGradient(Math.PI * 3 / 2, ctx.chart.chartArea.width / 2, ctx.chart.chartArea.height / 2)

              gradient.addColorStop(0, '#E3F6F4')
              gradient.addColorStop(0.1, '#CEEDEA')
              gradient.addColorStop(0.2, '#B9E4E1')
              gradient.addColorStop(0.3, '#A4DCD7')
              gradient.addColorStop(0.4, '#79CBC5')
              gradient.addColorStop(0.5, '#4FBAB2')
              gradient.addColorStop(0.65, '#25A99F')
              gradient.addColorStop(0.8, '#1E877F')
              gradient.addColorStop(0.95, '#16655F')
              gradient.addColorStop(1, '#0F4440')

              return gradient
            } else {
              return '#F5F5F5'
            }

          },
          borderWidth: 0,
          borderRadius: score < 100 ? [30, 0]: [0],
        }],
      },
      options: {
        maintainAspectRatio: false,
        cutout: '75%',
        animation: {
          animateRotate: false,
        },
        events: [],
        plugins: {
          circleCenterTextOnlyCnt: {
            cnt: score.toString(),
          },
          legend: {
              display: false,
          },
          tooltip: {
            enabled: false,
          },
        }
      },
      plugins: [this.circleCenterTextPluginOnlyCnt(), this.doughnutBackgroundColor()]
    })

    return chart
  }

  // プレファレンススコア用円チャート描画（ミニ）
  drawPreferenceChartMini(score, canvas) {
    if ( !canvas) {
      return
    }
    if (score > 100) {
      score = 100
    }

    const context = canvas.getContext('2d');
    context.canvas.height = "100%";
    const chart = new Chart(context, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [score, 100-score],
          backgroundColor: (ctx) => {
            if (ctx.dataIndex == 0) {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createConicGradient(Math.PI * 3 / 2, ctx.chart.chartArea.width / 2, ctx.chart.chartArea.height / 2)

              gradient.addColorStop(0, '#E3F6F4')
              gradient.addColorStop(0.1, '#CEEDEA')
              gradient.addColorStop(0.2, '#B9E4E1')
              gradient.addColorStop(0.3, '#A4DCD7')
              gradient.addColorStop(0.4, '#79CBC5')
              gradient.addColorStop(0.5, '#4FBAB2')
              gradient.addColorStop(0.65, '#25A99F')
              gradient.addColorStop(0.8, '#1E877F')
              gradient.addColorStop(0.95, '#16655F')
              gradient.addColorStop(1, '#0F4440')

              return gradient
            } else {
              return '#F5F5F5'
            }

          },
          borderWidth: 0,
          borderRadius: score < 100 ? [30, 0]: [0],
        }],
      },
      options: {
        maintainAspectRatio: false,
        cutout: '80%',
        animation: {
          animateRotate: false,
        },
        events: [],
        plugins: {
          circleCenterTextOnlyCnt: {
            cnt: score.toString(),
            fontSize: 16,
          },
          legend: {
              display: false,
          },
          tooltip: {
            enabled: false,
          },
        }
      },
      plugins: [this.circleCenterTextPluginOnlyCnt()]
    })

    return chart
  }

  // タレント増減用　ラインチャート
  drawLineChartUpdown(chartData, canvas, chart) {
    if ( !canvas || !chartData) {
      return
    }
    if (chart) {
      chart.destroy();
    }

    // Y軸最大最小
    const yMax = Math.max(...chartData.chart) + 1
    const yMin = Math.min(...chartData.chart) < 0 ?  Math.min(...chartData.chart) - 1 : 0

    // 線のポイントスタイル
    let pointStyle = null

    // データが1つだけの場合
    if (chartData.label.length == 1) {
      chartData.label = ["", chartData.label[0], ""]
      chartData.chart = [null, chartData.chart[0], null]
      pointStyle = 'circle'
    }

    const context = canvas.nativeElement.getContext('2d');
    chart = new Chart(context, {
      type: 'line',
      data: {
        labels: chartData.label,
        datasets: [{
          data: chartData.chart,
          borderColor: Constant.alChartMainColor,
          backgroundColor: Constant.alChartMainColor,
          borderWidth: 2,
          pointStyle: pointStyle ? pointStyle : false,
        },
        ]
      },
      options: {
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              maxTicksLimit: 20
            },
          },
          y: {
            suggestedMax: yMax,
            suggestedMin: yMin,
            ticks: {
              stepSize: 1,
              maxTicksLimit: 5
            },
            afterFit: function(axis) {
              // Y軸の幅をプレファレンススコアと合わせる
              if (axis.width < 34) {
                axis.width = 34
              }
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
              display: false
            },
            tooltip: {
              padding: 8,
              intersect: false,
              position: 'nearest',
              multiKeyBackground: 'transparent',
              bodyFont: {
                weight: 'bold'
              },
              footerFont: {
                weight: 'normal'
              },
              callbacks: {
                // タイトル（非表示）
                title: function(context) {
                  return null;
                },
                // ラベル
                label: function(context) {
                  return ' ' + context.label
                },
                footer: function(context) {
                  if (Number(context[0].formattedValue) > 0) {
                    context[0].formattedValue = '+' + context[0].formattedValue
                  } else if (Number(context[0].formattedValue) == 0) {
                    context[0].formattedValue = '±0'
                  }
                  return '     タレント数 : ' + context[0].formattedValue
                },
                labelColor: function(context) {
                  return {
                    borderColor: context.element.options.backgroundColor,
                    backgroundColor: context.element.options.backgroundColor,
                    borderWidth: 3,
                    borderRadius: 2,
                  }
                },
              }
            }
        },
      },
    })

    return chart
  }

  // プレファレンススコア分布用　バーチャート
  drawBarChartScorebunpu(chartData, canvas, chart) {
    if ( !canvas || !chartData) {
      return
    }
    if (chart) {
      chart.destroy();
    }

    // Y軸最大
    const yMax = Math.max(...chartData.chart.map(item => item.talent_total)) + 1

    const context = canvas.nativeElement.getContext('2d');
    chart = new Chart(context, {
      type: 'bar',
      data: {
        datasets: [{
          data: chartData.chart,
          backgroundColor: this.colorChartScorebunpu,
        },
        ]
      },
      options: {
        parsing: {
          xAxisKey: 'range',
          yAxisKey: 'talent_total'
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              maxTicksLimit: 20
            },
          },
          y: {
            suggestedMax: yMax,
            ticks: {
              stepSize: 1,
              maxTicksLimit: 5
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
              display: false
            },
            tooltip: {
              padding: 8,
              intersect: false,
              position: 'nearest',
              multiKeyBackground: 'transparent',
              bodyFont: {
                weight: 'bold'
              },
              footerFont: {
                weight: 'normal'
              },
              callbacks: {
                // タイトル（非表示）
                title: function(context) {
                  return null;
                },
                // ラベル
                label: function(context) {
                  return ' ' + context.label
                },
                footer: function(context) {
                  if (context[0]) {
                    const rate = Math.round(context[0].dataset.data[context[0].dataIndex]['percentage'])
                    return '     人数 : ' + context[0].formattedValue + '\n     割合 : ' + rate + '%'
                  }
                },
                labelColor: function(context) {
                  return {
                    borderColor: context.element.options.backgroundColor,
                    backgroundColor: context.element.options.backgroundColor,
                    borderWidth: 3,
                    borderRadius: 2,
                  }
                },
              }
            }
        },
      },
    })

    return chart
  }

  // プレファレンス用横棒グラフ
  drawPreferenceBarChart(chartData, canvas, chart, canvasHeight?) {
    if ( !canvas || !chartData) {
      return
    }
    if (chart) {
      chart.destroy();
    }

    const datasets = []
    chartData.chart.forEach((value, index) => {
      const item = {
        data: value,
        backgroundColor: this.colorChart5[index],
        barPercentage: 0.8,
        maxBarThickness: 24,
      }
      datasets.push(item)
    });

    const context = canvas.nativeElement.getContext('2d');
    if (canvasHeight) {
        context.canvas.height = canvasHeight
    }

    chart = new Chart(context, {
      type: 'bar',
      data: {
        labels: chartData.label,
        datasets: datasets,
      },
      options: {
        indexAxis: 'y',
        events: [],
        scales: {
          x: {
            stacked: true,
            position: 'top',
            ticks: {
              stepSize: 1,
              maxTicksLimit: 5,
              callback: function(value, index, ticks) {
                return Number(value) > 0 ? value + '人' : value;
              }
            },
          },
          y: {
            stacked: true,
            ticks: {
              callback: function(value, index, ticks) {
                  return '';
              }
            }
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    })

    return chart
  }

}
