<div class="header">
  <div>集計期間変更</div>
  <div mat-icon-button mat-dialog-close class="close-button">
    <img src="assets/analytics/close_icon.svg">
  </div>
</div>
<mat-dialog-content>
  <div class="desc">設定できる期間の上限：1年</div>
  <div class="rect range">
    {{ selectedDateRange.start|date:"yyyy年MM月dd日" }} 〜 {{ selectedDateRange.end|date:"yyyy年MM月dd日" }}

  </div>
  <div class="rect">
    <div class="calendar-area">
    <mat-calendar #calendar
      [(selected)]="selectedDateRange"
      (selectedChange)="rangeChanged($event)"
      [minDate]="startDate" [maxDate]="endDate"></mat-calendar>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button (click)="onCancel()">キャンセル</button>
  <button mat-flat-button (click)="onSave()"
  [disabled]="!selectedDateRange.start || !selectedDateRange.end" class="green">変更</button>
</mat-dialog-actions>
