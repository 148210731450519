import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { environment } from './../../../environments/environment';
import { ImageUtil } from './../../util/image-util';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { QuillCommonComponent } from './../../quill-common/quill-common.component';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-company-about-dialog',
  templateUrl: './company-about-dialog.component.html',
  styleUrl: './company-about-dialog.component.css'
})
export class CompanyAboutDialogComponent implements OnInit {
  public aboutInfo;
  public readonly Constant = Constant;
  public isSaving = false;
  public showSpinner = true;
  public mainImagePath = null;
  public textData
  private companyInfo;
  private aboutText = ''
  private apiPath = '/company';
  private mainImageData = null;
  private uploadedMainImagePath = null;

  public title = 'About（詳細ページ）'

  @ViewChild('imgFileInput')
  private imgFileInput: ElementRef;
  @ViewChild('quill')
  private quillComponent: QuillCommonComponent;

  constructor(
    public dialogRef: MatDialogRef<CompanyAboutDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private imageUtil: ImageUtil,
    private auth: AuthService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getCompanyData();
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.aboutText = ''
    this.toastUtil.clearAllShowingToast();

    // エディタ画像数チェック
    const imgCnt = this.quillComponent.getImgCnt()
    if (imgCnt > 50) {
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgErrorSaveEditorImg, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    // エディタ本文取得
    this.aboutText = this.quillComponent.getContent()

    if (this.mainImageData) {
      // 画像が変更されていた場合はS3に保存し、データを更新
      const config = environment.amplify.Storage.companyAboutMainImage;
      const maxWidth = Constant.uploadImageFullWidth;
      const maxHeight = Constant.uploadImageFullHeight;
      this.uploadImageData(this.mainImageData, config, maxWidth, maxHeight);
    } else {
      this.dataUpdate();
    }
  }

  // キャンセル
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.companyMsgConfirmEditCancel, this.title), check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close('');
      }
    });
  }

  // 画像選択
  onChangeImage(evt) {
    const file = evt.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function() {
      this.mainImagePath = fileReader.result;
      this.mainImageData = file;
    }.bind(this);

    fileReader.readAsDataURL(file);
  }

  // 画像削除
  onImageDelete() {
    this.mainImagePath = '';
    this.mainImageData = null;
    this.imgFileInput.nativeElement.value = '';
  }

  // 保存ボタンdisabled
  checkSaveBtn() {
    if (this.showSpinner || this.isSaving) {
      return true
    } else if (!this.quillComponent) {
      return true
    } else if (!this.quillComponent.mainText || !this.quillComponent.loadFlg || this.quillComponent.onEditorCount() > 5000) {
      return true
    }

    return false
  }

  // ダイアログを閉じる（エディタより起動)
  onCloseDialog() {
    this.dialogRef.close()
  }

   // 画像をS3にアップロードする
  private uploadImageData(imageData, config, maxWidth, maxHeight) {
    // 画像をアップロード
    this.imageUtil
      .uploadImageDataToS3(imageData, maxHeight, maxWidth, config)
      .then(image_path => {
        this.uploadedMainImagePath = image_path;
        this.dataUpdate();
      })
      .catch(err => {
        this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSecErr);
        this.isSaving = false;
      });
  }



  // 企業情報を取得する
  private getCompanyData() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.showSpinner = false;
      return;
    }

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          if (res.data.about.length>0) {
            this.aboutInfo = res.data.about[0];

          } else {
            this.aboutInfo = {text: '', id: null}
          }
          this.companyInfo = res.data.main;
          this.textData = {
            text: this.aboutInfo.text,
            type: Constant.editorTypeCompany
          }
          this.getImageData();
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }


  // 画像データを取得する
  private getImageData() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      const companyimageApiPath = this.apiPath + '/url';

      this.commonUtil
        .apiGet(companyimageApiPath, options)
        .then(res => {
          this.showSpinner = false;
          if (res.data.about_main_image_path) {
            this.mainImagePath = res.data.about_main_image_path;
          } else {
            this.mainImagePath = ''
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.clearAllShowingToast();
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }

  // 更新実行
  private dataUpdate() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    Auth.currentSession().then(session => {
      const imagePath = this.getImagePathParam();
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        type: Constant.companyUpdateTypeAbout,
        about_main_image_path: imagePath,
        about_text: this.aboutText,
        about_id: this.aboutInfo.id
      };
      this.commonUtil
        .apiPut(this.apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
            this.dialogRef.close(res.status);
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
          this.isSaving = false;
        });
    });
  }


  private getImagePathParam() {
    if (this.uploadedMainImagePath) {
      // 画像が更新されていた場合
      return this.uploadedMainImagePath;
    }
    if (this.mainImagePath === '') {
      // 画像が削除されていた場合
      return this.mainImagePath;
    } else {
      // 変更なし
      return this.companyInfo.about_main_image_path;
    }
  }
}


