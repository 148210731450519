import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Constant } from '../constant';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { CommonUtil } from '../util/common-util';
import { MomentUtil } from '../util/moment-util';
import { ToastUtil } from '../util/toast-util';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChartjsUtil  } from './../util/chartjs-util';
import { AnalyticsCalendarDialogComponent } from '../dialog/analytics-calendar-dialog/analytics-calendar-dialog.component';

@Component({
  selector: 'app-analytics-top',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  templateUrl: './analytics-top.component.html',
  styleUrl: './analytics-top.component.css'
})
export class AnalyticsTopComponent {
  public readonly Constant = Constant;
  public showSpinner = false;
  public startDay
  public endDay
  public selectedTerm = 7
  public sitePv  // サイトPV
  public contentsPv // 記事PV
  public documentPv // 資料内PV
  public jobofferPv // 求人PV
  public scoreTransition // スコア推移
  public totalData // タレント推移

  private apiLoadCnt = 0

  // チャート用
  private chartSitePv
  @ViewChild('canvasSitePv') canvasSitePv: ElementRef;
  private chartContentsPv
  @ViewChild('canvasContentsPv') canvasContentsPv: ElementRef;
  private chartDocumentPv
  @ViewChild('canvasDocumentPv') canvasDocumentPv: ElementRef;
  private chartJobofferPv
  @ViewChild('canvasJobofferPv') canvasJobofferPv: ElementRef;
  private chartPreferenceTotal
  @ViewChild('canvasPreferenceTotal') canvasPreferenceTotal: ElementRef;
  private chartScoreTransition
  @ViewChild('canvasScoreTransition') canvasScoreTransition: ElementRef;
  private chartTalentUpDown
  @ViewChild('canvasTalentUpDown') canvasTalentUpDown: ElementRef;

  constructor(
    private title: Title,
    private auth: AuthService,
    private dialog: MatDialog,
    private commonUtil: CommonUtil,
    private momentUtil: MomentUtil,
    private toastUtil: ToastUtil,
    private chartjsUtil: ChartjsUtil,
  ) {
    this.title.setTitle(Constant.pageTitleAnalytics + Constant.pageTitleCommon);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    // 初期表示は7日前
    this.onTermSelected(7)
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  // 表示期間変更
  onTermSelected(term) {
    if (term > 0) {
      // 今日からterm日前の日付を取得
      const today = new Date()
      const startDay = new Date(today.getTime())
      startDay.setDate(startDay.getDate() - term)
      this.startDay = startDay
      this.endDay = today

      // 詳細情報以外をクリア
      this.clearData()
      this.getReport()
    }
  }

  // 表示期間カスタム
  onTermCustom() {
    const dialogRef = this.dialog.open(AnalyticsCalendarDialogComponent, {
      width: Constant.analyticsCalendarDialogWidth,
      height: Constant.analyticsCalendarDialogHeight,
      maxHeight: Constant.analyticsCalendarDialogMaxHeight,
      autoFocus: false,
      data: {
        start: this.startDay,
        end: this.endDay
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.startDay = result.start
        this.endDay = result.end
        this.clearData()
        this.getReport()
      }
    });
  }

  private clearData() {
    this.sitePv = null
    this.totalData = null
    this.scoreTransition = null
    this.contentsPv = null
    this.documentPv = null
    this.jobofferPv = null
    this.apiLoadCnt = 0
  }

  private getReport() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    this.showSpinner = true
    Auth.currentSession().then(session => {
      // サイトPV
      this.getSitePv(session)

      // プレファレンススコア系取得
      this.getPreferenceTransition(session)

      // タレント推移取得
      this.getTalentTotal(session)

      // 記事PV取得
      this.getContentsReport(session)

      // 資料PV取得
      this.getDocumentReport(session)

      // 求人PV取得
      this.getJobofferReport(session)
    })

  }

  private getSitePv(session) {
    let apiPath = '/analytics/top?t=1'
    apiPath += '&start=' + this.momentUtil.getDateFormat(this.startDay)
    apiPath += '&end=' + this.momentUtil.getDateFormat(this.endDay)
    const options = this.auth.createApiHeader(session)
    this.commonUtil.apiGet(apiPath, options).then(res => {
      this.sitePv = res.data
      // サイトPV
      this.sitePv.chartData = this.createLineChartData(this.sitePv.pv_all)
      setTimeout(() => {
        this.chartSitePv = this.chartjsUtil.drawLineChart(this.sitePv.chartData, this.canvasSitePv, this.chartSitePv, 'PV', 5, 12)
      });
      this.apiLoadCnt++
      this.hideSpinner()

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });
  }

  // タレント推移取得
  private getTalentTotal(session) {
    // 初日の増減表示のため、1日前から取得する
    const startDay = new Date(this.startDay.getTime())
    startDay.setDate(this.startDay.getDate() - 1)
    const apiPath = '/analytics/talenttotal/'
    const options = this.auth.createApiHeader(session)
    options['body'] = {
      start_date: this.momentUtil.getDateFormat(startDay),
      end_date: this.momentUtil.getDateFormat(this.endDay),
    };
    this.commonUtil.apiPost(apiPath, options).then(res => {
      this.totalData = res.data
      if (this.totalData.list) {
        // タレント数推移
        const chartData = this.createTalentUpDownData(this.totalData.list)
        setTimeout(() => {
          this.chartTalentUpDown = this.chartjsUtil.drawLineChartUpdown(chartData, this.canvasTalentUpDown, this.chartTalentUpDown)
        });
      }

      // プレファレンススコア円グラフ
      const score = this.totalData.preference_score
      setTimeout(() => {
        this.chartPreferenceTotal = this.chartjsUtil.drawPreferenceChart(score, this.canvasPreferenceTotal, this.chartPreferenceTotal)
      });

      this.apiLoadCnt++
      this.hideSpinner()

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });

  }

  // プレファレンススコア推移取得
  private getPreferenceTransition(session) {
    let apiPath = '/analytics/preference/transition?interval=year'
    apiPath += '&start=' + this.momentUtil.getDateFormat(this.startDay)
    apiPath += '&end=' + this.momentUtil.getDateFormat(this.endDay)
    const options = this.auth.createApiHeader(session)

    this.commonUtil.apiGet(apiPath, options).then(res => {
      this.scoreTransition = res.data.preferent_score_transition
      // スコア推移チャート
      const chartData = this.createPreferenceLineChartData(this.scoreTransition)
      setTimeout(() => {
        this.chartScoreTransition = this.chartjsUtil.drawLineChartFill(chartData, this.canvasScoreTransition, this.chartScoreTransition, Constant.alChartTypeScore)
      });

      this.apiLoadCnt++
      this.hideSpinner()

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });
  }

  // 記事PV取得
  private getContentsReport(session) {
    const apiPath = '/analytics/prcontent/'
    const options = this.auth.createApiHeader(session)
    options['body'] = {
      contents_id: 0,
      start_date: this.momentUtil.getDateFormat(this.startDay),
      end_date: this.momentUtil.getDateFormat(this.endDay),
      only_talent: false,
      type: Constant.alChartTypePv
    };
    this.commonUtil.apiPost(apiPath, options).then(res => {
      this.contentsPv = res.data
      // 記事PVチャート
      this.contentsPv.chartData = this.createLineChartData(this.contentsPv.pv_detail)
      setTimeout(() => {
        this.chartContentsPv = this.chartjsUtil.drawLineChart(this.contentsPv.chartData, this.canvasContentsPv, this.chartContentsPv, 'PV', 5, 12)
      });
      this.apiLoadCnt++
      this.hideSpinner()

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });
  }

  // 資料内PV取得
  private getDocumentReport(session) {
    const apiPath = '/analytics/document/'
    const options = this.auth.createApiHeader(session)
    options['body'] = {
      document_id: 0,
      start_date: this.momentUtil.getDateFormat(this.startDay),
      end_date: this.momentUtil.getDateFormat(this.endDay),
      only_talent: false,
      type: Constant.alChartTypePv
    };
    this.commonUtil.apiPost(apiPath, options).then(res => {
      this.documentPv = res.data
      // 資料PVチャート
      this.documentPv.chartData = this.createLineChartData(this.documentPv.pv_detail)
      setTimeout(() => {
        this.chartDocumentPv = this.chartjsUtil.drawLineChart(this.documentPv.chartData, this.canvasDocumentPv, this.chartDocumentPv, 'PV', 5, 12)
      });
      this.apiLoadCnt++
      this.hideSpinner()

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });
  }

  // 求人PV取得
  private getJobofferReport(session) {
    const apiPath = '/analytics/joboffer/'
    const options = this.auth.createApiHeader(session)
    options['body'] = {
      joboffer_id: 0,
      start_date: this.momentUtil.getDateFormat(this.startDay),
      end_date: this.momentUtil.getDateFormat(this.endDay),
      only_talent: false,
      type: Constant.alChartTypePv
    };
    this.commonUtil.apiPost(apiPath, options).then(res => {
      this.jobofferPv = res.data
      // 資料PVチャート
      this.jobofferPv.chartData = this.createLineChartData(this.jobofferPv.pv_detail)
      setTimeout(() => {
        this.chartJobofferPv = this.chartjsUtil.drawLineChart(this.jobofferPv.chartData, this.canvasJobofferPv, this.chartJobofferPv, 'PV', 5, 12)
      });
      this.apiLoadCnt++
      this.hideSpinner()

    })
    .catch(err => {
      this.commonUtil.debug().log(err);
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
    });
  }

  // スピナー非表示のチェック
  hideSpinner() {
    // 全部の結果が返ってきたら非表示
    if (this.apiLoadCnt >= 6) {
      this.showSpinner = false
    }
  }

  // ラインチャート用データ作成
  createLineChartData(originData) {
    const date = new Date(this.startDay.getTime())
    const labelData = []
    const chartData = []
    while (date <= this.endDay) {
      const label = this.momentUtil.getDateFormatMonthDaySlash(date)
      labelData.push(label)
      const dateText = this.momentUtil.getDateFormat(date)
      const item = originData.filter(function(data) {
        return dateText === data.date;
      });
      if (item.length > 0) {
        chartData.push(item[0].cnt)
      } else {
        chartData.push(0)
      }
      date.setDate(date.getDate() + 1)
    }
    return {
      label: labelData,
      chart: chartData
    }
  }

  // プレファレンススコア推移用チャートデータ作成
  createPreferenceLineChartData(originData) {
    const date = new Date(this.startDay.getTime())
    const labelData = []
    const chartData = []
    while (date <= this.endDay) {
      const label = this.momentUtil.getDateFormatMonthDaySlash(date)
      labelData.push(label)
      const dateText = this.momentUtil.getDateFormat(date)
      const item = originData.filter(function(data) {
        return dateText === data.score_date;
      });
      if (item.length > 0) {
        chartData.push(item[0].median_score > 100 ? 100 : item[0].median_score)
      } else {
        chartData.push(0)
      }
      date.setDate(date.getDate() + 1)
    }
    return {
      label: labelData,
      chart: chartData
    }
  }

  // タレント増減　チャートデータ作成
  createTalentUpDownData(originData) {
    const labelAry = []
    const chartAry = []
    let preCnt = 0
    originData.forEach((element, index) => {
      if (index == 0) {
        preCnt = element.count
      } else {
        const date = new Date(element.target_date)
        labelAry.push((date.getMonth() + 1).toString() + '/' + date.getDate().toString())
        const changeCnt = element.count - preCnt
        chartAry.push(changeCnt)

        preCnt = element.count
      }

    });

    return {
      label: labelAry,
      chart: chartAry
    }
  }

}
