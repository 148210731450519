import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { TalentMapComponent } from './talent-map/talent-map.component';
import { HeaderComponent } from './header/header.component';
import { TalentListComponent } from './talent-list/talent-list.component';
import { TalentDetailComponent } from './talent-detail/talent-detail.component';
import { NoticeUrlComponent } from './notice-url/notice-url.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ReloginDialogComponent } from './dialog/relogin-dialog/relogin-dialog.component';
import { PassCompDialogComponent } from './dialog/pass-comp-dialog/pass-comp-dialog.component';
import { JobOfferBaseDialogComponent } from './dialog/joboffer-base-dialog/joboffer-base-dialog.component';
import { JobofferEditDialogComponent } from './dialog/joboffer-edit-dialog/joboffer-edit-dialog.component';
import { JobofferDetailDialogComponent } from './dialog/joboffer-detail-dialog/joboffer-detail-dialog.component';
import { JobofferAdoptDialogComponent } from './dialog/joboffer-adopt-dialog/joboffer-adopt-dialog.component';
import { NoticeEditDialogComponent } from './dialog/notice-edit-dialog/notice-edit-dialog.component';
import { NoticeDelDialogComponent } from './dialog/notice-del-dialog/notice-del-dialog.component';
import { NoticeConfDialogComponent } from './dialog/notice-conf-dialog/notice-conf-dialog.component';
import { GeneralYesNoDialogComponent } from './dialog/general-yes-no-dialog/general-yes-no-dialog.component';
import { GeneralMessageDialogComponent } from './dialog/general-message-dialog/general-message-dialog.component';
import { MultipleSelectDialogComponent } from './dialog/multiple-select-dialog/multiple-select-dialog.component';
import { JobOfferComponent } from './job-offer/job-offer.component';
import { CompanyComponent } from './company/company.component';
import { PrContentsComponent } from './pr-contents/pr-contents.component';
import { PrcontentDetailDialogComponent } from './dialog/prcontent-detail-dialog/prcontent-detail-dialog.component';
import { PrcontentEditDialogComponent } from './dialog/prcontent-edit-dialog/prcontent-edit-dialog.component';
import { RequestComponent } from './request/request.component';
import { DownloadComponent } from './download/download.component';
import { LinkyModule } from 'ngx-linky';

import { ToastUtil } from './util/toast-util';
import { CommonUtil } from './util/common-util';
import { ImageUtil } from './util/image-util';
import { MomentUtil } from './util/moment-util';
import { QuillUtil } from './util/quill-util';

import { LpComponent } from './lp/lp.component';
import { LpFooterComponent } from './lp-footer/lp-footer.component';
import { LpCaseComponent } from './lp-case/lp-case.component';
import { LpHeaderComponent } from './lp-header/lp-header.component';
import { LpFaqComponent } from './lp-faq/lp-faq.component';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SearchContainerComponent } from './search-container/search-container.component';
import { TalentListCommonComponent } from './talent-list-common/talent-list-common.component';
import { MultiMessageComponent } from './multi-message/multi-message.component';
import { MessageEditDialogComponent } from './dialog/message-edit-dialog/message-edit-dialog.component';
import { MultiMessageTalentComponent } from './multi-message-talent/multi-message-talent.component';
import { MultiMessageSendComponent } from './multi-message-send/multi-message-send.component';
import { MultiMessageExceptComponent } from './multi-message-except/multi-message-except.component';
import { UserguideDialogComponent } from './dialog/userguide-dialog/userguide-dialog.component';
import { UserguideComponent } from './userguide/userguide.component';
import { PdfviewerDialogComponent } from './dialog/pdfviewer-dialog/pdfviewer-dialog.component';
import { ShowFileComponent } from './show-file/show-file.component';
import { LpNewgradComponent } from './lp-newgrad/lp-newgrad.component';
import { LpCareerComponent } from './lp-career/lp-career.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AnalyticsResultDialogComponent } from './dialog/analytics-result-dialog/analytics-result-dialog.component';
import { TalenttagEditDialogComponent } from './dialog/talenttag-edit-dialog/talenttag-edit-dialog.component';
import { TalentEditDialogComponent } from './dialog/talent-edit-dialog/talent-edit-dialog.component';
import { TalentDelDialogComponent } from './dialog/talent-del-dialog/talent-del-dialog.component';
import { QuestionComponent } from './question/question.component';
import { QuestionEditDialogComponent } from './dialog/question-edit-dialog/question-edit-dialog.component';
import { QuestionDetailDialogComponent } from './dialog/question-detail-dialog/question-detail-dialog.component';
import { MessageSelectTalentDialogComponent } from './dialog/message-select-talent-dialog/message-select-talent-dialog.component';
import { CsvImportTalentComponent } from './csv-import-talent/csv-import-talent.component';
import { ImportTalentDialogComponent } from './dialog/import-talent-dialog/import-talent-dialog.component';
import { PrcontenttagEditDialogComponent } from './dialog/prcontenttag-edit-dialog/prcontenttag-edit-dialog.component';
import { PrContentsCategoryComponent } from './pr-contents-category/pr-contents-category.component';
import { PrcontentCategoryEditDialogComponent } from './dialog/prcontent-category-edit-dialog/prcontent-category-edit-dialog.component';
import { JobofferPriorityDialogComponent } from './dialog/joboffer-priority-dialog/joboffer-priority-dialog.component';
import { PrcontentPriorityDialogComponent } from './dialog/prcontent-priority-dialog/prcontent-priority-dialog.component';
import { QuillModule } from 'ngx-quill';
import { CompanyProfileDialogComponent } from './dialog/company-profile-dialog/company-profile-dialog.component';
import { CompanyMessageDialogComponent } from './dialog/company-message-dialog/company-message-dialog.component';
import { CompanyAboutDialogComponent } from './dialog/company-about-dialog/company-about-dialog.component';
import { QuillCommonComponent } from './quill-common/quill-common.component';
import { CompanyColorDialogComponent } from './dialog/company-color-dialog/company-color-dialog.component';
import { PrcontenttagCategoryDialogComponent } from './dialog/prcontenttag-category-dialog/prcontenttag-category-dialog.component';

// カレンダーフォーマット
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD'
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

// quill 改行コード
import Quill from 'quill';
const Delta = Quill.import('delta');
function lineBreakMatcher(node, delta) {
  var newDelta = new Delta();
  if (node.className !== "Apple-interchange-newline") {
    newDelta.insert({'sbreak': true});
  }
  return newDelta;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PasswordChangeComponent,
    TalentMapComponent,
    HeaderComponent,
    TalentListComponent,
    TalentDetailComponent,
    NoticeUrlComponent,
    PageNotFoundComponent,
    ReloginDialogComponent,
    LpComponent,
    PassCompDialogComponent,
    MaintenanceComponent,
    NoticeEditDialogComponent,
    NoticeDelDialogComponent,
    NoticeConfDialogComponent,
    JobOfferBaseDialogComponent,
    JobofferEditDialogComponent,
    JobofferDetailDialogComponent,
    JobofferAdoptDialogComponent,
    GeneralYesNoDialogComponent,
    GeneralMessageDialogComponent,
    JobOfferComponent,
    MultipleSelectDialogComponent,
    CompanyComponent,
    PrContentsComponent,
    PrcontentDetailDialogComponent,
    PrcontentEditDialogComponent,
    RequestComponent,
    DownloadComponent,
    SearchContainerComponent,
    TalentListCommonComponent,
    MultiMessageComponent,
    MessageEditDialogComponent,
    MultiMessageTalentComponent,
    MultiMessageSendComponent,
    MultiMessageExceptComponent,
    UserguideDialogComponent,
    UserguideComponent,
    PdfviewerDialogComponent,
    LpFooterComponent,
    LpCaseComponent,
    LpHeaderComponent,
    LpFaqComponent,
    ShowFileComponent,
    LpNewgradComponent,
    LpCareerComponent,
    AnalyticsComponent,
    AnalyticsResultDialogComponent,
    TalenttagEditDialogComponent,
    TalentEditDialogComponent,
    TalentDelDialogComponent,
    QuestionComponent,
    QuestionEditDialogComponent,
    QuestionDetailDialogComponent,
    MessageSelectTalentDialogComponent,
    CsvImportTalentComponent,
    ImportTalentDialogComponent,
    PrcontenttagEditDialogComponent,
    PrContentsCategoryComponent,
    PrcontentCategoryEditDialogComponent,
    JobofferPriorityDialogComponent,
    PrcontentPriorityDialogComponent,
    CompanyProfileDialogComponent,
    CompanyMessageDialogComponent,
    CompanyAboutDialogComponent,
    QuillCommonComponent,
    CompanyColorDialogComponent,
    PrcontenttagCategoryDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDividerModule,
    MatTableModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatSliderModule,
    MatGridListModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    LinkyModule,
    LazyLoadImageModule,
    PdfViewerModule,
    QuillModule.forRoot({
      formats: ['bold', 'strike', 'blockquote', 'list', 'align', 'header', 'link', 'image', 'video', 'sbreak', 'toc-div', 'ogp-div', 'ogp-loading'],
      modules: {
        clipboard: {
          matchVisual: false,
          matchers: [
            ['br', lineBreakMatcher]
          ]
        },
        history: {
          delay: 500,
        },
        keyboard: {
          bindings: {
            linebreak: {
              key: 13,
              handler: function (range) {
                const prevLeaf = this.quill.getLeaf(range.index - 1)[0]
                const currentLeaf = this.quill.getLeaf(range.index)[0]
                const nextLeaf = this.quill.getLeaf(range.index + 1)[0]
                if (currentLeaf.parent.domNode.nodeName === 'BLOCKQUOTE'
                || currentLeaf.parent.parent?.domNode.nodeName === 'BLOCKQUOTE'
                || currentLeaf.parent.parent?.parent?.domNode.nodeName === 'BLOCKQUOTE'
                || currentLeaf.parent.parent?.parent?.parent?.domNode.nodeName === 'BLOCKQUOTE') {
                  if (currentLeaf.domNode.nodeName === 'BR' && !currentLeaf.prev && !currentLeaf.next) {
                    // 空行のみ
                    this.quill.removeFormat(range.index, 0, 'user')
                    this.quill.insertText(range.index, '\n', 'user');
                    this.quill.setSelection(range.index + 1, 0, 'user')
                  }
                  else if (currentLeaf.domNode.nodeName === 'BR' && (prevLeaf.domNode.nodeName === 'BR' || currentLeaf.next?.domNode.nodeName === 'BR' || nextLeaf?.domNode.nodeName === 'BR')) {
                    // 引用内、改行が2回続いたら引用を終了する
                    let cursor = range.index;
                    if (nextLeaf.domNode.nodeName === 'BR') {
                      this.quill.deleteText(cursor, 1, 'user');
                    }
                    this.quill.insertText(cursor, '\n', 'user');
                    this.quill.removeFormat(cursor + 1, 0, 'user')
                    // 後ろにテキストがある場合、2文字目にカーソルが当たってしまうことを防ぐ
                    if (currentLeaf.next?.domNode.nodeName === '#text') {
                      cursor--
                    }
                    this.quill.setSelection(cursor + 1, 0, 'user')

                  } else {
                    let cursor = range.index;
                    // 引用内の改行挿入
                    this.quill.insertEmbed(cursor, 'sbreak', true, 'user');

                    // Insert a second break if:
                    if (nextLeaf === null || (currentLeaf.parent !== nextLeaf.parent &&
                      nextLeaf.parent.constructor.name !== 'Blockquote' && nextLeaf.parent.parent?.constructor.name !== 'Blockquote' &&
                      nextLeaf.parent.parent?.parent?.constructor.name !== 'Blockquote' && nextLeaf.parent.parent?.parent?.parent?.constructor.name !== 'Blockquote')) {
                        this.quill.insertEmbed(cursor, 'sbreak', true, 'user');
                    }

                    this.quill.setSelection(cursor + 1, 0, 'user');
                  }

                } else {
                  this.quill.insertText(range.index, '\n', 'user');
                  this.quill.setSelection(range.index + 1, 0, 'user');
                }

              }
            },
          }
        }
      }
    })
  ],
  providers: [
    AuthService,
    AuthGuard,
    ToastUtil,
    CommonUtil,
    ImageUtil,
    MomentUtil,
    QuillUtil,
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
