<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">コンテンツ #タグ</div>
      <div class="desc">※上限100</div>
      <div class="right">
        <button mat-raised-button type="button" (click)="onCategory()" class="add-btn" [disabled]="!btnEnable || isSaving">カテゴリー設定</button>
        <button mat-raised-button type="button" (click)="onAdd()" class="add-btn" [disabled]="!btnEnable || lists.controls.length>99">タグ追加</button>
      </div>
    </div>
    <div class="list-area">
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <form [formGroup]="form" *ngIf="!showSpinner">
        <table *ngIf="tags" class="tag-table">
          <tr>
            <th class="category">カテゴリー</th>
            <th class="title">タイトル <span class="red-text">※必須</span></th>
            <th class="cnt">付与数</th>
            <th></th>
          </tr>
          <ng-container formArrayName="lists">
            <ng-container *ngFor="let val of lists.controls; let i = index;">
              <ng-container [formGroupName]="i">
                <tr class="row-{{i}}">
                  <td class="category">
                    <mat-form-field floatLabel="never" class="input-select no-line">
                      <mat-select formControlName="tagcategory_id" panelWidth="" panelClass="tagcategory-select">
                        <mat-option [value]="0"></mat-option>
                        <mat-option *ngFor="let cat of categoryMaster" [value]="cat.id">
                          {{ cat.title }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td class="title">
                    <div class="label-count" [class.length-red]="title.value.length>titleMaxLen">{{ title.value.length }}/{{ titleMaxLen }}</div>
                    <mat-form-field class="input-area no-line">
                      <input matInput type="text" formControlName="title" [maxlength]="titleMaxLen" #title />
                    </mat-form-field>
                  </td>
                  <td class="cnt">
                    {{ val.value.cnt }}
                  </td>
                  <td class="btn">
                    <button mat-raised-button (click)="onDel(i)" class="del-btn" *ngIf="val.value.del_flg!=1">削除</button>
                    <button mat-raised-button (click)="onDelCancel(i)" class="cancel-btn" *ngIf="val.value.del_flg==1">取消</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </table>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="!btnEnable || form.invalid || isSaving" class="yes-btn">保存</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
