<div class="header">
  <ng-container *ngIf="!showSpinner">
    <ng-container *ngIf="jobOffer.release_status == Constant.joReleaseStatusNoRelease">
      <div  class="release_status default">
        未公開
      </div>
      @if (jobOffer.limited_flag == 1) {
        <img class="limited-icon" src="assets/common/lock_red.svg">
      }
    </ng-container>
    <ng-container *ngIf="jobOffer.release_status == Constant.joReleaseStatusRelease">
      <div class="release_status released">
        公開中
      </div>
      @if (jobOffer.limited_flag == 1) {
        <img class="limited-icon" src="assets/common/lock_blue.svg">
      }
    </ng-container>
    <ng-container *ngIf="jobOffer.release_status == Constant.joReleaseStatusStop">
      <div class="release_status stopped">
        休止中
      </div>
      @if (jobOffer.limited_flag == 1) {
        <img class="limited-icon" src="assets/common/lock_gray.svg">
      }
    </ng-container>
  </ng-container>
  <div class="buttons">
    <button mat-raised-button type="button" (click)="onEdit()">編集</button>
    <button
      *ngIf="jobOffer?.release_status != Constant.joReleaseStatusRelease"
      mat-raised-button
      type="button"
      (click)="onRelease(true)"
    >
      公開中にする
    </button>
    <button
      *ngIf="jobOffer?.release_status == Constant.joReleaseStatusRelease"
      mat-raised-button
      type="button"
      (click)="onRelease(false)"
    >
      休止中にする
    </button>
  </div>
  <div mat-icon-button (click)="onClose()">
    <img
      src="assets/joboffer/close_button.png"
      srcset="
        assets/joboffer/close_button.png    1x,
        assets/joboffer/close_button@2x.png 2x,
        assets/joboffer/close_button@3x.svg 3x
      "
      class="close-button"
    />
  </div>
</div>
<mat-dialog-content>
  <div class="spinner" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="!showSpinner">
  <div class="all-container" *ngIf="!showSpinner">
    <div *ngIf="jobOffer.image_url" class="generalfont label">
      <img src="assets/notice/mark_gray.png" srcset="
          assets/notice/mark_gray.png    1x,
          assets/notice/mark_gray@2x.png 2x,
          assets/notice/mark_gray@3x.svg 3x
      " /> 求人情報 メイン画像
    </div>
    <div *ngIf="jobOffer.image_url" class="top-container">
      <div class="baseimage-area">
        <img class="baseimage" [src]="jobOffer.image_url">
      </div>
    </div>
    <div class="container">
      <div class="titlearea">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> タイトル(職種名)
        </div>
        <div class="value pre-line" *ngIf="jobOffer.title">
          <span>{{ jobOffer.title }}</span>
        </div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 職種カテゴリー
        </div>
        <div class="flexarea" *ngIf="jobOffer.job_category_text">
          <div class="value pre-line">{{ jobOffer.job_category_text }}<span *ngIf="jobOffer.job_category_middle_text">　＞　{{ jobOffer.job_category_middle_text }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="jobOffer.pr" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 求人PR
        </div>
        <div class="value pre-line quill-area">
          <ng-container *ngIf="pr">
            <quill-view [content]="pr"
              preserveWhitespace="true"
              style ="border-width: 0;"
              [class.hidden]="!loadFlg"
              (onEditorCreated)="onEditorCreated($event)">
            </quill-view>
          </ng-container>
          <div class="spinner" *ngIf="!loadFlg || !pr">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 仕事内容
        </div>
        <div class="value pre-line quill-area" *ngIf="desc">
          <ng-container *ngIf="desc">
            <quill-view [content]="desc"
              preserveWhitespace="true"
              style ="border-width: 0;"
              [class.hidden]="!loadFlg"
              (onEditorCreated)="onEditorCreated($event)"></quill-view>
          </ng-container>
          <div class="spinner" *ngIf="!loadFlg || !desc">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 求めるスキル/経験
        </div>
        <div class="value pre-line" *ngIf="jobOffer.skill">{{ jobOffer.skill }}</div>
      </div>
      <div *ngIf="jobOffer.person_type" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 求める人物像
        </div>
        <div class="value pre-line">{{ jobOffer.person_type }}</div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 雇用形態
        </div>
        <div class="value pre-line" *ngIf="jobOffer.employment_text">{{ jobOffer.employment_text }}</div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 契約期間
        </div>
        <div class="value pre-line" *ngIf="jobOffer.contract_period">
          <div class="value-margin"><span>{{ jobOffer.contract_period }}</span></div>
          <div class="value-margin" *ngIf="jobOffer.contract_period_detail"><span>{{ jobOffer.contract_period_detail }}</span></div>
        </div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 試用期間
        </div>
        <div class="value pre-line" *ngIf="jobOffer.trial_period">
          <div class="value-margin"><span>{{ jobOffer.trial_period }}</span></div>
          <div class="value-margin" *ngIf="jobOffer.trial_period_detail"><span>{{ jobOffer.trial_period_detail }}</span></div>
        </div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 給与
        </div>
        <div class="value pre-line" *ngIf="jobOffer.salary_type">
          <span>{{ jobOffer.salary_type }}<span *ngIf="jobOffer.salary_lower">　:　{{ jobOffer.salary_lower | number }}円 ～ <span *ngIf="jobOffer.salary_higher">{{ jobOffer.salary_higher | number }}円</span></span></span>
        </div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 固定残業
        </div>
        <div class="value pre-line" *ngIf="jobOffer.fixed_overtime">
          <div class="value-margin"><span>{{ jobOffer.fixed_overtime }}</span></div>
          <div class="value-margin" *ngIf="jobOffer.fixed_overtime_detail"><span>{{ jobOffer.fixed_overtime_detail }}</span></div>
        </div>
      </div>
      <div *ngIf="jobOffer.raise" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 昇給・賞与
        </div>
        <div class="value pre-line">{{ jobOffer.raise }}</div>
      </div>
      <div *ngIf="jobOffer.salary_note" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 給与 備考
        </div>
        <div class="value pre-line">{{ jobOffer.salary_note }}</div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 勤務地
        </div>
        <div class="value pre-line subplace" *ngIf="jobOffer.job_place_text"><span>{{ jobOffer.job_place_text }}{{ jobOffer.job_place_city }}<ng-container *ngIf="subPlaceItems.length>0">、</ng-container></span>
          <span *ngFor="let place of subPlaceItems; last as last">{{ place }}<ng-container *ngIf="!last">、</ng-container></span>
        </div>
      </div>
      <div *ngIf="jobOffer.job_place_note" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 勤務地 備考
        </div>
        <div class="value pre-line">{{ jobOffer.job_place_note }}</div>
      </div>
      <div *ngIf="jobOffer.work_hours" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 勤務時間
        </div>
        <div class="value pre-line">{{ jobOffer.work_hours }}</div>
      </div>
      <div *ngIf="jobOffer.holiday" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 休日・休暇
        </div>
        <div class="value pre-line">{{ jobOffer.holiday }}</div>
      </div>
      <div *ngIf="jobOffer.welfare" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 待遇・福利厚生
        </div>
        <div class="value pre-line">{{ jobOffer.welfare }}</div>
      </div>
      <div *ngIf="jobOffer.other_title1 || jobOffer.other_detail1" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> {{ jobOffer.other_title1 }}
        </div>
        <div class="value pre-line">{{ jobOffer.other_detail1 }}</div>
      </div>
      <div *ngIf="jobOffer.other_title2 || jobOffer.other_detail2" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> {{ jobOffer.other_title2 }}
        </div>
        <div class="value pre-line">{{ jobOffer.other_detail2 }}</div>
      </div>
      <div *ngIf="jobOffer.other_title3 || jobOffer.other_detail3" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> {{ jobOffer.other_title3 }}
        </div>
        <div class="value pre-line">{{ jobOffer.other_detail3 }}</div>
      </div>
      <div *ngIf="jobOffer.closing" class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> クロージング
        </div>
        <div class="value pre-line quill-area">
          <ng-container *ngIf="closing">
            <quill-view [content]="jobOffer.closing"
              preserveWhitespace="true"
              style ="border-width: 0;"
              [class.hidden]="!loadFlg"
              (onEditorCreated)="onEditorCreated($event)"></quill-view>
          </ng-container>
          <div class="spinner" *ngIf="!loadFlg || !closing">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </div>
      <div class="contentsmargin">
        <div class="generalfont label">
          <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
        " /> 一覧ページ表示設定
        </div>
        <div class="value">
          @if (jobOffer.limited_flag != 1) {
            表示する
          } @else {
            表示しない
          }
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="jobOffer.release_status != Constant.joReleaseStatusNoRelease">
    <div class="url-container generalfont">
      <div>求人ページURL</div>
      <div class="flexarea">
        <div class="url">
          <a href="{{ jobOffer.url }}">{{ jobOffer.url }}</a>
        </div>
        <div class="copy" (click)="onCopy()">
          <img
            src="assets/notice/copy-icon.png"
            srcset="
              assets/notice/copy-icon.png    1x,
              assets/notice/copy-icon@2x.png 2x,
              assets/notice/copy-icon@3x.svg 3x
            "
          />
          URLをコピー
        </div>
        <input id="url" type="hidden" value="{{ jobOffer.url }}" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="jobOffer.release_status != Constant.joReleaseStatusNoRelease">
    <div class="flexarea actionarea">
      <div class="generalfont">
        <span>閲覧数</span>
        <span class="actionvalue">{{ jobOffer.view_cnt }}</span>
      </div>
      <div class="generalfont">
        <span>シェア</span>
        <span class="actionvalue">{{ jobOffer.share_cnt }}</span>
      </div>
      <div class="generalfont">
        <span>気になる</span>
        <span class="actionvalue">{{ jobOffer.mind_cnt }}</span>
      </div>
      <div class="generalfont">
        <span>応募</span>
        <span class="actionvalue">{{ jobOffer.apply_cnt }}</span>
      </div>
    </div>
  </ng-container>
  <div class="footer">
    <div
      *ngIf="jobOffer.release_status != Constant.joReleaseStatusNoRelease"
      class="flexarea generalfont release"
    >
      <div class="labelarea">初公開</div>
      <div class="byarea">{{ jobOffer.released_by }}</div>
      <div class="datearea">{{ jobOffer.release_date }}</div>
    </div>
    <div class="flexarea generalfont last_modify">
      <div class="labelarea">最終編集</div>
      <div class="byarea">{{ jobOffer.updated_by }}</div>
      <div class="datearea">{{ jobOffer.updated_at }}</div>
    </div>
    <div class="flexarea generalfont created">
      <div class="labelarea">作成</div>
      <div class="byarea">{{ jobOffer.created_by }}</div>
      <div class="datearea">{{ jobOffer.created_at }}</div>
    </div>
  </div>
  </ng-container>
</mat-dialog-content>
