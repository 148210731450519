<mat-toolbar class="background">
  <span>
    <img src="assets/logo_green.png" class="logo" />
  </span>

  <span class="example-fill-remaining-space"></span>

  <span class="inner-text">
    <span *ngIf="(auth.loggedIn | async)">{{ this.auth.companyName }}</span>
  </span>
  <span class="inner-text">
    <a *ngIf="(auth.loggedIn | async)" (click)="onClickLogout()">ログアウト</a>
  </span>
</mat-toolbar>
