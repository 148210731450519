<ng-container *ngIf="headers">
  <div class="tabs">
    <form [formGroup]="form" (ngSubmit)="onSearch()">
      <ng-container *ngFor="let tabItem of searchTabTitles; index as i; first as first; last as last">
        <input id="tab-{{ i }}" type="checkbox" name="tab_item" (click)="onSearchTab($event)"
          [checked]="searchTabCheck[i]" />
        <label *ngIf="!first && !last" class="tab_item" for="tab-{{ i }}">{{ tabItem }}</label>
        <label *ngIf="first" class="tab_item first" for="tab-{{ i }}">{{ tabItem }}</label>
        <label *ngIf="last" class="tab_item last" for="tab-{{ i }}">
          {{ tabItem }}
        </label>
      </ng-container>
      <div class="search-button">
        <button mat-raised-button type="submit" color="warn" [disabled]="form.invalid || searching">
          検索
        </button>
        <button mat-raised-button type="button" class="search-clear" (click)="onSearchConditionClear()">
          条件クリア
        </button>
      </div>
      <ng-container *ngFor="let header of headers; index as i">
        <div class="tab_content" id="tab-{{ i }}_content">
          <div *ngFor="let list of header.master_list; last as last" class="header" [class.select]="list.type==Constant.tlGraduateYearType" [class.margintop]="list.type==Constant.tlSexType">
            <ng-container *ngIf="list.type == Constant.tlAddQuestionType">
              <ng-container *ngTemplateOutlet="formQuestion;  context:{ datas: list.data }"></ng-container>
            </ng-container>
            <div class="header-title" *ngIf="header.master_list.length>1"
              [class.top]="list.type==Constant.offerActionType || list.type==Constant.pcActionType || list.type==Constant.dcActionType
              || list.type==Constant.tlTalentNameType
              || (list.kind==Constant.tlSearchKindPeriod && list.type!=Constant.tlRegisteredDateType)"
              [class.top2]="list.type==Constant.tlGraduateYearType"
              [class.top0]="list.type == Constant.tlRegisteredDateType">
              <span class="list-title">{{ list.title }}</span>
              <span class="header-subtitle" [class.left]="list.type == Constant.tlDocumentReadStartType"
                *ngIf="list.type == Constant.tlActivitiesType || list.type == Constant.tlTalentAgeFromType || list.type == Constant.tlDesireIncomeFromType
                || list.type == Constant.tlDocumentReadStartType">※半角数字</span>
            </div>
            <div class="header-contents" *ngIf="list.type != Constant.tlAddQuestionType">
              <ng-container *ngIf="list.type == Constant.tlDocumentReadStartType; then formDocumentRead"> </ng-container>
              <ng-container *ngIf="list.type == Constant.tlGraduateYearType">
                <ng-container *ngTemplateOutlet="formGraduateYear;  context:{ datas: list.data }"></ng-container>
              </ng-container>
              <ng-container *ngIf="list.type == Constant.tlActivitiesType; then formChangeJobPlans"> </ng-container>
              <ng-container *ngIf="list.type == Constant.tlTalentNameType; then formTarentName"> </ng-container>
              <ng-container *ngIf="list.type == Constant.tlTalentAgeFromType; then formTarentAge"> </ng-container>
              <ng-container *ngIf="list.type == Constant.tlDesireIncomeFromType; then formDesireIncome"> </ng-container>
              <ng-container *ngIf="list.kind == Constant.tlSearchKindPeriod">
                <ng-container *ngTemplateOutlet="formPeriod; context:{ type: list.type }"></ng-container>
              </ng-container>
              <ul *ngIf="list.kind == Constant.tlSearchKindJobOfferTitle" class="contents-title-checkbox">
                <!-- 求人タイトル、PRコンテンツタイトルのチェックボックス -->
                <div class="contents-title-scroll {{list.type}}">
                  <ng-container *ngFor="let data of list.data">
                    <li class="header-contents-status">
                      <mat-checkbox color="primary" id="{{ data.type + Constant.pythen + data.id }}"
                        name="{{ data.type + Constant.pythen + data.id }}"
                        [formControlName]="data.type + Constant.pythen + data.id" (change)="checkAllChidlen(
                          $event.checked,
                          header.title,
                          list.type,
                          data.id
                          )">
                        {{ data.item_value }}
                      </mat-checkbox>
                      <ng-container *ngFor="let offer of data.data">
                        <ul>
                          <li class="header-contents-title">
                            <mat-checkbox color="primary"
                              id="{{ data.type + Constant.pythen + data.id + Constant.pythen + offer.id }}"
                              name="{{ data.type + Constant.pythen + data.id + Constant.pythen + offer.id }}"
                              [formControlName]="list.type + Constant.pythen + data.id + Constant.pythen + offer.id"
                              (change)="checkParent(
                                header.title,
                                list.type,
                                data.id,
                                data.type
                                )">
                              {{ offer.item_value }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </ng-container>
                    </li>
                  </ng-container>
                </div>
              </ul>
              <ul *ngIf="list.kind == Constant.tlSearchKindContact" class="contact-box contents-title-scroll {{ list.type }}">
                <ng-container *ngFor="let data of list.data">
                  <li>
                    <mat-checkbox *ngIf="data.id!=Constant.tlContactTagNullId" color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                      name="{{ list.type + Constant.pythen + data.id }}"
                      [formControlName]="list.type + Constant.pythen + data.id" (change)="changeContactTagCheckState()">
                      {{ data.item_value }}
                    </mat-checkbox>
                    <mat-checkbox *ngIf="data.id==Constant.tlContactTagNullId" color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                      name="{{ list.type + Constant.pythen + data.id }}"
                      [formControlName]="list.type + Constant.pythen + data.id" (change)="changeOtherCheckState(
                        !$event.checked,
                        Constant.tlSearchCategoryContactTag,
                        [Constant.tlContactTagType, Constant.tlContactTagPeriodType],
                        data.id
                        )"
                      >
                      {{ data.item_value }}
                    </mat-checkbox>
                  </li>
                </ng-container>
              </ul>
              <ul *ngIf="list.kind == Constant.tlSearchKindJobPlace" class="contents-title-checkbox {{list.type}}">
                <!-- 希望勤務地のチェックボックス -->
                <div class="contents-title-scroll {{list.type}}">
                  <ng-container *ngFor="let data of list.data; first as firstdata">
                    <li class="header-contents-status">
                      <mat-checkbox *ngIf="firstdata" color="primary" id="{{ data.type + Constant.pythen + data.id }}"
                        name="{{ data.type + Constant.pythen + data.id }}"
                        [formControlName]="data.type + Constant.pythen + data.id" (change)="checkAllChidlen(
                          $event.checked,
                          header.title,
                          list.type,
                          data.id
                          )">
                        {{ data.item_value }}
                      </mat-checkbox>
                      <mat-checkbox *ngIf="!firstdata" color="primary" id="{{ data.type + Constant.pythen + data.id }}"
                        name="{{ data.type + Constant.pythen + data.id }}"
                        [formControlName]="data.type + Constant.pythen + data.id ">
                        {{ data.item_value }}
                      </mat-checkbox>
                      <ul>
                        <ng-container *ngFor="let place of data.data">
                          <li class="header-contents-job-place-list">
                            <mat-checkbox color="primary"
                              id="{{ data.type + Constant.pythen + data.id + Constant.pythen + place.id }}"
                              name="{{ data.type + Constant.pythen + data.id + Constant.pythen + place.id }}"
                              [formControlName]="list.type + Constant.pythen + data.id + Constant.pythen + place.id"
                              (change)="checkParent(
                                header.title,
                                Constant.offerTitleType,
                                data.id,
                                data.type
                                )">
                              {{ place.item_value }}
                            </mat-checkbox>
                          </li>
                        </ng-container>
                      </ul>
                    </li>
                    <br *ngIf="firstdata">
                  </ng-container>
                </div>
              </ul>
              <ul *ngIf="list.kind != Constant.tlSearchKindJobOfferTitle && list.kind != Constant.tlSearchKindJobPlace && list.kind != Constant.tlSearchKindContact"
              [class.one_msg]="list.type == Constant.tlMsgStatusType"
              [class.multi_msg]="list.type == Constant.tlMultiMsgSendType"
              [class.school]="list.type == Constant.tlSchoolType"
              [class.paddingbottom]="list.type == Constant.tlSexType">
                <!-- 希望職種・経験職種チェックボックス -->
                <div *ngIf="list.kind == Constant.tlSearchKindDesireJob" class="desire-job-scroll" [class.short]="list.type!=Constant.tlExpJobType">
                  <ng-container *ngFor="let data of list.data">
                    <li class="header-contents-desirejob-list">
                      <ng-container *ngIf="list.type == Constant.tlExpJobType">
                        <!-- 経験職種のチェックボックス -->
                        <ng-container *ngIf="data.id != Constant.tlExpJobTypeNone">
                          <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                            name="{{ list.type + Constant.pythen + data.id }}"
                            [formControlName]="list.type + Constant.pythen + data.id" (change)="changeExpJobCheckState()">
                            {{ data.item_value }}
                          </mat-checkbox>
                        </ng-container>
                        <!-- 経験職種：未登録のチェックボックス -->
                        <ng-container *ngIf="data.id == Constant.tlExpJobTypeNone">
                          <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                            name="{{ list.type + Constant.pythen + data.id }}"
                            [formControlName]="list.type + Constant.pythen + data.id" (change)="changeOtherCheckState(
                              !$event.checked,
                              Constant.tlSearchCategoryHistory,
                              [Constant.tlExpJobType, Constant.tlExpJobLatestType],
                              data.id
                              )">
                            {{ data.item_value }}
                          </mat-checkbox>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="list.type != Constant.tlExpJobType">
                        <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                          name="{{ list.type + Constant.pythen + data.id }}"
                          [formControlName]="list.type + Constant.pythen + data.id">
                          {{ data.item_value }}
                        </mat-checkbox>
                      </ng-container>
                    </li>
                  </ng-container>
                </div>
                <!-- 上記以外のチェックボックス -->
                <ng-container *ngFor="let data of list.data">
                  <li
                    *ngIf="list.kind != Constant.tlSearchKindDesireJob &&
                     list.kind != Constant.tlSearchKindJobOfferTitle &&
                     list.kind != Constant.tlSearchKindJobPlace &&
                     list.kind != Constant.tlSearchKindContact &&
                     list.type != Constant.tlGraduateYearType &&
                     list.kind != Constant.tlSearchKindPeriod"
                    class="header-contents-general-list">
                    <!-- 求人アクション「応募」「いいね」「シェア」「閲覧」「未閲覧」のチェックボックス -->
                    <ng-container *ngIf="list.type == Constant.offerActionType">
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id" (change)="changeJobofferActionCheckState($event.checked, data.id)">
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>
                    <!-- コンテンツアクションのチェックボックス -->
                    <ng-container *ngIf="list.type==Constant.pcActionType && (data.id == Constant.pcActionGood ||
                      data.id == Constant.pcActionMore || data.id == Constant.pcActionShare ||
                      data.id == Constant.pcActionNone)"
                    >
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id" (change)="changeOtherCheckState(
                          !$event.checked,
                          Constant.tlSearchCategoryContents,
                          [Constant.pcActionType],
                          data.id
                          )">
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>
                    <!-- 資料アクションのチェックボックス -->
                    <ng-container *ngIf="list.type==Constant.dcActionType && (data.id == Constant.dcActionGood ||
                      data.id == Constant.dcActionView || data.id == Constant.dcActionShare ||
                      data.id == Constant.dcActionNone)"
                    >
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id" (change)="changeDocumentActionCheckState(
                          $event.checked,
                          data.id
                          )">
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>
                    <!-- 一斉メッセージ除外のチェックボックス -->
                    <ng-container *ngIf="data.type == Constant.tlMultiMsgExceptType">
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id">
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>
                    <!-- お気に入りフラグのチェックボックスは星アイコン追加 -->
                    <ng-container *ngIf="data.type == Constant.tlTalentFavoriteType">
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id">
                        <img class="icon-star" src="assets/talent-list/bookmark_on.svg" />
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>
                    <!-- 学校区分：未登録のチェックボックス -->
                    <ng-container *ngIf="list.type == Constant.tlSchoolType && data.id == Constant.tlSchoolTypeNone">
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id" (change)="changeOtherCheckState(
                          !$event.checked,
                          Constant.tlSearchCategoryHistory,
                          [Constant.tlSchoolType, Constant.tlBunriType],
                          data.id
                          )">
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>
                    <!-- 登録種別（自己登録・手動）のチェックボックス -->
                    <ng-container *ngIf="list.type == Constant.tlTalentRegistType">
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id" (change)="changeRegistyTypeCheckState(
                          $event.checked,
                          data.id
                        )">
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>
                    <!-- メッセージ拒否等を除くのチェックボックス -->
                    <ng-container *ngIf="list.type == Constant.tlDataStatusType && data.id == Constant.tlDataStatusTypeIdUnsubscribe">
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id">
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>

                    <!-- 上記以外のチェックボックス -->
                    <ng-container *ngIf="(list.type != Constant.offerActionType) &&
                      (list.type != Constant.pcActionType) &&
                      (list.type != Constant.dcActionType) &&
                      (data.type != Constant.tlTalentFavoriteType) &&
                      (data.type != Constant.tlMultiMsgExceptType) &&
                      (data.id != Constant.tlSchoolTypeNone || list.type != Constant.tlSchoolType) &&
                      list.type != Constant.tlTalentRegistType &&
                      (data.id != Constant.tlDataStatusTypeIdUnsubscribe || list.type != Constant.tlDataStatusType)"
                    >
                      <mat-checkbox color="primary" id="{{ list.type + Constant.pythen + data.id }}"
                        name="{{ list.type + Constant.pythen + data.id }}"
                        [formControlName]="list.type + Constant.pythen + data.id">
                        {{ data.item_value }}
                      </mat-checkbox>
                    </ng-container>
                  </li>
                  <!-- フラグ　縦の区切り線追加 -->
                  <li *ngIf="(data.type== Constant.tlTalentFavoriteType && data.id==Constant.tlTalentFavoriteOff) || (list.type== Constant.tlDataStatusType && data.id==Constant.tlDataStatusTypeIdUpdate)" class="header-contents-general-list flag-separator">｜</li>
                </ng-container>
              </ul>
              <ng-container *ngIf="list.type == Constant.offerActionType">
                <!-- 求人アクション　期間追加 -->
                <div class="period-separator">/</div>
                <mat-form-field class="input-area input-date" (click)="jobpicker1.open()" floatLabel="never">
                  <input matInput [matDatepicker]="jobpicker1" [formControlName]="Constant.tlJobOfferActionStartType"
                    placeholder="期間" [(ngModel)]="jobPeriod.start" name="jobPeriodStart" [min]="getMinDate('')" [max]="getMaxDate(jobPeriod.end)">
                  <mat-datepicker-toggle matSuffix [for]="jobpicker1" class="date-pick-icon"></mat-datepicker-toggle>
                  <mat-datepicker #jobpicker1></mat-datepicker>
                </mat-form-field>
                <div class="period-separator2">〜</div>
                <mat-form-field class="input-area input-date" (click)="jobpicker2.open()" floatLabel="never">
                  <input matInput [matDatepicker]="jobpicker2" [formControlName]="Constant.tlJobOfferActionEndType"
                    placeholder="期間" [(ngModel)]="jobPeriod.end" name="jobPeriodEnd" [min]="getMinDate(jobPeriod.start)" [max]="getMaxDate('')">
                  <mat-datepicker-toggle matSuffix [for]="jobpicker2" class="date-pick-icon"></mat-datepicker-toggle>
                  <mat-datepicker #jobpicker2></mat-datepicker>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="list.type == 'CONTENTS_ACTION_TYPE'">
                <!-- コンテンツアクション　期間追加 -->
                <div class="period-separator">/</div>
                <mat-form-field class="input-area input-date" (click)="conpicker1.open()" floatLabel="never">
                  <input matInput [matDatepicker]="conpicker1" [formControlName]="Constant.tlContentsActionStartType"
                    placeholder="期間" [(ngModel)]="conPeriod.start" name="conPeriodStart" [min]="getMinDate('')" [max]="getMaxDate(conPeriod.end)">
                  <mat-datepicker-toggle matSuffix [for]="conpicker1" class="date-pick-icon"></mat-datepicker-toggle>
                  <mat-datepicker #conpicker1></mat-datepicker>
                </mat-form-field>
                <div class="period-separator2">〜</div>
                <mat-form-field class="input-area input-date" (click)="conpicker2.open()" floatLabel="never">
                  <input matInput [matDatepicker]="conpicker2" [formControlName]="Constant.tlContentsActionEndType"
                    placeholder="期間" [(ngModel)]="conPeriod.end" name="conPeriodEnd" [min]="getMinDate(conPeriod.start)" [max]="getMaxDate('')">
                  <mat-datepicker-toggle matSuffix [for]="conpicker2" class="date-pick-icon"></mat-datepicker-toggle>
                  <mat-datepicker #conpicker2></mat-datepicker>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="list.type == Constant.dcActionType">
                <!-- 資料アクション　期間追加 -->
                <div class="period-separator">/</div>
                <mat-form-field class="input-area input-date" (click)="docpicker1.open()" floatLabel="never">
                  <input matInput [matDatepicker]="docpicker1" [formControlName]="Constant.tlDocumentActionStartType"
                    placeholder="期間" [(ngModel)]="docPeriod.start" name="docPeriodStart" [min]="getMinDate('')" [max]="getMaxDate(docPeriod.end)">
                  <mat-datepicker-toggle matSuffix [for]="docpicker1" class="date-pick-icon"></mat-datepicker-toggle>
                  <mat-datepicker #docpicker1></mat-datepicker>
                </mat-form-field>
                <div class="period-separator2">〜</div>
                <mat-form-field class="input-area input-date" (click)="docpicker2.open()" floatLabel="never">
                  <input matInput [matDatepicker]="docpicker2" [formControlName]="Constant.tlDocumentActionEndType"
                    placeholder="期間" [(ngModel)]="docPeriod.end" name="docPeriodEnd" [min]="getMinDate(docPeriod.start)" [max]="getMaxDate('')">
                  <mat-datepicker-toggle matSuffix [for]="docpicker2" class="date-pick-icon"></mat-datepicker-toggle>
                  <mat-datepicker #docpicker2></mat-datepicker>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="list.type == Constant.tlBunriType">
                <!-- 文理タイプに中退追加 -->
                <div class="age-separator">｜</div>
                <mat-checkbox color="primary" id="{{ Constant.tlExceptDropoutType }}"
                  name="{{ Constant.tlExceptDropoutType }}"
                  [formControlName]="Constant.tlExceptDropoutType">
                  中退を除く
                </mat-checkbox>
              </ng-container>
            </div>
            <mat-divider class="clear" *ngIf="!last"
            [class.short]="list.type == Constant.offerActionType || list.type == Constant.offerTitleType || list.type == Constant.pcTitleType
            || list.type==Constant.tlContactTagType || list.type==Constant.tlMultiMsgSendType || list.type==Constant.tlDataStatusType || list.type==Constant.tlMultiMsgExceptType
            || list.type==Constant.tlExpJobType || list.type==Constant.tlSchoolType"
            [class.basic]="i==4">
            </mat-divider>
          </div>
        </div>
      </ng-container>
      <!-- ** テンプレート **-->
      <!-- 期間設定用 -->
      <ng-template #formPeriod let-type="type">
        <!-- タレントプール登録日 -->
        <ng-container *ngIf="type == Constant.tlRegisteredDateType">
          <mat-form-field class="input-area input-date" (click)="pooledpicker1.open()" floatLabel="never">
            <input matInput [matDatepicker]="pooledpicker1" [formControlName]="Constant.tlRegisteredDateStartType"
              placeholder="期間" [(ngModel)]="pooledPeriod.start" name="pooledPeriodStart" [min]="getMinDate('')" [max]="getMaxDate(pooledPeriod.end)">
            <mat-datepicker-toggle matSuffix [for]="pooledpicker1" class="date-pick-icon"></mat-datepicker-toggle>
            <mat-datepicker #pooledpicker1></mat-datepicker>
          </mat-form-field>
          <div class="period-separator2">〜</div>
          <mat-form-field class="input-area input-date" (click)="pooledpicker2.open()" floatLabel="never">
            <input matInput [matDatepicker]="pooledpicker2" [formControlName]="Constant.tlRegisteredDateEndType"
              placeholder="期間" [(ngModel)]="pooledPeriod.end" name="pooledPeriodEnd" [min]="getMinDate(pooledPeriod.start)" [max]="getMaxDate('')">
            <mat-datepicker-toggle matSuffix [for]="pooledpicker2" class="date-pick-icon"></mat-datepicker-toggle>
            <mat-datepicker #pooledpicker2></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <!-- 接点タグ -->
        <ng-container *ngIf="type == Constant.tlContactTagPeriodType">
          <mat-form-field class="input-area input-date" (click)="tagpicker1.open()" floatLabel="never">
            <input matInput [matDatepicker]="tagpicker1" [formControlName]="Constant.tlContactTagStartType"
              placeholder="期間" [(ngModel)]="tagPeriod.start" name="tagPeriodStart" [min]="getMinDate('')" [max]="getMaxDate(tagPeriod.end)">
            <mat-datepicker-toggle matSuffix [for]="tagpicker1" class="date-pick-icon"></mat-datepicker-toggle>
            <mat-datepicker #tagpicker1></mat-datepicker>
          </mat-form-field>
          <div class="period-separator2">〜</div>
          <mat-form-field class="input-area input-date" (click)="tagpicker2.open()" floatLabel="never">
            <input matInput [matDatepicker]="tagpicker2" [formControlName]="Constant.tlContactTagEndType"
              placeholder="期間" [(ngModel)]="tagPeriod.end" name="tagPeriodEnd" [min]="getMinDate(tagPeriod.start)" [max]="getMaxDate('')">
            <mat-datepicker-toggle matSuffix [for]="tagpicker2" class="date-pick-icon"></mat-datepicker-toggle>
            <mat-datepicker #tagpicker2></mat-datepicker>
          </mat-form-field>
        </ng-container>
      </ng-template>
      <!-- 新卒採用枠(卒業年) -->
      <ng-template #formGraduateYear let-datas="datas">
        <div>
          <mat-form-field class="graduate-select" appearance="fill">
            <mat-select [formControlName]="Constant.tlGraduateStartYearType">
              <mat-option value=""></mat-option>
              <ng-container  *ngFor="let data of datas">
                <mat-option *ngIf="data.id>=Constant.tlGraduateYearMin && (!form.value[Constant.tlGraduateEndYearType] || data.id<=form.value[Constant.tlGraduateEndYearType])" [value]="data.id">
                  {{ data.item_value.replace('年卒', '') }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          年卒　〜
          <mat-form-field class="graduate-select" appearance="fill">
            <mat-select [formControlName]="Constant.tlGraduateEndYearType">
              <mat-option value=""></mat-option>
              <ng-container  *ngFor="let data of datas">
                <mat-option *ngIf="data.id>=Constant.tlGraduateYearMin && (!form.value[Constant.tlGraduateStartYearType] || data.id>=form.value[Constant.tlGraduateStartYearType])" [value]="data.id">
                  {{ data.item_value.replace('年卒', '') }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          年卒
          <ul class="graduate-ul">
            <ng-container *ngFor="let data of datas">
              <li class="header-contents-general-list" *ngIf="data.id < Constant.tlGraduateYearMin">
                <mat-checkbox color="primary" [formControlName]="Constant.tlGraduateYearType + Constant.pythen + data.id">{{ data.item_value }}</mat-checkbox>
              </li>
            </ng-container>
          </ul>
        </div>

      </ng-template>
      <!-- 資料読了率 -->
      <ng-template #formDocumentRead>
        <div class="range-input-area">
          <div>
            <input class="input-area input-age" type="text" placeholder="0"
              [maxlength]="3"
              [formControlName]="Constant.tlDocumentReadStartType" />
            <span class="search-prefix">
              %　〜</span>
          </div>
          <div>
            <input class="input-area input-age" type="text" placeholder="100"
              [maxlength]="3"
              [formControlName]="Constant.tlDocumentReadEndType" />
            <span class="search-prefix">
              %　</span>
          </div>
        </div>
      </ng-template>
      <!-- 就業/転職活動予定時期 -->
      <ng-template #formChangeJobPlans>
        <ul class="range-input-area relative">
          <div>
            <input class="input-area input-year" min="2018" placeholder="2018"
              [maxlength]="maxLenFour"
              [formControlName]="Constant.tlActivitiesStartYearType" />
            <span class="search-prefix">年</span>
            <div class="error-text absolute" *ngIf="activitiesStartYear.invalid && activitiesStartYear.touched">
              無効な数値です
            </div>
          </div>
          <div>
            <input class="input-area input-month" min="1" max="12" placeholder="3"
              [maxlength]="maxLenTwo"
              [formControlName]="Constant.tlActivitiesStartMonthType" />
            <span class="search-prefix">月 〜</span>
            <div class="error-text absolute" *ngIf="activitiesStartMonth.invalid && activitiesStartMonth.touched">
              無効な数値です
            </div>
          </div>
          <div>
            <input class="input-area input-year" min="2018" placeholder="2020"
              [maxlength]="maxLenFour"
              [formControlName]="Constant.tlActivitiesEndYearType" />
            <span class="search-prefix">年</span>
            <div class="error-text absolute" *ngIf="activitiesEndYear.invalid && activitiesEndYear.touched">
              無効な数値です
            </div>
          </div>
          <div>
            <input class="input-area input-month" min="1" max="12" placeholder="3"
              [maxlength]="maxLenTwo"
              [formControlName]="Constant.tlActivitiesEndMonthType" />
            <span class="search-prefix">月</span>
            <div class="error-text absolute" *ngIf="activitiesEndMonth.invalid && activitiesEndMonth.touched">
              無効な数値です
            </div>
          </div>
        </ul>
      </ng-template>
      <!-- 名前 -->
      <ng-template #formTarentName>
        <ul>
          <input class="input-area input-name" type="text" placeholder="タレント名"
            [formControlName]="Constant.tlTalentNameType" />
        </ul>
      </ng-template>
      <!-- 年齢 -->
      <ng-template #formTarentAge>
        <div class="range-input-area">
          <div>
            <input class="input-area input-age" type="text" placeholder="年齢"
              [maxlength]="maxLenTwo"
              [formControlName]="Constant.tlTalentAgeFromType" />
            <span class="search-prefix">歳　〜</span>
          </div>
          <div>
            <input class="input-area input-age" type="text" placeholder="年齢"
              [maxlength]="maxLenTwo"
              [formControlName]="Constant.tlTalentAgeToType" />
            <span class="search-prefix">歳　</span>
          </div>
        </div>
        <ul>
          <li class="header-contents-general-list" >
            <mat-checkbox color="primary" [formControlName]="Constant.tlTalentAgeTypeNone">
              未登録
            </mat-checkbox>
          </li>
        </ul>
      </ng-template>
      <!-- 希望年収 -->
      <ng-template #formDesireIncome>
        <div class="range-input-area">
          <div>
            <input class="input-area input-age" type="text" placeholder="400"
              [maxlength]="maxLenFive"
              [formControlName]="Constant.tlDesireIncomeFromType" />
            <span class="search-prefix"> 万円　〜</span>
          </div>
          <div>
            <input class="input-area input-age" type="text" placeholder="600"
              [maxlength]="maxLenFive"
              [formControlName]="Constant.tlDesireIncomeToType" />
            <span class="search-prefix"> 万円　</span>
          </div>
        </div>
        <ul>
          <li class="header-contents-general-list" >
            <mat-checkbox color="primary" [formControlName]="Constant.tlDesireIncomeTypeNone">
              未登録
            </mat-checkbox>
          </li>
        </ul>
      </ng-template>
      <!-- 追加プロフ -->
      <ng-template #formQuestion let-datas="datas">
        <div class="question-list-area">
          <div class="question-no" *ngIf="datas.length == 0">
            質問がまだ作成されていません。<br>
            <a routerLink="/question" target="_blank">質問を作成する</a>
          </div>
        <ng-container *ngFor="let data of datas; first as first; last as last">
          <div class="question" [class.top0]="first">
            <div class="header-title">
              <span class="list-title">{{ data.title }}</span>
            </div>
            <ul class="question-checkbox">
              <li *ngFor="let item of data.select_item" [class.gray]="item.item_order_no == 0">
                <mat-checkbox color="primary" id="{{ Constant.tlAddQuestionType + Constant.pythen + data.question_id + Constant.pythen + item.id }}"
                name="{{ Constant.tlAddQuestionType + Constant.pythen + data.question_id + Constant.pythen + item.id }}"
                [formControlName]="Constant.tlAddQuestionType + Constant.pythen + data.question_id + Constant.pythen + item.id">
                  {{ item.item_value }}
                </mat-checkbox>
              </li>
            </ul>
          </div>
          <mat-divider class="clear" *ngIf="!last"></mat-divider>
        </ng-container>
        </div>
      </ng-template>
    </form>
  </div>
</ng-container>
<!-- 並び替えメニュー -->
<div class="list-order" *ngIf="searchData && searchData.conditions && headers">
  <div class="select-title" [class.selected]="searchData.conditions.type == Constant.tlOrderDefault"
    (click)="onDefaultOrder()">標準
  </div>
  <div class="select-partition">|</div>
  <div class="select-title" [class.selected]="searchData.conditions.type == Constant.tlOrderMsgType"
    (click)="onMsgOrder()">メッセージ順
  </div>
  <div class="select-partition">|</div>
  <div class="select-title" [class.selected]="searchData.conditions.type == Constant.tlOrderOfrApplyType"
    (click)="onOfrApplyOrder()">応募順
  </div>
  <div class="select-partition">|</div>
  <div class="select-title" [class.selected]="searchData.conditions.type == Constant.tlOrderOfrMindType"
    (click)="onOfrMindOrder()">気になる順
  </div>
  <div class="select-partition">|</div>
  <div class="select-title" [class.selected]="searchData.conditions.type == Constant.tlOrderAccessType"
    (click)="onAccessOrder()">アクセス順
  </div>
  <div class="select-partition">|</div>
  <div class="select-title" [class.selected]="searchData.conditions.type == Constant.tlOrderDataType"
    (click)="onDataOrder()">プロフ更新順/タレント操作
  </div>
  <div class="select-partition">|</div>
  <div class="select-title" [class.selected]="searchData.conditions.type == Constant.tlOrderPoolType"
    (click)="onPoolOrder()">タレントプール登録順
  </div>
  <div class="select-partition">|</div>
  <div class="select-title" [class.selected]="searchData.conditions.type == Constant.tlOrderCompanyType"
    (click)="onCompanyOrder()">プロフ･メモ更新順/貴社操作
  </div>
  <div class="caution">
    ※ 並び替えには絞込条件も適用されます。
  </div>
</div>
