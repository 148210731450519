import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { AuthService } from '../../auth/auth.service';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { Constant } from '../../constant';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-prcontenttag-category-dialog',
  templateUrl: './prcontenttag-category-dialog.component.html',
  styleUrl: './prcontenttag-category-dialog.component.css'
})
export class PrcontenttagCategoryDialogComponent implements OnInit {
  public form: FormGroup;
  public showSpinner = true;
  public isSaving = false;
  public category;
  private apiPath = '/prcontent/tagcategory/master';
  private maxLenTitle = 10

  constructor(
    private dialogRef: MatDialogRef<PrcontenttagCategoryDialogComponent>,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) { }

  ngOnInit(): void {
    this.getMaster();
  }

  // 保存
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const sendData = [];
    this.category.forEach(element => {
      sendData.push(
        {title: this.form.value['title'+element.order_no], order_no: element.order_no}
      );
    });
    this.postMaster(sendData);
  }

  private initForm() {
    const formControl = {};
    this.category.forEach(item => {
      formControl['title' + item.order_no] = new FormControl('', [Validators.maxLength(this.maxLenTitle)]);
    });

    this.form = new FormGroup(formControl);

    setTimeout(() => {
      let title = {}
      this.category.forEach(item => {
        title['title' + item.order_no] = item.title
      });
      this.form.patchValue(title)
    });
  }

  // マスタ取得
  private getMaster() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil.apiGet(this.apiPath, options).then(res => {
        this.category = res.data
        // カテゴリー未登録の場合、1~8の空を作る
        if (!this.category || this.category.length === 0) {
          for (let i=1; i<= 8; i++) {
            this.category.push({
              id: 0,
              order_no: i,
              title: '',
            })
          }
        }
        this.initForm()
        this.showSpinner = false
      })
      .catch(err => {
        this.showSpinner = false;
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        // ダイアログ閉じる
        this.dialogRef.close();
      });
    });
  }

  private postMaster(sendData) {
    this.isSaving = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {categorys: sendData};

      this.commonUtil.apiPost(this.apiPath, options).then(res => {
        this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
        // ダイアログ閉じる
        this.dialogRef.close(res.data.category);
      })
      .catch(err => {
        this.toastUtil.clearAllShowingToast();
        // 保存失敗エラー
        this.isSaving = false;
        this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
      });
    });
  }

}
