<div class="title-area">
  <h2 mat-subheader class="title">求人情報管理</h2>
  <div class="btn-new">
    <button mat-raised-button type="button" color="warn" [disabled]="showSpinner" (click)="onEdit()">
      新規作成
    </button>
    <button mat-raised-button type="button" class="basicinfo" [disabled]="showSpinner" (click)="onPriority()">
      優先表示
    </button>
    <button mat-raised-button type="button" class="basicinfo" [disabled]="showSpinner" (click)="onBaseInfo()">
      一覧ページ 登録
    </button>
  </div>
</div>
<div class="desc">
  求人情報を有効にする場合は、「新規作成」した求人のステータスを「未公開」から「公開中」に変更してください。<br />
  各アクション数の上段は「全体数」で、タレントプール未登録/解除済のタレントによるものを含みます。<br>
  下段の（　）内は、タレントプール登録中のタレントによるアクション数です。
</div>

<div *ngIf="showSpinner" class="spinner"><mat-spinner></mat-spinner></div>
<table *ngIf="!showSpinner" class="job-offer">
  <tr>
    <th>ステータス</th>
    <th colspan="2">求人情報</th>
    <th>閲覧数</th>
    <th>シェア</th>
    <th>気になる</th>
    <th>応募</th>
    <th>Menu</th>
  </tr>
  <ng-container *ngFor="let joboffer of jobOffers; index as idx">
    <tr class="inuse{{ joboffer.release_status }}">
      <!-- ステータス -->
      <td rowspan="3" class="use" *ngIf="joboffer.release_status == Constant.joReleaseStatusNoRelease">
        @if (joboffer.limited_flag != 1) {
          <img
          src="assets/notice/default-check.png"
          srcset="
            assets/notice/default-check.png    1x,
            assets/notice/default-check@2x.png 2x,
            assets/notice/default-check@3x.svg 3x">
        } @else {
          <img src="assets/common/lock_red.svg" class="limited-icon">
        }
        <br />未公開
      </td>
      <td rowspan="3" class="use" *ngIf="joboffer.release_status == Constant.joReleaseStatusRelease">
        @if (joboffer.limited_flag != 1) {
          <img
          src="assets/notice/use-check.png"
          srcset="
            assets/notice/use-check.png    1x,
            assets/notice/use-check@2x.png 2x,
            assets/notice/use-check@3x.svg 3x">
        } @else {
          <img src="assets/common/lock_blue.svg" class="limited-icon">
        }
        <br />公開中
      </td>
      <td rowspan="3" class="use" *ngIf="joboffer.release_status == Constant.joReleaseStatusStop">
        @if (joboffer.limited_flag != 1) {
          <img
          src="assets/notice/stop-check.png"
          srcset="
            assets/notice/stop-check.png    1x,
            assets/notice/stop-check@2x.png 2x,
            assets/notice/stop-check@3x.svg 3x">
        } @else {
          <img src="assets/common/lock_gray.svg" class="limited-icon">
        }
        <br />非公開
      </td>
      <!-- 求人情報（画像） -->
      <td class="job-image" rowspan="3">
        <div *ngIf="joboffer.image_url == Constant.empty" class="noimage">
          <span>No Image</span>
        </div>
        <img *ngIf="joboffer.image_url != Constant.empty" src="{{ joboffer.image_url }}" />
      </td>
      <!-- 求人情報（タイトル） -->
      <td rowspan="1" class="title-row">
        <div class="title-container">
          <a (click)="onDetail(idx)">{{ joboffer.title }}</a>
        </div>
      </td>
      <!-- 閲覧数 -->
      <td class="cnt" rowspan="3">
        <ng-container *ngIf="joboffer.release_status==Constant.joReleaseStatusNoRelease">-</ng-container>
        <ng-container *ngIf="joboffer.release_status!=Constant.joReleaseStatusNoRelease">
          {{ joboffer.view_cnt }}<br>（<a (click)="onToList(Constant.offerActionView, idx)">{{ joboffer.view_cnt_talent }}</a>）
        </ng-container>
      </td>
      <!-- シェア -->
      <td class="cnt" rowspan="3">
        <ng-container *ngIf="joboffer.release_status==Constant.joReleaseStatusNoRelease">-</ng-container>
        <ng-container *ngIf="joboffer.release_status!=Constant.joReleaseStatusNoRelease">
          {{ joboffer.share_cnt }}<br>（<a (click)="onToList(Constant.offerActionShare, idx)">{{ joboffer.share_cnt_talent }}</a>）
        </ng-container>
      </td>
      <!-- 気になる -->
      <td class="cnt" rowspan="3">
        <ng-container *ngIf="joboffer.release_status==Constant.joReleaseStatusNoRelease">-</ng-container>
        <ng-container *ngIf="joboffer.release_status!=Constant.joReleaseStatusNoRelease">
          {{ joboffer.mind_cnt }}<br>（<a (click)="onToList(Constant.offerActionMind, idx)">{{ joboffer.mind_cnt_talent }}</a>）
        </ng-container>
      </td>
      <!-- 応募 -->
      <td class="cnt" rowspan="3">
        <ng-container *ngIf="joboffer.release_status==Constant.joReleaseStatusNoRelease">-</ng-container>
        <ng-container *ngIf="joboffer.release_status!=Constant.joReleaseStatusNoRelease">
          {{ joboffer.apply_cnt }}<br>（<a (click)="onToList(Constant.offerActionApply, idx)">{{ joboffer.apply_cnt_talent }}</a>）
        </ng-container>
      </td>
      <td class="btn-edit" rowspan="3">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-btn">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" overlapTrigger="false">
          <button mat-menu-item (click)="onDetail(idx)">詳細</button>
          <button mat-menu-item (click)="onEdit(idx)">編集</button>
          <button
            mat-menu-item
            (click)="onRelease(idx, false)"
            *ngIf="joboffer.release_status == Constant.joReleaseStatusRelease"
          >
            非公開にする
          </button>
          <button
            mat-menu-item
            (click)="onRelease(idx, true)"
            *ngIf="
              joboffer.release_status == Constant.joReleaseStatusNoRelease ||
              joboffer.release_status == Constant.joReleaseStatusStop
            "
          >
            公開する
          </button>
          <button mat-menu-item (click)="onJobOfferCopy(idx)">コピー</button>
          <button mat-menu-item (click)="onDel(idx)">削除</button>
        </mat-menu>
      </td>
    </tr>
    <tr class="inuse{{ joboffer.release_status }}">
      <td class="jobcategory-container display-flex">
        <div class="image-container"><img class="icon-small" src="assets/joboffer/label.png" /></div>
        <div class="text-container">
          <span *ngIf="!joboffer.job_category_middle_text" class="text">
            {{ joboffer.job_category_text }}
          </span>
          <span *ngIf="joboffer.job_category_middle_text" class="text"
            >{{ joboffer.job_category_text }} > {{ joboffer.job_category_middle_text }}</span
          >
        </div>
      </td>
      <td class="jobplace-container display-flex">
        <div class="image-container"><img class="icon-small" src="assets/joboffer/map.png" /></div>
        <div class="text-container">
          <span class="text">{{ joboffer.job_place_text }} {{ joboffer.job_place_city }}<ng-container *ngIf="joboffer.sub_job_place_ary.length>0">、</ng-container></span>
          <span class="text" *ngFor="let place of joboffer.sub_job_place_ary; last as last">{{ place }}<ng-container *ngIf="!last">、</ng-container></span>
        </div>
      </td>
      <td class="employment-container display-flex">
        <div class="image-container"><img class="icon-small" src="assets/joboffer/business.png" /></div>
        <div class="text-container">
          <span class="text">{{ joboffer.employment_text }}</span>
        </div>
      </td>
    </tr>
    <tr class="inuse{{ joboffer.release_status }} border-bottom">
      <td colspan="1" class="url-container">
        <div class="url-text-container" *ngIf="joboffer.release_status !== Constant.joReleaseStatusNoRelease">
          <div class="text">URL</div>
          <div class="url"><a href="{{ joboffer.url }}" target="_blank">{{ joboffer.url }}</a></div>
          <div class="copy" (click)="onCopy(idx)">
            <img
              src="assets/notice/copy-icon.png"
              srcset="
                assets/notice/copy-icon.png    1x,
                assets/notice/copy-icon@2x.png 2x,
                assets/notice/copy-icon@3x.svg 3x
              "
            />
            URLをコピー
          </div>
          <input id="url-code-{{ idx }}" type="hidden" value="{{ joboffer.url }}" />
        </div>
      </td>
    </tr>
  </ng-container>
</table>

<div
  *ngIf="!showSpinner && jobOffers && jobOffers.length == 0"
  class="no-joboffer"
>
  求人情報がありません。求人情報を作成してください。
</div>
