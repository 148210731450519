<div class="header">
  <ng-container *ngIf="!showSpinner">
    <div class="header-left" *ngIf="material.release_status == Constant.pcReleaseStatusRelease">
      <div class="release_status released">
        公開中
      </div>
      @if (material.limited_flag == 1) {
        <img class="limited-icon" src="assets/common/lock_blue.svg">
      }
    </div>
    <div class="header-left" *ngIf="material.release_status == Constant.pcReleaseStatusStop">
      <div class="release_status stopped">
        非公開
      </div>
      @if (material.limited_flag == 1) {
        <img class="limited-icon" src="assets/common/lock_gray.svg">
      }
    </div>
  </ng-container>
  <div mat-icon-button (click)="onClose()">
    <img src="assets/common/close_button.png" srcset="
        assets/common/close_button.png    1x,
        assets/common/close_button@2x.png 2x,
        assets/common/close_button@3x.svg 3x
      " class="close-button" />
  </div>
</div>
<mat-dialog-content>
  <div class="spinner" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container>
    <div class="content" [class.hidden]="showSpinner">
      <div class="title bold" *ngIf="material">{{ material.title }}</div>
      <div class="pdf-container">
        <div class="pdf" *ngIf="documentUrl">
          <pdf-viewer [src]="documentUrl" class="pdf-viewer" [show-all]="false" [page]="documentPage"
          [fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true" [render-text]="false"
          (after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"></pdf-viewer>
          <div class="pdf-viewer-navi" *ngIf="documentTotalPage>0">
            <div class="leftbtn">
              <button mat-flat-button (click)="prevBtn()" [disabled]="documentPage==1">＜</button>
              <button mat-flat-button (click)="nextBtn()" [disabled]="documentPage==documentTotalPage">＞</button>
            </div>
            <span>{{ documentPage }} / {{ documentTotalPage }}</span>
            <div class="rightbtn">
              <button mat-icon-button (click)="onPdf()"><mat-icon>fullscreen</mat-icon></button>
            </div>
          </div>
        </div>
        <div class="pdf" *ngIf="!documentUrl">
          <div class="no-image-frame">
            <div class="noimage">
              No Image
            </div>
          </div>
          <div class="pdf-viewer-navi">
            <div class="leftbtn">
              <button mat-flat-button disabled>＜</button>
              <button mat-flat-button disabled>＞</button>
            </div>
          </div>
        </div>
      </div>
      @if (talentPv) {
        <div class="title-talent">タレント個人別の閲覧状況</div>
        <table>
          <tr>
            <th>ページ</th>
            <th>PV</th>
            <th>閲覧時間</th>
            <th>最新日</th>
          </tr>
          <ng-container *ngFor="let pv of talentPv">
            <tr>
              <td>{{ pv.page }}</td>
              <td>{{ pv.pv }}</td>
              <td>{{ pv.time }}</td>
              <td>{{ pv.show_date }}</td>
            </tr>
          </ng-container>
        </table>
      }
    </div>
  </ng-container>
</mat-dialog-content>
