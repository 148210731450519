<div class="header">
  <div>{{ type }}の変更</div>
  <div mat-icon-button mat-dialog-close class="close-button">
    <img src="assets/analytics/close_icon.svg">
  </div>
</div>
<mat-dialog-content>
  <div class="spinner" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
  <div class="content" *ngIf="!showSpinner">
    <div class="rect">
      <table>
        <tr>
          <th class="title" colspan="2"></th>
          <th class="middle">ステータス</th>
          <th class="middle">PV</th>
          @if (type == Constant.alTypeJoboffer) {
            <th class="middle">応募</th>
            <th class="middle">気になる</th>
          } @else {
            <th class="middle">いいね</th>
          }

          <th class="middle">初回公開日</th>
        </tr>
        <tr class="row">
          <td class="image-container all">
            <a (click)="onSelect(0)">
              <div class="image noimage">
                <div>ALL</div>
              </div>
            </a>
          </td>
          <td class="title">
            <a (click)="onSelect(0)">すべての記事</a>
          </td>
          <td class="middle">
            -
          </td>
          <td class="middle">
            {{ allCnt.view_cnt|number }}
          </td>
          @if (type == Constant.alTypeJoboffer) {
            <td class="middle">
              {{ allCnt.apply_cnt|number }}
            </td>
            <td class="middle">
              {{ allCnt.mind_cnt|number }}
            </td>
          } @else {
            <td class="middle">
              {{ allCnt.good_cnt|number }}
            </td>
          }
          <td class="middle">
            -
          </td>
        </tr>
      </table>
    </div>

    <div class="rect">
      <table>
        <tr>
          <th class="title" colspan="2">タイトル</th>
          <th class="middle">ステータス</th>
          <th class="middle">PV</th>
          @if (type == Constant.alTypeJoboffer) {
            <th class="middle">応募</th>
            <th class="middle">気になる</th>
          } @else {
            <th class="middle">いいね</th>
          }
          <th class="middle">初回公開日</th>
        </tr>
        <ng-container *ngFor="let item of dataList; index as idx">
          <tr class="row">
            <td class="image-container">
              <a (click)="onSelect(item.id)">
                <div *ngIf="!item.image_url" class="image noimage">
                  <span>No Image</span>
                </div>
                <img *ngIf="item.image_url" src="{{ item.image_url }}" class="image">
              </a>
            </td>
            <td class="title">
              <a (click)="onSelect(item.id)">{{ item.title }}</a>
            </td>
            <td class="middle status" [class.gray]="item.release_status!= Constant.pcReleaseStatusRelease">
              @if (item.release_status == Constant.pcReleaseStatusRelease) {
                公開中
              } @else {
                非公開
              }
            </td>
            <td class="middle">
              {{ item.view_cnt|number }}
            </td>
            @if (type == Constant.alTypeJoboffer) {
              <td class="middle">
                {{ item.apply_cnt|number }}
              </td>
              <td class="middle">
                {{ item.mind_cnt|number }}
              </td>
            } @else {
              <td class="middle">
                {{ item.good_cnt|number }}
              </td>
            }

            <td class="middle">
              {{ item.release_date }}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>

</mat-dialog-content>
