import { Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRange, MatDatepickerModule,DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel, MatCalendar } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import * as moment from 'moment';
import { ToastUtil } from '../../util/toast-util';
import { Constant } from './../../constant';


@Component({
  selector: 'app-analytics-calendar-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
  ],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
  templateUrl: './analytics-calendar-dialog.component.html',
  styleUrl: './analytics-calendar-dialog.component.css'
})
export class AnalyticsCalendarDialogComponent {
  public selectedDateRange;
  public startDate = moment(new Date(2018, 0, 1))
  public endDate = moment()
  @ViewChild('calendar') calendar: MatCalendar<Date>

  constructor(
    private dialogRef: MatDialogRef<AnalyticsCalendarDialogComponent>,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private toastUtil: ToastUtil,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.selectedDateRange = new DateRange(
      moment(this.data.start),
      moment(this.data.end),
    );
    this.selectionModel.updateSelection(this.selectedDateRange, this)
  }

  ngAfterViewInit() {
    // 開始日時に移動
    if (this.selectedDateRange.start) {
      this.calendar._goToDateInView(this.selectedDateRange.start, "month")
    }
  }

  // カレンダー選択
  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );

    this.selectionModel.updateSelection(newSelection, this);

    this.selectedDateRange = new DateRange<Date>(
      newSelection.start,
      newSelection.end
    );
  }

  // キャンセル
  onCancel() {
    this.dialogRef.close();
  }

  // 変更
  onSave() {
    // 1年以内のチェック
    if (moment(this.selectedDateRange.start).add(1, 'years').subtract(1, 'days').isBefore(this.selectedDateRange.end)) {
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.alMsgErrWithinYear, Constant.toastShowMiliSec)
      return
    }

    const res = {
      start: this.selectedDateRange.start.toDate(),
      end: this.selectedDateRange.end.toDate(),
    }
    this.dialogRef.close(res);
  }
}
