<div class="flex">
  <div class="side">
    <div class="button-container">
      <button mat-raised-button (click)="onClose()">閉じる</button>
    </div>
  </div>
  <div class="side-main">
    <h2 mat-subheader class="title">{{ Constant.pageTitleMultiMessageExcept }}</h2>
    <div class="container">
      <div class="desc">
        <span class="red-text">こちらの除外リストに登録したタレントには、一斉メッセージが送信されません。</span><br>
        除外リストには、タレントプールの登録を解消したタレントは表示されません。
        なお、除外リストに登録されていたタレントが再度タレントプールに登録してきた場合、自動的に除外リストへ追加されます。<br>
        除外リストにタレントを追加/削除する場合は、「<a (click)="onTalentList()">タレントリスト</a>」からタレントの詳細情報ページを開き、操作してください。
      </div>
      <div *ngIf="showSpinner && !talents" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <!-- 人数カウンター -->
      <div class="persons-cnt" *ngIf="talents">{{ totalCnt }}人</div>
      <!-- タレントリストを表示 -->
      <app-talent-list-common [talentData]="talentData"></app-talent-list-common>
      <div class="spinner" *ngIf="talents && showSpinner">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>
  </div>
</div>
