import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { environment } from './../../../environments/environment';
import { ImageUtil } from './../../util/image-util';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';
import { sprintf } from 'sprintf-js';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PdfviewerDialogComponent } from '../pdfviewer-dialog/pdfviewer-dialog.component';

@Component({
  selector: 'app-material-edit-dialog',
  templateUrl: './material-edit-dialog.component.html',
  styleUrl: './material-edit-dialog.component.css'
})
export class MaterialEditDialogComponent {
  public readonly Constant = Constant;
  public mode;
  public isSaving = false;
  public showSpinner = true;
  public material
  public form: FormGroup;
  public documentUrl = null;
  public documentTotalPage = 0;
  public documentPage = 1;
  public maxLenTitle = 20
  private apiPath = '/document';
  private documentData = null;
  private uploadedDocumentPath = '';
  private documentImageBlob = null
  private uploadedDocumentImgPath = '';

  @ViewChild('documentFileInput')
  private documentFileInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<MaterialEditDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private imageUtil: ImageUtil,
    private auth: AuthService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mode = data.type;
  }

  ngOnInit() {
    this.initForm();

    if (this.data.id !== 0) {
      this.getDetail();
    } else {
      this.material = {
        image_url: Constant.empty,
        image_path: Constant.empty,
        title: Constant.empty,
        limited_flag: 0,
        top_flag: 0,
      };
      this.setFormValue()
      this.showSpinner = false
    }
  }

  // 更新
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.toastUtil.clearAllShowingToast();
    const config = environment.amplify.Storage.companyDocument;
    if (this.documentData) {
      // PDFが変更されていた場合はS3に保存し、データを更新
      this.uploadPdfData(this.documentData, config);
    } else if (this.documentImageBlob) {
      // サムネイル画像のみ、S3に保存し、データ更新
      this.uploadThumImgData(config);
    } else {
      this.dataUpdate();
    }
  }


  // キャンセル
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.dcMsgConfirmEditCancel, this.mode), check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close('');
      }
    });
  }

  // PDF選択
  onChangeImage(evt) {
    const file = evt.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function() {
      // 表示前にファイル形式チェック
      if (file.type !== Constant.fileFormatPdf) {
        this.toastUtil.showErrorToast('', Constant.msgErrorInvalidFile, Constant.toastShowMiliSec);
        return;
      }
      this.documentImageBlob = null
      this.documentUrl = fileReader.result;
      this.documentData = file;
      this.documentPage = 1;
    }.bind(this);

    fileReader.readAsDataURL(file);
  }

  // PDF削除
  onImageDelete() {
    this.documentUrl = '';
    this.documentData = null;
    this.documentFileInput.nativeElement.value = '';
    this.documentImageBlob = null
  }

  // トップページ表示切り替え
  changeTopFlg(checked) {
    if (checked) {
      // 一覧表示フラグdisabled
      this.form.get('limited_flag').setValue(0)
      this.form.get('limited_flag').disable()

    } else {
      this.form.get('limited_flag').enable()
    }

  }

  /* API */
  // 詳細取得
  private getDetail() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = this.apiPath + '/detail/' + this.data.id;
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(apiPath, options).then(res => {
        if (!res.data) {
          // 削除済み
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgDeleteMaterialAlreadyError, Constant.toastShowMiliSec);
          this.dialogRef.close(true);
          return;
        }
        this.material = res.data;
        this.documentUrl = res.data.document_url;
        this.setFormValue()
        this.changeTopFlg(this.material.top_flag)
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
        this.dialogRef.close();
      });
    });
  }

  /* private */
  // フォーム作成
  private initForm() {
    this.form = new FormGroup({
      title: new FormControl(Constant.empty, [Validators.required, Validators.maxLength(this.maxLenTitle)]),
      limited_flag: new FormControl(Constant.empty),
      top_flag: new FormControl(Constant.empty),
    });
  }

  //　フォームに値をセット
  private setFormValue() {
    setTimeout(() => {
      this.form.patchValue({
        title: this.material.title,
        limited_flag: this.material.limited_flag,
        top_flag: this.material.top_flag,
      });
    });
  }

  // 更新実行
  private dataUpdate() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        document_path: this.uploadedDocumentPath,
        image_path: this.uploadedDocumentImgPath,
        title: this.commonUtil.replaceSpace(this.form.value.title),
        top_flag: this.form.value.top_flag ? 1 : 0,
        limited_flag: this.form.value.limited_flag ? this.form.value.limited_flag: 0,
        email: localStorage.getItem(Constant.lsOperator),
      };
      if (this.material.id) {
        options['body']['id'] = this.material.id
      }

      this.commonUtil
        .apiPost(this.apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            let msg
            if (this.mode === Constant.dcTypeAdd) {
              msg = Constant.dcMsgAddSaveSuccess
            } else {
              msg = Constant.msgResultSaveSuccess
            }
            this.toastUtil.showInformationToast('', msg, Constant.toastShowMiliSec);
            this.dialogRef.close(res.status);
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.isSaving = false;

          if (err.status === Constant.NG && err.data.cnt == 0) {
            // 削除済み
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgResultSaveFailed,
              Constant.toastShowMiliSec
            );
            this.dialogRef.close(err.status);
          } else {
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgSaveFailedRetry,
              Constant.toastShowMiliSec
            );
          }
        });
    });
  }

  /* PDF関連 */
  pdfLoadComplete(pdfData) {
    this.documentTotalPage = pdfData.numPages;
    this.showSpinner = false
  }

  pdfRendered(event) {
    // 1ページ目のimage
    if (event.pageNumber === 1 && !this.material.image_path && !this.documentImageBlob) {
      const canvas = event.source.canvas
      if (canvas) {
        const thumImg = canvas.toDataURL(Constant.imageFormatPng);
        this.documentImageBlob = this.imageUtil.base64ToBlob(thumImg, Constant.imageFormatPng)
      }
    }
  }

  public pdfError() {
    if (this.documentTotalPage === 0) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
    }
  }
  public prevBtn() {
    this.documentPage--;
  }
  public nextBtn() {
    this.documentPage++;
  }
  // PDF全画面ダイアログ表示
  public onPdf() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const dialogRef = this.dialog.open(PdfviewerDialogComponent, {
      width: '100%',
      maxWidth: '99vw',
      maxHeight: '99%',
      autoFocus: false,
      panelClass: 'pdf-dialog',
      data: { url: this.documentUrl }
    });
  }

  // PDFをS3にアップロードする
  private uploadPdfData(pdfData, config) {
    this.imageUtil.uploadPdfDataToS3(pdfData, config).then(pdf_path => {
      this.uploadedDocumentPath = pdf_path;
      this.uploadThumImgData(config);
    })
    .catch(err => {
      this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSecErr);
      this.isSaving = false;
    });
  }

  // サムネイル画像をS3にアップロードする
  private uploadThumImgData(config) {
    this.imageUtil
      .uploadImageBlobToS3(this.documentImageBlob, Constant.imageFormatPng, Constant.fileExtensionPng, config)
      .then(image_path => {
        this.uploadedDocumentImgPath = image_path;
        this.dataUpdate();
      })
      .catch(err => {
        this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSecErr);
        this.isSaving = false;
      });
  }
}
