import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { ImageUtil } from './../../util/image-util';
import { MatDialog } from '@angular/material/dialog';
import { MultipleSelectDialogComponent } from './../multiple-select-dialog/multiple-select-dialog.component';
import { environment } from './../../../environments/environment';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';
import { sprintf } from 'sprintf-js';
import { QuillCommonComponent } from './../../quill-common/quill-common.component';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-joboffer-edit-dialog',
  templateUrl: './joboffer-edit-dialog.component.html',
  styleUrls: ['./joboffer-edit-dialog.component.css']
})
export class JobofferEditDialogComponent implements OnInit {
  public showSpinner = true
  public form: FormGroup;
  public jobOffer;
  public master;
  public jobCategoryMaster;
  public jobPlaceMaster;
  public employmentTypeMaster;
  public employmentType;
  public jobPlaceId;
  public jobCategoryId;
  public jobCategoryMiddleId;
  public readonly Constant = Constant;
  public mode;
  public jobCategories = [];
  public jobCategoriesMiddle = [];
  public maxLenTitle = 35;
  public maxLen15 = 15;
  public maxLen20 = 20;
  public maxLen100 = 100;
  public maxLen200 = 200;
  public maxLen400 = 400;
  public maxLenAddress = 255;
  public jobImagePath = null;
  public isSaving = false;
  public selectedSubJobPlaceAry = []
  private jobOfferId
  private selectedSubJobPlaceIds = '';
  private jobImageData = null;
  private uploadedJobImagePath = null;

  @ViewChild('imgFileInput')
  private imgFileInput: ElementRef;

  // エディタ関連
  public editorData
  private editorText = []
  @ViewChild('quillPr')
  private quillPr: QuillCommonComponent;
  @ViewChild('quillDesc')
  private quillDesc: QuillCommonComponent;
  @ViewChild('quillClose')
  private quillClose: QuillCommonComponent;

  constructor(
    public dialogRef: MatDialogRef<JobofferEditDialogComponent>,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private imageUtil: ImageUtil,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.jobCategoryMaster = data.master[Constant.joJobType];
    this.jobPlaceMaster = data.master[Constant.joJobPlaceType];
    this.employmentTypeMaster = data.master[Constant.joEmploymentType];
    this.mode = data.type;
    this.jobOfferId = data.id
    this.master = data.master
  }

  ngOnInit() {
    if (this.jobOfferId !== 0) {
      this.getDetail();
    } else {
      if (Object.keys(this.master).length === 0) {
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
        this.dialogRef.close();
        return
      }
      this.jobOffer = {
        id: null,
        image_url: '',
        image_path: '',
        sub_job_place_id: '',
      };
      this.jobImagePath = ''
      this.editorData = [
        {
          text: this.jobOffer.pr ? this.jobOffer.pr: '',
          type: Constant.editorTypeJoboffer
        },
        {
          text: this.jobOffer.description ? this.jobOffer.description: '',
          type: Constant.editorTypeJobBase
        },
        {
          text: this.jobOffer.closing ? this.jobOffer.closing: '',
          type: Constant.editorTypeJoboffer
        }
      ]
      this.initForm();
    }
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.toastUtil.clearAllShowingToast();

    // エディタ画像数チェック
    this.editorText = []
    const quillComponet = [
      this.quillPr,
      this.quillDesc,
      this.quillClose
    ]
    let imgCnt = 0
    quillComponet.forEach(element => {
      imgCnt += element.getImgCnt()
    });
    if (imgCnt > 50) {
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgErrorSaveEditorImg, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    // エディタ本文取得
    quillComponet.forEach(element => {
      this.editorText.push(element.getContent())
    });

    if (this.jobImageData) {
      // 画像を更新した場合はアップロードしてから登録
      this.uploadImageData();
    } else {
      this.dataUpdate();
    }
  }

  // キャンセル
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.joMsgConfirmEditCancel, this.mode), check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close('');
      }
    });
  }

  // ダイアログを閉じる（エディタより起動)
  onCloseDialog() {
    this.dialogRef.close()
  }

  onCategorySelected() {
    const categoryid = this.form.value.job_category;
    this.form.patchValue({job_category_middle: ''})
    this.filterJobCategoryMiddle(categoryid);
  }

  onAddSubJobPlace() {
    let currentData = '';
    const isMultiRow = true;
    let title = '';
    const fontSize = Constant.elementValGeneralFontSize;
    const rowWidth = Constant.elementVal4RowWidth;
    currentData = this.selectedSubJobPlaceIds;
    title = Constant.companyTextSelectSubJobPlace;
    const dialogRef = this.dialog.open(MultipleSelectDialogComponent, {
      width: Constant.multiSelectDialogWidth,
      autoFocus: false,
      data: {
        masterData: this.jobPlaceMaster,
        currentData: currentData,
        isMultiRow: isMultiRow,
        fontSize: fontSize,
        rowWidth: rowWidth,
        title: title
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // チェックされたidをテキストに変換し、連結する
        this.convertMasterIdToText(result);
        // 再度ダイアログに渡す用のカンマ区切りの文字列にする
        const strResult = this.commonUtil.concatenateTexts(result, Constant.hsCommma);
        this.selectedSubJobPlaceIds = strResult;
      }
    });
  }

  // 画像選択
  onChangeImage(evt) {
    const file = evt.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = function () {
      this.jobImagePath = fileReader.result;
      this.jobImageData = file;
    }.bind(this);

    fileReader.readAsDataURL(file);
  }

  // 画像削除
  onImageDelete() {
    this.jobImagePath = Constant.empty;
    this.jobImageData = null;
    this.imgFileInput.nativeElement.value = '';
  }

  // 給与にカンマ区切り追加
  onInputNumber(event) {
    if (event.isComposing) {
      // IME入力中は飛ばす
      return false;
    }
    const formName = event.target.getAttribute('formControlName');
    let changeText = event.target.value.replace(/,/g, '');
    const num = Number(changeText);
    if (!changeText || isNaN(num)) {
      return false;
    }
    if (changeText.length > 12) {
      changeText = changeText.substr(0, 12);
    }
    changeText = formatNumber(changeText, 'en-US')
    if (event.target.value !== changeText) {
      setTimeout(() => {
        this.form.patchValue({[formName]: changeText });
      }, 100);
    }
  }
  onCompositionEnd(event) {
    this.onInputNumber(event)
  }

  // 保存ボタン制御
  checkSaveBtn() {
    if (this.showSpinner || this.isSaving || this.form.invalid) {
      return true
    } else if (
      (this.form.value.contract_period !== Constant.contractPeriodTypeNone && !this.form.value.contract_period_detail) ||
      (this.form.value.trial_period !== Constant.trialPeriodTypeNone && !this.form.value.trial_period_detail) ||
      (this.form.value.fixed_overtime !== Constant.fixedOvertimeNone && !this.form.value.fixed_overtime_detail) ||
      (this.form.value.salary_type <= Constant.salaryTypeNenpou && (!this.form.value.salary_lower || !this.checkSararyInput()))
      ) {
      return true
    } else if (!this.quillPr || !this.quillDesc || !this.quillClose) {
      return true
    } else if (this.quillPr.getError() || this.quillDesc.getError() || !this.quillDesc.mainText || this.quillClose.getError()) {
      return true
    }

    return false
  }

  checkSararyInput() {
    if (this.form.value.salary_lower.length > this.maxLen15 || this.form.value.salary_higher.length > this.maxLen15 ||
        !this.form.value.salary_lower.match(/^[0-9,]*$/) || !this.form.value.salary_higher.match(/^[0-9,]*$/)) {
      return false
    } else {
      return true
    }
  }


  /* プライベート */
  private initializeData() {
    let current_id = 0;
    for (const rowData of this.jobCategoryMaster) {
      if (rowData.large_id !== current_id) {
        current_id = rowData.large_id;
        const jobCategoryData = {
          large_id: rowData.large_id,
          large_item_value: rowData.large_item_value
        };
        this.jobCategories.push(jobCategoryData);
      }
    }

    this.filterJobCategoryMiddle(this.jobOffer.job_category_id);
    this.selectedSubJobPlaceIds = this.jobOffer.sub_job_place_id;
  }

  // 職種の中カテゴリーを抽出する
  private filterJobCategoryMiddle(categoryid) {
    const selectedCategory = this.jobCategoryMaster.filter(function(jobCategory) {
      return jobCategory.large_id === categoryid;
    });
    this.jobCategoriesMiddle = [];
    const noData = {
      id: 0,
      item_value: Constant.joCategoryNotSelected
    };
    this.jobCategoriesMiddle.push(noData);
    for (const rowData of selectedCategory) {
      const middleCateory = {
        id: rowData.id,
        item_value: rowData.item_value
      };
      this.jobCategoriesMiddle.push(middleCateory);
    }
  }

  // フォーム作成
  private initForm() {
    this.initializeData()

    this.form = new FormGroup({
      title: new FormControl('', [Validators.required, Validators.maxLength(this.maxLenTitle)]),
      skill: new FormControl('', [Validators.required, Validators.maxLength(this.maxLen400)]),
      person_type: new FormControl('', [Validators.maxLength(this.maxLen400)]),
      employment_type: new FormControl('', [Validators.required]),
      contract_period: new FormControl('', [Validators.required]),
      contract_period_detail: new FormControl('', [Validators.maxLength(this.maxLen100)]),
      trial_period: new FormControl('', [Validators.required]),
      trial_period_detail: new FormControl('', [Validators.maxLength(this.maxLen100)]),
      salary_type: new FormControl('', [Validators.required]),
      salary_lower: new FormControl(''),
      salary_higher: new FormControl(''),
      fixed_overtime: new FormControl('', [Validators.required]),
      fixed_overtime_detail: new FormControl('', [Validators.maxLength(this.maxLen100)]),
      raise: new FormControl('', [Validators.maxLength(this.maxLen100)]),
      salary_note: new FormControl('', [Validators.maxLength(this.maxLen200)]),
      job_place: new FormControl('', [Validators.required]),
      job_place_city: new FormControl('', [Validators.required, Validators.maxLength(this.maxLenAddress)]),
      job_place_note: new FormControl('', [Validators.maxLength(this.maxLen200)]),
      work_hours: new FormControl('', [Validators.maxLength(this.maxLen200)]),
      holiday: new FormControl('', [Validators.maxLength(this.maxLen400)]),
      welfare: new FormControl('', [Validators.maxLength(this.maxLen400)]),
      other_title1: new FormControl('', [Validators.maxLength(this.maxLen20)]),
      other_detail1: new FormControl('', [Validators.maxLength(this.maxLen400)]),
      other_title2: new FormControl('', [Validators.maxLength(this.maxLen20)]),
      other_detail2: new FormControl('', [Validators.maxLength(this.maxLen400)]),
      other_title3: new FormControl('', [Validators.maxLength(this.maxLen20)]),
      other_detail3: new FormControl('', [Validators.maxLength(this.maxLen400)]),
      job_category: new FormControl('', [Validators.required]),
      job_category_middle: new FormControl(''),
    });

    // 初期値設定
    setTimeout(() => {
      this.form.patchValue({
        title: this.jobOffer.title ? this.jobOffer.title: '',
        skill: this.jobOffer.skill ? this.jobOffer.skill: '',
        person_type: this.jobOffer.person_type ? this.jobOffer.person_type: '',
        employment_type: this.jobOffer.employment_type ? Number(this.jobOffer.employment_type) : Constant.employmentTypeRegular,
        contract_period: this.jobOffer.contract_period ? this.jobOffer.contract_period : Constant.contractPeriodTypeNone,
        contract_period_detail: this.jobOffer.contract_period_detail ? this.jobOffer.contract_period_detail: '',
        trial_period: this.jobOffer.trial_period ? this.jobOffer.trial_period : Constant.trialPeriodTypeNone,
        trial_period_detail: this.jobOffer.trial_period_detail ? this.jobOffer.trial_period_detail: '',
        salary_type: this.jobOffer.salary_type ? this.jobOffer.salary_type: Constant.salaryTypeMonth,
        salary_lower: this.jobOffer.salary_lower ? formatNumber(this.jobOffer.salary_lower, 'en-US'): '',
        salary_higher: this.jobOffer.salary_higher ? formatNumber(this.jobOffer.salary_higher, 'en-US'): '',
        fixed_overtime: this.jobOffer.fixed_overtime ? this.jobOffer.fixed_overtime: Constant.fixedOvertimeNone,
        fixed_overtime_detail: this.jobOffer.fixed_overtime_detail ? this.jobOffer.fixed_overtime_detail: '',
        raise: this.jobOffer.raise ? this.jobOffer.raise: '',
        salary_note: this.jobOffer.salary_note ? this.jobOffer.salary_note: '',
        job_place: this.jobOffer.job_place_id ? this.jobOffer.job_place_id: '',
        job_place_city: this.jobOffer.job_place_city ? this.jobOffer.job_place_city: '',
        job_place_note: this.jobOffer.job_place_note ? this.jobOffer.job_place_note: '',
        work_hours: this.jobOffer.work_hours ? this.jobOffer.work_hours: '',
        holiday: this.jobOffer.holiday ? this.jobOffer.holiday: '',
        welfare: this.jobOffer.welfare ? this.jobOffer.welfare: '',
        other_title1: this.jobOffer.other_title1 ? this.jobOffer.other_title1: '',
        other_detail1: this.jobOffer.other_detail1 ? this.jobOffer.other_detail1: '',
        other_title2: this.jobOffer.other_title2 ? this.jobOffer.other_title2: '',
        other_detail2: this.jobOffer.other_detail2 ? this.jobOffer.other_detail2: '',
        other_title3: this.jobOffer.other_title3 ? this.jobOffer.other_title3: '',
        other_detail3: this.jobOffer.other_detail3 ? this.jobOffer.other_detail3: '',
        job_category: this.jobOffer.job_category_id,
        job_category_middle: this.jobOffer.job_category_middle_id,
      });

      this.showSpinner = false;

      // エディタテキスト反映
      if (this.jobOffer.id) {
        setTimeout(() => {
          this.editorData = [
            {
              text: this.jobOffer.pr ? this.jobOffer.pr: '',
              type: Constant.editorTypeJoboffer
            },
            {
              text: this.jobOffer.description ? this.jobOffer.description: '',
              type: Constant.editorTypeJobBase
            },
            {
              text: this.jobOffer.closing ? this.jobOffer.closing: '',
              type: Constant.editorTypeJoboffer
            }
          ]
        }, 500);
      }
    });
  }

  // マスタのidからテキストに変換する
  private convertMasterIdToText(targetIds) {
    // 既存の入力値保存
    const saveInput = []
    this.selectedSubJobPlaceAry.forEach(element => {
      const input = <HTMLInputElement>document.getElementById('subplace-city-' + element.id)
      saveInput.push({id:  element.id, value: input.value})
    });

    this.selectedSubJobPlaceAry = [];
    targetIds.forEach(id => {
      const selectedData = this.jobPlaceMaster.filter(function (data) {
        return data.id === Number(id);
      });
      if (selectedData.length > 0) {
        const save = saveInput.filter(function (data) {
          return data.id === Number(id);
        });
        let city = ''
        if (save.length > 0) {
          city = save[0].value
        }
        this.selectedSubJobPlaceAry.push({
          'id': id,
          'item_value': selectedData[0].item_value,
          'city': city
        });
      }
    });
  }

  // 求人情報を更新する
  private dataUpdate() {
    // 主な勤務地と勤務地（追加）に重複がないかチェック
    const subJobPlaceAry = this.selectedSubJobPlaceIds.split(',');

    const checkDucplicatePlace = subJobPlaceAry.filter(
      function(subPlace) {
        return Number(subPlace) === this.form.value.job_place;
      }.bind(this)
    );
    if (checkDucplicatePlace.length > 0) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgDuplicateJobPlace, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    this.selectedSubJobPlaceAry.forEach(element => {
      const input = <HTMLInputElement>document.getElementById('subplace-city-' + element.id)
      element["city"] = input.value
    });

    const employment_type = [];
    employment_type.push(this.form.value.employment_type);

    // 給与
    let salary_lower = this.form.value.salary_lower.replace(/,/g, '')
    let salary_higher = this.form.value.salary_higher.replace(/,/g, '')
    if (this.form.value.salary_type > Constant.salaryTypeNenpou) {
      salary_lower = ''
      salary_higher = ''
    }
    if (salary_higher && Number(salary_lower) > Number(salary_higher)) {
      salary_lower = this.form.value.salary_higher.replace(/,/g, '')
      salary_higher = this.form.value.salary_lower.replace(/,/g, '')
    }

    // 画像のパス
    const imagePath = this.getImagePathParam();

    const data = {
      image_path: imagePath,
      title: this.commonUtil.replaceSpace(this.form.value.title),
      pr: this.editorText[0],
      description: this.editorText[1],
      skill: this.commonUtil.replaceSpace(this.form.value.skill),
      person_type: this.commonUtil.replaceSpace(this.form.value.person_type),
      employment_type: employment_type,
      contract_period: this.form.value.contract_period,
      contract_period_detail: this.form.value.contract_period === Constant.contractPeriodTypeNone ? '': this.commonUtil.replaceSpace(this.form.value.contract_period_detail),
      trial_period: this.form.value.trial_period,
      trial_period_detail: this.form.value.trial_period === Constant.trialPeriodTypeNone ? '': this.commonUtil.replaceSpace(this.form.value.trial_period_detail),
      salary_type: this.form.value.salary_type,
      salary_lower: salary_lower,
      salary_higher: salary_higher,
      fixed_overtime: this.form.value.fixed_overtime,
      fixed_overtime_detail: this.form.value.fixed_overtime === Constant.fixedOvertimeNone ? '': this.commonUtil.replaceSpace(this.form.value.fixed_overtime_detail),
      raise: this.commonUtil.replaceSpace(this.form.value.raise),
      salary_note: this.commonUtil.replaceSpace(this.form.value.salary_note),
      job_place_id: this.form.value.job_place,
      job_place_city: this.commonUtil.replaceSpace(this.form.value.job_place_city),
      job_place_note: this.commonUtil.replaceSpace(this.form.value.job_place_note),
      work_hours: this.commonUtil.replaceSpace(this.form.value.work_hours),
      holiday: this.commonUtil.replaceSpace(this.form.value.holiday),
      welfare: this.commonUtil.replaceSpace(this.form.value.welfare),
      other_title1: this.commonUtil.replaceSpace(this.form.value.other_title1),
      other_detail1: this.commonUtil.replaceSpace(this.form.value.other_detail1),
      other_title2: this.commonUtil.replaceSpace(this.form.value.other_title2),
      other_detail2: this.commonUtil.replaceSpace(this.form.value.other_detail2),
      other_title3: this.commonUtil.replaceSpace(this.form.value.other_title3),
      other_detail3: this.commonUtil.replaceSpace(this.form.value.other_detail3),
      closing: this.editorText[2],
      job_category_id: this.form.value.job_category,
      job_category_middle_id: this.form.value.job_category_middle ? this.form.value.job_category_middle : 0,
      sub_job_place: this.selectedSubJobPlaceAry,
      sub_job_place_id: this.selectedSubJobPlaceIds,
      email: this.data.email
    };
    this.updateJobOffer(data);
  }

  // 画像をS3にアップロードする
  private uploadImageData() {
    // 画像をアップロード
    const maxHeight = Constant.uploadImageHeight;
    const maxWidth = Constant.uploadImageWidth;
    const config = environment.amplify.Storage.jobofferImage;

    this.imageUtil
      .uploadImageDataToS3(this.jobImageData, maxHeight, maxWidth, config)
      .then(image_path => {
        this.uploadedJobImagePath = image_path;
        this.dataUpdate();
      })
      .catch(err => {
        let msg;
        if (this.jobOffer.id != null) {
          msg = Constant.msgSaveFailedRetry;
        } else {
          msg = Constant.msgNoticeNewFailedRetry;
        }
        this.toastUtil.showErrorToast('', msg, Constant.toastShowMiliSec);
        this.isSaving = false;
      });
  }

  // 画像のパスを取得
  private getImagePathParam() {
    if (this.uploadedJobImagePath) {
      // 画像が更新されていた場合
      return this.uploadedJobImagePath;
    }
    if (this.jobImagePath === Constant.empty) {
      // 画像が削除されていた場合
      return this.jobImagePath;
    }
    // 削除の変更もされていない場合はnullを返却
    return this.jobOffer.image_path;
  }

  // 求人情報を登録・更新する
  private updateJobOffer(data) {
    if (!this.commonUtil.checkOnline()) {
      this.isSaving = false;
      return;
    }
    const apiPath = '/joboffer';
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = data

      if (this.jobOffer.id != null) {
        // 求人情報更新
        // GAイベント
        this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionJobOfferEditSave);
        options['body']['id'] = this.jobOffer.id;
        this.commonUtil
          .apiPut(apiPath, options)
          .then(res => {
            this.toastUtil.showInformationToast(
              Constant.empty,
              Constant.msgResultSaveSuccess,
              Constant.toastShowMiliSec
            );
            this.dialogRef.close(res.status);
          })
          .catch(err => {
            this.commonUtil.debug().log(err);
            this.isSaving = false;
            if (err.status === Constant.NG && err.data.cnt === 0) {
              // 削除済み
              this.toastUtil.showErrorToast(
                Constant.empty,
                Constant.msgResultSaveFailed,
                Constant.toastShowMiliSec
              );
              this.dialogRef.close(err.status);
            } else {
              this.toastUtil.showErrorToast(
                Constant.empty,
                Constant.msgSaveFailedRetry,
                Constant.toastShowMiliSec
              );
              this.isSaving = false;
            }
          });
      } else {
        // 求人情報登録
        // GAイベント
        this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionJobOfferNewSave);
        this.commonUtil
          .apiPost(apiPath, options)
          .then(res => {
            this.toastUtil.showInformationToast(
              Constant.empty,
              Constant.msgNoticeNew,
              Constant.toastShowMiliSec
            );
            this.dialogRef.close(res.status);
          })
          .catch(err => {
            this.commonUtil.debug().log(err);
            this.toastUtil.showErrorToast(
              Constant.empty,
              Constant.msgNoticeNewFailedRetry,
              Constant.toastShowMiliSec
            );
            this.isSaving = false;
          });
      }
    });
  }

  // 詳細取得
  private getDetail() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = '/joboffer/detail/' + this.jobOfferId;
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(apiPath, options).then(res => {
        if (!res.data.id) {
          // 削除済み
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgDeleteJobAlreadyError, Constant.toastShowMiliSec);
          this.dialogRef.close(true);
          return;
        }
        this.jobOffer = res.data;
        this.jobImagePath = this.jobOffer.image_url;
        this.selectedSubJobPlaceAry = res.data.sub_place
        this.initForm();
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
        this.dialogRef.close();
      });
    });
  }
}
