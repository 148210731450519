<div class="analytics-main">
  <div class="page-link">
    <a [routerLink]="['/' + Constant.rtAnalytics]">アナリティクス</a>　>　<span class="here">求人情報レポート</span>
  </div>
  <div class="page-title">求人情報レポート</div>
  <div class="rect content-detail" *ngIf="detailInfo">
    <table>
      <tr>
        <th colspan="2" class="text-left">求人情報</th>
        <th class="status bolc">ステータス</th>
        <th class="date">初回公開日</th>
        <th class="btn"></th>
      </tr>
      <tr>
        <td class="image-container">
          @if (contentsId == 0) {
            <div class="image noimage bold" *ngIf="contentsId == 0">
              <div>ALL</div>
            </div>
          } @else {
            <div *ngIf="!detailInfo.detail.image_url" class="image noimage">
              <span>No Image</span>
            </div>
            <img *ngIf="detailInfo.detail.image_url" src="{{ detailInfo.detail.image_url }}" class="image">
          }
        </td>
        <td class="title">
          @if (contentsId == 0) {
            すべての求人情報
          } @else {
            {{detailInfo.detail.title}}
          }
        </td>
        <td class="status">
          @if (contentsId == 0) {
            -
          } @else if (detailInfo.detail.release_status == Constant.pcReleaseStatusRelease) {
            公開中
          } @else {
            非公開
          }
        </td>
        <td class="date">
          @if (contentsId == 0) {
            -
          } @else {
            {{ detailInfo.detail.release_date }}
          }
        </td>
        <td class="btn">
          <button mat-flat-button type="button" (click)="onChange()" [disabled]="showSpinner">変更</button>
        </td>
      </tr>
    </table>
  </div>
  <div class="summary-area" *ngIf="detailInfo">
    <div class="condition-area">
      <div class="left chart-switch-area">
        <button mat-flat-button type="button" [class.active]="!onlyTalent" (click)="onChangeOnlyTalent(false)" [disabled]="showSpinner">ALL</button>
        <button mat-flat-button type="button" [class.active]="onlyTalent" (click)="onChangeOnlyTalent(true)" [disabled]="showSpinner">タレントのみ</button>
      </div>
      <div class="right">
        <div class="date-text" *ngIf="startDay && endDay" >{{ startDay|date:"yyyy年MM月dd日" }} ~ {{ endDay|date:"yyyy年MM月dd日" }}</div>
        <mat-form-field class="input-select no-line" appearance="fill">
          <mat-select panelWidth="120"
          [(ngModel)]="selectedTerm"
          (selectionChange)="onTermSelected($event.value)"
          [disabled]="showSpinner">
            <ng-container *ngFor="let term of Constant.alTermSelect">
              <mat-option [value]="term.value">{{ term.label }}</mat-option>
            </ng-container>
            <mat-option [value]="0" (click)="onTermCustom()">カスタム</mat-option>
          </mat-select>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="summary" *ngIf="detailInfo?.pv_cnt!==null">
      <div class="rect">
        <div class="icon-label">
          <img src="/assets/analytics/eye_icon.svg">
          <div>PV</div>
        </div>
        <div><span class="cnt-text-big">{{ detailInfo.pv_cnt|number }}</span> PV</div>
      </div>
      <div class="rect">
        <div class="icon-label">
          <img src="/assets/analytics/oubo_icon.svg">
          <div>応募</div>
        </div>
        <div><span class="cnt-text-big">{{ detailInfo.apply_cnt|number }}</span> 応募</div>
      </div>
      <div class="rect">
        <div class="icon-label">
          <img src="/assets/analytics/mind_icon.svg">
          <div>気になる</div>
        </div>
        <div><span class="cnt-text-big">{{ detailInfo.mind_cnt|number }}</span> 気になる</div>
      </div>
      <div class="rect">
        <div class="icon-label">
          <img src="/assets/analytics/share.svg">
          <div>シェア</div>
        </div>
        <div><span class="cnt-text-big">{{ detailInfo.share_cnt|number }}</span> シェア</div>
      </div>
    </div>
  </div>
  <div class="loading-area">
    <div class="chart-switch-area" *ngIf="onlyTalent && detailInfo?.pv_cnt !== null">
      <button mat-flat-button type="button" [class.active]="selectedChartType == Constant.alChartTypePv" (click)="onChangeChartType(Constant.alChartTypePv)" [disabled]="showSpinner">PV</button>
      <button mat-flat-button type="button" [class.active]="selectedChartType == Constant.alChartTypeApply" (click)="onChangeChartType(Constant.alChartTypeApply)" [disabled]="showSpinner">応募</button>
      <button mat-flat-button type="button" [class.active]="selectedChartType == Constant.alChartTypeMind" (click)="onChangeChartType(Constant.alChartTypeMind)" [disabled]="showSpinner">気になる</button>
    </div>

    <div *ngIf="showSpinner" class="spinner" [class.top-thin]="detailinfo?.pv_cnt!==null">
      <mat-spinner></mat-spinner>
    </div>

    @if (detailInfo?.pv_detail && (!onlyTalent || selectedChartType == Constant.alChartTypePv)) {
      <!-- PVチャート -->
      <div class="chart-area rect">
        <div class="chart-header">
          <div class="icon-label">
            <img src="/assets/analytics/eye_icon.svg">
            <div>PV</div>
          </div>
          <div><span class="cnt-text-big">{{ detailInfo.pv_cnt|number }}</span> PV</div>
        </div>
        <div class="chart-container-line">
          <canvas #canvasPv></canvas>
        </div>
      </div>
    }
    @if (detailInfo?.apply_detail && (!onlyTalent || selectedChartType == Constant.alChartTypeApply)) {
      <!-- 応募チャート -->
      <div class="chart-area rect">
        <div class="chart-header">
          <div class="icon-label">
            <img src="/assets/analytics/oubo_icon.svg">
            <div>応募</div>
          </div>
          <div><span class="cnt-text-big">{{ detailInfo.apply_cnt|number }}</span> 応募</div>
        </div>
        <div class="chart-container-line">
          <canvas #canvasApply></canvas>
        </div>
      </div>
    }
    @if (detailInfo?.mind_detail && (!onlyTalent || selectedChartType == Constant.alChartTypeMind)) {
      <!-- 気になるチャート -->
      <div class="chart-area rect">
        <div class="chart-header">
          <div class="icon-label">
            <img src="/assets/analytics/mind_icon.svg">
            <div>気になる</div>
          </div>
          <div><span class="cnt-text-big">{{ detailInfo.mind_cnt|number }}</span> 気になる</div>
        </div>
        <div class="chart-container-line">
          <canvas #canvasMind></canvas>
        </div>
      </div>
    }
    <!-- タレントクロス分析 -->
    <div class="chart-area-cross" *ngIf="onlyTalent && detailInfo?.talent_detail">
      <div class="chart-area rect half">
        <div class="chart-header">
          年齢
        </div>
        <div class="chart-container-circle age">
          <canvas #canvasAge></canvas>
        </div>
      </div>
      <div class="chart-area rect half">
        <div class="chart-header">
          性別
        </div>
        <div class="chart-container-circle age">
          <canvas #canvasSex></canvas>
        </div>
      </div>
      <div class="chart-area rect half">
        <div class="chart-header">
          最終学歴
        </div>
        <div class="chart-container-circle">
          <canvas #canvasSchool></canvas>
        </div>
      </div>

      <!-- 経験職種 -->
      <div class="chart-area rect half scroll">
        <div class="chart-header">
          経験職種
        </div>
        <div class="table-scroll">
        <table class="table">
          <tr>
            <th class="sticky no"></th>
            <th class="sticky name">経験職種</th>
            <th class="cnt sticky">
              @if (selectedChartType == Constant.alChartTypePv) {
                <span>PV</span>
              } @else if (selectedChartType == Constant.alChartTypeApply) {
                <span>応募</span>
              } @else {
                <span>気になる</span>
              }
            </th>
            <th class="cnt sticky small">割合</th>
          </tr>
          <ng-container *ngFor="let item of detailInfo.talent_detail.job; index as idx;">
            <tr>
              <td class="no">{{ idx+ 1}}</td>
              <td class="name">{{ item.item_value ? item.item_value: '未登録' }}<div class="border" [style]="'width:' + item.cnt / detailInfo.talent_detail.joball.cnt * 100 + '%'"></div></td>
              <td class="cnt">{{ item.cnt | number }}</td>
              <td class="cnt small">{{ item.cnt > 0 ? (item.cnt / detailInfo.talent_detail.joball.cnt * 100 | number: '1.0-0') : 0 }}%</td>
            </tr>
          </ng-container>

        </table>
        </div>
      </div>

      <!-- 年収 -->
      <div class="chart-area rect wide">
        <div class="chart-header">
          現年収
        </div>
        <div class="rect-flex">
          <div class="col2">
            <div class="chart-container-circle income">
              <canvas #canvasIncome></canvas>
            </div>
          </div>
          <div class="col2">
            <div class="table-scroll">
            <table class="table">
              <tr>
                <th class="sticky no"></th>
                <th class="sticky name">年収</th>
                <th class="cnt sticky">
                  @if (selectedChartType == Constant.alChartTypePv) {
                    <span>PV</span>
                  } @else if (selectedChartType == Constant.alChartTypeApply) {
                    <span>応募</span>
                  } @else {
                    <span>気になる</span>
                  }
                <th class="cnt sticky">割合</th>
              </tr>
              <ng-container *ngFor="let item of detailInfo.talent_detail.income_table; index as idx;">
                <tr>
                  <td class="no">{{ idx + 1 }}</td>
                  @if (selectedChartType == Constant.alChartTypePv) {
                    <td class="name">{{ item.key }}<div class="border" [style]="'width:' + item.value / detailInfo.pv_cnt * 100 + '%'"></div></td>
                  } @else if (selectedChartType == Constant.alChartTypeApply) {
                    <td class="name">{{ item.key }}<div class="border" [style]="'width:' + item.value / detailInfo.apply_cnt * 100 + '%'"></div></td>
                  } @else {
                    <td class="name">{{ item.key }}<div class="border" [style]="'width:' + item.value / detailInfo.mind_cnt * 100 + '%'"></div></td>
                  }
                  <td class="cnt">{{ item.value | number}}</td>
                  @if (selectedChartType == Constant.alChartTypePv) {
                    <td class="cnt">{{ item.value / detailInfo.pv_cnt * 100 | number: '1.0-0' }}%</td>
                  } @else if (selectedChartType == Constant.alChartTypeApply) {
                    <td class="cnt">{{ item.value / detailInfo.apply_cnt * 100 | number: '1.0-0' }}%</td>
                  } @else {
                    <td class="cnt">{{ item.value / detailInfo.mind_cnt * 100 | number: '1.0-0' }}%</td>
                  }
                </tr>
              </ng-container>
            </table>
            </div>
          </div>
        </div>
      </div>

      <!-- 接点経路 -->
      <div class="chart-area rect half scroll">
        <div class="chart-header">
          接点経路タグ
        </div>
        <div class="table-scroll">
        <table class="table">
          <tr>
            <th class="sticky no"></th>
            <th class="sticky name">接点経路タグ</th>
            <th class="cnt sticky">
              @if (selectedChartType == Constant.alChartTypePv) {
                <span>PV</span>
              } @else if (selectedChartType == Constant.alChartTypeApply) {
                <span>応募</span>
              } @else {
                <span>気になる</span>
              }
            </th>
            <th class="cnt sticky small">割合</th>
          </tr>
          <ng-container *ngFor="let item of detailInfo.talent_detail.tag; index as idx;">
            <tr>
              <td class="no">{{ idx+ 1}}</td>
              <td class="name">{{ item.title }}<div class="border" [style]="'width:' + item.cnt / detailInfo.talent_detail.tagall.cnt * 100 + '%'"></div></td>
              <td class="cnt">{{ item.cnt | number}}</td>
              <td class="cnt small">{{ item.cnt > 0 ? (item.cnt / detailInfo.talent_detail.tagall.cnt * 100 | number: '1.0-0') : 0 }}%</td>
            </tr>
          </ng-container>
        </table>
        </div>
      </div>

      <!-- タレント管理タグ -->
      <div class="chart-area rect half scroll">
        <div class="chart-header">
          タレント管理タグ
        </div>
        <div class="table-scroll">
        <table class="table">
          <tr>
            <th class="sticky no"></th>
            <th class="sticky name">タレント管理タグ</th>
            <th class="cnt sticky">
              @if (selectedChartType == Constant.alChartTypePv) {
                <span>PV</span>
              } @else if (selectedChartType == Constant.alChartTypeApply) {
                <span>応募</span>
              } @else {
                <span>気になる</span>
              }
            </th>
            <th class="cnt sticky small">割合</th>
          </tr>
          <ng-container *ngFor="let item of detailInfo.talent_detail.talenttag; index as idx;">
            <tr>
              <td class="no">{{ idx+ 1}}</td>
              <td class="name">{{ item.title }}<div class="border" [style]="'width:' + item.cnt / detailInfo.talent_detail.talenttagall.cnt * 100 + '%'"></div></td>
              <td class="cnt">{{ item.cnt | number}}</td>
              <td class="cnt small">{{ item.cnt > 0 ? (item.cnt / detailInfo.talent_detail.talenttagall.cnt * 100 | number: '1.0-0') : 0 }}%</td>
            </tr>
          </ng-container>
        </table>
        </div>
      </div>
    </div>
  </div>
</div>
