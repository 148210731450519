<app-lp-header page="faq"></app-lp-header>
<div class="wrap">
  <h2>FAQ - よくある質問</h2>
  <mat-divider></mat-divider>
  <div class="item">
    <div class="title">
      <div>Q.</div>
      <div>中途採用やアルバイト採用などに限定して利用できますか?</div>
    </div>
    <div class="answer">
      ぜひご利用ください。<br>
      学生から社会人までつながり続けるコンセプトのため、どのようなキャリアやステータスの方が利用しても違和感がないサービスになっています。
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="item">
    <div class="title">
      <div>Q.</div>
      <div>インターンシップの募集はできますか？</div>
    </div>
    <div class="answer">
      求人情報の登録機能からインターンシップを募集することができます。<br>
      長期インターンシップから1Dayインターンシップまで、制限はありません。<br>
      つながっている候補者へ情報をプッシュしたい場合は、一斉メッセージ送信機能が便利です。
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="item">
    <div class="title">
      <div>Q.</div>
      <div>フリーランスをタレントプールすることは可能ですか？</div>
    </div>
    <div class="answer">
      可能です。「業務委託」向けの求人情報を作成することも可能ですので、ご活用ください。<br>
      応募者の選考状況（お見送り/選考中/採用合意など）を管理することも可能です。
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="item">
    <div class="title">
      <div>Q.</div>
      <div>自社とつながっていないCaLinの会員に対して、スカウトは送れますか？</div>
    </div>
    <div class="answer">
      送れません。<br>
      スカウトできるのは、自社とつながっている(タレントプールに登録している)候補者のみとなります。<br>
      自社とつながっている候補者に対しては、自由に何度でもスカウトやメッセージ送信ができます。<br>
      しかし、過度なアプローチはつながり解消の原因となりますのでお気をつけください。
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="item">
    <div class="title">
      <div>Q.</div>
      <div>採用担当者のアカウントは追加できますか？</div>
    </div>
    <div class="answer">
      追加できます。担当にお問い合わせください。<br>
      アカウント追加による料金は発生しません。<br>
      なお、セキュリティ維持のため、システムからの追加はできません。ご不便をおかけいたします。
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="item">
    <div class="title">
      <div>Q.</div>
      <div>デモ画面を見せてもらえますか？</div>
    </div>
    <div class="answer">
      もちろんです。担当がオンラインでご紹介します。<br>
      サービス説明やデモをご希望の方は、下記よりご依頼ください。<br>
      <a href="https://forms.gle/WvYjSquCUUBFfUyw5" target="_blank" (click)="onClickGAEvent(Constant.gaActionFaqForm)">https://forms.gle/WvYjSquCUUBFfUyw5</a>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="item">
    <div class="title">
      <div>Q.</div>
      <div>ログインできなくなりました。どうすれば良いですか？</div>
    </div>
    <div class="answer">
      ご不便をおかけしております。<br>
      ログインURLなどが不明の場合は、アカウント発行時のメールをご覧ください。<br>
      解決しない場合は、担当にお問い合わせいただくか、<a href="https://www.corp.calin.co.jp/contact" target="_blank" (click)="onClickGAEvent(Constant.gaActionFaqContact)">お問い合わせフォーム</a>よりご連絡ください。
    </div>
  </div>
</div>
<app-lp-footer></app-lp-footer>
