import { Component, Inject, OnInit } from '@angular/core';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';
import { sprintf } from 'sprintf-js';
import { GeneralMessageDialogComponent } from '../general-message-dialog/general-message-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-talent-edit-dialog',
  templateUrl: './talent-edit-dialog.component.html',
  styleUrls: ['./talent-edit-dialog.component.css']
})
export class TalentEditDialogComponent implements OnInit {
  public readonly Constant = Constant;
  public form: FormGroup;
  public mode = Constant.csvTalentTypeAdd;  // 作成 or 更新
  public talentId = null;
  public showSpinner = true;
  public updateSpinner = false;
  public profile;
  public master = {};
  public jobMaster = [];  // 職種マスタ
  public errorMessage = {};

  public maxLenName = 10;
  public minLenTel = 10;
  public maxLenTel = 11;
  public maxLenMail = 256;
  public maxLenPlace = 30;
  public maxWorkCareer = 6;
  public maxSchoolHistory = 3;

  private csvFlg = 0;
  private careerIndex = 1;
  private schoolIndex = 1;

  constructor(
    public dialogRef: MatDialogRef<TalentEditDialogComponent>,
    private router: Router,
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.mode = this.data.mode;
    this.talentId = this.data.talentId;
    this.csvFlg = this.data.csvFlg;
    this.master = this.data.master;

    if (!this.master || Object.keys(this.master).length === 0) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
      return;
    }

    // 職種マスタ作成
    this.jobMaster = this.convertMaster(this.master[Constant.joJobType]);

    // フォーム作成
    this.initForm();

    if (this.talentId) {
      this.getTalentProfile();
    }
  }

  /* テンプレート */
  // 作成 or 更新ボタン
  onSave() {
    if (!this.form.valid) {
      return;
    }
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.msgConfirmCarteSave, this.mode) }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dataUpdate();
      }
    });
  }

  // キャンセルボタン
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.msgConfirmCarteCancel, this.mode), check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close();
      }
    });
  }

  // 登録ボタンの有効判定
  checkFormDisabled() {
    if (!this.form || !this.form.valid || this.updateSpinner) {
      return true;
    }
    // エラーメッセージ有無
    if (Object.keys(this.errorMessage).length > 0) {
      return true;
    }

    return false;
  }


  // enter無効
  onKeyDown(event) {
    return event.preventDefault(); // enterを無効化
  }

  // ラジオボタンのチェックボックスを外す
  onRadio(id, form, name, event) {
    if (event.target.className === 'mdc-label') {
      return;
    }
    if (id === form.value[name]) {
      form.get(name).reset();
      const elm = <HTMLElement>document.activeElement;
      elm.blur();
    }

  }

  onBlurBirthday() {
    delete this.errorMessage[Constant.apBirthday];
    if (this.form.value[Constant.apBirthdayYear] || this.form.value[Constant.apBirthdayMonth] || this.form.value[Constant.apBirthdayDay]) {
      const birthday = this.commonUtil.dateFromYearMonthDay(
        this.form.get(Constant.apBirthdayYear).value,
        this.form.get(Constant.apBirthdayMonth).value,
        this.form.get(Constant.apBirthdayDay).value
      );
      if (!birthday) {
        if (!this.form.value[Constant.apBirthdayYear] || !this.form.value[Constant.apBirthdayMonth] || !this.form.value[Constant.apBirthdayDay]) {
          this.errorMessage[Constant.apBirthday] = sprintf(Constant.msgErrorInputAllTerm, '生年月日');
        } else {
          this.errorMessage[Constant.apBirthday] = Constant.msgErrorInvalidType;
        }
      }
    }
  }

  onBlurCity() {
    delete this.errorMessage[Constant.apAddress1];
    if (this.form.value[Constant.apAddress2] && !this.form.value[Constant.apAddress1]) {
      this.errorMessage[Constant.apAddress1] = Constant.msgErrorInvalidAddress;
    }
  }

  onBlurActiveTerm() {
    delete this.errorMessage[Constant.apActivePlannedStart];
    if (this.form.value[Constant.tfStartYear] || this.form.value[Constant.tfStartMonth]
       || this.form.value[Constant.tfEndYear] || this.form.value[Constant.tfEndMonth]) {
        const active_start = this.commonUtil.dateFromYearMonth(this.form.value[Constant.tfStartYear], this.form.value[Constant.tfStartMonth]);
        const active_end = this.commonUtil.dateFromYearMonth(this.form.value[Constant.tfEndYear], this.form.value[Constant.tfEndMonth]);
      if (!active_start || !active_end) {
        if (!this.form.value[Constant.tfStartYear] || !this.form.value[Constant.tfStartMonth]
          || !this.form.value[Constant.tfEndYear] || !this.form.value[Constant.tfEndMonth]) {
          this.errorMessage[Constant.apActivePlannedStart] = sprintf(Constant.msgErrorInputAllTerm, '活動予定時期');
        } else {
          this.errorMessage[Constant.apActivePlannedStart] = Constant.msgErrorInvalidType;
        }
      } else if (active_start > active_end) {
        this.errorMessage[Constant.apActivePlannedStart] = Constant.msgErrorInvalidType;
      }
    }
  }

  onCareerTerm(stY, stM, edY, edM, idx) {
    delete this.errorMessage[Constant.apJoinDate + '-' + idx];
    if (stY || stM || edY || edM) {
      const start = this.commonUtil.dateFromYearMonth(stY, stM);
      const end = this.commonUtil.dateFromYearMonth(edY, edM);
      // if (end && (!stY || !stM)) {
      if (!stY || !stM || !edY || !edM) {
        if (start && !edY && !edM) {
          // 期末だけ未入力の場合はエラーなし
        } else if ((stY && stM && !start) || (edY && edM && !end)) {
          this.errorMessage[Constant.apJoinDate + '-' + idx] = Constant.msgErrorInvalidType;
        } else {
          this.errorMessage[Constant.apJoinDate + '-' + idx] = Constant.msgErrorInputStartDate;
        }
      } else if (start && end && start > end) {
        this.errorMessage[Constant.apJoinDate + '-' + idx] = Constant.msgErrorInvalidType;
      }
    } else {
      // 全て空の場合必須入力エラー
      this.errorMessage[Constant.apJoinDate + '-' + idx] = Constant.msgErrorInputStartDate;
    }
  }

  onSchoolTerm(stY, stM, edY, edM, idx) {
    delete this.errorMessage[Constant.apAdmissionDate + '-' + idx];
    if (stY || stM || edY || edM) {
      const start = this.commonUtil.dateFromYearMonth(stY, stM);
      const end = this.commonUtil.dateFromYearMonth(edY, edM);
      if (!stY || !stM || !edY || !edM) {
        this.errorMessage[Constant.apAdmissionDate + '-' + idx] = Constant.msgErrorInputAllTerm2;
      } else if (start > end) {
        this.errorMessage[Constant.apAdmissionDate + '-' + idx] = Constant.msgErrorInvalidType;
      }
    } else {
      // 全て空の場合必須入力エラー
      this.errorMessage[Constant.apAdmissionDate + '-' + idx] = Constant.msgErrorInputAllTerm2;
    }
  }

  // 活動予定時期未定チェック
  onChangeUndecided() {
    this.disabledTerm(this.form.get(Constant.apActiveUndecidedFlag).value);
    this.onBlurActiveTerm();
  }

  // 雇用形態未定チェック
  onChangeUndecidedEmployment(checked) {
    // const checked = this.form.get(Constant.apDesirejobUndecidedFlag).value;
    this.master[Constant.joEmploymentType].forEach(element => {
      if (checked) {
        this.form.get(Constant.apDesireEmployeement + '_' + element.id).disable();
      } else {
        this.form.get(Constant.apDesireEmployeement + '_' + element.id).enable();
      }
    });
  }

  // 職種未定チェック
  onChangeUndecidedJob() {
    const checked = this.form.get(Constant.apDesirejobUndecidedFlag).value;
    this.jobMaster.forEach(element => {
      if (checked) {
        this.form.get('desirejob_' + element.large_id).disable();
        element.middle.forEach(middle => {
          this.form.get('desirejob_' + element.large_id + '_' + middle.id).disable();
        });
      } else {
        this.form.get('desirejob_' + element.large_id).enable();
        element.middle.forEach(middle => {
          this.form.get('desirejob_' + element.large_id + '_' + middle.id).enable();
        });
      }
    });
  }

  // 職種 大分類チェックで小分類のチェック
  onLargeId(checked, id, prefix, form) {
    this.jobMaster.forEach(element => {
      if (element[Constant.apLargeId] === id) {
        element.middle.forEach(middle => {
          form.get(prefix + id + '_' + middle.id).setValue(checked);
        });
      }
    });
  }

  // 職種 小分類チェックで大分類のチェック
  onMiddleId(parentId, prefix, form) {
    let checkParent = true;
    this.jobMaster.forEach(element => {
      if (element[Constant.apLargeId] === parentId) {
        element.middle.forEach(middle => {
          if (!form.get(prefix + element[Constant.apLargeId] + '_' + middle.id).value) {
            checkParent = false;
          }
        });
        form.get(prefix + element[Constant.apLargeId].toString()).setValue(checkParent);
      }
    });
  }

  // 職歴追加
  onAddCareer(data?) {
    const formControl = {};

    formControl[Constant.apCompanyName] = new FormControl('', [
      Validators.required,
      Validators.maxLength(Constant.fvMaxCompanyName)
    ]);
    formControl[Constant.tfStartYear] = new FormControl('', {validators: [
      Validators.required,
      Validators.min(Constant.fvMinYear),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});

    formControl[Constant.tfStartMonth] = new FormControl('', {validators: [
      Validators.required,
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});

    formControl[Constant.tfEndYear] = new FormControl('', {validators: [
      Validators.min(Constant.fvMinYear),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});

    formControl[Constant.tfEndMonth] = new FormControl('', {validators: [
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});
    formControl[Constant.apEmployeementTypeId] = new FormControl('');
    formControl[Constant.apAnnualIncome] = new FormControl('', [
      Validators.min(0),
      Validators.maxLength(Constant.fvMaxLenIncome),
      Validators.pattern(/^[0-9]*$/)
    ]);
    formControl[Constant.apDepartment] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxCompanyName)
    ]);
    formControl[Constant.apPosition] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxCompanyName)
    ]);

    this.jobMaster.forEach(large => {
      const keyString: string = 'careerjob_' + large.large_id;
      formControl[keyString] = new FormControl();
      large.middle.forEach(element => {
        const keyString2: string = 'careerjob_' + large.large_id + '_' + element.id;
        formControl[keyString2] = new FormControl();
      });
    });
    formControl[Constant.apJobDetail] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxLenCompanyDetail)
    ]);
    formControl[Constant.apSkill] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxLenCompanyDetail)
    ]);
    formControl[Constant.apOrderNo] = new FormControl(this.careerIndex);

    const item = this.formBuilder.group(formControl);

    if (data) {
      const formValue = {};
      formValue[Constant.apCompanyName] = data[Constant.apCompanyName];
      if (data[Constant.apJoinDate]) {
        const date = data[Constant.apJoinDate].split('/');
        formValue[Constant.tfStartYear] = date[0];
        formValue[Constant.tfStartMonth] = date[1];
      }
      if (data[Constant.apExitDate]) {
        const date = data[Constant.apExitDate].split('/');
        formValue[Constant.tfEndYear] = date[0];
        formValue[Constant.tfEndMonth] = date[1];
      }
      formValue[Constant.apEmployeementTypeId] = data[Constant.apEmployeementTypeId];
      if (data[Constant.apAnnualIncome] > -1) {
        formValue[Constant.apAnnualIncome] = data[Constant.apAnnualIncome];
      }
      formValue[Constant.apDepartment] = data[Constant.apDepartment];
      formValue[Constant.apPosition] = data[Constant.apPosition];
      formValue[Constant.apJobDetail] = data[Constant.apJobDetail];
      formValue[Constant.apSkill] = data[Constant.apSkill];
      data['careerjobs'].forEach(element => {
        const keyString: string = 'careerjob_' + element.large_id + '_' + element.middle_id;
        formValue[keyString] = true;
      });
      item.patchValue(formValue);

      // 職種全てチェック
      data['careerjobs'].forEach(element => {
        this.onMiddleId(element.large_id, 'careerjob_', item);
      });

    }
    this.careers.push(item);
    this.careerIndex++;
  }

  // 職歴削除
  onDelCareer(index, no) {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: sprintf(Constant.msgConfirmCarteDelHistory, '職歴(' + no + ')') }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.careers.removeAt(index);
        delete this.errorMessage[Constant.apJoinDate + '-' + index];
      }
    });
  }

  get careers(): FormArray {
    return this.form.get(Constant.apCareers) as FormArray;
  }

  // 学歴追加
  onAddSchool(data?) {
    const formControl = {};

    formControl[Constant.apSchoolName] = new FormControl('', [
      Validators.required,
      Validators.maxLength(Constant.fvMaxCompanyName)
    ]);
    formControl[Constant.apSchoolTypeId] = new FormControl('');
    formControl[Constant.tfStartYear] = new FormControl('', {validators: [
      Validators.required,
      Validators.min(Constant.fvMinYear),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});

    formControl[Constant.tfStartMonth] = new FormControl('', {validators: [
      Validators.required,
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});

    formControl[Constant.tfEndYear] = new FormControl('', {validators: [
      Validators.required,
      Validators.min(Constant.fvMinYear),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});

    formControl[Constant.tfEndMonth] = new FormControl('', {validators: [
      Validators.required,
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});

    formControl[Constant.apDropout] = new FormControl('');
    formControl[Constant.apBunriTypeId] = new FormControl('');
    formControl[Constant.apDepartment] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxCompanyName)
    ]);
    formControl[Constant.apMainActivities] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxLenCompanyDetail)
    ]);
    formControl[Constant.apOrderNo] = new FormControl(this.schoolIndex);

    const item = this.formBuilder.group(formControl);

    // 更新時はデータセット
    if (data) {
      const formValue = {};
      formValue[Constant.apSchoolName] = data[Constant.apSchoolName];
      if (data[Constant.apSchoolTypeId]) {
        formValue[Constant.apSchoolTypeId] = data[Constant.apSchoolTypeId];
      }
      if (data[Constant.apAdmissionDate]) {
        const date = data[Constant.apAdmissionDate].split('/');
        formValue[Constant.tfStartYear] = date[0];
        formValue[Constant.tfStartMonth] = date[1];
      }
      if (data[Constant.apExitDate]) {
        const date = data[Constant.apExitDate].split('/');
        formValue[Constant.tfEndYear] = date[0];
        formValue[Constant.tfEndMonth] = date[1];
      }
      formValue[Constant.apDropout] = data[Constant.apDropout];
      if (data[Constant.apBunriTypeId]) {
        formValue[Constant.apBunriTypeId] = data[Constant.apBunriTypeId];
      }
      formValue[Constant.apDepartment] = data[Constant.apDepartment];
      formValue[Constant.apMainActivities] = data[Constant.apMainActivities];

      item.patchValue(formValue);
    }

    this.schools.push(item);
    this.schoolIndex++;
  }

  // 学歴削除
  onDelSchool(index, no) {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: sprintf(Constant.msgConfirmCarteDelHistory, '学歴(' + no + ')')}
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.schools.removeAt(index);
        delete this.errorMessage[Constant.apAdmissionDate + '-' + index];
      }
    });
  }

  get schools(): FormArray {
    return this.form.get(Constant.apSchools) as FormArray;
  }

  /* プライベート */
  // 保存実行
  private dataUpdate() {
    this.updateSpinner = true;
    const data = {};
    this.errorMessage = {};
    data[Constant.apBase] = [];
    data[Constant.apDesirejobPlace] = [];
    data[Constant.apDesirejob] = [];
    data[Constant.apComment] = [];

    for (const formName in this.form.value) {
      // 飛ばす項目（日付など）
      if (formName === Constant.apBirthdayYear ||
          formName === Constant.apBirthdayMonth ||
          formName === Constant.apBirthdayDay  ||
          formName === Constant.tfStartYear  ||
          formName === Constant.tfStartMonth  ||
          formName === Constant.tfEndYear  ||
          formName === Constant.tfEndMonth
      ) {
        continue;
      }

      if (this.form.value.hasOwnProperty(formName)) {
        if (formName.indexOf('desire_employeement_') >= 0) {
          // 雇用形態
          const desire_employeement = [];
          this.master[Constant.joEmploymentType].forEach(element => {
            if (this.form.get('desire_employeement_' + element.id).value) {
              desire_employeement.push({ key: 'id', value: element.id });
            }
          });
          data[Constant.apDesireEmployeement] = desire_employeement;

        } else if (formName.indexOf('desirejobplace_') >= 0) {
          // 希望勤務地
          if (this.form.value[formName]) {
            const param = formName.split('_');
            data[Constant.apDesirejobPlace].push({
              key: 'id',
              value: param[1]
            });
          }

        } else if (formName.indexOf('desirejob_') >= 0) {
          // 職種
          if (formName === Constant.apDesirejobUndecidedFlag) {
            data[Constant.apBase].push({
              key: formName,
              value: Number(this.form.value[formName])
            });

          } else if (this.form.value[formName]) {
            const jobParam = formName.split('_');
            if (jobParam.length >= 3) {
              // 全部カテゴリは送信しない
              data[Constant.apDesirejob].push({
                large_key: 'id',
                large_value: jobParam[1],
                key: 'id',
                value: jobParam[2]
              });
            }

          }

        } else if (formName === Constant.apCareers || formName === Constant.apSchools) {
          // 職歴 学歴
          const history = [];
          this.form.value[formName].forEach(element => {
            // 期間チェック
            const joinDate = this.commonUtil.dateFromYearMonth(
              element[Constant.tfStartYear],
              element[Constant.tfStartMonth]
            );
            const exitDate = this.commonUtil.dateFromYearMonth(
              element[Constant.tfEndYear],
              element[Constant.tfEndMonth]
            );

            const historyItem = [];
            const career_job = [];
            for (const item in element) {
              if (
                item === Constant.tfStartYear ||
                item === Constant.tfStartMonth ||
                item === Constant.tfEndYear ||
                item === Constant.tfEndMonth ||
                item === Constant.apOrderNo
              ) {
                continue;
              }

              if (item.indexOf('careerjob_') >= 0) {
                if (element[item]) {
                  // 職種
                  const jobParam = item.split('_');
                  if (jobParam.length >= 3) {
                    career_job.push({
                      large_key: 'id',
                      large_value: jobParam[1],
                      key: 'id',
                      value: jobParam[2]
                    });
                  }
                }

              } else {
                let value = element[item];

                if (typeof value === 'string') {
                  value = this.commonUtil.replaceSpace(value);
                } else if (typeof value === 'boolean') {
                  value = Number(value);
                } else if ((typeof value === 'number' && value === -1) || value === null) {
                  value = '';
                }

                historyItem.push({
                  key: item,
                  value: value
                });
              }
            }

            // 期間
            if (formName === Constant.apCareers) {
              historyItem.push({ key: Constant.apJoinDate, value: joinDate });
            } else {
              historyItem.push({ key: Constant.apAdmissionDate, value: joinDate });
            }
            historyItem.push({ key: Constant.apExitDate, value: exitDate });

            //  職種
            if (career_job.length > 0) {
              historyItem.push({ key: Constant.apCategory, value: career_job });
            }
            history.push(historyItem);

          });
          data[formName] = history;

        } else if (formName.indexOf('comment_') >= 0) {
          // メモ系
          data[Constant.apComment].push({
            key: formName,
            value: this.commonUtil.replaceSpace(this.form.value[formName])
          });


        } else {
          // その他は基本情報
          let value = this.form.value[formName];
          if (typeof value === 'string') {
            value = this.commonUtil.replaceSpace(value);
          } else if (typeof value === 'boolean') {
            value = Number(value);
          } else if (value === null) {
            value = '';
          }
          data[Constant.apBase].push({
            key: formName,
            value: value
          });
        }
      }
    }

    // 生年月日
    const birthday = this.commonUtil.dateFromYearMonthDay(
      this.form.get(Constant.apBirthdayYear).value,
      this.form.get(Constant.apBirthdayMonth).value,
      this.form.get(Constant.apBirthdayDay).value
    );
    data[Constant.apBase].push({
      key: Constant.apBirthday,
      value: birthday
    });

    // 活動予定時期
    let active_start = '';
    let active_end = '';
    if (this.form.value[Constant.tfStartYear] && this.form.value[Constant.tfStartMonth]
      && this.form.value[Constant.tfEndYear] && this.form.value[Constant.tfEndMonth]) {
       active_start = this.commonUtil.dateFromYearMonth(this.form.value[Constant.tfStartYear], this.form.value[Constant.tfStartMonth]);
       active_end = this.commonUtil.dateFromYearMonth(this.form.value[Constant.tfEndYear], this.form.value[Constant.tfEndMonth]);
    }
    data[Constant.apBase].push({
      key: Constant.apActivePlannedStart,
      value: active_start
    });
    data[Constant.apBase].push({
      key: Constant.apActivePlannedEnd,
      value: active_end
    });

    if (Object.keys(this.errorMessage).length > 0) {
      this.updateSpinner = false;
      return;
    }

    if (this.talentId) {
      data['id'] = Number(this.talentId);
    }


    Auth.currentSession().then(session => {
      const apiPath = '/talentprofile';
      const options = this.auth.createApiHeader(session);
      options['body'] = data;

      this.commonUtil.apiPost(apiPath, options).then(res => {
        this.afterSave();
      })
      .catch(err => {
        this.afterSaveFailed(err);
      });

    });
  }

  // 保存成功
  private afterSave() {
    const dialogRef = this.dialog.open(GeneralMessageDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: sprintf(Constant.msgCompleteCarteSave, this.mode)
    });
    dialogRef.afterClosed().subscribe(res => {
      this.updateSpinner = false;
      this.dialogRef.close(true);
    });
  }

  // 保存失敗
  private afterSaveFailed(err) {
    let msg = sprintf(Constant.msgFailedCarteSave, this.mode);
    if (err.data?.del) {
      msg = Constant.msgFailedCarteSaveDeleted;
    }
    const dialogRef = this.dialog.open(GeneralMessageDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      disableClose: true,
      data: msg
    });
    this.updateSpinner = false;

    if (err.data?.del) {
      dialogRef.afterClosed().subscribe(res => {
        this.dialogRef.close(Constant.csvTalentTypeDel);
      });
    }
  }

  // タレントプロフィール取得（更新時）
  private getTalentProfile() {
    Auth.currentSession().then(session => {
      const apiPath = '/talent';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        id: this.talentId,
        csv_flag: this.csvFlg
      };

      this.commonUtil.apiPost(apiPath, options).then(res => {
        if (!res.data.base) {
          this.router.navigate(['error']);
          this.dialogRef.close();
          return;
        }

        this.profile = res.data;
        // 職歴：職種のデータ整形
        this.shapingWorkCareerJobData();
        // フォームへの値セット
        this.setValueForm();

      })
      .catch(err => {
        this.showSpinner = false;
        this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        this.dialogRef.close();
      });
    });
  }

  // メモの取得と設定（更新時）
  private getTalentMemo() {
    Auth.currentSession().then(session => {
      let apiPath = '/talent/memo/' + this.talentId;
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          if (res.data.comment) {
            this.form.patchValue({[Constant.apCommentTalent]: res.data.comment});
          }
        })
        .catch(err => { });

      apiPath = '/talent/memo/school/' + this.talentId;
      this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          if (res.data.comment) {
            this.form.patchValue({[Constant.apCommentSchool]: res.data.comment});
          }
        })
        .catch(err => { });

      apiPath = '/talent/memo/career/' + this.talentId;
      this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          if (res.data.comment) {
            this.form.patchValue({[Constant.apCommentCareer]: res.data.comment});
          }
        })
        .catch(err => { });
    });
  }


  // フォーム作成
  private initForm() {
    const formControl = {};

    formControl[Constant.apLastName] = new FormControl('', [
      Validators.required,
      Validators.maxLength(this.maxLenName)
    ]);
    formControl[Constant.apFirstName] = new FormControl('', [
      Validators.required,
      Validators.maxLength(this.maxLenName)
    ]);
    formControl[Constant.apLastNameKana] = new FormControl('', {validators: [
      Validators.required,
      Validators.maxLength(this.maxLenName),
      Validators.pattern(/^[\u3040-\u309f|\u002d|\u002f|\u003d|\u30fb|\u30fc|\uff0f|\uff1d|\uff65]+$/)
    ], updateOn: 'blur'});
    formControl[Constant.apFirstNameKana] = new FormControl('', {validators: [
      Validators.required,
      Validators.maxLength(this.maxLenName),
      Validators.pattern(/^[\u3040-\u309f|\u002d|\u002f|\u003d|\u30fb|\u30fc|\uff0f|\uff1d|\uff65]+$/)
    ], updateOn: 'blur'});
    formControl[Constant.apTelno] = new FormControl('', {validators: [
      Validators.pattern(/^[0-9]*$/),
      Validators.maxLength(this.maxLenTel),
      Validators.minLength(this.minLenTel)
    ], updateOn: 'blur'});

    formControl[Constant.apMailAddress] = new FormControl('', {validators: [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.+_-]{0,64}@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/),
      Validators.maxLength(this.maxLenMail)
    ], updateOn: 'blur'});
    formControl[Constant.apSexId] = new FormControl();
    formControl[Constant.apBirthdayYear] = new FormControl('', {validators: [
      Validators.min(Constant.fvMinYear),
      Validators.max(Constant.fvMaxYear),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});
    formControl[Constant.apBirthdayMonth] = new FormControl('', {validators: [
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});
    formControl[Constant.apBirthdayDay] = new FormControl('', {validators: [
      Validators.min(Constant.fvMinDay),
      Validators.max(Constant.fvMaxDay),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});
    formControl[Constant.apAddress1] = new FormControl();
    formControl[Constant.apAddress2] = new FormControl('', [
      Validators.maxLength(this.maxLenPlace)
    ]);
    formControl[Constant.apGraduateYearType] = new FormControl();

    this.master[Constant.joEmploymentType].forEach(element => {
      const keyString: string = 'desire_employeement_' + element.id;
      formControl[keyString] = new FormControl();
    });

    formControl[Constant.tfStartYear] = new FormControl('', {validators: [
      Validators.min(Constant.fvActiveStartYear),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});
    formControl[Constant.tfStartMonth] = new FormControl('', {validators: [
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});
    formControl[Constant.tfEndYear] = new FormControl('', {validators: [
      Validators.min(Constant.fvActiveStartYear),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});
    formControl[Constant.tfEndMonth] = new FormControl('', {validators: [
      Validators.min(Constant.fvMinMonth),
      Validators.max(Constant.fvMaxMonth),
      Validators.pattern(/^[0-9]*$/)
    ], updateOn: 'blur'});
    formControl[Constant.apActiveUndecidedFlag] = new FormControl();
    formControl[Constant.apDesirejobUndecidedFlag] = new FormControl();
    formControl[Constant.apDesireAnnualIncome] = new FormControl('', [
      Validators.min(0),
      Validators.maxLength(Constant.fvMaxLenIncome),
      Validators.pattern(/^[0-9]*$/)
    ]);

    this.master[Constant.joJobPlaceType].forEach(element => {
      const keyString: string = 'desirejobplace_' + element.id;
      formControl[keyString] = new FormControl();
    });

    this.jobMaster.forEach(large => {
      const keyString: string = 'desirejob_' + large.large_id;
      formControl[keyString] = new FormControl();

      large.middle.forEach(element => {
        const keyString2: string = 'desirejob_' + large.large_id + '_' + element.id;
        formControl[keyString2] = new FormControl();
      });
    });

    formControl[Constant.apCommentCareer] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxMemo)
    ]);
    formControl[Constant.apCommentSchool] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxMemo)
    ]);
    formControl[Constant.apCommentTalent] = new FormControl('', [
      Validators.maxLength(Constant.fvMaxMemo)
    ]);

    formControl[Constant.apCareers] = this.formBuilder.array([]);
    formControl[Constant.apSchools] = this.formBuilder.array([]);

    this.form = new FormGroup(formControl);


    // データのセット、メモの取得
    if (this.talentId) {
      this.setValueForm();
      this.getTalentMemo();
    } else {
      this.showSpinner = false;
    }
  }

  // フォームに値のセット
  private setValueForm() {
    if (!this.profile || !this.form) {
      return;
    }

    const formValue = {};
    // 基本情報
    const name = this.profile.base.name.split(' ');
    formValue[Constant.apLastName] = name[0];
    formValue[Constant.apFirstName] = name[1];
    const kana = this.profile.base.name_kana.split(' ');
    formValue[Constant.apLastNameKana] = kana[0];
    formValue[Constant.apFirstNameKana] = kana[1];
    formValue[Constant.apTelno] = this.profile.base[Constant.apTelno];
    formValue[Constant.apMailAddress] = this.profile.base[Constant.apMailAddress];
    if (this.profile.base[Constant.apSexId]) {
      formValue[Constant.apSexId] = Number(this.profile.base[Constant.apSexId]);
    }
    if (this.profile.base[Constant.apBirthday]) {
      const birthday = this.profile.base[Constant.apBirthday].split('/');
      formValue[Constant.apBirthdayYear] = birthday[0];
      formValue[Constant.apBirthdayMonth] = birthday[1];
      formValue[Constant.apBirthdayDay] = birthday[2];
    }
    formValue[Constant.apAddress1] = this.profile.base[Constant.apAddress1];
    formValue[Constant.apAddress2] = this.profile.base[Constant.apAddress2];

    if (this.profile.base[Constant.apGraduateYearType] !== '') {
      formValue[Constant.apGraduateYearType] = Number(this.profile.base[Constant.apGraduateYearType]);
    } else {
      formValue[Constant.apGraduateYearType] = this.profile.base[Constant.apGraduateYearType];
    }

    if (this.profile.base[Constant.apActivePlannedStart]) {
      let date = this.profile.base[Constant.apActivePlannedStart].split('/');
      formValue[Constant.tfStartYear] = date[0];
      formValue[Constant.tfStartMonth] = date[1];
      date = this.profile.base[Constant.apActivePlannedEnd].split('/');
      formValue[Constant.tfEndYear] = date[0];
      formValue[Constant.tfEndMonth] = date[1];
    }
    if (this.profile.base[Constant.apActiveUndecidedFlag]) {
      formValue[Constant.apActiveUndecidedFlag] = Number(this.profile.base[Constant.apActiveUndecidedFlag]);
      this.onChangeUndecided();
    }
    if (this.profile.base[Constant.apDesireAnnualIncome] > -1) {
      formValue[Constant.apDesireAnnualIncome] = this.profile.base[Constant.apDesireAnnualIncome]
    }

    const employeement = this.profile.base[Constant.apDesireEmployeement].split(',');
    this.master[Constant.joEmploymentType].forEach(element => {
      const keyString: string = 'desire_employeement_' + element.id;
      const value: boolean = employeement.indexOf(element.item_value) > -1 ? true : false;
      formValue[keyString] = value;
    });

    if (this.profile.base[Constant.apDesirejobPlace] === '全国') {
      // 全部にチェックをつける
      this.master[Constant.joJobPlaceType].forEach(element => {
        const keyString: string = 'desirejobplace_' + element.id;
        formValue[keyString] = true;
      });
    } else if (this.profile.base[Constant.apDesirejobPlace]) {
      const place = this.profile.base[Constant.apDesirejobPlace].split(',');
      this.master[Constant.joJobPlaceType].forEach(element => {
        const keyString: string = 'desirejobplace_' + element.id;
        const value: boolean = place.indexOf(element.item_value) > -1 ? true : false;
        formValue[keyString] = value;
      });
    }

    this.profile.desirejob.forEach(element => {
      const keyString: string = 'desirejob_' + element.large_id + '_' + element.id;
      formValue[keyString] = true;
    });
    formValue[Constant.apDesirejobUndecidedFlag] = Number(this.profile.base[Constant.apDesirejobUndecidedFlag]);

    // 職歴
    if (this.profile.work_career.length > this.maxWorkCareer) {
      this.profile.work_career = this.profile.work_career.slice(0, this.maxWorkCareer);  // 最大6つ
    }
    this.profile.work_career.forEach(element => {
      this.onAddCareer(element);
    });

    // 学歴
    if (this.profile.school_history.length > this.maxWorkCareer) {
      this.profile.school_history = this.profile.school_history.slice(0, this.maxSchoolHistory);  // 最大3つ
    }
    this.profile.school_history.forEach(element => {
      this.onAddSchool(element);
    });

    this.form.patchValue(formValue);

    // 職種選択：全てのチェック
    this.profile.desirejob.forEach(element => {
      this.onMiddleId(element.large_id, 'desirejob_', this.form);
    });
    // 非活性制御
    this.onChangeUndecided();
    this.onChangeUndecidedJob();

    this.showSpinner = false;
  }


  // マスタを利用しやすい形式にする
  public convertMaster(master) {
    const newJobMaster = {};
    const retJobMaster = [];

    master.forEach(element => {
      if (newJobMaster[element[Constant.apLargeId]]) {
        newJobMaster[element[Constant.apLargeId]]['middle'].push({
          id: element.id,
          item_value: element[Constant.apItemValue],
          all: element
        });
      } else {
        newJobMaster[element[Constant.apLargeId]] = {
          large_id: element[Constant.apLargeId],
          large_item_value: element[Constant.apLargeItemValue],
          middle: [
            {
              id: element.id,
              item_value: element[Constant.apItemValue],
              all: element
            }
          ]
        };
      }
    });
    for (const key in newJobMaster) {
      if (newJobMaster.hasOwnProperty(key)) {
        const element = newJobMaster[key];
        retJobMaster.push(element);
      }
    }

    return retJobMaster;
  }

  // 取得した職歴の職種を使用しやすい形に整形する
  private shapingWorkCareerJobData() {
    if (this.profile.work_career) {
      let careerjobAry = [];
      for (const wcIndex in this.profile.work_career) {
        if (this.profile.work_career[wcIndex] !== null) {
          const currentId = this.profile.work_career[wcIndex].id;
          for (const djIndex in this.profile.wc_desirejob) {
            // word_careerとwc_desirejobでidが一致するレコードを判別する
            if (this.profile.wc_desirejob[djIndex].id === currentId) {
              careerjobAry.push(this.profile.wc_desirejob[djIndex]);
            }
          }
          this.profile.work_career[wcIndex]['careerjobs'] = careerjobAry;
          careerjobAry = [];
        }
      }
    }
  }

  private disabledTerm(checked) {
    if (checked) {
      this.form.get(Constant.tfStartYear).disable();
      this.form.get(Constant.tfStartMonth).disable();
      this.form.get(Constant.tfEndYear).disable();
      this.form.get(Constant.tfEndMonth).disable();
    } else {
      this.form.get(Constant.tfStartYear).enable();
      this.form.get(Constant.tfStartMonth).enable();
      this.form.get(Constant.tfEndYear).enable();
      this.form.get(Constant.tfEndMonth).enable();
    }
  }

}
