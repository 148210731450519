<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">優先表示の設定</div>
      <div class="desc">エントリーページで優先表示したい求人情報を指定できます。</div>
    </div>
    <div class="list-area">
      <div *ngIf="showSpinner" class="spinner">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="!showSpinner && joboffers?.length == 0" class="no-data">
        求人情報がありません。<br>
        求人情報を作成してください。
      </div>
      <form [formGroup]="form" *ngIf="!showSpinner && form">
        <table *ngIf="joboffers">
          <tr *ngFor="let job of joboffers">
            <td class="order_no">
              <mat-form-field class="input-select no-line">
                <mat-select [formControlName]="'job-'+job.id" panelWidth=""
                (selectionChange)="onSortChange($event.value, 'job-'+job.id)">
                  <mat-option [value]="0"></mat-option>
                  <ng-container *ngFor="let num of orderNum; let n = index">
                    <mat-option [value]="n+1">
                      {{ n+1 }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </td>
            <td *ngIf="job.release_status == Constant.joReleaseStatusNoRelease" class="status">
              <div class="no-release">未公開</div>
            </td>
            <td *ngIf="job.release_status == Constant.joReleaseStatusRelease" class="status">
              <div class="release">公開中</div>
            </td>
            <td *ngIf="job.release_status == Constant.joReleaseStatusStop" class="status">
              <div class="stop">非公開</div>
            </td>
            <td class="title-job">{{ job.title }}</td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="!btnEnable || isSaving" class="yes-btn">保存</button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
