<div class="analytics-main">
  <div class="flex between topmargin">
    <div class="page-title">アナリティクス</div>
    <div class="date-select-area">
      <div class="date-text" *ngIf="startDay && endDay" >{{ startDay|date:"yyyy年MM月dd日" }} ~ {{ endDay|date:"yyyy年MM月dd日" }}</div>
      <mat-form-field class="input-select no-line" appearance="fill">
        <mat-select panelWidth="120"
        [(ngModel)]="selectedTerm"
        (selectionChange)="onTermSelected($event.value)"
        [disabled]="showSpinner">
          <ng-container *ngFor="let term of Constant.alTermSelect">
            <mat-option [value]="term.value">{{ term.label }}</mat-option>
          </ng-container>
          <mat-option [value]="0" (click)="onTermCustom()">カスタム</mat-option>
        </mat-select>
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="showSpinner" class="spinner">
    <mat-spinner></mat-spinner>
  </div>

  <div class="loading-area" [class.hidden]="showSpinner">
    <div class="flex-score-chart" *ngIf="totalData || scoreTransition">
      <div class="rect left">
        <div class="flex between">
          <div class="header">プレファレンススコア</div>
          <div class="gray">全期間</div>
        </div>
        <div class="chart-container-preference-total">
          <canvas #canvasPreferenceTotal></canvas>
        </div>
        <div class="chart-btn-area center">
          <button mat-flat-button type="button" [routerLink]="['/analytics/preference']">詳細
            <img src="assets/analytics/arrow_right.svg">
          </button>
        </div>
      </div>
      <div class="rect right">
        <div class="header">プレファレンススコア推移</div>
        <div class="chart-container-line score">
          <canvas #canvasScoreTransition></canvas>
        </div>
      </div>
    </div>

    <div class="flex-score-chart" *ngIf="totalData">
      <div class="rect left total">
        <div class="flex between">
          <div class="header">累計タレント数</div>
          <div class="gray">全期間</div>
        </div>
        <div class="center"><span class="cnt-text-big total-talent">{{ totalData.all }}</span> 人</div>
      </div>
      <div class="rect right">
        <div class="header">タレント増減数</div>
        <div class="chart-container-line talent">
          <canvas #canvasTalentUpDown></canvas>
        </div>
      </div>
    </div>


    <div class="chart-area" [class.hidden]="showSpinner">
      <div class="flex chart" *ngIf="sitePv && sitePv.chartData">
        <div class="rect">
          <div class="chart-header">
            <div class="icon-label">
              <img src="/assets/analytics/eye_icon.svg">
              <div>サイトPV</div>
            </div>
            <div><span class="cnt-text-big">{{ sitePv.pv_all_cnt|number }}</span> PV</div>
          </div>
          <div class="chart-container-line">
            <canvas #canvasSitePv></canvas>
          </div>
          <div class="chart-btn-area"></div>
        </div>
      </div>

      <div class="flex chart">
        <div class="rect" *ngIf="contentsPv && contentsPv.chartData">
          <div class="chart-header">
            <div class="icon-label">
              <img src="/assets/analytics/eye_icon.svg">
              <div>記事PV</div>
            </div>
            <div><span class="cnt-text-big">{{ contentsPv.pv_cnt|number }}</span> PV</div>
            <div class="summary-area flex">
              <div class="icon-cnt">
                <img src="/assets/analytics/haert.svg">
                <div class="cnt"><span class="cnt-text-middle">{{ contentsPv.good_cnt|number }}</span> いいね</div>
              </div>
              <div class="icon-cnt">
                <img src="/assets/analytics/share.svg">
                <div class="cnt"><span class="cnt-text-middle">{{ contentsPv.share_cnt|number }}</span> シェア</div>
              </div>
            </div>
          </div>
          <div class="chart-container-line">
            <canvas #canvasContentsPv></canvas>
          </div>
          <div class="chart-btn-area">
            <button mat-flat-button type="button" [routerLink]="['/analytics/pr_contents']">詳細
              <img src="assets/analytics/arrow_right.svg">
            </button>
          </div>
        </div>

        <div class="rect" *ngIf="documentPv && documentPv.chartData">
          <div class="chart-header">
            <div class="icon-label">
              <img src="/assets/analytics/eye_icon.svg">
              <div>資料内PV</div>
            </div>
            <div><span class="cnt-text-big">{{ documentPv.pv_cnt|number }}</span> PV</div>
            <div class="summary-area flex">
              <div class="icon-cnt">
                <img src="/assets/analytics/haert.svg">
                <div class="cnt"><span class="cnt-text-middle">{{ documentPv.good_cnt|number }}</span> いいね</div>
              </div>
              <div class="icon-cnt">
                <img src="/assets/analytics/share.svg">
                <div class="cnt"><span class="cnt-text-middle">{{ documentPv.share_cnt|number }}</span> シェア</div>
              </div>
            </div>
          </div>
          <div class="chart-container-line">
            <canvas #canvasDocumentPv></canvas>
          </div>
          <div class="chart-btn-area">
            <button mat-flat-button type="button" [routerLink]="['/' + Constant.rtAnalytics + '/' + Constant.rtMaterials]">詳細
              <img src="assets/analytics/arrow_right.svg">
            </button>
          </div>
        </div>

      </div>

      <div class="flex chart">
        <div class="rect" *ngIf="jobofferPv && jobofferPv.chartData">
          <div class="chart-header">
            <div class="icon-label">
              <img src="/assets/analytics/eye_icon.svg">
              <div>求人情報PV</div>
            </div>
            <div><span class="cnt-text-big">{{ jobofferPv.pv_cnt|number }}</span> PV</div>
            <div class="summary-area flex">
              <div class="icon-cnt">
                <img src="/assets/analytics/oubo_icon.svg">
                <div class="cnt"><span class="cnt-text-middle">{{ jobofferPv.apply_cnt|number }}</span> 応募</div>
              </div>
              <div class="icon-cnt">
                <img src="/assets/analytics/mind_icon.svg">
                <div class="cnt"><span class="cnt-text-middle">{{ jobofferPv.mind_cnt|number }}</span> 気になる</div>
              </div>

              <div class="icon-cnt">
                <img src="/assets/analytics/share.svg">
                <div class="cnt"><span class="cnt-text-middle">{{ jobofferPv.share_cnt|number }}</span> シェア</div>
              </div>
            </div>
          </div>
          <div class="chart-container-line">
            <canvas #canvasJobofferPv></canvas>
          </div>
          <div class="chart-btn-area">
            <button mat-flat-button type="button" [routerLink]="['/' + Constant.rtAnalytics + '/job_offer']">詳細
              <img src="assets/analytics/arrow_right.svg">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
