<div class="analytics-main">
  <div *ngIf="showSpinner" class="spinner">
    <mat-spinner></mat-spinner>
  </div>
  <div class="flex" *ngIf="!showSpinner && totalData">
    <div class="left">
      <div class="flex">
        <div class="rect total">
          <div class="header">累計タレント数</div>
          <div><span class="cnt-text-big total-talent">{{ totalData.all | number }}</span> 人</div>
        </div>
        <div class="rect total-chart">
          <div class="flex between">
            <div class="header">タレント数推移</div>
            <div class="date-text" *ngIf="startDay && endDay" >{{ startDay|date:"yyyy年MM月dd日" }} ~ {{ endDay|date:"yyyy年MM月dd日" }}</div>
          </div>
          <div class="chart-container-line">
            <canvas #canvasTalent></canvas>
          </div>
        </div>
      </div>
      <!-- 対応状況 -->
      <div class="rect action" *ngIf="actionInfo">
        <div class="header">未対応状況</div>
        <div class="flex">
          <div class="rect small">
            <div class="title">新着タレント</div>
            <div>
              <a class="cnt-text-big" *ngIf="actionInfo.new_talent>0" (click)="onToList(Constant.tlDataStatusType, Constant.tlDataStatusTypeIdNew)">{{ actionInfo.new_talent }}</a>
              <span class="cnt-text-big" *ngIf="actionInfo.new_talent==0">0</span>
              人</div>
          </div>
          <div class="rect small">
            <div class="title">未読メッセージ</div>
            <div>
              <a class="cnt-text-big" *ngIf="actionInfo.mail_unread>0" (click)="onToList(Constant.tlMsgStatusType, Constant.tlMsgStatusReceivedUnread)">{{ actionInfo.mail_unread }}</a>
              <span class="cnt-text-big" *ngIf="actionInfo.mail_unread==0">0</span>
              件
            </div>
          </div>
          <div class="rect small">
            <div class="title">応募</div>
            <div>
              <a class="cnt-text-big" *ngIf="actionInfo.noaction_apply>0" (click)="onToList(Constant.offerActionType, Constant.offerActionApply, Constant.offerReactType, Constant.offerReplyNoReply)">{{ actionInfo.noaction_apply }}</a>
              <span class="cnt-text-big" *ngIf="actionInfo.noaction_apply==0">0</span>
              件
            </div>
          </div>
          <div class="rect small">
            <div class="title">気になる</div>
            <div>
              <a class="cnt-text-big" *ngIf="actionInfo.noaction_mind>0" (click)="onToList(Constant.offerActionType, Constant.offerActionMind, Constant.offerReactType, Constant.offerReplyNoReply)">{{ actionInfo.noaction_mind }}</a>
              <span class="cnt-text-big" *ngIf="actionInfo.noaction_mind==0">0</span>
              件
            </div>

          </div>
        </div>


      </div>

      <!-- お知らせ -->
      <div class="rect info" *ngIf="newsInfo">
        <div class="header">お知らせ</div>
        <div class="table-scroll">
          @if (newsInfo.list.length > 0) {
            <table class="info-table">
              <ng-container *ngFor="let item of newsInfo.list; index as idx;">
                <tr>
                  <td class="icon">
                    <img src="assets/analytics/news_contents.svg" *ngIf="item.action_type == 'contents_good' || item.action_type == 'contents_share_history'">
                    <img src="assets/analytics/news_document.svg" *ngIf="item.action_type == 'document_good' || item.action_type == 'document_share_history'">
                    <img src="assets/analytics/news_profile.svg" *ngIf="item.action_type == 'profile_update' || item.action_type == 'question_update'">
                  </td>
                  <td class="title">
                    <a (click)="onShowTalentKarte(item.talent_id)">{{ item.last_name || item.first_name ? item.last_name + item.first_name : '名前未設定' }}さんが{{ actionLabel[item.action_type] }}しました。
                    </a>
                  </td>
                  <td class="date">{{ item.created_at }}</td>
                </tr>
              </ng-container>
            </table>
          } @else {
            お知らせはありません。
          }
        </div>

      </div>

    </div>
    <div class="right">
      <div class="rect">
        <div class="header">プレファレンススコア</div>
        <div class="chart-container-preference">
          <canvas #canvasPreference></canvas>
        </div>
      </div>

      <div class="rect" *ngIf="actionInfo?.preference_top">
        <div class="header">プレファレンススコア急上昇</div>
        @if (actionInfo.preference_top.length>0) {
          <table class="preference-top">
            <tr *ngFor="let item of actionInfo.preference_top; index as idx;">
              <td class="img">
                <a (click)="onShowTalentKarte(item.talent_id)">
                  <img class="profile-image" src="{{ item.image_url }}" *ngIf="item.image_url"/>
                  <img class="profile-image" src="assets/talent-list/icon_User_men.png" *ngIf="!item.image_url && item.sex_id==Constant.tlSexIDMen"
                    srcset="assets/talent-list/icon_User_men.png 1x, assets/talent-list/icon_User_men@2x.png 2x" />
                  <img class="profile-image" src="assets/talent-list/icon_User_woman.png" *ngIf="!item.image_url && item.sex_id==Constant.tlSexIDWomen"
                    srcset="assets/talent-list/icon_User_woman.png 1x, assets/talent-list/icon_User_woman@2x.png 2x" />
                  <img class="profile-image" src="assets/talent-list/icon_User.png" *ngIf="!item.image_url && item.sex_id==Constant.tlSexIDOther"
                    srcset="assets/talent-list/icon_User.png 1x, assets/talent-list/icon_User@2x.png 2x" />
                </a>
              </td>
              <td class="name-area">
                <div class="flex">
                  <a (click)="onShowTalentKarte(item.talent_id)">
                    <div class="name" *ngIf="item.last_name || item.first_name">{{ item.last_name }}{{item.first_name}} </div>
                    <div class="name" *ngIf="!item.last_name && !item.first_name">名前未設定 </div>
                  </a>
                  <div class="age" *ngIf="item.age">{{item.age}}歳</div>
                </div>
                <div class="job" *ngIf="item.desirejob">{{item.desirejob}}</div>
                <!-- <span class="age" *ngIf="!item.age">年齢未登録</span> -->
              </td>
              <td>
                <div class="score">
                  <canvas id="score-top-{{idx}}"></canvas>
                </div>
              </td>
            </tr>
          </table>
        } @else {
          急上昇したタレントはいません。
        }
      </div>

    </div>
  </div>
</div>
