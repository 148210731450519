<div class="analytics-main">
  <div class="page-link">
    <a [routerLink]="['/' + Constant.rtAnalytics]">アナリティクス</a>　>　<span class="here">プレファレンススコアレポート</span>
  </div>
  <div class="page-title">プレファレンススコアレポート</div>

  <div class="flex between margintop">
    <div class="chart-switch-area">
      <button mat-flat-button type="button" class="active">タレントのみ</button>
    </div>
    <div class="date-select-area">
      <div class="date-text" *ngIf="startDay && endDay" >{{ startDay|date:"yyyy年MM月dd日" }} ~ {{ endDay|date:"yyyy年MM月dd日" }}</div>
      <mat-form-field class="input-select no-line" appearance="fill">
        <mat-select panelWidth="120"
        [(ngModel)]="selectedTerm"
        (selectionChange)="onTermSelected($event.value)"
        [disabled]="showSpinner">
          <ng-container *ngFor="let term of Constant.alTermSelect">
            <mat-option [value]="term.value">{{ term.label }}</mat-option>
          </ng-container>
          <mat-option [value]="0" (click)="onTermCustom()">カスタム</mat-option>
        </mat-select>
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="showSpinner" class="spinner">
    <mat-spinner></mat-spinner>
  </div>

  <div class="loading-area" [class.hidden]="showSpinner">
    <div class="flex-score-chart" *ngIf="totalData || scoreTransition">
      <div class="rect left">
        <div class="flex between">
          <div class="header">プレファレンススコア</div>
          <div class="gray">全期間</div>
        </div>
        <div class="chart-container-preference-total">
          <canvas #canvasPreferenceTotal></canvas>
        </div>
      </div>
      <div class="rect right" *ngIf="scoreTransition">
        <div class="header">プレファレンススコア推移</div>
        <div class="chart-container-line wide">
          <canvas #canvasScoreTransition></canvas>
        </div>
      </div>
    </div>

    <div class="flex-score-chart" *ngIf="totalData">
      <div class="rect left total">
        <div class="flex between">
          <div class="header">累計タレント数</div>
          <div class="gray">全期間</div>
        </div>
        <div class="center"><span class="cnt-text-big total-talent">{{ totalData.all }}</span> 人</div>
      </div>
      <div class="rect right">
        <div class="header">タレント増減数</div>
        <div class="chart-container-line wide">
          <canvas #canvasTalentUpDown></canvas>
        </div>
      </div>
    </div>

    <div class="rect score" *ngIf="scoreDetail">
      <div class="flex between">
        <div class="header">プレファレンススコア分布</div>
        <div class="gray">全期間</div>
      </div>

      <div class="chart-container-line margintop">
        <canvas #canvasScorebunpu></canvas>
      </div>
    </div>



    <div class="chart-switch-area margintop" *ngIf="scoreDetail">
      <button mat-flat-button type="button" [class.active]="selectedType == Constant.alPreferenceTypeProfile" (click)="onChangeType(Constant.alPreferenceTypeProfile)" [disabled]="showSpinner">プロフィール</button>
      <button mat-flat-button type="button" [class.active]="selectedType == Constant.alPreferenceTypeHistory" (click)="onChangeType(Constant.alPreferenceTypeHistory)" [disabled]="showSpinner">経歴</button>
      <button mat-flat-button type="button" [class.active]="selectedType == Constant.alPreferenceTypeTag" (click)="onChangeType(Constant.alPreferenceTypeTag)" [disabled]="showSpinner">タグ</button>
    </div>

    <!-- 年齢別-->
    @if (scoreDetail) {
    <div class="tab-content" [class.hide]="selectedType != Constant.alPreferenceTypeProfile">
      <div class="rect">
        <div class="flex between">
          <div class="header">年齢</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart">
          <div class="detail-table-area">
            <table class="detail-table" id="table-age">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.profile.age; track item.range;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title">{{item.range}}</td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }

            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasAge></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="rect">
        <div class="flex between">
          <div class="header">性別</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart">
          <div class="detail-table-area">
            <table class="detail-table" id="table-sex">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.profile.sex; track item.sex;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title">{{item.sex}}</td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }

            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasSex></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="rect">
        <div class="flex between">
          <div class="header">最終学歴</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart">
          <div class="detail-table-area">
            <table class="detail-table" id="table-school">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.profile.school_type; track item.school_type;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title">{{item.school_type}}</td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }

            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasSchool></canvas>
            </div>
          </div>
        </div>
      </div>

      <!-- 採用枠 -->
      <div class="rect">
        <div class="flex between">
          <div class="header">採用枠</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart">
          <div class="detail-table-area">
            <table class="detail-table" id="table-recruite">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.profile.recruite_quota; track item.career_type;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title">{{ careerTypeLabel[item.career_type] }}</td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }

            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasCareerType></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 経歴タブ -->
    <div class="tab-content" [class.hide]="selectedType != Constant.alPreferenceTypeHistory">
      <!-- 経験職種-->
      <div class="rect">
        <div class="flex between">
          <div class="header">経験職種</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart scroll">
          <div class="detail-table-area">
            <table class="detail-table" id="table-exp-job">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.career.experience_work_career; track item.career_type;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title"><div>{{item.career_type}}</div></td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }

            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasExpCareer></canvas>
            </div>
          </div>
        </div>
      </div>

      <!-- 希望職種-->
      <div class="rect">
        <div class="flex between">
          <div class="header">希望職種</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart scroll">
          <div class="detail-table-area">
            <table class="detail-table" id="table-desire-job">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.career.hope_work_career; track item.career_type;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title"><div>{{item.career_type}}</div></td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }

            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasDesireCareer></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="rect">
        <div class="flex between">
          <div class="header">雇用形態</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart">
          <div class="detail-table-area">
            <table class="detail-table" id="table-employment">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.career.employment_status; track item.employment_type;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title">{{ item.employment_type }}</td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }

            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasEmployment></canvas>
            </div>
          </div>
        </div>
      </div>

      <!-- 現年収 -->
      <div class="rect">
        <div class="flex between">
          <div class="header">現年収</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart scroll">
          <div class="detail-table-area">
            <table class="detail-table" id="table-income">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.career.income_status; track item.income_range;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title">{{ item.income_range }}</td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }
            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasIncome></canvas>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- タグタブ -->
    <div class="tab-content" [class.hide]="selectedType != Constant.alPreferenceTypeTag">
      <!-- 接点経路タグ -->
      <div class="rect">
        <div class="flex between">
          <div class="header">接点経路タグ</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart scroll">
          <div class="detail-table-area">
            <table class="detail-table" id="table-contact">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.tag.contact_tag; track item.contact_tag_id;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title">{{ item.contact_tag }}</td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }
            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasContact></canvas>
            </div>
          </div>
        </div>
      </div>

      <!-- タレント管理タグ -->
      <div class="rect">
        <div class="flex between">
          <div class="header">タレント管理タグ</div>
          <div class="gray">全期間</div>
        </div>
        <div class="flex table-chart scroll">
          <div class="detail-table-area">
            <table class="detail-table" id="table-talenttag">
              <tr>
                <th class="title">スコア別人数</th>
                <th class="total">合計</th>
                <th class="cnt">19以下</th>
                <th class="cnt">20-39</th>
                <th class="cnt">40-59</th>
                <th class="cnt">60-79</th>
                <th class="cnt">80以上</th>
              </tr>
              @for (item of scoreDetail.tag.talent_management_tag; track item.talent_tag;) {
                @if (item.talent_total > 0) {
                  <tr>
                    <td class="title">{{ item.talent_tag }}</td>
                    <td class="total">{{ item.talent_total }}</td>
                    @for (score of item.score_range; track score.range;) {
                      @if (score.max_flg) {
                        <td class="cnt max-{{$index}}">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      } @else {
                        <td class="cnt">{{ score.talent_total }}<br><span class="small">({{ score.percentage | number: '1.0-0' }}%)</span></td>
                      }
                    }
                  </tr>
                }
              }
            </table>
          </div>
          <div class="chart-area">
            <div class="chart-container-bar">
              <canvas #canvasTalenttag></canvas>
            </div>
          </div>
        </div>
      </div>

    </div>
    }
  </div>
</div>
