import { Component, Inject, OnInit, AfterViewInit, OnDestroy, HostListener, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { MomentUtil } from '../../util/moment-util';
import { ToastUtil } from '../../util/toast-util';
import { TalentListCondition } from '../../model/talent-list-condition';

@Component({
  selector: 'app-analytics-result-dialog',
  templateUrl: './analytics-result-dialog.component.html',
  styleUrls: ['./analytics-result-dialog.component.css']
})
export class AnalyticsResultDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly Constant = Constant;
  public showSpinner = false;
  public selectedType;
  public selectedUnit;
  public title;
  public result;
  public resultStart;
  public resultEnd;
  private auth;

  @ViewChildren('chatColumns') resultScroll: QueryList<any>;

  constructor(
    public dialogRef: MatDialogRef<AnalyticsResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonUtil: CommonUtil,
    private momentUtil: MomentUtil,
    private toastUtil: ToastUtil,
  ) { }

  ngOnInit(): void {
    if (!this.commonUtil.checkOnline()) {
      this.dialogRef.close();
    }
    this.selectedType = this.data.selectedType;
    this.selectedUnit = this.data.selectedUnit;
    this.result = this.data.result;
    this.auth = this.data.auth;

    this.execSearch();

    this.setTitle();
  }

  ngAfterViewInit(): void {
    // 横スクロールで戻るのを禁止する
    document.body.classList.add('dialog');

    // safariで横スクロールの戻り禁止対応
    this.resultScroll.changes.subscribe(data => {
      const scrollDiv = document.querySelector('.result-scroll');
      scrollDiv.scrollLeft = 1;
    });
  }

  ngOnDestroy(): void {
    document.body.classList.remove('dialog');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
     // safariで横スクロールの戻り禁止対応
    if (event.target.scrollLeft < 1) {
      event.target.scrollLeft = 1;
    }
  }

  onToList(id, dateLength?, index?, date?, registType?) {
    const condition: TalentListCondition[] = [];

    let dateConditionStartType, dateConditionEndType;
    if (this.selectedType === Constant.alTypeTalent) {
      // タレント増加数
      dateConditionStartType = Constant.tlRegisteredDateStartType;
      dateConditionEndType = Constant.tlRegisteredDateEndType;

      if (registType !== null) {
        condition.push({
          type: Constant.tlTalentRegistType,
          id: registType
        });
      }

    } else if (this.selectedType === Constant.alTypeContactAll || this.selectedType === Constant.alTypeContactUse) {
      // 接点・経路タグ付与数
      dateConditionStartType = Constant.tlContactTagStartType;
      dateConditionEndType = Constant.tlContactTagEndType;

      if (id) {
        condition[0] = {
          type: Constant.tlContactTagType,
          id: id
        };
      } else {
        this.result[1].forEach(element => {
          condition.push({
            type: Constant.tlContactTagType,
            id: element.value[0].id
          });
        });
      }

    } else if (this.selectedType === Constant.alTypeContentsViewAll || this.selectedType === Constant.alTypeContentsViewUse
      || this.selectedType === Constant.alTypeContentsShareAll || this.selectedType === Constant.alTypeContentsShareUse
      || this.selectedType === Constant.alTypeContentsGoodAll || this.selectedType === Constant.alTypeContentsGoodUse) {
      // PRコンテンツ（閲覧数・いいね数・シェア数）
      dateConditionStartType = Constant.tlContentsActionStartType;
      dateConditionEndType = Constant.tlContentsActionEndType;

      if (this.selectedType === Constant.alTypeContentsViewAll || this.selectedType === Constant.alTypeContentsViewUse) {
        // 閲覧
        condition[0] = {
          type: Constant.pcActionType,
          id: Constant.pcActionMore
        };
      } else if (this.selectedType === Constant.alTypeContentsShareAll || this.selectedType === Constant.alTypeContentsShareUse) {
        // シェア
        condition[0] = {
          type: Constant.pcActionType,
          id: Constant.pcActionShare
        };
      } else {
        // いいね
        condition[0] = {
          type: Constant.pcActionType,
          id: Constant.pcActionGood
        };
      }

      if (id) {
        condition[1] = {
          type: Constant.pcTitleType,
          id: id
        };
      } else {
        // 合計の場合全選択
        this.result[1].forEach(element => {
          condition.push({
            type: Constant.pcTitleType,
            id: element.value[0].id
          });
        });
        condition.push({
          type: Constant.pcStatusType,
          id: 1
        });
        if (this.selectedType === Constant.alTypeContentsViewAll || this.selectedType === Constant.alTypeContentsShareAll
          || this.selectedType === Constant.alTypeContentsGoodAll ) {
          condition.push({
            type: Constant.pcStatusType,
            id: 2
          });
        }
      }

    } else {
      // 求人（閲覧数・気になる・応募）
      dateConditionStartType = Constant.tlJobOfferActionStartType;
      dateConditionEndType = Constant.tlJobOfferActionEndType;

      if (this.selectedType === Constant.alTypeJobofferViewAll || this.selectedType === Constant.alTypeJobofferViewUse) {
        // 閲覧
        condition[0] = {
          type: Constant.offerActionType,
          id: Constant.offerActionView
        };
      } else if (this.selectedType === Constant.alTypeJobofferShareAll || this.selectedType === Constant.alTypeJobofferShareUse) {
        // シェア
        condition[0] = {
          type: Constant.offerActionType,
          id: Constant.offerActionShare
        };
      } else if (this.selectedType === Constant.alTypeJobofferMindAll || this.selectedType === Constant.alTypeJobofferMindUse) {
        // 気になる
        condition[0] = {
          type: Constant.offerActionType,
          id: Constant.offerActionMind
        };
      } else if (this.selectedType === Constant.alTypeJobofferApplyAll || this.selectedType === Constant.alTypeJobofferApplyUse) {
        // 応募
        condition[0] = {
          type: Constant.offerActionType,
          id: Constant.offerActionApply
        };
      }

      if (id) {
        condition.push({
          type: Constant.offerTitleType,
          id: id
        });
      } else {
        this.result[1].forEach(element => {
          condition.push({
            type: Constant.offerTitleType,
            id: element.value[0].id
          });
        });
        condition.push({
          type: Constant.offerStatusType,
          id: 1
        });
        if (this.selectedType === Constant.alTypeJobofferViewAll || this.selectedType === Constant.alTypeJobofferShareAll
          || this.selectedType === Constant.alTypeJobofferMindAll || this.selectedType === Constant.alTypeJobofferApplyAll ) {
          condition.push({
            type: Constant.offerStatusType,
            id: 2
          });
        }
      }
    }

    // 日時条件作成
    let startDate = this.momentUtil.getDateFormat(this.data.period.start);
    let endDate = this.momentUtil.getDateFormat(this.data.period.end);
    if (date) {
      if (this.selectedUnit === Constant.alTermUnitDayId) {
        // 日
        startDate = date;
        endDate = date;
      } else if (this.selectedUnit === Constant.alTermUnitMonthId) {
        // 月
        if (dateLength > 1) {
          if (index > 0) {
            // 月初
            startDate = date + '-01';
          }
          if (index < dateLength - 1) {
            endDate = this.momentUtil.getEndDateMonth(date);
          }
        }
      } else {
        // 年
        if (index > 0) {
          // 月初
          startDate = date + '-01-01';
        }
        if (index < dateLength - 1) {
          endDate = date + '-12-31';
        }
      }
    }
    condition.push({
      type: dateConditionStartType,
      id: startDate
    });
    condition.push({
      type: dateConditionEndType,
      id: endDate
    });

    localStorage.setItem(Constant.lsTalentListCondition, JSON.stringify(condition));
    window.open('list');
  }

  // 累計計算
  cntSum(idx, data) {
    let sum = 0;
    for (let i = 0; i <= idx; i++) {
      sum += data[i].cnt;
    }
    return sum;
  }

  // 日付別計算
  cntSumDate(idx, data, data2?) {
    let sum = 0;
    data.forEach(element => {
      sum += element.value[idx].cnt;
    });

    if (data2) {
      sum += data2[idx].cnt;
    }
    return sum;
  }

  // 全累計
  cntSumAll(data, data2?) {
    let sum = 0;
    data.forEach(element => {
      for (let i = 0; i < element.value.length; i++) {
        sum += element.value[i].cnt;
      }
    });
    if (data2) {
      for (let i = 0; i < data2.length; i++) {
        sum += data2[i].cnt;
      }
    }
    return sum;
  }

  // コピー
  onCopy() {
    const table = document.querySelector('.result-table');
    const range = document.createRange();
    range.selectNodeContents(table);

    // 文字色黒・背景色白に設定、()非表示
    const th = document.getElementsByClassName('date') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < th.length; i++) {
      th.item(i).style.color = '#000';
      th.item(i).style.background = '#fff';
    }
    const title = document.getElementsByClassName('title') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < title.length; i++) {
      title.item(i).style.background = '#fff';
    }
    const link = document.getElementsByClassName('link-text') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < link.length; i++) {
      link.item(i).style.color = '#000';
      link.item(i).style.fontWeight = 'normal';
      // cntData = cnt.item(i).innerHTML.replace()
    }
    const kakko = document.getElementsByClassName('kakko') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < kakko.length; i++) {
      kakko.item(i).style.display = 'none';
    }

    // 選択
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // クリップボードにコピー
    const succeed = document.execCommand('copy');
    if (succeed) {
      // コピー成功
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showInformationToast('', Constant.msgCopySuccess, Constant.toastShowMiliSec);
    } else {
      // コピー失敗
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgCopyError, Constant.toastShowMiliSec);
    }

    // 選択解除
    // 文字色戻す
    for (let i = 0; i < title.length; i++) {
      title.item(i).style.background = '#e7e7f1';
    }
    for (let i = 0; i < th.length; i++) {
      th.item(i).style.color = '#fff';
      th.item(i).style.background = '#3f51b5';
    }
    for (let i = 0; i < link.length; i++) {
      link.item(i).style.color = '#3f51b5';
      link.item(i).style.fontWeight = 'bold';
    }
    for (let i = 0; i < kakko.length; i++) {
      kakko.item(i).style.display = 'inline';
    }
    const gray = document.getElementsByClassName('link-gray') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < gray.length; i++) {
      gray.item(i).style.color = '#999999';
      gray.item(i).style.fontWeight = 'normal';
    }
    selection.removeAllRanges();
  }

  // 検索実行
  private execSearch() {
    Auth.currentSession().then(session => {
      const apiPath = '/analytics/search';
      const options = this.auth.createApiHeader(session);
      options['body'] = this.data.body;
      this.showSpinner = true;

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.commonUtil.debug().log(res);
          this.convertData(res.data);
          if (this.data.selectedUnit === Constant.alTermUnitMonthId) {
            this.resultStart = this.momentUtil.getDateFormatDay(this.data.period.start);
            this.resultEnd = this.momentUtil.getDateFormatDay(this.data.period.end);
          } else if (this.data.selectedUnit === Constant.alTermUnitYearId) {
            this.resultStart = this.momentUtil.getDateFormatMonthDay(this.data.period.start);
            this.resultEnd = this.momentUtil.getDateFormatMonthDay(this.data.period.end);
          }
          this.showSpinner = false;
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          if (err.status === Constant.NG) {
            this.toastUtil.showErrorToast(Constant.empty,　Constant.msgAnalyticsFailed,　Constant.toastShowMiliSec);
          } else {
            this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
          }
          this.dialogRef.close();
        });

    });
  }

  // 取得データの整形
  private convertData(data) {
    if (this.selectedType === Constant.alTypeContactAll || this.selectedType === Constant.alTypeContactUse) {
      // 接点・経路タグ
      const tmp = data[0].reduce(function(rv, x) {
        (rv[x['created_at']] = rv[x['created_at']] || []).push(x);
          return rv;
       }, {});
      data[0] = Object.keys(tmp)
        .map(function(k) { return { key: k, value: tmp[k] }; })
        .sort(function(a, b) { return a.value - b.value; });

      const tmp2 = data[1].reduce(function(rv, x) {
          (rv[x['created_at']] = rv[x['created_at']] || []).push(x);
            return rv;
         }, {});
      data[1] = Object.keys(tmp2)
          .map(function(k) { return { key: k, value: tmp2[k] }; })
          .sort(function(a, b) { return a.value - b.value; });

    } else if (this.selectedType === Constant.alTypePageviewAll || this.selectedType === Constant.alTypePageviewUse
       || this.selectedType === Constant.alTypeLoginpageviewAll || this.selectedType === Constant.alTypeLoginpageviewUse) {
      // エントリーページビュー、ログインページビュー
      // 作成日時降順でグループ化
      const tmp = data[1].reduce(function(rv, x) {
        (rv[x['created_at']] = rv[x['created_at']] || []).push(x);
          return rv;
       }, {});
      data[1] = Object.keys(tmp)
        .map(function(k) { return { key: k, value: tmp[k] }; })
        .sort(function(a, b) { return a.value - b.value; });


    } else if (this.selectedType !== Constant.alTypeTalent) {
      // PRコンテンツ、求人情報
      const tmp = data[0].reduce(function(rv, x) {
        (rv[x['release_date']] = rv[x['release_date']] || []).push(x);
          return rv;
       }, {});
      data[0] = Object.keys(tmp)
        .map(function(k) { return { key: k, value: tmp[k] }; })
        .sort(function(a, b) { return a.value - b.value; });

      const tmp2 = data[1].reduce(function(rv, x) {
          (rv[x['release_date']] = rv[x['release_date']] || []).push(x);
            return rv;
         }, {});
      data[1] = Object.keys(tmp2)
          .map(function(k) { return { key: k, value: tmp2[k] }; })
          .sort(function(a, b) { return a.value - b.value; });

    }
    this.result = data;
  }

  // タイトル設定
  private setTitle() {
    this.data.typeMaster.forEach(element => {
      if (element.id === this.selectedType) {
        this.title = element.item_value;
      }
    });
  }
}
