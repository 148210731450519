import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Auth } from '@aws-amplify/auth';
import * as moment from 'moment';
import { AuthService } from '../auth/auth.service';
import { TalentListCondition } from '../model/talent-list-condition';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { Constant } from './../constant';

@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.css']
})
export class SearchContainerComponent implements OnInit {
  @Input() searchData;
  @Output() searchTalent = new EventEmitter();
  public readonly Constant = Constant;
  public searching = false;
  public headers;
  public condition;
  public conditions;
  public form: FormGroup;
  public searchTabTitles = []; // 検索タブのタイトル配列
  public searchTabCheck = []; // 検索タブのチェック状態
  public maxLenTwo = 2; // 入力制限2桁
  public maxLenFour = 4; // 入力制限4桁
  public maxLenFive = 5; // 入力制限5桁
  public isMsgExceptFlg;
  public searchGraduateYearList;
  public jobPeriod = {start: null, end: null};  // datepicker 最大最小
  public conPeriod = {start: null, end: null};
  public docPeriod = {start: null, end: null};
  public tagPeriod = {start: null, end: null};
  public pooledPeriod = {start: null, end: null};
  private orderType: string;
  private isCheckCondContactTagAny = false;
  private isCheckExpJobAny = false;
  private msgSelectTalentType;

  constructor(
    private auth: AuthService,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
  ) {}

  ngOnInit(): void {
    this.condition = this.searchData.condition;
    this.conditions = this.searchData.conditions;
    this.isMsgExceptFlg = this.searchData.msgExceptFlg;
    if (this.isMsgExceptFlg) {
      this.msgSelectTalentType = this.searchData.msgSelectTalentType;
    }

    this.orderType = Constant.tlOrderDefault;
    this.getHeaderInfo();
  }

  // 検索
  onSearch() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.searching = true;

    // 親画面のぐるぐる表示
    this.searchTalent.emit(true);

    // 検索条件保存、フォーム再作成のため
    if (!this.createSearchConditoins()) {
      this.searchTalent.emit(false);
      this.searching = false;
      return;
    }

    // 最新の条件を取得してから検索
    this.getHeaderInfo();
  }

  // タレントリストより呼び出し
  resetHeaderInfo() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    // 親画面のぐるぐる表示
    this.searchTalent.emit(true);

    // 最新の条件を取得してから検索
    this.getHeaderInfo();
  }

  // 検索のタブ押下
  onSearchTab(event: any) {
    for (let i = 0; i < this.searchTabCheck.length; i++) {
      this.searchTabCheck[i] = false;
    }
    if (event.target.checked) {
      const index = event.target.id.slice(-1);
      this.searchTabCheck[index] = true;
    }
  }

  // 並び替え
  onDefaultOrder() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSortStandard);
    this.orderType = Constant.tlOrderDefault;
    this.searchData.conditions.type = Constant.tlOrderDefault;
    this.onSearch();
  }
  onDataOrder() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSortProfile);
    this.orderType = Constant.tlOrderDataType;
    this.searchData.conditions.type = Constant.tlOrderDataType;
    this.onSearch();
  }

  onMsgOrder() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSortMessage);
    this.orderType = Constant.tlOrderMsgType;
    this.searchData.conditions.type = Constant.tlOrderMsgType;
    this.onSearch();
  }

  onOfrApplyOrder() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSortApply);
    this.orderType = Constant.tlOrderOfrApplyType;
    this.searchData.conditions.type = Constant.tlOrderOfrApplyType;
    this.onSearch();
  }

  onOfrMindOrder() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSortAttract);
    this.orderType = Constant.tlOrderOfrMindType;
    this.searchData.conditions.type = Constant.tlOrderOfrMindType;
    this.onSearch();
  }

  onPoolOrder() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSortPool);
    this.orderType = Constant.tlOrderPoolType;
    this.searchData.conditions.type = Constant.tlOrderPoolType;
    this.onSearch();
  }

  onAccessOrder() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSortAccess);
    this.orderType = Constant.tlOrderAccessType;
    this.searchData.conditions.type = Constant.tlOrderAccessType;
    this.onSearch();
  }

  onCompanyOrder() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSortProfileCompany);
    this.orderType = Constant.tlOrderCompanyType;
    this.searchData.conditions.type = Constant.tlOrderCompanyType;
    this.onSearch();
  }


  /**
   * 子供のチェックボックスに全てチェックを入れる
   * @param {Boolean} checked        - チェックするかどうか(true:チェックする、false:チェックを外す)
   * @param {string} conditionTitle  - 検索条件のタイトル
   * @param {Array} targetType       - ターゲットとする検索条件のtype
   * @param {integer} parentId       - チェックした自身のid
   */
  checkAllChidlen(checked, conditionTitle, targetType, parentId) {

    this.headers.forEach(header => {
      if (header.title === conditionTitle) {
        // カテゴリが"求人アクション・対応"の場合
        header.master_list.forEach(target => {
          if (target.type === targetType) {
            target.data.forEach(parent => {
              if (parent.id === parentId) {
                parent.data.forEach(child => {
                  this.form
                    .get(target.type + Constant.pythen + parent.id + Constant.pythen + child.id)
                    .setValue(checked);
                });
              }
            });
            return;
          }
        });
      }
    });
  }

  /**
 * 親のチェックボックスを子供のチェックボックスの状態によって制御する
 * @param {string} conditionTitle  - 検索条件のタイトル
 * @param {Array} targetType       - ターゲットとする検索条件のtype(大カテゴリ)
 * @param {integer} parentId       - 親のid
 * @param {integer} parentType     - 親のtype
 */
  checkParent(conditionTitle, targetType, parentId, parentType) {
    // 子供のチェックボックスが全てチェックされていた場合、親にもチェックを入れる
    // 子供のチェックボックスが１つでもチェックされていなかった場合、親からもチェックを外す
    let checkParent = false;
    this.headers.forEach(header => {
      if (header.title === conditionTitle) {
        header.master_list.forEach(target => {
          if (target.type === targetType) {
            target.data.forEach(parent => {
              if (parent.id === parentId) {
                let checkedCnt = 0;
                parent.data.forEach(child => {
                  const childChecked = this.form
                    .get(target.type + Constant.pythen + parent.id + Constant.pythen + child.id)
                    .value;
                  if (childChecked) {
                    checkedCnt++;
                  }
                });
                if (checkedCnt === parent.data.length) {
                  // 子供に全てチェックが付いていた場合
                  checkParent = true;
                }
              }
            });
          }
        });
      }
    });
    // 親チェックボックスの値を設定
    this.form.get(parentType + Constant.pythen + parentId).setValue(checkParent);
  }

  /**
     * 自身以外のチェックボックスについて活性・非活性状態を変更する
     * @param {Boolean} enable         - 非活性にするかどうか(true:活性、false:非活性)
     * @param {string} conditionTitle  - 検索条件のタイトル
     * @param {Array} targetTypes      - ターゲットとする検索条件のtype
     * @param {integer} selfId           - チェックした自身のid
     */
  changeOtherCheckState(enable, conditionTitle, targetTypes, selfId) {
    this.headers.forEach(header => {
      if (header.title === conditionTitle) {
        header.master_list.forEach(element => {
          if (targetTypes.indexOf(element.type) >= 0) {
            if (element.type === Constant.tlContactTagPeriodType) {
              // 接点タグの期間入力
              if (enable) {
                this.checkContactTagPeriod();
              } else {
                this.form.get(Constant.tlContactTagStartType).disable();
                this.form.get(Constant.tlContactTagEndType).disable();
              }
              return;
            }
            element.data.forEach(item => {
              if (item.id !== selfId) {
                if (enable) {
                  this.form.get(element.type + Constant.pythen + item.id).enable();
                } else {
                  if (element.type === Constant.pcActionType) {
                    // アクションのチェックボックスは一つしか選択できないように他のチェックを外す
                    // 記事
                    if (selfId === Constant.pcActionGood) {
                      this.form.get(element.type + Constant.pythen + Constant.pcActionMore).reset();
                      this.form.get(element.type + Constant.pythen + Constant.pcActionShare).reset();
                      this.form.get(element.type + Constant.pythen + Constant.pcActionNone).reset();
                    } else if (selfId === Constant.pcActionMore) {
                      this.form.get(element.type + Constant.pythen + Constant.pcActionGood).reset();
                      this.form.get(element.type + Constant.pythen + Constant.pcActionShare).reset();
                      this.form.get(element.type + Constant.pythen + Constant.pcActionNone).reset();
                    } else if (selfId === Constant.pcActionShare) {
                      this.form.get(element.type + Constant.pythen + Constant.pcActionGood).reset();
                      this.form.get(element.type + Constant.pythen + Constant.pcActionMore).reset();
                      this.form.get(element.type + Constant.pythen + Constant.pcActionNone).reset();
                    } else if (selfId === Constant.pcActionNone) {
                      this.form.get(element.type + Constant.pythen + Constant.pcActionGood).reset();
                      this.form.get(element.type + Constant.pythen + Constant.pcActionMore).reset();
                      this.form.get(element.type + Constant.pythen + Constant.pcActionShare).reset();
                    }
                  } else if (element.type === Constant.dcActionType) {
                    // アクションのチェックボックスは一つしか選択できないように他のチェックを外す
                    // 資料
                    if (selfId === Constant.dcActionGood) {
                      this.form.get(element.type + Constant.pythen + Constant.dcActionView).reset();
                      this.form.get(element.type + Constant.pythen + Constant.dcActionShare).reset();
                      this.form.get(element.type + Constant.pythen + Constant.dcActionNone).reset();
                    } else if (selfId === Constant.dcActionView) {
                      this.form.get(element.type + Constant.pythen + Constant.dcActionGood).reset();
                      this.form.get(element.type + Constant.pythen + Constant.dcActionShare).reset();
                      this.form.get(element.type + Constant.pythen + Constant.dcActionNone).reset();
                    } else if (selfId === Constant.dcActionShare) {
                      this.form.get(element.type + Constant.pythen + Constant.dcActionGood).reset();
                      this.form.get(element.type + Constant.pythen + Constant.dcActionView).reset();
                      this.form.get(element.type + Constant.pythen + Constant.dcActionNone).reset();
                    } else if (selfId === Constant.dcActionNone) {
                      this.form.get(element.type + Constant.pythen + Constant.dcActionGood).reset();
                      this.form.get(element.type + Constant.pythen + Constant.dcActionView).reset();
                      this.form.get(element.type + Constant.pythen + Constant.dcActionShare).reset();
                    }
                  } else {
                    this.form.get(element.type + Constant.pythen + item.id).disable();
                  }
                }
              }
            });
          }
        });
      }
    });

    // 求人アクションの期間活性、対応状況活性
    if (conditionTitle === Constant.tlSearchCategoryOfferAction) {
      this.checkJobOfferActionPeriod();

    // 記事アクションの期間活性
    } else if (conditionTitle === Constant.tlSearchCategoryContents) {
      this.checkContentsActionPeriod();

    // 資料アクションの期間活性
    } else if (conditionTitle === Constant.tlSearchCategoryDocument) {
      this.checkDocumentActionPeriod();

    // 経験職種、直近企業のチェック活性
    } else if (targetTypes.indexOf(Constant.tlExpJobLatestType) >= 0) {
      this.changeExpJobCheckState();
    }

    // 文理タイプの選択時は中退も含める
    if (targetTypes.indexOf(Constant.tlBunriType) >= 0) {
      if (enable) {
        this.form.get(Constant.tlExceptDropoutType).enable();
      } else {
        this.form.get(Constant.tlExceptDropoutType).disable();
      }
    }
  }

  changeJobofferActionCheckState(enable, selfId) {
    if (enable) {
      // アクションのチェックボックスは一つしか選択できないように他のチェックを外す(一度全部外して再設定する)
      this.form.get(Constant.offerActionType + Constant.pythen + Constant.offerActionApply).reset()
      this.form.get(Constant.offerActionType + Constant.pythen + Constant.offerActionMind).reset()
      this.form.get(Constant.offerActionType + Constant.pythen + Constant.offerActionShare).reset()
      this.form.get(Constant.offerActionType + Constant.pythen + Constant.offerActionView).reset()
      this.form.get(Constant.offerActionType + Constant.pythen + Constant.offerActionNone).reset()

      this.form.get(Constant.offerActionType + Constant.pythen + selfId).setValue(true)
    }

    const conditionTitle = Constant.tlSearchCategoryOfferAction;
    const targetTypes = [Constant.offerReactType];
    // 応募または気になるにチェックが入っている場合のみ、対応状況を有効にする
    if (this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionApply]
      || this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionMind]) {
        this.changeOtherCheckState(true, conditionTitle, targetTypes, 0);
    } else {
      this.changeOtherCheckState(false, conditionTitle, targetTypes, 0);
    }

    // 期間有効無効
    this.checkJobOfferActionPeriod();
  }

  // 資料アクションのチェックボックス変更
  changeDocumentActionCheckState(enable, selfId) {
    if (enable) {
      // アクションのチェックボックスは一つしか選択できないように他のチェックを外す(一度全部外して再設定する)
      this.form.get(Constant.dcActionType + Constant.pythen + Constant.dcActionGood).reset()
      this.form.get(Constant.dcActionType + Constant.pythen + Constant.dcActionShare).reset()
      this.form.get(Constant.dcActionType + Constant.pythen + Constant.dcActionView).reset()
      this.form.get(Constant.dcActionType + Constant.pythen + Constant.dcActionNone).reset()

      this.form.get(Constant.dcActionType + Constant.pythen + selfId).setValue(true)
    }

    // 閲覧にチェックが入っている場合のみ、読了率を有効にする
    if (this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionView]) {
      this.form.get(Constant.tlDocumentReadStartType).enable()
      this.form.get(Constant.tlDocumentReadEndType).enable()
    } else {
      this.form.get(Constant.tlDocumentReadStartType).disable()
      this.form.get(Constant.tlDocumentReadEndType).disable()
    }

    // 期間有効無効
    this.checkDocumentActionPeriod();
  }

  changeContactTagCheckState() {
    this.isCheckCondContactTagAny = false;
    for (const key in this.form.value) {
      if (key.indexOf(Constant.tlContactTagType) !== -1) {
        if (this.form.value[key] && key !== Constant.tlContactTagType + '-' + Constant.tlContactTagNullId) {
          this.isCheckCondContactTagAny = true;
          break;
        }
      }
    }
    this.checkContactTagPeriod();
  }

  changeExpJobCheckState() {
    this.isCheckExpJobAny = false;
    for (const key in this.form.value) {
      if (key.indexOf(Constant.tlExpJobType) !== -1) {
        if (this.form.value[key] && key !== Constant.tlExpJobType + '-' + Constant.tlExpJobTypeNone) {
          this.isCheckExpJobAny = true;
          break;
        }
      }
    }
    this.checkExpJobLatest();
  }

  // 自己登録or手動/CSVのチェックボックス
  changeRegistyTypeCheckState(enable, selfId) {
    if (enable) {
      if (selfId === 1) {
        this.form.get(Constant.tlTalentRegistType + Constant.pythen + '0').reset();
      } else if(selfId === 0) {
        this.form.get(Constant.tlTalentRegistType + Constant.pythen + '1').reset();
      }
    }
  }

  // 条件クリアボタンクリック時
  onSearchConditionClear() {
    // GAイベント
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionSearchClear);

    this.form.reset();
    this.condition = [];
    // 求人 アクションを有効化
    let conditionTitle = Constant.tlSearchCategoryOfferAction;
    let targetTypes = [Constant.offerActionType];
    let selfId = Constant.offerActionNone;
    this.changeOtherCheckState(true, conditionTitle, targetTypes, selfId);
    // 求人 対応状況を無効化
    targetTypes = [Constant.offerReactType];
    this.changeOtherCheckState(false, conditionTitle, targetTypes, selfId);
    // コンテンツ部分のチェックボックスを有効化
    conditionTitle = Constant.tlSearchCategoryContents;
    targetTypes = [Constant.pcActionType];
    selfId = Constant.pcActionNone;
    this.changeOtherCheckState(true, conditionTitle, targetTypes, selfId);
    // 資料アクション期間のチェックボックスを無効化
    conditionTitle = Constant.tlSearchCategoryDocument;
    targetTypes = [Constant.dcActionType];
    selfId = Constant.dcActionNone;
    this.changeOtherCheckState(true, conditionTitle, targetTypes, selfId);
    // 資料 読了率を無効化
    this.changeDocumentActionCheckState(false, 0)
    // 接点タグのチェックボックスを有効化
    conditionTitle = Constant.tlSearchCategoryContactTag;
    targetTypes = [Constant.tlContactTagType, Constant.tlContactTagPeriodType];
    selfId = Constant.tlContactTagNullId;
    this.changeOtherCheckState(true, conditionTitle, targetTypes, selfId);
    // 接点タグの期間は無効
    this.isCheckCondContactTagAny = false;
    this.checkContactTagPeriod();
    // 直近学校・文理タイプを有効化
    conditionTitle = Constant.tlSearchCategoryHistory;
    targetTypes = [Constant.tlSchoolType, Constant.tlBunriType];
    selfId = Constant.tlSchoolTypeNone;
    this.changeOtherCheckState(true, conditionTitle, targetTypes, selfId);
    // 経験職種を有効化
    conditionTitle = Constant.tlSearchCategoryHistory;
    targetTypes = [Constant.tlExpJobType, Constant.tlExpJobLatestType];
    selfId = Constant.tlExpJobTypeNone;
    this.changeOtherCheckState(true, conditionTitle, targetTypes, selfId);
    // 経験職種最新のみを無効化
    this.isCheckExpJobAny = false;
    this.checkExpJobLatest();

    // 一斉メッセージ除外フラグ
    if (this.isMsgExceptFlg) {
      this.disabledMsgExceptCondition();
      this.form.get(Constant.tlMultiMsgExceptType + Constant.pythen + 0).setValue(true);
      this.form.get(Constant.tlTalentRegistType + Constant.pythen + this.msgSelectTalentType).setValue(true);
      this.form.get(Constant.tlDataStatusType + Constant.pythen + Constant.tlDataStatusTypeIdUnsubscribe).setValue(true);
    }
  }

  // 期間選択用
  getMaxDate(date) {
    return date ? date : new Date();
  }
  getMinDate(date) {
    return date ? date : new Date(2018, 0, 1);
  }

  // バリデーションチェック用
  get activitiesStartYear() {
    return this.form.get(Constant.tlActivitiesStartYearType);
  }
  get activitiesStartMonth() {
    return this.form.get(Constant.tlActivitiesStartMonthType);
  }
  get activitiesEndYear() {
    return this.form.get(Constant.tlActivitiesEndYearType);
  }
  get activitiesEndMonth() {
    return this.form.get(Constant.tlActivitiesEndMonthType);
  }

  private getHeaderInfo() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.searching = false;
      return;
    }
    Auth.currentSession().then(session => {
      const apiPath = '/talentlistcondition';
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiPost(apiPath, options)
        .then(res => {
          this.headers = res.data;
          this.commonUtil.debug().log(this.headers);
          this.searching = false;
          this.createFormControl();
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.searchTalent.emit(false);
          this.searching = false;
          if (this.commonUtil.apiNetworkError(err.message)) {
            this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          }
        });
    });
  }

  private createFormControl() {
    this.searchTabTitles = [];
    const conditionControl = {};
    this.headers.forEach(element => {
      this.searchTabTitles.push(element.title);
      this.searchTabCheck.push(false);
      element.master_list.forEach(list => {
        if (list.type === Constant.tlGraduateYearType) {
          let selectItem = this.checkDefaultVaue(Constant.tlGraduateStartYearType);
          if (selectItem === '') {
            conditionControl[Constant.tlGraduateStartYearType] = new FormControl();
          } else {
            conditionControl[Constant.tlGraduateStartYearType] = new FormControl(Number(selectItem));
          }
          selectItem = this.checkDefaultVaue(Constant.tlGraduateEndYearType);
          if (selectItem === '') {
            conditionControl[Constant.tlGraduateEndYearType] = new FormControl();
          } else {
            conditionControl[Constant.tlGraduateEndYearType] = new FormControl(Number(selectItem));
          }

          let keyString = '';
          let selected = false;
          list.data.forEach(data => {
            if (data.id < Constant.tlGraduateYearMin) {
              keyString = list.type + Constant.pythen + data.id;
              selected = this.checkDefaultCondition(keyString);
              conditionControl[keyString] = new FormControl(selected);
            }
          });
        } else if (list.type === Constant.tlAddQuestionType) {
          list.data.forEach(data => {
            let keyString = '';
            let selected = false;
            data.select_item.forEach(item => {
              keyString = list.type + Constant.pythen + data.question_id + Constant.pythen + item.id;
              selected = this.checkDefaultConditionAddProf(keyString);
              conditionControl[keyString] = new FormControl(selected);
            });
          });

        } else {
          list.data.forEach(data => {
            let keyString = '';
            let selected = false;
            if ((list.kind === Constant.tlSearchKindJobOfferTitle) ||
              (list.kind === Constant.tlSearchKindJobPlace)) {
              // 求人タイトル or コンテンツタイトル or 勤務地
              data.data.forEach(offer => {
                keyString = list.type + Constant.pythen + data.id + Constant.pythen + offer.id;
                selected = this.checkDefaultCondition(keyString);
                conditionControl[keyString] = new FormControl(selected);
              });
              // 求人ステータス or コンテンツステータス
              keyString = data.type + Constant.pythen + data.id;
            } else if (list.kind === Constant.tlSearchKindJobPlace) {
              // 勤務地
              data.data.forEach(offer => {
                keyString = list.type + Constant.pythen + data.id + Constant.pythen + offer.id;
                selected = this.checkDefaultCondition(keyString);
                conditionControl[keyString] = new FormControl(selected);
              });
            } else {
              keyString = list.type + Constant.pythen + data.id;
            }
            selected = this.checkDefaultCondition(keyString);
            conditionControl[keyString] = new FormControl(selected);
          });
        }
      });
    });
    conditionControl[Constant.tlActivitiesStartYearType] = new FormControl(
      this.checkDefaultVaue(Constant.tlActivitiesStartYearType),
      [Validators.min(2018)]
    );
    conditionControl[Constant.tlActivitiesStartMonthType] = new FormControl(
      this.checkDefaultVaue(Constant.tlActivitiesStartMonthType),
      [Validators.min(1), Validators.max(12), Validators.maxLength(2)]
    );
    conditionControl[Constant.tlActivitiesEndYearType] = new FormControl(
      this.checkDefaultVaue(Constant.tlActivitiesEndYearType),
      [Validators.min(2018)]
    );
    conditionControl[Constant.tlActivitiesEndMonthType] = new FormControl(
      this.checkDefaultVaue(Constant.tlActivitiesEndMonthType),
      [Validators.min(1), Validators.max(12), Validators.maxLength(2)]
    );
    // タレント氏名
    conditionControl[Constant.tlTalentNameType] = new FormControl(
      this.checkDefaultVaue(Constant.tlTalentNameType)
    );
    // 年齢 from
    conditionControl[Constant.tlTalentAgeFromType] = new FormControl(
      this.checkDefaultVaue(Constant.tlTalentAgeFromType)
    );
    // 年齢 to
    conditionControl[Constant.tlTalentAgeToType] = new FormControl(
      this.checkDefaultVaue(Constant.tlTalentAgeToType)
    );
    // 年齢　未登録
    conditionControl[Constant.tlTalentAgeTypeNone] = new FormControl(
      this.checkDefaultVaue(Constant.tlTalentAgeTypeNone)
    );
    // 希望年収 from
    conditionControl[Constant.tlDesireIncomeFromType] = new FormControl(
      this.checkDefaultVaue(Constant.tlDesireIncomeFromType)
    );
    // 希望年収 to
    conditionControl[Constant.tlDesireIncomeToType] = new FormControl(
      this.checkDefaultVaue(Constant.tlDesireIncomeToType)
    );
    // 希望年収　未登録
    conditionControl[Constant.tlDesireIncomeTypeNone] = new FormControl(
      this.checkDefaultVaue(Constant.tlDesireIncomeTypeNone)
    );
    // 資料読了率 start
    conditionControl[Constant.tlDocumentReadStartType] = new FormControl(
      this.checkDefaultVaue(Constant.tlDocumentReadStartType)
    );
    // 資料読了率 end
    conditionControl[Constant.tlDocumentReadEndType] = new FormControl(
      this.checkDefaultVaue(Constant.tlDocumentReadEndType)
    );
    // 記事　アクション期間
    conditionControl[Constant.tlContentsActionStartType] = new FormControl();
    this.conPeriod.start = this.checkDefaultDate(Constant.tlContentsActionStartType);
    conditionControl[Constant.tlContentsActionEndType] = new FormControl();
    this.conPeriod.end = this.checkDefaultDate(Constant.tlContentsActionEndType);
    // 資料　アクション期間
    conditionControl[Constant.tlDocumentActionStartType] = new FormControl();
    this.docPeriod.start = this.checkDefaultDate(Constant.tlDocumentActionStartType);
    conditionControl[Constant.tlDocumentActionEndType] = new FormControl();
    this.docPeriod.end = this.checkDefaultDate(Constant.tlDocumentActionEndType);
    // 求人　アクション期間
    conditionControl[Constant.tlJobOfferActionStartType] = new FormControl();
    this.jobPeriod.start = this.checkDefaultDate(Constant.tlJobOfferActionStartType);
    conditionControl[Constant.tlJobOfferActionEndType] = new FormControl();
    this.jobPeriod.end = this.checkDefaultDate(Constant.tlJobOfferActionEndType);
    // タレントプール登録日
    conditionControl[Constant.tlRegisteredDateStartType] = new FormControl();
    this.pooledPeriod.start = this.checkDefaultDate(Constant.tlRegisteredDateStartType);
    conditionControl[Constant.tlRegisteredDateEndType] = new FormControl();
    this.pooledPeriod.end = this.checkDefaultDate(Constant.tlRegisteredDateEndType);
    // 接点　期間
    conditionControl[Constant.tlContactTagStartType] = new FormControl();
    this.tagPeriod.start = this.checkDefaultDate(Constant.tlContactTagStartType);
    conditionControl[Constant.tlContactTagEndType] = new FormControl();
    this.tagPeriod.end = this.checkDefaultDate(Constant.tlContactTagEndType);

    // 中退を除く
    conditionControl[Constant.tlExceptDropoutType] = new FormControl(
      this.checkDefaultVaue(Constant.tlExceptDropoutType)
    );

    this.form = new FormGroup(conditionControl);

    // チェックボックスの活性状態制御
    // 求人アクション「応募」「気になる」にチェックが入っていなかった場合、対応状況は非活性
    if (!this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionApply]
       && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionMind]) {
      const conditionTitle = Constant.tlSearchCategoryOfferAction;
      const targetTypes = [Constant.offerReactType];
      this.changeOtherCheckState(false, conditionTitle, targetTypes, Constant.offerActionView);
    }

    // 求人アクションにチェックが入っていなかった場合、期間は非活性
    this.checkJobOfferActionPeriod();

    // 記事アクションにチェックが入っていなかった場合、期間は非活性
    this.checkContentsActionPeriod();

    // 資料アクションにチェックが入っていなかった場合、期間は非活性
    this.checkDocumentActionPeriod();

    // 資料アクション「閲覧」にチェックが入っていなかった場合、読了率は非活性
    if (!this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionView]) {
      this.form.get(Constant.tlDocumentReadStartType).disable()
      this.form.get(Constant.tlDocumentReadEndType).disable()
   }

    // 接点タグ未登録にチェックが入っている場合
    if (this.form.value[Constant.tlContactTagType + Constant.pythen + Constant.tlContactTagNullId]) {
      const conditionTitle = Constant.tlSearchCategoryContactTag;
      const targetTypes = [Constant.tlContactTagType, Constant.tlContactTagPeriodType];
      const selfId = Constant.tlContactTagNullId;
      this.changeOtherCheckState(false, conditionTitle, targetTypes, selfId);
    }

    // 接点タグ未登録以外にチェックが入っていた場合のみ、期間入力可
    this.checkContactTagPeriod();

    // 直近学校未登録にチェックが入っている場合
    if (this.form.value[Constant.tlSchoolType + Constant.pythen + Constant.tlSchoolTypeNone]) {
      const conditionTitle = Constant.tlSearchCategoryHistory;
      const targetTypes = [Constant.tlSchoolType, Constant.tlBunriType];
      const selfId = Constant.tlSchoolTypeNone;
      this.changeOtherCheckState(false, conditionTitle, targetTypes, selfId);
    }

    // 経験職種未登録にチェックが入っている場合
    if (this.form.value[Constant.tlExpJobType + Constant.pythen + Constant.tlExpJobTypeNone]) {
      const conditionTitle = Constant.tlSearchCategoryHistory;
      const targetTypes = [Constant.tlExpJobType, Constant.tlExpJobLatestType];
      const selfId = Constant.tlExpJobTypeNone;
      this.changeOtherCheckState(false, conditionTitle, targetTypes, selfId);
      this.isCheckExpJobAny = false;
    }

    // 経験職種最新のみのチェックボックス制御
    this.checkExpJobLatest();

    // 一斉メッセージ選択の場合、フラグのチェックボックス制御
    if (this.isMsgExceptFlg) {
      this.disabledMsgExceptCondition();
    }

    // 初回検索
    setTimeout(() => {
      this.execSearch();
    });
  }

// チェックボックスのデフォルト値を取得する
  private checkDefaultCondition(keyString: string): boolean {
    // ハイフンで分割
    const typeId = keyString.split(Constant.pythen);

    const result = this.condition.filter(function (item) {
      let index = 1;
      if (item.type === Constant.offerTitleType || item.type === Constant.pcTitleType || item.type === Constant.dcTitleType
      || item.type === Constant.joJobPlaceType) {
        index = 2;
      }
      return item.type === typeId[0] && item.id === Number(typeId[index]);
    });
    if (result.length > 0) {
      // 接点タグ未登録以外
      if (result[0].type === Constant.tlContactTagType && result[0].id !== Constant.tlContactTagNullId) {
        this.isCheckCondContactTagAny = true;
      }

      // 経験職種未登録以外
      if (result[0].type === Constant.tlExpJobType && result[0].id !== Constant.tlExpJobTypeNone) {
        this.isCheckExpJobAny = true;
      }

      return true;
    } else {
      return false;
    }
  }

  // フリー入力項目のデフォルト値を取得する
  private checkDefaultVaue(keyString: string): string {
    let defaultValue = '';
    const result = this.condition.filter(function (item) {
      return item.type === keyString;
    });
    if (result.length > 0) {
      defaultValue = String(result[0].id);
    }
    return defaultValue;
  }

  // 日付入力のデフォルト値を取得する
  private checkDefaultDate(keyString: string) {
    let defaultDate = null;
    const result = this.condition.filter(function (item) {
      return item.type === keyString;
    });
    if (result.length > 0) {
      defaultDate = new Date(result[0].id);
    }
    return defaultDate;
  }

  // 追加プロフのデフォルト値を取得する
  private checkDefaultConditionAddProf(keyString: string): boolean {
    // ハイフンで分割
    const typeId = keyString.split(Constant.pythen);

    const result = this.condition.filter(function (item) {
      return item.type === typeId[0] && item.id === typeId[1] + Constant.pythen + typeId[2];
    });
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  /*
   ** 入力された検索条件からAPIのパラメータを作成する
   */
  private createSearchConditoins(): boolean {
    this.toastUtil.clearAllShowingToast();
    let errMsg: { title?: string; message?: string; } = {};
    const newCond = [];
    if (this.form) {
      this.commonUtil.debug().log(this.form.value);
      this.commonUtil.debug().log(this.form.controls);
      for (const key in this.form.controls) {
        if (typeof this.form.controls[key].value === 'boolean') {
          // チェックボックスの検索条件
          if (!this.form.controls[key].value ||
            (this.form.controls[key].disabled
              && key !== Constant.tlMultiMsgExceptType + '-0'
              && key !== Constant.tlMultiMsgExceptType + '-1'
              && key !== Constant.tlTalentRegistType + Constant.pythen + Constant.tlRegistTypeTalent
              && key !== Constant.tlTalentRegistType + Constant.pythen + Constant.tlRegistTypeCsv
              && key !== Constant.tlDataStatusType + Constant.pythen + Constant.tlDataStatusTypeIdUnsubscribe)) {
            // チェックが入っていないもの、disabled（メッセージ除外関連以外）は弾く
            continue;
          }
          let type = '';
          let id;
          const values = key.split(Constant.pythen);
          type = values[0];
          if (type === Constant.offerTitleType || type === Constant.pcTitleType || type === Constant.dcTitleType
          || type === Constant.joJobPlaceType) {
            // 求人・コンテンツのアクション選択チェック
            errMsg = this.checkSearchParamAction(type);
            if (errMsg.title) {
              break;
            }
            id = Number(values[2]);
          } else if (type === Constant.tlAddQuestionType) {
            id = values[1] + Constant.pythen + values[2]

          } else {
            id = Number(values[1]);
          }
          const condition: TalentListCondition = {
            type: type,
            id: id
          };
          newCond.push(condition);
        } else if (typeof this.form.controls[key].value === 'string') {
          if (this.form.controls[key].value === '') {
            continue;
          }
          if (key === Constant.tlTalentNameType) {
            // タレント名
            const includeEmptyValue = String(this.form.controls[key].value);
            // スペースが含まれていた場合は削除する
            const paramValue = includeEmptyValue.replace(/\s+/g, '');
            const condition: TalentListCondition = {
              type: key,
              id: paramValue
            };
            newCond.push(condition);
          } else if (key === Constant.tlTalentAgeFromType || key === Constant.tlTalentAgeToType) {
            // 年齢
            errMsg = this.checkFromToCondition(key, Constant.tlTalentAgeFromType, Constant.tlTalentAgeToType, Constant.titleAge);
            if (errMsg.title) {
              // 不正な入力値が入力されていた場合はその時点で終了
              break;
            }
            const condition: TalentListCondition = {
              type: key,
              id: this.form.controls[key].value
            };
            newCond.push(condition);
          } else if (key === Constant.tlDocumentReadStartType || key === Constant.tlDocumentReadEndType) {
              // 読了率
              errMsg = this.checkFromToCondition(key, Constant.tlDocumentReadStartType, Constant.tlDocumentReadEndType, Constant.titleDcRead);
              if (errMsg.title) {
                // 不正な入力値が入力されていた場合はその時点で終了
                break;
              }
              const condition: TalentListCondition = {
                type: key,
                id: Number(this.form.controls[key].value)
              };
              newCond.push(condition);
          } else if (key === Constant.tlDesireIncomeFromType || key === Constant.tlDesireIncomeToType) {
            // 希望年収
            errMsg = this.checkFromToCondition(key, Constant.tlDesireIncomeFromType, Constant.tlDesireIncomeToType, '希望年収');
            if (errMsg.title) {
              // 不正な入力値が入力されていた場合はその時点で終了
              break;
            }
            const condition: TalentListCondition = {
              type: key,
              id: this.form.controls[key].value
            };
            newCond.push(condition);

          } else {
            // 最終学歴卒業年・就業/転職活動予定時期
            // 入力値の妥当性チェック
            errMsg = this.checkCommonSearchParam(key);
            if (errMsg.title) {
              // 不正な入力値が入力されていた場合はその時点で終了
              break;
            }
            // 入力された下限・上限の妥当性をチェック
            if (key.indexOf('_END_') > 0 && key.indexOf('_YEAR_') > 0) {
              errMsg = this.checkSearchParamRange(key);
            }
            if (errMsg.title) {
              // 不正な入力値が入力されていた場合はその時点で終了
              // this.showSpinner = false;
              break;
            }
            // 年と月がセットで登録されているかもチェックする
            errMsg = this.checkDependSearchParam(key);
            if (errMsg.title) {
              // 年と月が両方入力されていなかった場合はその時点で終了
              break;
            }

            const condition: TalentListCondition = {
              type: key,
              id: Number(this.form.controls[key].value)
            };
            newCond.push(condition);
          }
        } else if (typeof this.form.controls[key].value === 'number') {
          // 新卒採用枠（卒業年）
          const condition: TalentListCondition = {
            type: key,
            id: this.form.controls[key].value
          };
          newCond.push(condition);
        } else if (key === Constant.tlContentsActionStartType || key === Constant.tlContentsActionEndType
           || key === Constant.tlDocumentActionStartType || key === Constant.tlDocumentActionEndType
           || key === Constant.tlJobOfferActionStartType || key === Constant.tlJobOfferActionEndType
           || key === Constant.tlRegisteredDateStartType || key === Constant.tlRegisteredDateEndType
           || key === Constant.tlContactTagStartType || key === Constant.tlContactTagEndType) {
             if (this.form.controls[key].value) {
              const condition: TalentListCondition = {
                type: key,
                id: moment(this.form.controls[key].value).format('YYYY-MM-DD')
              };
              newCond.push(condition);
             }
        }
      }
    }
    let result = true;
    if (errMsg.title) {
      result = false;
      setTimeout(() => {
        this.toastUtil.showErrorToast(errMsg['title'], errMsg['message'], Constant.toastShowMiliSec);
      }, 300);
    } else {
      this.condition = newCond;
    }
    this.commonUtil.debug().log(this.condition);

    return result;
  }

  // 検索時の入力値をチェックし、エラーを表示する
  private checkCommonSearchParam(key: string): { title?: string; message?: string; } {
    const errorMsg: { title?: string; message?: string; } = {};
    const paramLabelDic = {
      ACTIVITIES_START_YEAR_TYPE: '就業/転職活動予定時期(開始-年)：',
      ACTIVITIES_START_MONTH_TYPE: '就業/転職活動予定時期(開始-/月)：',
      ACTIVITIES_END_YEAR_TYPE: '就業/転職活動予定時期(終了-年)：',
      ACTIVITIES_END_MONTH_TYPE: '就業/転職活動予定時期(終了-月)：',
      TALENT_AGE_FROM_TYPE: '年齢(下限)：',
      TALENT_AGE_TO_TYPE: '年齢(上限)：',
      DESIRE_INCOME_FROM_TYPE: '希望年収(下限)：',
      DESIRE_INCOME_TO_TYPE: '希望年収(上限)：',
      DOCUMENT_READ_START_TYPE: '読了率(下限)：',
      DOCUMENT_READ_END_TYPE: '読了率(上限)：',
    };
    // 共通のチェック
    if (!String(this.form.value[key]).match(/^-?[0-9]+$/)) {
      // 数字以外の値が入力(e,-,.など)されてた場合
      errorMsg['title'] = paramLabelDic[key];
      errorMsg['message'] = Constant.msgInputSingleByteInteger;
    } else if (key === Constant.tlDesireIncomeFromType || key === Constant.tlDesireIncomeToType
      || key === Constant.tlDocumentReadStartType || key === Constant.tlDocumentReadEndType
    ) {
      // 年収・読了率は0未満不可
      if (this.form.value[key] < 0) {
        errorMsg['title'] = paramLabelDic[key];
        errorMsg['message'] = Constant.msgInputZeroAndMoreInteger;
      }
    } else if (this.form.value[key] < 1) {
      // その他は1未満不可
      errorMsg['title'] = paramLabelDic[key];
      errorMsg['message'] = Constant.msgInputMoreZeroInteger;
    }
    return errorMsg;
  }

  private checkSearchParamRange(key: string): { title?: string; message?: string; } {
    const errorMsg: { title?: string; message?: string; } = {};
    // 就業/転職活動予定時期
    if (key === Constant.tlActivitiesEndYearType) {
      const startDate =
        this.form.value[Constant.tlActivitiesStartYearType] +
        ('00' + this.form.value[Constant.tlActivitiesStartMonthType]).slice(-2);
      const endDate =
        this.form.value[Constant.tlActivitiesEndYearType] +
        ('00' + this.form.value[Constant.tlActivitiesEndMonthType]).slice(-2);
      if (Number(startDate) > Number(endDate)) {
        errorMsg['title'] = Constant.titleActivities;
        errorMsg['message'] = '開始と終了の時系列が逆です。';
      }
    }
    return errorMsg;
  }

  // 求人・コンテンツタイトル選択時のアクション選択チェック
  private checkSearchParamAction(type: string): { title?: string; message?: string; } {
    const errorMsg: { title?: string; message?: string; } = {};
    // 求人アクション
    if (type === Constant.offerTitleType) {
      if ( !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionApply]
        && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionMind]
        && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionShare]
        && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionView]
        && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionNone] ) {
          errorMsg['title'] = Constant.titleOfferAction;
          errorMsg['message'] = Constant.msgInputCheck;
      }
    // 記事アクション
    } else if (type === Constant.pcTitleType) {
      if ( !this.form.value[Constant.pcActionType + Constant.pythen + Constant.pcActionGood]
        && !this.form.value[Constant.pcActionType + Constant.pythen + Constant.pcActionMore]
        && !this.form.value[Constant.pcActionType + Constant.pythen + Constant.pcActionShare]
        && !this.form.value[Constant.pcActionType + Constant.pythen + Constant.pcActionNone]) {
          errorMsg['title'] = Constant.titlePcAction;
          errorMsg['message'] = Constant.msgInputCheck;
      }
    // 資料アクション
    } else if (type === Constant.dcTitleType) {
      if ( !this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionGood]
        && !this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionView]
        && !this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionShare]
        && !this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionNone]) {
          errorMsg['title'] = Constant.titleDcAction;
          errorMsg['message'] = Constant.msgInputCheck;
      }
    }
    return errorMsg;
  }

  // 年と月の入力妥当性のチェック
  private checkDependSearchParam(key: string): { title?: string; message?: string; } {
    const errorMsg: { title?: string; message?: string; } = {};
    // 就業/転職活動予定時期は年と月がセット
    switch (key) {
      case Constant.tlActivitiesStartYearType:
      case Constant.tlActivitiesStartMonthType:
        // 開始
        // 年と月が両方入力されているかどうか
        if (
          (this.form.value[Constant.tlActivitiesStartYearType] > 0 &&
            !this.form.value[Constant.tlActivitiesStartMonthType]) ||
          (!this.form.value[Constant.tlActivitiesStartYearType] &&
            this.form.value[Constant.tlActivitiesStartMonthType] > 0)
        ) {
          errorMsg['title'] = Constant.titleActivitiesStart;
          errorMsg['message'] = Constant.msgInputYearAndMonth;
        }
        break;
      case Constant.tlActivitiesEndYearType:
      case Constant.tlActivitiesEndMonthType:
        // 終了
        if (
          (this.form.value[Constant.tlActivitiesEndYearType] > 0 &&
            !this.form.value[Constant.tlActivitiesEndMonthType]) ||
          (!this.form.value[Constant.tlActivitiesEndYearType] &&
            this.form.value[Constant.tlActivitiesEndMonthType] > 0)
        ) {
          errorMsg['title'] = Constant.titleActivitiesEnd;
          errorMsg['message'] = Constant.msgInputYearAndMonth;
        }
        break;
    }
    return errorMsg;
  }

  // 検索条件 From To の入力値チェック
  private checkFromToCondition(key: string, fromName: string, toName: string, errorTitle: string) {
    let errorMsg: { title?: string; message?: string; } = {};
    // 入力値の妥当性チェック
    errorMsg = this.checkCommonSearchParam(key);
    if (errorMsg.title) {
      return errorMsg;
    }

    if (!this.form.value[fromName] || !this.form.value[toName]) {
      // 上限か下限どちらかが入力されていない場合はここで終了
      return errorMsg;
    }

    // 上限と下限のチェック
    const from = Number(this.form.value[fromName]);
    const to = Number(this.form.value[toName]);
    if (from > to) {
      errorMsg['title'] = errorTitle;
      errorMsg['message'] = Constant.msgInputMinBiggerThanMax;
      return errorMsg;
    }
    return errorMsg;
  }

  // 求人期間の有効無効判定
  private checkJobOfferActionPeriod() {
    if ( !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionApply]
      && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionMind]
      && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionShare]
      && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionView]
      && !this.form.value[Constant.offerActionType + Constant.pythen + Constant.offerActionNone] ) {
        this.form.get(Constant.tlJobOfferActionStartType).disable();
        this.form.get(Constant.tlJobOfferActionEndType).disable();
    } else {
      this.form.get(Constant.tlJobOfferActionStartType).enable();
      this.form.get(Constant.tlJobOfferActionEndType).enable();
    }
  }

  // コンテンツ期間の有効無効判定
  private checkContentsActionPeriod() {
    if ( !this.form.value[Constant.pcActionType + Constant.pythen + Constant.pcActionGood]
      && !this.form.value[Constant.pcActionType + Constant.pythen + Constant.pcActionMore]
      && !this.form.value[Constant.pcActionType + Constant.pythen + Constant.pcActionShare]
      && !this.form.value[Constant.pcActionType + Constant.pythen + Constant.pcActionNone]) {
        this.form.get(Constant.tlContentsActionStartType).disable();
        this.form.get(Constant.tlContentsActionEndType).disable();
    } else {
      this.form.get(Constant.tlContentsActionStartType).enable();
      this.form.get(Constant.tlContentsActionEndType).enable();
    }
  }

  // 資料期間の有効無効判定
  private checkDocumentActionPeriod() {
    if ( !this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionGood]
      && !this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionView]
      && !this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionShare]
      && !this.form.value[Constant.dcActionType + Constant.pythen + Constant.dcActionNone]) {
        this.form.get(Constant.tlDocumentActionStartType).disable();
        this.form.get(Constant.tlDocumentActionEndType).disable();
    } else {
      this.form.get(Constant.tlDocumentActionStartType).enable();
      this.form.get(Constant.tlDocumentActionEndType).enable();
    }
  }

  private checkContactTagPeriod() {
    if (this.isCheckCondContactTagAny) {
        this.form.get(Constant.tlContactTagStartType).enable();
        this.form.get(Constant.tlContactTagEndType).enable();
    } else {
      this.form.get(Constant.tlContactTagStartType).disable();
      this.form.get(Constant.tlContactTagEndType).disable();
    }
  }

  private checkExpJobLatest() {
    if (this.isCheckExpJobAny) {
        this.form.get(Constant.tlExpJobLatestType + '-1').enable();
    } else {
      this.form.get(Constant.tlExpJobLatestType + '-1').disable();
    }
  }

  private disabledMsgExceptCondition() {
    this.form.get(Constant.tlMultiMsgExceptType + Constant.pythen + 0).disable();
    this.form.get(Constant.tlMultiMsgExceptType + Constant.pythen + 1).disable();
    this.form.get(Constant.tlTalentRegistType + Constant.pythen + Constant.tlRegistTypeTalent).disable();
    this.form.get(Constant.tlTalentRegistType + Constant.pythen + Constant.tlRegistTypeCsv).disable();
    this.form.get(Constant.tlDataStatusType + Constant.pythen + Constant.tlDataStatusTypeIdUnsubscribe).disable();
  }

  private execSearch() {
    // 検索条件作成
    if (!this.createSearchConditoins()) {
      this.searchTalent.emit(false);
      return;
    }

    // 並び順
    const condition: TalentListCondition = {
      type: this.orderType,
      id: 1
    };
    this.condition.push(condition);

    // 親コンポーネントでリスト取得
    this.searchTalent.emit(this.condition);

    for (let i = 0; i < this.searchTabCheck.length; i++) {
      this.searchTabCheck[i] = false;
    }
  }
}
