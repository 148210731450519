<mat-dialog-content>
  <div class="content">
    <div class="spinner center" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <div class="header" *ngIf="!showSpinner">
      <ng-container *ngIf="mode == Constant.joTypeEdit">
        <div class="header-left" *ngIf="prContent.release_status == Constant.pcReleaseStatusNoRelease">
          <div class="release_status default">
            未公開
          </div>
        </div>
        <div class="header-left" *ngIf="prContent.release_status == Constant.pcReleaseStatusRelease">
          <div class="release_status released">
            公開中
          </div>
        </div>
        <div class="header-left" *ngIf="prContent.release_status == Constant.pcReleaseStatusStop">
          <div class="release_status stopped">
            非公開
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="mode == Constant.joTypeAdd">
        <div class="release_status new">新規作成</div>
      </ng-container>
    </div>
    <form [formGroup]="form" *ngIf="form">
      <div class="label">
        <img src="assets/notice/mark_blue.png" srcset="
            assets/notice/mark_blue.png    1x,
            assets/notice/mark_blue@2x.png 2x,
            assets/notice/mark_blue@3x.svg 3x
          " /> 採用PRコンテンツ メイン画像<span class="normal"> {{ Constant.prefixFormatPngJpg }}、{{Constant.prefixImageTriming169 }}</span>
      </div>
      <div class="image-container">
        <div *ngIf="contentsImagePath == Constant.empty" class="noimage">
          <span>No Image</span>
        </div>
        <img *ngIf="contentsImagePath != Constant.empty" class="primage" [src]="contentsImagePath" />
        <div class="buttons">
          <div mat-icon-button (click)="imgFileInput.click()">
            <img src="assets/joboffer/image_upload.png" srcset="
                assets/joboffer/image_upload.png    1x,
                assets/joboffer/image_upload@2x.png 2x,
                assets/joboffer/image_upload@3x.svg 3x
              " />
            <input hidden type="file" #imgFileInput accept="image/png,image/jpeg" (change)="onChangeImage($event)" />
          </div>
          <div mat-icon-button (click)="onImageDelete()">
            <img src="assets/joboffer/image_delete.png" srcset="
                assets/joboffer/image_delete.png    1x,
                assets/joboffer/image_delete@2x.png 2x,
                assets/joboffer/image_delete@3x.svg 3x
              " />
          </div>
        </div>
      </div>
      <div class="titlearea">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> タイトル
          <span class="required">※必須</span>
        </div>
        <div class="label-count" [class.length-red]="title.value.length>maxLenTitle">{{ title.value.length }}/{{ maxLenTitle }}</div>
        <input
          class="input-text"
          formControlName="title"
          #title
          placeholder="タイトル"
          [maxlength]="maxLenTitle"
        />
      </div>
      <div class="descriptionarea">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> 本文
          <span class="required">※必須（100文字以上）</span> <span class="normal">※画像にリンク設定はできません。</span>
        </div>
        <div class="quill-area">
        <div id="description" class="label-count" *ngIf="editor && loadFlg" [class.length-red]="onEditorCount()>maxLenDescription">{{ onEditorCount() }}/{{ maxLenDescription }}</div>
        <quill-editor placeholder="本文" formControlName="description" [class.hidden]="!loadFlg"
        bounds="self" preserveWhitespace="true"
        [readOnly]="!loadFlg"
        (onEditorCreated)="onEditorCreated($event)"
        (onContentChanged)="onContentChanged($event)">
          <div quill-editor-toolbar>
            <span class="ql-formats">
              <button class="ql-bold" title="太字"></button>
              <button class="ql-strike" title="取り消し線"></button>
              <button class="ql-blockquote" title="引用/強調"></button>
              <button class="ql-list" value="bullet" title="箇条書き"></button>
              <button class="ql-list" value="ordered" title="段落番号"></button>
              <button class="ql-align left" value="" title="左揃え"></button>
              <button class="ql-align" value="center" title="中央揃え"></button>
              <button class="ql-align" value="right" title="右揃え"></button>
              <button class="ql-header" value="3" title="大見出し">大見出し</button>
              <button class="ql-header" value="4" title="小見出し">小見出し</button>
              <button class="ql-toc" title="目次の挿入" (click)="onEditorToc()">目次</button>
              <button class="ql-link" title="リンク設定"></button>
              <button class="ql-image" title="画像の挿入" (click)="onImageUpload()"></button>
              <button class="ql-video" title="動画(YouTube)の挿入"></button>
              <button class="ql-clean" title="書式のクリア"></button>
            </span>
          </div>
        </quill-editor>
        <div class="spinner" *ngIf="!loadFlg">
          <mat-spinner></mat-spinner>
        </div>
      </div>
      </div>
      <div class="tag-area">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> カテゴリー
        </div>
        <div class="tag-valuearea">
          <div class="tag-select-area">
            <mat-form-field floatLabel="never" class="select-value">
              <mat-select class="select-value" formControlName="category_id" placeholder="未登録">
                <mat-option [value]="">未登録</mat-option>
                <mat-option *ngFor="let cat of categoryMaster" [value]="cat.id">
                  {{ cat.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="tag-area">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> #タグ
        </div>
        <div class="tag-valuearea">
          <div class="tag-select-area">
            <mat-form-field floatLabel="never" class="select-value">
              <mat-select formControlName="tag1" placeholder="未登録" (selectionChange)="onTagChange()">
                <mat-option *ngFor="let tag of tag1Choices" [value]="tag.id">
                  @if (tag.tagcategory_title) {
                    {{ tag.tagcategory_title }} - {{ tag.title }}
                  } @else {
                    {{ tag.title }}
                  }
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="tag-select-area">
            <mat-form-field floatLabel="never" class="select-value">
              <mat-select formControlName="tag2" placeholder="未登録" (selectionChange)="onTagChange()">
                <mat-option *ngFor="let tag of tag2Choices" [value]="tag.id">
                  @if (tag.tagcategory_title) {
                    {{ tag.tagcategory_title }} - {{ tag.title }}
                  } @else {
                    {{ tag.title }}
                  }
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="tag-select-area">
            <mat-form-field floatLabel="never" class="select-value">
              <mat-select formControlName="tag3" placeholder="未登録" (selectionChange)="onTagChange()">
                <mat-option *ngFor="let tag of tag3Choices" [value]="tag.id">
                  @if (tag.tagcategory_title) {
                    {{ tag.tagcategory_title }} - {{ tag.title }}
                  } @else {
                    {{ tag.title }}
                  }
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="tag-select-area">
            <mat-form-field floatLabel="never" class="select-value">
              <mat-select formControlName="tag4" placeholder="未登録" (selectionChange)="onTagChange()">
                <mat-option *ngFor="let tag of tag4Choices" [value]="tag.id">
                  @if (tag.tagcategory_title) {
                    {{ tag.tagcategory_title }} - {{ tag.title }}
                  } @else {
                    {{ tag.title }}
                  }
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="tag-select-area">
            <mat-form-field floatLabel="never" class="select-value">
              <mat-select formControlName="tag5" placeholder="未登録" (selectionChange)="onTagChange()">
                <mat-option *ngFor="let tag of tag5Choices" [value]="tag.id">
                  @if (tag.tagcategory_title) {
                    {{ tag.tagcategory_title }} - {{ tag.title }}
                  } @else {
                    {{ tag.title }}
                  }
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="showSpinner || form.invalid || isSaving || onEditorCount()<100 || onEditorCount()>maxLenDescription || !loadFlg" class="yes-btn" *ngIf="mode == Constant.joTypeAdd">
    作成
  </button>
  <button mat-raised-button (click)="onSave()" [disabled]="showSpinner || form.invalid || isSaving || onEditorCount()<100 || onEditorCount()>maxLenDescription || !loadFlg" class="yes-btn" *ngIf="mode == Constant.joTypeEdit">
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
