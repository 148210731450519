import { Component, Inject } from '@angular/core';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { PdfviewerDialogComponent } from '../pdfviewer-dialog/pdfviewer-dialog.component';

@Component({
  selector: 'app-material-talent-dialog',
  templateUrl: './material-talent-dialog.component.html',
  styleUrl: './material-talent-dialog.component.css'
})
export class MaterialTalentDialogComponent {
  public material;
  public talentPv
  public readonly Constant = Constant;
  public showSpinner = true;
  public documentUrl = null;
  public documentTotalPage = 0;
  public documentPage = 1;

  constructor(
    public dialogRef: MatDialogRef<MaterialTalentDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.getDetail();
  }

  onClose() {
    this.dialogRef.close();
  }

  /* API */
  // 詳細取得
  private getDetail() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = '/talent/documentaction';
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        document_id: this.data.id,
        talent_id: this.data.talent_id
      }
      this.commonUtil.apiPost(apiPath, options).then(res => {
        if (!res.data) {
          // 削除済み
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgDeleteMaterialAlreadyError, Constant.toastShowMiliSec);
          this.dialogRef.close();
          return;
        }
        this.material = res.data.document;
        this.documentUrl = res.data.document.document_url;
        this.talentPv = res.data.pv
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec)
        this.dialogRef.close()
      });
    });
  }

  /* PDF関連 */
  pdfLoadComplete(pdfData) {
    this.documentTotalPage = pdfData.numPages;
    this.showSpinner = false;
  }

  public pdfError() {
    if (this.documentTotalPage === 0) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
    }
  }
  public prevBtn() {
    this.documentPage--;
  }
  public nextBtn() {
    this.documentPage++;
  }
  // PDF全画面ダイアログ表示
  public onPdf() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const dialogRef = this.dialog.open(PdfviewerDialogComponent, {
      width: '100%',
      maxWidth: '99vw',
      maxHeight: '99%',
      autoFocus: false,
      panelClass: 'pdf-dialog',
      data: { url: this.documentUrl }
    });
  }

}
