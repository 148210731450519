import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Constant } from '../constant';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { CommonUtil } from '../util/common-util';
import { MomentUtil } from '../util/moment-util';
import { ToastUtil } from '../util/toast-util';
import { RouterModule } from '@angular/router';
import { AnalyticsSelectDialogComponent } from '../dialog/analytics-select-dialog/analytics-select-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChartjsUtil  } from './../util/chartjs-util';
import { AnalyticsCalendarDialogComponent } from '../dialog/analytics-calendar-dialog/analytics-calendar-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-analytics-materials',
  standalone: true,
    imports: [
      CommonModule,
      MatButtonModule,
      MatSelectModule,
      FormsModule,
      ReactiveFormsModule,
      MatDividerModule,
      MatFormFieldModule,
      MatIconModule,
      MatProgressSpinnerModule,
      MatTooltipModule,
      RouterModule,
      PdfViewerModule,
    ],
  templateUrl: './analytics-materials.component.html',
  styleUrl: './analytics-materials.component.css'
})

export class AnalyticsMaterialsComponent {
  public readonly Constant = Constant;
  public showSpinner = false;
  public startDay
  public endDay
  public onlyTalent = false
  public selectedChartType = Constant.alChartTypePv
  public selectedTerm = 7
  public detailInfo
  public documentId = 0
  public documentUrl
  public pdfPage = 1;  // PDF表示ページ
  public pdfTotalPage = 0;  // PDF全ページ数
  private chartPv
  private chartGood
  private chartAge
  private chartSex
  private chartSchool
  private chartIncome

  @ViewChild('canvasPv') canvasPv: ElementRef;
  @ViewChild('canvasGood') canvasGood: ElementRef;
  @ViewChild('canvasAge') canvasAge: ElementRef;
  @ViewChild('canvasSex') canvasSex: ElementRef;
  @ViewChild('canvasSchool') canvasSchool: ElementRef;
  @ViewChild('canvasIncome') canvasIncome: ElementRef;

  constructor(
    private title: Title,
    private auth: AuthService,
    private dialog: MatDialog,
    private commonUtil: CommonUtil,
    private momentUtil: MomentUtil,
    private toastUtil: ToastUtil,
    private chartjsUtil: ChartjsUtil,
  ) {
    this.title.setTitle(Constant.pageTitleAnalyticsDocument + Constant.pageTitleCommon);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    // 初期表示は7日前
    this.onTermSelected(7)
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }


  // 表示期間変更
  onTermSelected(term) {
    if (term > 0) {
      // 今日からterm日前の日付を取得
      const today = new Date()
      const startDay = new Date(today.getTime())
      startDay.setDate(startDay.getDate() - term)
      this.startDay = startDay
      this.endDay = today

      // 詳細情報以外をクリア
      this.clearData()
      this.getReport()
    }
  }

  // 表示期間カスタム
  onTermCustom() {
    const dialogRef = this.dialog.open(AnalyticsCalendarDialogComponent, {
      width: Constant.analyticsCalendarDialogWidth,
      height: Constant.analyticsCalendarDialogHeight,
      maxHeight: Constant.analyticsCalendarDialogMaxHeight,
      autoFocus: false,
      data: {
        start: this.startDay,
        end: this.endDay
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.startDay = result.start
        this.endDay = result.end
        this.clearData()
        this.getReport()
      }
    });
  }

  // 資料変更
  onChange() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(AnalyticsSelectDialogComponent, {
      width: Constant.analyticsSelectDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      data: {
        type: Constant.alTypeMaterial,
        id: this.documentId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== '' && result >= 0) {
        this.documentId = result
        this.detailInfo = null
        this.documentUrl = null
        this.pdfPage = 1
        this.pdfTotalPage = 0;
        this.getReport()
      }
    });
  }

  // タレントALL切り替え
  onChangeOnlyTalent(flg) {
    this.onlyTalent = flg
    this.clearData()
    this.getReport()
  }

  // PVいいね切り替え
  onChangeChartType(type) {
    this.selectedChartType = type
    this.detailInfo.pv_detail = null
    this.detailInfo.good_detail = null
    this.detailInfo.talent_detail = null
    this.getReport()
  }

  // データクリア
  private clearData() {
    if (this.detailInfo) {
      this.detailInfo.pv_cnt = null
      this.detailInfo.good_cnt = null
      this.detailInfo.share_cnt = null
      this.detailInfo.good_detail = null
      this.detailInfo.pv_detail = null
      this.detailInfo.talent_detail = null
      if (this.detailInfo.detail) {
        this.detailInfo.detail.pv_list_new = null
      }

    }
  }

  // レポート取得
  private getReport() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.clearAllShowingToast()
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    this.showSpinner = true
    Auth.currentSession().then(session => {
      const apiPath = '/analytics/document/'
      const options = this.auth.createApiHeader(session)
      options['body'] = {
        document_id: this.documentId,
        start_date: this.momentUtil.getDateFormat(this.startDay),
        end_date: this.momentUtil.getDateFormat(this.endDay),
        only_talent: this.onlyTalent,
        type: this.selectedChartType
      };

      this.commonUtil.apiPost(apiPath, options).then(res => {
        this.detailInfo = res.data
        if (this.documentId > 0 && !this.detailInfo.detail) {
          // 対象コンテンツなしの場合、全選択とする
          this.documentId = 0
          // エラートースト表示
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgDeleteMaterialAlreadyError, Constant.toastShowMiliSec);
        }

        if (this.documentId > 0 && this.detailInfo.detail){
          if (!this.documentUrl) {
            this.documentUrl = this.detailInfo.detail.document_url;
          } else {
            // 詳細PVを0埋め
            this.setDetailPvList()
          }

        }

        const typeText = this.selectedChartType == Constant.alChartTypePv ? 'PV': 'いいね'
        const allCnt = this.selectedChartType == Constant.alChartTypePv ? this.detailInfo.pv_cnt : this.detailInfo.good_cnt

        if (this.detailInfo.pv_detail) {
          // PVチャート
          const pvChartData = this.createLineChartData(this.detailInfo.pv_detail)
          setTimeout(() => {
            this.chartPv = this.chartjsUtil.drawLineChart(pvChartData, this.canvasPv, this.chartPv, 'PV')
          });
        }
        if (this.detailInfo.good_detail) {
          // いいねチャート
          const goodChartData = this.createLineChartData(this.detailInfo.good_detail)
          setTimeout(() => {
            this.chartGood = this.chartjsUtil.drawLineChart(goodChartData, this.canvasGood, this.chartGood, 'いいね')
          });
        }

        // タレントの詳細データ
        if (this.onlyTalent) {
          // 年齢
          const ageData = this.createAgeChartData(this.detailInfo.talent_detail.age)
          ageData['color'] = this.chartjsUtil.colorChart9
          ageData['align'] = 'start'
          setTimeout(() => {
            this.chartAge = this.chartjsUtil.drawCircleChart(ageData, this.canvasAge, this.chartAge, typeText, allCnt)
          });

          // 性別
          const sexData = this.createCircleChartData(this.detailInfo.talent_detail.sex)
          sexData['color'] = this.chartjsUtil.colorChart3
          sexData['align'] = 'center'
          setTimeout(() => {
            this.chartSex = this.chartjsUtil.drawCircleChart(sexData, this.canvasSex, this.chartSex, typeText, allCnt)
          });

          // 最終学歴
          const schoolData = this.createCircleChartData(this.detailInfo.talent_detail.school)
          schoolData['color'] = this.chartjsUtil.colorChart9
          schoolData['align'] = 'start'
          setTimeout(() => {
            this.chartSchool = this.chartjsUtil.drawCircleChart(schoolData, this.canvasSchool, this.chartSchool, typeText, allCnt)
          });

          // 年収（チャート）
          const incomeData = this.createCircleChartData(this.detailInfo.talent_detail.income_chart)
          incomeData['color'] = this.chartjsUtil.colorChart7
          incomeData['align'] = 'start'
          setTimeout(() => {
            this.chartIncome = this.chartjsUtil.drawCircleChart(incomeData, this.canvasIncome, this.chartIncome, typeText, allCnt)
          });
          // 年収（テーブル）は0件を除き、PV順にする
          const temp = Object.keys(this.detailInfo.talent_detail.income_table).map(key => ({
            key: key,
            value: this.detailInfo.talent_detail.income_table[key]
          })).sort(function(a, b) { return b.value - a.value})
          const incomeTable = temp.filter(function(data) {
            return data.value > 0;
          });
          this.detailInfo.talent_detail.income_table = incomeTable
        }

        this.showSpinner = false

      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.clearAllShowingToast()
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });

  }

  // ラインチャート用データ作成
  createLineChartData(originData) {
    const date = new Date(this.startDay.getTime())
    const labelData = []
    const chartData = []
    while (date <= this.endDay) {
      const label = this.momentUtil.getDateFormatMonthDaySlash(date)
      labelData.push(label)
      const dateText = this.momentUtil.getDateFormat(date)
      const item = originData.filter(function(data) {
        return dateText === data.date;
      });
      if (item.length > 0) {
        chartData.push(item[0].cnt)
      } else {
        chartData.push(0)
      }
      date.setDate(date.getDate() + 1)
    }
    return {
      label: labelData,
      chart: chartData
    }
  }

  // 円グラフデータ作成（共通）
  createCircleChartData(originData) {
    const labelAry = []
    const chartAry = []
    Object.keys(originData).forEach(key => {
      labelAry.push(key)
      chartAry.push(originData[key])
    })

    return {
      label: labelAry,
      chart: chartAry
    }
  }

  // 年齢データ作成
  createAgeChartData(originData) {
    const chartAry = []
    Object.keys(originData).forEach(key => {
      chartAry.push(originData[key])
    })

    return {
      label: Constant.alChartAgeLabel,
      chart: chartAry
    }
  }

  /* PDF関連 */
  pdfLoadComplete(pdfData) {
    this.pdfTotalPage = pdfData.numPages;

    // プレビューエリアのPVを0埋め
    this.setDetailPvList()

    this.showSpinner = false;
  }

  public prevBtn() {
    this.pdfPage--;
  }
  public nextBtn() {
    this.pdfPage++;
  }
  public pdfError() {
    if (this.pdfTotalPage === 0) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
    }
  }

  // 詳細エリアのPVを0埋めする
  private setDetailPvList() {
    let page = 1
    const newPvList = []
    while (page <= this.pdfTotalPage) {
      const item = this.detailInfo.detail.pv_list.filter(function(data) {
        return page === data.page;
      });
      if (item?.length > 0) {
        newPvList.push(item[0])
      } else {
        newPvList.push({
          page: page,
          pv: 0,
          time: '-',
          talent_cnt: 0,
        })
      }
      page++
    }
    this.detailInfo.detail.pv_list_new = newPvList

  }
}
