<div class="header">
  <ng-container *ngIf="!showSpinner">
    <div class="header-left" *ngIf="material.release_status == Constant.pcReleaseStatusRelease">
      <div class="release_status released">
        公開中
      </div>
      @if (material.limited_flag == 1) {
        <img class="limited-icon" src="assets/common/lock_blue.svg">
      }
    </div>
    <div class="header-left" *ngIf="material.release_status == Constant.pcReleaseStatusStop">
      <div class="release_status stopped">
        非公開
      </div>
      @if (material.limited_flag == 1) {
        <img class="limited-icon" src="assets/common/lock_gray.svg">
      }
    </div>
  </ng-container>
  <div class="buttons">
    <button mat-raised-button type="button" (click)="onEdit()">編集</button>
    <button *ngIf="material.release_status != Constant.pcReleaseStatusRelease" mat-raised-button type="button" (click)="onRelease(true)">
      公開中にする
    </button>
    <button *ngIf="material.release_status == Constant.pcReleaseStatusRelease" mat-raised-button type="button" (click)="onRelease(false)">
      非公開にする
    </button>
  </div>
  <div mat-icon-button (click)="onClose()">
    <img src="assets/common/close_button.png" srcset="
        assets/common/close_button.png    1x,
        assets/common/close_button@2x.png 2x,
        assets/common/close_button@3x.svg 3x
      " class="close-button" />
  </div>
</div>
<mat-dialog-content>
  <div class="spinner" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container>
    <div class="content" [class.hidden]="showSpinner">
      <div class="generalfont bold">
        <img src="assets/notice/mark_gray.png" srcset="
            assets/notice/mark_gray.png    1x,
            assets/notice/mark_gray@2x.png 2x,
            assets/notice/mark_gray@3x.svg 3x
          " /> 資料
      </div>
      <div class="pdf-container">
        <div class="pdf" *ngIf="documentUrl">
          <pdf-viewer [src]="documentUrl" class="pdf-viewer" [show-all]="false" [page]="documentPage"
          [fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true" [render-text]="false"
          (after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"></pdf-viewer>
          <div class="pdf-viewer-navi" *ngIf="documentTotalPage>0">
            <div class="leftbtn">
              <button mat-flat-button (click)="prevBtn()" [disabled]="documentPage==1">＜</button>
              <button mat-flat-button (click)="nextBtn()" [disabled]="documentPage==documentTotalPage">＞</button>
            </div>
            <span>{{ documentPage }} / {{ documentTotalPage }}</span>
            <div class="rightbtn">
              <button mat-icon-button (click)="onPdf()"><mat-icon>fullscreen</mat-icon></button>
            </div>
          </div>
        </div>
        <div class="pdf" *ngIf="!documentUrl">
          <div class="no-image-frame">
            <div class="noimage">
              No Image
            </div>
          </div>
          <div class="pdf-viewer-navi">
            <div class="leftbtn">
              <button mat-flat-button disabled>＜</button>
              <button mat-flat-button disabled>＞</button>
            </div>
          </div>
        </div>
      </div>

      @if (!showSpinner) {
        <div class="container">
          <div class="generalfont bold">
            <img src="assets/notice/mark_gray.png" srcset="
                assets/notice/mark_gray.png    1x,
                assets/notice/mark_gray@2x.png 2x,
                assets/notice/mark_gray@3x.svg 3x
              " /> タイトル
          </div>
          <div class="value pre-line">{{ material.title }}</div>
        </div>

        <div class="container">
          <div class="generalfont bold">
            <img src="assets/notice/mark_gray.png"
          /> PICK UP表示設定
          </div>
          <div class="value">
            @if (material.top_flag != 1) {
              なし
            } @else {
              PICK UPする
            }
          </div>
        </div>

        <div class="container">
          <div class="generalfont bold">
            <img src="assets/notice/mark_gray.png"
          /> 一覧ページ表示設定
          </div>
          <div class="value">
            @if (material.limited_flag != 1) {
              表示する
            } @else {
              表示しない
            }
          </div>
        </div>
      }

    </div>
    @if (!showSpinner) {
      <mat-divider></mat-divider>
      <div class="flex actionarea">
        <div class="generalfont">
          <span>資料内PV</span>
          <span class="actionvalue">{{ material. pv_cnt }}</span>
        </div>
        <div class="generalfont">
          <span>シェア</span>
          <span class="actionvalue">{{ material.share_cnt }}</span>
        </div>
        <div class="generalfont">
          <span>いいね</span>
          <span class="actionvalue">{{ material.good_cnt }}</span>
        </div>
        <div class="generalfont">
          <span>読了率</span>
          <span class="actionvalue">{{ material.reading_ratio }}%</span>
        </div>
      </div>
      <div class="footer">
        <div class="flex generalfont">
          <div class="labelarea">初公開</div>
          <div class="byarea">{{ material.created_by }}</div>
          <div class="datearea">{{ material.created_at }}</div>
        </div>
        <div class="flex generalfont">
          <div class="labelarea">最終編集</div>
          <div class="byarea">{{ material.updated_by }}</div>
          <div class="datearea">{{ material.updated_at }}</div>
        </div>
      </div>
    }
  </ng-container>
</mat-dialog-content>
