<div *ngIf="showSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<div class="header" *ngIf="!showSpinner && result">
  <div class="header-left">
    <div class="header-title">
      {{ title }}
    </div>
    <div class="desc" *ngIf="selectedType==Constant.alTypeContactAll || selectedType==Constant.alTypeContactUse">
      上段は「全体数」で、タレントプール解除済のタレントに付与した数を含みます。<br>
      下段の（　）内は、タレントプール登録中のタレントに付与している接点・経路タグの数です。
    </div>
    <div class="desc" *ngIf="(selectedType>=Constant.alTypeContentsViewAll && selectedType<Constant.alTypeLoginpageviewAll)
      || (selectedType>=Constant.alTypeContentsShareAll && selectedType<=Constant.alTypeJobofferShareUse)">
      上段は「全体数」で、タレントプール未登録/解除済のタレントによるアクションを含みます。<br>
      下段の（　）内は、タレントプール登録中のタレントによるアクション数です。
    </div>
    <button mat-button (click)="onCopy()" class="copy-button">表をコピー</button>
  </div>
  <div mat-icon-button mat-dialog-close>
    <img src="assets/common/close_button.png" srcset="
        assets/common/close_button.png    1x,
        assets/common/close_button@2x.png 2x,
        assets/common/close_button@3x.svg 3x
      " class="close-button" />
  </div>
</div>
<mat-dialog-content>
  <ng-container *ngIf="!showSpinner && result">
    <div class="result-scroll" (scroll)="onWindowScroll($event)" #resultScroll>
      <ng-container *ngIf="selectedType==Constant.alTypeTalent">
        <!-- タレント増加数 -->
        <table class="result-table" id="result-table">
          <ng-container *ngTemplateOutlet="tableTitle; context:{ datas: result[0] }"></ng-container>
          <tr>
            <td class="title">タレント増加数</td>
            <ng-container *ngFor="let data of result[0]; index as idx">
              <td><a (click)="onToList(0, result[0].length, idx, data.grouping_date)" class="link-text">{{ data.cnt }}</a></td>
            </ng-container>
            <td><a (click)="onToList(0, result[0].length)" class="link-text">{{ cntSum(result[0].length - 1, result[0]) }}</a></td>
          </tr>
          <tr>
            <td class="title">− 自己登録のタレント</td>
            <ng-container *ngFor="let data of result[1]; index as idx">
              <td><a (click)="onToList(0, result[1].length, idx, data.grouping_date, 0)" class="link-text">{{ data.cnt }}</a></td>
            </ng-container>
            <td><a (click)="onToList(0, result[1].length, null, null, 0)" class="link-text">{{ cntSum(result[1].length - 1, result[1]) }}</a></td>
          </tr>
          <tr>
            <td class="title">− 手動/CSV取込のタレント</td>
            <ng-container *ngFor="let data of result[2]; index as idx">
              <td><a (click)="onToList(0, result[2].length, idx, data.grouping_date, 1)" class="link-text">{{ data.cnt }}</a></td>
            </ng-container>
            <td><a (click)="onToList(0, result[2].length, null, null, 1)" class="link-text">{{ cntSum(result[2].length - 1, result[2]) }}</a></td>
          </tr>
          <tr>
            <td class="title">累計</td>
            <ng-container *ngFor="let data of result[0]; index as idx">
              <td>{{ cntSum(idx, result[0]) }}</td>
            </ng-container>
            <td>-</td>
          </tr>
        </table>
      </ng-container>

      <!-- エントリーページビュー、ログインフォーム -->
      <ng-container *ngIf="selectedType==Constant.alTypePageviewAll || selectedType==Constant.alTypePageviewUse
      || selectedType==Constant.alTypeLoginpageviewAll || selectedType==Constant.alTypeLoginpageviewUse">
        <table class="result-table">
          <ng-container *ngTemplateOutlet="tableTitle; context:{ datas: result[0] }"></ng-container>
          <tr>
            <td class="title" *ngIf="selectedType==Constant.alTypePageviewAll || selectedType==Constant.alTypePageviewUse">デフォルトURLのエントリーページ</td>
            <td class="title" *ngIf="selectedType==Constant.alTypeLoginpageviewAll || selectedType==Constant.alTypeLoginpageviewUse">デフォルトURLのログインフォーム</td>
            <ng-container *ngFor="let data of result[0]; index as idx">
              <td>{{ data.cnt }}</td>
            </ng-container>
            <td>{{ cntSum(result[0].length - 1, result[0]) }}</td>
          </tr>
          <ng-container *ngFor="let datas of result[1]">
            <tr [class.gray]="datas.value[0].inuse==0">
              <td class="title">{{ datas.value[0].title }}
                <ng-container *ngIf="datas.value[0].inuse==0">（停止中）</ng-container>
              </td>
              <ng-container *ngFor="let data of datas.value; index as idx">
                <td>{{ data.cnt }}</td>
              </ng-container>
              <td>{{ cntSum(datas.value.length-1, datas.value) }}</td>
            </tr>
          </ng-container>
          <tr>
            <td class="title">合計</td>
            <ng-container *ngFor="let data of result[0]; index as idx">
              <td>{{ cntSumDate(idx, result[1], result[0]) }}</td>
            </ng-container>
            <td>{{ cntSumAll(result[1], result[0]) }}</td>
          </tr>
        </table>
      </ng-container>

      <!-- 接点・経路タグ・PRコンテンツ・求人情報 -->
      <ng-container *ngIf="selectedType!=Constant.alTypeTalent && selectedType!=Constant.alTypePageviewAll && selectedType!=Constant.alTypePageviewUse
      && selectedType!=Constant.alTypeLoginpageviewAll && selectedType!=Constant.alTypeLoginpageviewUse">
        <table class="result-table">
          <ng-container *ngTemplateOutlet="tableTitle; context:{ datas: result[0][0].value }"></ng-container>
          <ng-container *ngFor="let datas of result[0]; index as idx">
            <tr [class.gray]="datas.value[0].inuse==0 || datas.value[0].release_status==2">
              <td class="title">{{ datas.value[0].title }}
                <ng-container *ngIf="datas.value[0].inuse==0 && (selectedType==Constant.alTypeContactAll || selectedType==Constant.alTypeContactUse)">（停止中）</ng-container>
                <ng-container *ngIf="datas.value[0].release_status==2 && (selectedType==Constant.alTypeContentsViewAll || selectedType==Constant.alTypeContentsShareAll || selectedType==Constant.alTypeContentsGoodAll)">（非公開）</ng-container>
                <ng-container *ngIf="datas.value[0].release_status==2 && (selectedType==Constant.alTypeJobofferViewAll || selectedType==Constant.alTypeJobofferShareAll
                 || selectedType==Constant.alTypeJobofferMindAll || selectedType==Constant.alTypeJobofferApplyAll)">（休止中）
                </ng-container>
              </td>
              <ng-container *ngFor="let data of datas.value; index as idx2">
                <td>{{ data.cnt }}<br>
                  <span class="kakko">(</span><a (click)="onToList(data.id, datas.value.length, idx2, data.grouping_date)"
                  [class.link-text]="datas.value[0].inuse!=0 && datas.value[0].release_status!=2"
                  [class.link-gray]="datas.value[0].inuse==0 || datas.value[0].release_status==2">{{ result[1][idx].value[idx2].cnt }}</a><span class="kakko">)</span>
                </td>
              </ng-container>
              <td>{{ cntSum(datas.value.length-1, datas.value) }}<br>
                <span class="kakko">(</span><a (click)="onToList(datas.value[0].id, datas.value.length)"
                [class.link-text]="datas.value[0].inuse!=0 && datas.value[0].release_status!=2"
                [class.link-gray]="datas.value[0].inuse==0 || datas.value[0].release_status==2">{{ cntSum(datas.value.length-1, result[1][idx].value) }}</a><span class="kakko">)</span>
              </td>
            </tr>
          </ng-container>
          <tr>
            <td class="title">合計</td>
            <ng-container *ngFor="let data of result[0][0].value; index as idx">
              <td>{{ cntSumDate(idx, result[0]) }}<br>
                <span class="kakko">(</span><a (click)="onToList(0, result[0][0].value.length, idx, data.grouping_date)" class="link-text">{{ cntSumDate(idx, result[1]) }}</a><span class="kakko">)</span>
              </td>
            </ng-container>
            <td>{{ cntSumAll(result[0]) }}<br>
              <span class="kakko">(</span><a (click)="onToList(0)" class="link-text">{{ cntSumAll(result[1]) }}</a><span class="kakko">)</span>
            </td>
          </tr>
        </table>
      </ng-container>

      <!-- テーブルの日付部分 -->
      <ng-template #tableTitle let-datas="datas">
        <tr>
          <th class="title date"></th>
          <ng-container *ngFor="let data of datas; index as idx; first as first; last as last;">
            <th class="date"
            [class.month]="selectedUnit == Constant.alTermUnitMonthId"
            [class.year]="selectedUnit == Constant.alTermUnitYearId"
            [class.month-long]="datas.length==1 && selectedUnit == Constant.alTermUnitMonthId"
            [class.year-long]="datas.length==1 && selectedUnit == Constant.alTermUnitYearId">
              <div class="date-text">
                {{ data.date }}
                <div *ngIf="selectedUnit == Constant.alTermUnitMonthId">
                  <ng-container *ngIf="datas.length==1">{{ this.resultStart }}〜{{ this.resultEnd }}</ng-container>
                  <ng-container *ngIf="datas.length>1 && first">{{ this.resultStart }}〜</ng-container>
                  <ng-container *ngIf="datas.length>1 && last">〜{{ this.resultEnd }}</ng-container>
                </div>
                <div *ngIf="selectedUnit == Constant.alTermUnitYearId">
                  <ng-container *ngIf="datas.length==1">{{ this.resultStart }}〜{{ this.resultEnd }}</ng-container>
                  <ng-container *ngIf="datas.length>1 && first">{{ this.resultStart }}〜</ng-container>
                  <ng-container *ngIf="datas.length>1 && last">〜{{ this.resultEnd }}</ng-container>
                </div>
              </div>
            </th>
          </ng-container>
          <th class="date"
            [class.month]="selectedUnit == Constant.alTermUnitMonthId"
            [class.year]="selectedUnit == Constant.alTermUnitYearId"
            [class.month-long]="datas.length==1 && selectedUnit == Constant.alTermUnitMonthId"
            [class.year-long]="datas.length==1 && selectedUnit == Constant.alTermUnitYearId">
            <div class="date-text">累計</div>
          </th>
        </tr>
      </ng-template>
    </div>
  </ng-container>
</mat-dialog-content>
